import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { IconButton, InputAdornment } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { Formik, Field, Form } from 'formik'
import { TextField } from 'formik-material-ui'
import useViewport from '../hooks/useViewport'
import { makeStyles } from '@material-ui/core/styles'
import * as Yup from 'yup'
import { resetPassword, login } from '../store/actions/account-action'
import routeConstants from '../constants/route-constants'
import customization from '../utils/customization-utils'
import { setThemeLocale } from '../store/actions/ui-actions'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  demo1: {
    // backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
  },
  inputField: {
    marginBottom: 32,
  },
  input: {
    backgroundColor: 'white',
    border: '1px solid #C2C5C7',
    borderRadius: 8,
    padding: '8px 8px',
    [theme.breakpoints.down('md')]: {
      padding: '5px 5px',
    },
  },
  label: {
    color: 'black',
  },
  menuPaper: {
    maxHeight: 500,
  },
  link: {
    color: '#0D3082',
    marginTop: 16,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
  },
  submitButton: {
    whiteSpace: 'nowrap',
    minWidth: '100%',
    // textTransform: "none",
    marginTop: 9,
    marginBottom: 16,
    borderRadius: 8,
    padding: '8px 8px',
    [theme.breakpoints.down('md')]: {
      padding: '5px',
      fontSize: 14,
    },
    height: 48,
    fontSize: 16,
  },
}))

const ResetPassword = () => {
  const { width } = useViewport()
  const dispatch = useDispatch()
  const classes = useStyles()
  // const { token } = useParams()

  const params = new URLSearchParams(window.location.search)
  const unique_id = params.get('unique_id')

  const selectedLocale = useSelector((state) => state.ui.locale)
  // const userLocale =
  //   navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language

  // useEffect(() => {
  //   if (/^de\b/.test(userLocale)) {
  //     dispatch(setThemeLocale('DE'))
  //   } else if (/^es\b/.test(userLocale)) {
  //     dispatch(setThemeLocale('ES'))
  //   } else if (/^fr\b/.test(userLocale)) {
  //     dispatch(setThemeLocale('FR'))
  //   } else {
  //     dispatch(setThemeLocale('EN'))
  //   }
  // }, [])

  const c = customization(selectedLocale)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)

  const validationSchema = Yup.object({
    new_password: Yup.string()
      .required(c.ValidationMessages_NewPasswordRequired_text)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        c.ValidationMessages_PasswordCriteria_text
      ),
    confirm_new_password: Yup.string()
      .required(c.ValidationMessages_ConfirmNewPasswordRequired_text)
      .oneOf([Yup.ref('new_password'), null], c.ValidationMessages_PasswordDoNotMatch_text),

    verification_code: Yup.string().required(c.ValidationMessages_verificationCodeRequired_text),
  })

  const handleToggleNewPassword = () => setShowNewPassword((showNewPassword) => !showNewPassword)
  const handleToggleConfirmNewPassword = () =>
    setShowConfirmNewPassword((showConfirmNewPassword) => !showConfirmNewPassword)

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="column"
        >
          <Box style={{ maxWidth: 500 }}>
            <Typography
              variant="h4"
              style={{ fontWeight: 500, marginBottom: 46, marginTop: 52, textAlign: 'center' }}
            >
              {c.ResetPassword_titleResetPassword_text}
            </Typography>
            <Formik
              enableReinitialize={true}
              initialValues={{
                unique_id: unique_id ? unique_id : '',
                password: '',
                passwordConfirmation: '',
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)
                dispatch(
                  resetPassword({
                    unique_id: values.unique_id,
                    verification_code: values.verification_code,
                    new_password: values.new_password,
                    selectedLocale,
                    onSuccessCallback: () =>
                      dispatch(login(values.unique_id, values.new_password, '', selectedLocale)),
                    onErrorCallback: () => setSubmitting(false),
                  })
                )
              }}
              validationSchema={validationSchema}
            >
              {({ errors, touched, isSubmitting, values, setFieldValue, isValid, dirty }) => (
                <Form>
                  <Field
                    component={TextField}
                    className={classes.inputField}
                    label={c.ForgotPassword_labelUsername_text}
                    name="unique_id"
                    fullWidth
                    disabled={true}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Field
                    component={TextField}
                    className={classes.inputField}
                    label={c.ResetPassword_labelNewPassword_text}
                    name="new_password"
                    type={showNewPassword ? 'text' : 'password'}
                    fullWidth
                    error={errors.new_password && touched.new_password}
                    helperText={
                      errors.new_password && touched.new_password ? errors.new_password : null
                    }
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleToggleNewPassword}>
                            {showNewPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Field
                    component={TextField}
                    className={classes.inputField}
                    label={c.ResetPassword_labelConfirmNewPassword_text}
                    type={showConfirmNewPassword ? 'text' : 'password'}
                    name="confirm_new_password"
                    fullWidth
                    error={errors.confirm_new_password && touched.confirm_new_password}
                    helperText={
                      errors.confirm_new_password && touched.confirm_new_password
                        ? errors.confirm_new_password
                        : null
                    }
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleToggleConfirmNewPassword}>
                            {showConfirmNewPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Alert sx={{ mb: 1 }} severity="info">
                    {c.SuccessMessages_ForgetPasswordLink_text}
                  </Alert>

                  <Field
                    component={TextField}
                    className={classes.inputField}
                    label={c.ResetPassword_labelVerificationCode_text}
                    name="verification_code"
                    fullWidth
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Button
                    type="submit"
                    disabled={
                      isSubmitting ||
                      !values.new_password ||
                      !values.confirm_new_password ||
                      !values.verification_code
                    }
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}
                  >
                    {c.ResetPassword_labelSubmitButton_text}
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </div>
    </div>
  )
}

export default ResetPassword
