//DE
const main = {
    ForgotPassword_titleForgotPassword_display: true,
    ForgotPassword_titleForgotPassword_text: 'Passwort vergessen?',
    ForgotPassword_labelProcessInfo_display: true,
    ForgotPassword_labelProcessInfo_text: "Geben Sie Ihren registrierten Benutzernamen ein und wir senden Ihnen einen Link, um Ihr Passwort zurückzusetzen.",
    ForgotPassword_labelUsername_display: true,
    ForgotPassword_labelUsername_text: 'Benutzername',
    ForgotPassword_labelSubmitButton_display: true,
    ForgotPassword_labelSubmitButton_text: 'Absenden',
    ForgotPassword_labelBackToLoginLink_display: true,
    ForgotPassword_labelBackToLoginLink_text: 'Zurück zu Login',
    ForgotPassword_validationMessageUsernameRequired_display: true,
    ForgotPassword_validationMessageUsernameRequired_text: 'Benutzername ist erforderlich',
    ForgotPassword_validationMessageEmailInvalid_display: true,
    ForgotPassword_validationMessageEmailInvalid_text: 'Ungültiges E-Mail-Adressformat',
    ForgotPassword_errorMessageUsernameNotExist_display: true,
    ForgotPassword_errorMessageUsernameNotExist_text: 'Der Benutzername existiert nicht in der Datenbank. Bitte geben Sie den richtigen registrierten Benutzernamen ein.',
    ForgotPassword_errorMessageProcessFailed_display: true,
    ForgotPassword_errorMessageProcessFailed_text: 'Prozess fehlgeschlagen. Bitte versuchen Sie es erneut!',
    ForgotPassword_errorMessageNetworkIssues_display: true,
    ForgotPassword_errorMessageNetworkIssues_text: 'Bitte überprüfen Sie Ihr Netzwerk!',
    ForgotPassword_errorMessageServerIssues_display: true,
    ForgotPassword_errorMessageServerIssues_text: 'Sie haben keine Antwort erhalten! Bitte versuchen Sie es erneut.',
}

export default {
    ...main
}
