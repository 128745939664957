//DE
const main = {
  Eula_title_display: true,
  Eula_title_text: 'ENDBENUTZER-LIZENZVEREINBARUNG',
  Eula_labelAgreeButton_display: true,
  Eula_labelAgreeButton_text: 'Akzeptieren',
  Eula_display: true,
  Eula_text: `
 <!DOCTYPE html>
<html>
<head>
	<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
	<title></title>
	<meta name="generator" content="LibreOffice 7.4.4.2 (Linux)"/>
	<meta name="author" content="Bilgin Berksoy"/>
	<meta name="created" content="2022-03-04T12:10:00"/>
	<meta name="changed" content="2023-08-22T18:47:03.449862845"/>
	<meta name="AppVersion" content="16.0000"/>
	<meta name="ComplianceAssetId" content=""/>
	<meta name="ContentTypeId" content="0x010100476224809ABE224A900BF4DFEFD0895F"/>
	<meta name="Order" content="272000"/>
	<meta name="SharedWithUsers" content="17;#Wypior Sebastian;#13;#Rouais Nicolas;#107;#Jung Alexander;#12;#Bilgin Berksoy"/>
	<meta name="TemplateUrl" content=""/>
	<meta name="TriggerFlowInfo" content=""/>
	<meta name="_ExtendedDescription" content=""/>
	<meta name="xd_ProgID" content=""/>
	<meta name="xd_Signature" content="false"/>
	<style type="text/css">
		@page { size: 8.27in 11.69in; margin-left: 0.98in; margin-right: 0.98in; margin-top: 0.98in; margin-bottom: 0.79in }
		p { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }
		p.western { font-family: "Calibri", serif }
		a:link { color: #000000; text-decoration: underline }
		a.western:link { font-family: "Arial", serif; font-style: italic }
		a.cjk:link { font-family: "Arial"; font-style: italic }
		a.ctl:link { font-family: "Arial" }
		a:visited { color: #800000; text-decoration: underline }
	</style>
</head>
<body lang="de-DE" link="#000000" vlink="#800000" dir="ltr"><p align="center" style="line-height: 115%; margin-bottom: 0.08in">

</p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif">DURCH
DIE VERWENDUNG DIESER SOFTWARE UND IHRER AKTUALISIERUNGEN
(ZUSAMMENGEFASST: „SOFTWARE“) BESTÄTIGT DER
ENDBENUTZER-LIZENZNEHMER, EINE KOPIE DIESER LIZENZVEREINBARUNG
ERHALTEN ZU HABEN UND DEN NACHSTEHENDEN BEDINGUNGEN DIESER LIZENZ
ZUZUSTIMMEN. FALLS DER ENDBENUTZER-LIZENZNEHMER DEN BEDINGUNGEN
DIESER VEREINBARUNG NICHT ZUSTIMMT, IST DIE NUTZUNG DIESER SOFTWARE
VERBOTEN. DIE BEDINGUNGEN DIESER LIZENZ ENTHALTEN INSBESONDERE
HAFTUNGSBESCHRÄNKUNGEN IN ARTIKEL&nbsp;11.</font></font></p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif">Diese
Endbenutzer-Lizenzvereinbarung (EULA) tritt in Kraft, sobald der
Endbenutzer-Lizenznehmer ihren Bedingungen entsprechend dem in dieser
EULA beschriebenen Verfahren zustimmt. Sie ist eine rechtsgültige
Vereinbarung zwischen:</font></font></p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif">KroniKare
Pte. Ltd. mit Sitz an der Adresse 75 Ayer Rajah Crescent #03-12,
Singapur 139953, (im weiteren Verlauf „Lizenzgeber“ genannt) </font></font>
</p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif">und</font></font></p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif">Organisation
(im weiteren Verlauf „Endbenutzer-Lizenznehmer“ genannt). </font></font>
</p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif">Der
Lizenzgeber gewährt dem Endbenutzer-Lizenznehmer hiermit ein nicht
ausschließliches, nicht übertragbares, beschränktes Nutzungsrecht
für: </font></font>
</p>
<ul>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">die Software des
	Vintens™ Geräts für digitales Wundmanagement <b>(„Wundscanner“)</b>,
	die mit der Software bereitgestellten Daten und die
	zugehörige/zusätzliche Software, bestehend aus Geräte-App,
	Dashboard und Back-End-Software („Software“) sowie</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">die Dokumente</font></font></p>
</ul>
<p align="justify" style="line-height: 115%; margin-bottom: 0in"><font color="#000000"><font face="Arial, serif">für
die in dieser Lizenz („Endbenutzerlizenz“) ausdrücklich
genannten Zwecke. Alle Rechte, die in dieser Lizenz nicht
ausdrücklich gewährt werden, bleiben dem Lizenzgeber vorbehalten.
Der Lizenzgeber lizenziert das Recht zur Nutzung der Software und der
Dokumente auf der Grundlage dieser Lizenz an den
Endbenutzer-Lizenznehmer. Der Lizenzgeber verkauft weder die Software
noch die Dokumente an den Endbenutzer-Lizenznehmer. </font></font>
</p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif"><b>PRÄAMBEL</b></font></font></p>
<ol type="A">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Der Lizenzgeber ist
	Hersteller des Wundscanners und der Software sowie Entwickler der
	Dokumente; der Lizenzgeber hat mit seinen Vertriebshändlern einen
	Leasingvertrag über die Bereitstellung des Wundscanners an den
	Endbenutzer-Lizenznehmer abgeschlossen. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Der
	Endbenutzer-Lizenznehmer hat mit den Vertriebshändlern des
	Lizenzgebers einen Unterleasingvertrag über das Leasing des
	Wundscanners („Unterleasingvertrag“) abgeschlossen und möchte
	die Software und die Dokumente für die Verwendung des Wundscanners
	entsprechend dem vorgesehenen Zweck nutzen.</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Der
	Endbenutzer-Lizenznehmer möchte den Wundscanner, die Software und
	die Dokumente im Rahmen seiner Geschäftstätigkeit zur Versorgung
	seiner Patienten verwenden und die Software innerhalb seiner
	Organisation bestimmten ausgewählten Benutzern („autorisierte
	Benutzer“) zur Verfügung stellen. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Vor dem Hintergrund
	der vorstehenden Ausführungen willigt der Endbenutzer-Lizenznehmer
	ein, die Software und die zugehörigen Dienstleistungen gemäß den
	Bedingungen dieser EULA zu verwenden. </font></font>
	</p>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in; page-break-before: always">
<font color="#000000"><font face="Arial, serif">Zwischen den Parteien
wurde Folgendes vereinbart: </font></font>
</p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif"><b>1.	Definitionen</b></font></font></p>
<p class="western" align="justify" style="line-height: 115%; orphans: 0; widows: 0; margin-left: 0.49in; text-indent: -0.49in; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif">1,1.</font>	<font face="Arial, serif">Im
Rahmen dieser EULA besitzen die nachstehenden Begriffe sowohl im
Singular als auch im Plural die jeweils angegebene Bedeutung mit
zugehöriger Definition:</font></font></p>
<ol type="a">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">„<font face="Arial, serif"><b>Administrator</b>“:
	d<span style="background: #ffffff">ie Person, die der Organisation
	des </span>Endbenutzer<span style="background: #ffffff">-Lizenznehmers
	angehört und der vom </span>Endbenutzer<span style="background: #ffffff">-Lizenznehmer
	die Verantwortung für die Wartung, die Konfiguration und den
	zuverlässigen Betrieb der Software übertragen wurde. Sie wurde zum
	Vertreter des </span>Endbenutzer<span style="background: #ffffff">-Lizenznehmers
	gemäß den Gesetzen des Landes, in dem der </span>Endbenutzer<span style="background: #ffffff">-Lizenznehmer
	ansässig ist, ernannt, um diese EULA im Namen des
	</span>Endbenutzer<span style="background: #ffffff">-Lizenznehmers
	zu akzeptieren. </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">„<font face="Arial, serif"><b>Autorisiertes
	Gerät</b>“: der Wundscanner. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">„<font face="Arial, serif"><b>Autorisierte
	Benutzer</b>“: alle Personen, die zur Organisation des
	Endbenutzer-Lizenznehmers gehören (d.&nbsp;h. die direkten
	Mitarbeiter des Endbenutzer-Lizenznehmers oder Berater, die eine
	Geschäftsbeziehung mit dem Endbenutzer-Lizenznehmer unterhalten),
	die aufgrund einer oder mehrerer persönlicher und nicht
	übertragbarer Lizenzen, die vom Endbenutzer-Lizenznehmer erworben
	wurden, berechtigt sind, auf die Software zuzugreifen und sie zu
	nutzen. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">„<font face="Arial, serif">Dokumente“:
	alle gedruckten Materialien und elektronischen Dokumente, die sich
	auf den Wundscanner und die Software beziehen, u.&nbsp;a. die
	Gebrauchsanweisung sowie alle Vereinbarungen zur Verwendung des
	Wundscanners. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">„<font face="Arial, serif"><b>Vertriebshändler</b>“:<span style="background: #ffffff">
	die juristischen Personen in der Lieferkette, bei denen es sich
	nicht um den Hersteller oder den Importeur handelt, die dem
	Endbenutzer-Lizenznehmer den Wundscanner zur Verfügung stellen. </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">„<font face="Arial, serif">Datum des
	Inkrafttretens“: das Datum, das im Kopf dieser EULA angegeben ist.</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">„<font face="Arial, serif"><b>Endbenutzer-Lizenznehmer</b>“:
	die in der EULA genannte Vertragspartei, an die der Lizenzgeber die
	Software ausschließlich für den internen Gebrauch (und nicht für
	Weitervertrieb, Remarketing oder Timesharing) lizenziert und die mit
	den Vertriebshändlern des Lizenzgebers einen Unterleasingvertrag
	für die Verwendung des Wundscanners abgeschlossen hat. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">„<font face="Arial, serif"><b>Vorfall</b>“:
	jede Fehlfunktion oder Verschlechterung der Eigenschaften oder der
	Leistung eines auf dem Markt erhältlichen Geräts, einschließlich
	Bedienungsfehler aufgrund ergonomischer Merkmale, sowie jegliche
	Unzulänglichkeit der vom Hersteller bereitgestellten Informationen
	und jede unerwünschte Nebenwirkung.</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">„<font face="Arial, serif"><b>Gebrauchsanweisung</b>“:
	die vom Hersteller bereitgestellten Informationen, die den Benutzer
	über den beabsichtigten Zweck und den ordnungsgemäßen Gebrauch
	des Geräts sowie über alle zu treffenden Vorsichtsmaßnahmen
	informieren.<span style="background: #ffffff"> </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">„<font face="Arial, serif"><b>Geistige
	Eigentumsrechte</b>“: Patente, Marken, Gebrauchsmuster,
	Geschäftsgeheimnisse, Handelsnamen, Dienstleistungsmarken,
	Domainnamen, Urheberrechte, Rechte an Datenbanken (einschließlich
	der Rechte, die Extraktion oder Wiederverwendung von Informationen
	aus einer Datenbank zu verhindern), Geschmacksmusterrechte,
	Topografieschutzrechte, Softwaredesign, Benutzeroberfläche (UI),
	UI-Design, Branding-Rechte nach Datenexklusivitätsgesetzen,
	Erweiterung dieser Rechte sowie sämtliche Rechte oder Schutzformen
	ähnlicher Art oder mit gleicher oder ähnlicher Wirkung dieser
	Rechte, die unter Umständen irgendwo auf der Welt bestehen, ob
	eingetragen oder nicht, sowie Anträge bzw. Anmeldungen und das
	Antrags- und Anmeldungsrecht für das vorstehende eingetragene
	Eigentum und die vorstehenden eingetragenen Rechte und ähnliche
	oder entsprechende Rechte irgendwo auf der Welt. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">„<font face="Arial, serif"><b>Partei</b>“:
	jede der Parteien dieser EULA. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">„<font face="Arial, serif">Parteien“:
	beide Parteien dieser Vereinbarung zusammengefasst. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">„<font face="Arial, serif"><b>Personenbezogene
	Daten</b>“: sämtliche Informationen, die sich auf eine
	identifizierte oder identifizierbare natürliche Person beziehen;
	als identifizierbar wird eine natürliche Person angesehen, die
	direkt oder indirekt, insbesondere mittels Zuordnung zu einer
	Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu
	einer Online-Kennung oder zu einem oder mehreren besonderen
	Merkmalen, die Ausdruck der physischen, physiologischen,
	genetischen, psychischen, wirtschaftlichen, kulturellen oder
	sozialen Identität dieser natürlichen Person sind, identifiziert
	werden kann.</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">„<font face="Arial, serif"><b>Release</b>“:
	die Änderungen, Aktualisierungen und/oder Upgrades der Software,
	die der Lizenzgeber dem Endbenutzer-Lizenznehmer im Allgemeinen zur
	Verfügung stellt.</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
	<font color="#000000">„<font face="Arial, serif"><b>Gebiet</b>“:
	das Land, in dem der Endbenutzer-Lizenznehmer ansässig ist.</font></font></p>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif"><b>2.	GEWÄHRUNG
UND GELTUNGSBEREICH DER LIZENZ</b></font></font></p>
<ol start="2">
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a944711_1"></a>
		<font color="#000000"><font face="Arial, serif">Der Lizenzgeber
		gewährt dem Endbenutzer-Lizenznehmer während der Laufzeit des
		Unterleasingvertrags für den Wundscanner (außer bei vorzeitiger
		Kündigung gemäß den hierin enthaltenen Bedingungen) und für das
		Gebiet eine nicht ausschließliche, nicht übertragbare,
		beschränkte Lizenz zur Nutzung der Software und der Dokumente
		gemäß den Bedingungen dieser Lizenz. Dabei ist es unerheblich, ob
		dieser Vertrag mit dem Lizenzgeber oder einem Vertriebshändler
		abgeschlossen wird. Die im Rahmen der EULA gewährten Rechte werden
		vom Endbenutzer-Lizenznehmer ab dem Datum des Inkrafttretens
		ausgeübt.</font></font></p>
	</ol>
</ol>
<ol>
	<ol type="a">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Die EULA gilt ab
		dem Datum des Inkrafttretens als zwischen den Parteien rechtsgültig
		ausgefertigt und wirksam. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Der
		Endbenutzer-Lizenznehmer und seine autorisierten Mitarbeiter
		(„autorisierte Benutzer“) sind gemäß den Bedingungen dieser
		Lizenz berechtigt:</font></font></p>
		<ol type="i">
			<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a298333_1"></a>
			<font color="#000000"><font face="Arial, serif">die Software nur
			für ihre internen Geschäftszwecke in Übereinstimmung mit den
			Dokumenten und dem beabsichtigten Zweck des Wundscanners
			herunterzuladen, zu installieren und zu verwenden und die Software
			entsprechend der Gebrauchsanweisung für den Wundscanner für die
			vereinbarte Anzahl von autorisierten Benutzern, die im
			Unterleasingvertrag angegeben ist, zu nutzen,</font></font></p>
			<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
			<font color="#000000"><font face="Arial, serif">kostenlosen
			zusätzlichen Softwarecode oder Updates der Software mit „Patches“
			und Fehlerkorrekturen zu erhalten und zu verwenden; dies wird zu
			gegebener Zeit vom Lizenzgeber bereitgestellt; und</font></font></p>
			<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a397482_1"></a>
			<font color="#000000"><font face="Arial, serif">jegliche Dokumente
			zur Unterstützung der unter dieser Lizenz erlaubten Nutzung, die
			in Artikel&nbsp;2 angegeben ist, zu verwenden und Kopien der
			Dokumente anzufertigen, die für die rechtmäßige Nutzung
			vernünftigerweise erforderlich sind.</font></font></p>
		</ol>
	</ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Unbeschadet der
	anderen Artikel dieser EULA gilt: Falls zu irgendeinem Zeitpunkt
	während der Laufzeit dieser EULA die Anzahl der Benutzer der
	Software höher ist als die Anzahl der autorisierten Benutzer, die
	dem Endbenutzer-Lizenznehmer gemäß Artikel&nbsp;2.1(b)(i) oben
	gewährt wurde, ist der Lizenzgeber berechtigt, diese EULA mit
	sofortiger Wirkung zu kündigen. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
	<font color="#000000"><font face="Arial, serif">Folgendes ist
	ausdrücklich vom Geltungsbereich dieser EULA ausgenommen: (i)
	Lizenzen für Basissoftware von Drittanbietern (z.&nbsp;B.
	Betriebssysteme, Microsoft® Excel®, Adobe Reader® usw.), (ii)
	Software, die dem Endbenutzer-Lizenznehmer über Dritte
	(Integrationspartner) zur Integration der Software in die Systeme
	des Endbenutzer-Lizenznehmers und/oder in das elektronische
	Patientendatensystem bereitgestellt wird, (iii) alles, was der
	Lizenzgeber nicht ausdrücklich in dieser EULA aufführt. </font></font>
	</p>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><br/>
<br/>

</p>
<ol start="2">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>EIGENTUM</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">Der
		Endbenutzer-Lizenznehmer stimmt zu, dass der Lizenzgeber sämtliche
		Rechte an der Software behält. </font></font>
		</p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>EINSCHRÄNKUNGEN</b></font></font></p>
</ol>
<ol start="4">
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Sofern in dieser
		Lizenz nicht ausdrücklich das Gegenteil festgelegt wird,
		verpflichtet sich der Endbenutzer-Lizenznehmer: </font></font>
		</p>
	</ol>
</ol>
<ol type="a">
	<ol type="a">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a618882_1"></a>
		<font color="#000000"><font face="Arial, serif">die Software oder
		die Dokumente nicht zu kopieren,</font></font></p>
	</ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">die Software oder
	die Dokumente nicht zu vermieten, zu verleasen, unterzulizenzieren,
	zu verleihen, zu übersetzen, zusammenzuführen, anzupassen,
	abzuwandeln oder zu verändern,</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a168255_1"></a>
	<font color="#000000"><font face="Arial, serif">keine Änderungen
	oder Modifikationen der Software in ihrer Gesamtheit oder in Teilen
	vorzunehmen und die Kombination der Software oder eines Teils davon
	mit anderen Programmen oder ihre Integration in andere Programme
	nicht zuzulassen,</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">die Software weder
	vollständig noch teilweise zu disassemblieren, zu dekompilieren,
	zurückzuentwickeln (Reverse Engineering) oder abgeleitete Werke
	davon zu erstellen noch dies zu versuchen, es sei denn, dies kann
	nicht verboten werden, weil es zur Erzielung der Interoperatibilität
	der Software mit einem anderen Softwareprogramm unabdingbar ist; in
	diesem Fall dürfen die Informationen, die der
	Endbenutzer-Lizenznehmer und/oder autorisierte Benutzer
	währenddessen erhalten, nur zum Zwecke der Interoperabilität der
	Software mit einem anderen Softwareprogramm verwendet werden und</font></font></p>
	<ol type="a">
		<ol type="i">
			<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
			<font color="#000000"><font face="Arial, serif">ohne unsere
			vorherige schriftliche Zustimmung nicht unnötigerweise Dritten
			offengelegt oder mitgeteilt werden und</font></font></p>
			<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a146892_1"></a>
			<font color="#000000"><font face="Arial, serif">nicht verwendet
			werden, um Software zu erstellen, die der Software im Wesentlichen
			ähnlich ist,</font></font></p>
		</ol>
	</ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">ohne unsere
	vorherige schriftliche Zustimmung die Software weder ganz noch
	teilweise (u.&nbsp;a. Programmlisten, Objekt- und
	Quellprogrammlisten, Objektcode und Quellcode) in irgendeiner Form
	Personen bereitzustellen oder anderweitig verfügbar zu machen und </font></font>
	</p>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.89in; margin-bottom: 0.08in">
<font color="#000000"><font face="Arial, serif">die Software nicht
auf nicht autorisierte Geräte herunterzuladen oder auf nicht
autorisierten Geräten zu installieren oder zu verwenden.</font></font></p>
<ol start="4">
	<ol start="2">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Unbeschadet des
		Vorstehenden darf der Endbenutzer-Lizenznehmer eine Kopie der
		Dokumente zum Zwecke der Vertragsverwaltung und, falls unbedingt
		erforderlich, zur Patientenversorgung anfertigen. Der
		Endbenutzer-Lizenznehmer kann über seinen Administrator
		Statusberichte wie Benutzer- oder Geräteprotokolle, allgemeine
		Patientenstatistiken und andere relevante Informationen, die sich
		direkt auf die Patientenversorgung beziehen, im Rahmen der
		Versorgungspflichten, des Ressourcenmanagements und der
		Berichterstattung des Endbenutzer-Lizenznehmers herunterladen.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Der
		Endbenutzer-Lizenznehmer darf die Software nur so verwenden, wie
		sie auf autorisierten Geräten installiert ist. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Der
		Endbenutzer-Lizenznehmer überwacht und kontrolliert die Nutzung
		der Software durch autorisierte Benutzer und stellt sicher, dass
		die Software von autorisierten Benutzern in Übereinstimmung mit
		den Bedingungen dieser Lizenz verwendet wird.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">Der
		Endbenutzer-Lizenznehmer muss alle geltenden Gesetze und
		Vorschriften zu Technologiekontrolle oder Export befolgen.</font></font></p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.49in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>SUPPORT-LEISTUNGEN</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="_Hlk74843159"></a>
		<font color="#000000"><font size="2" style="font-size: 10pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt">Der
		Lizenzgeber erbringt während der Laufzeit dieser Vereinbarung über
		seine Vertriebshändler für den Endbenutzer-Lizenznehmer
		vernünftigerweise angeforderte Support-Leistungen in Bezug auf die
		Software. Support kann vom Endbenutzer-Lizenznehmer über das
		Ticketsystem des Vertriebshändlers angefordert werden und
		unterliegt den Bedingungen des Unterleasingvertrags.</font></font></font></font></p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>PERSONENBEZOGENE
	DATEN</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><font color="#000000">Die
		Nutzung der Software erfordert die Verarbeitung personenbezogener
		Daten von Patienten und Mitarbeitern des Endbenutzer-Lizenznehmers
		gemäß der Verordnung (EU) 2016/679&nbsp;–
		Datenschutz-Grundverordnung. Die Verarbeitung personenbezogener
		Daten erfolgt durch das verbundene Unternehmen KroniKare Limited
		des Lizenzgebers mit Sitz in Watford, Vereinigtes Königreich (The
		Wenta Business Centre, Watford, Hertfordshire, England, WD24 7ND,
		Vereinigtes Königreich). Die Kontaktdaten des Beauftragten in der
		Europäischen Union für alle Anliegen in Zusammenhang mit der
		Verarbeitung personenbezogener Daten gemäß Art.&nbsp;27 DSGVO
		lauten wie folgt: Kaleidoscope Data Privacy Consultants SL (KDPC
		ES), Calle Balmes 173, 4-2, Barcelona 08006, Spanien
		(</font><font color="#000000"><font face="Arial, serif"><i><u><a class="western" href="mailto:dpr.kronikare@kdpc.es"><font color="#000000">dpr.kronikare@kdpc.es</font></a></u></i></font></font><font color="#000000">)
		im Auftrag von KroniKare Ltd. Dieser Beauftragte agiert
		entsprechend eines schriftlichen Mandats von KroniKare Ltd., das
		den Beauftragten bevollmächtigt, mit den Aufsichtsbehörden
		zusammenzuarbeiten und als Ansprechpartner für betroffene Personen
		zu fungieren.</font></font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Der
		Endbenutzer-Lizenznehmer stimmt in seiner Eigenschaft als
		Verantwortlicher gemäß DSGVO (vgl. Art.&nbsp;4 Abs.&nbsp;7 DSGVO)
		zu, einen Datenverarbeitungsvertrag gemäß Art.&nbsp;28 DSGVO mit
		KroniKare Limited (als Datenverarbeiter gemäß Art.&nbsp;4 Abs.&nbsp;8
		DSGVO) für die Verarbeitung personenbezogener Daten für den Zweck
		„<i>Bereitstellung von Wundversorgung durch den Verantwortlichen</i>“
		abzuschließen. Die Ausführung des Vertrages erfolgt durch die
		Vertriebshändler. Nach Ansicht der Europäischen Kommission ist
		das Datenschutzniveau im Vereinigten Königreich im Wesentlichen
		mit dem der Europäischen Union vergleichbar (vgl. Art.&nbsp;45
		DSGVO). Aufgrund dessen erfolgen Übermittlungen personenbezogener
		Daten aus der Europäischen Union in dieses Drittland unter
		Umständen ohne weitere geeignete Sicherheitsvorkehrungen und
		Maßnahmen.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="_Hlk94996667"></a><a name="_Hlk94996298"></a>
		<font color="#000000"><font face="Arial, serif">Unbeschadet von
		Artikel&nbsp;6.2 fungiert KroniKare Limited als Verantwortlicher
		für alle personenbezogenen Daten in Bezug auf die mit KroniKare
		Limited geschlossenen Verträge, im Allgemeinen in Bezug auf
		personenbezogene Daten des Endbenutzer-Lizenznehmers und, um
		Zweifel auszuschließen, insbesondere wenn der
		Endbenutzer-Lizenznehmer eine Einzelperson oder ein
		Einzelunternehmer ist, z.&nbsp;B. praktizierender oder unabhängiger
		Arzt oder unabhängige oder selbstständige Pflegekraft. Diese
		Verantwortlichkeit des Lizenzgebers erstreckt sich unter keinen
		Umständen auf Patientendaten, die vom Endbenutzer-Lizenznehmer
		bereitgestellt werden. Ein entsprechender Datenschutzhinweis gemäß
		Art.&nbsp;13 und 14 DSGVO ist am Ende dieser EULA zu finden.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">Die Verarbeitung
		personenbezogener Daten gemäß Artikel&nbsp;6.2 zur Erbringung von
		Gesundheitsdienstleistungen erfordert die Speicherung von und den
		Zugriff auf Informationen, einschließlich personenbezogener Daten,
		über den berechtigten Benutzer, die auf Endgeräten (dem
		Wundscanner und&nbsp;– wenn verfügbar&nbsp;– anderen Geräten,
		auf denen die Software verwendet werden kann) gespeichert sind,
		sowie den Zugang zu den Informationen für die Erbringung von
		Verarbeitungsdienstleistungen, die der Kontrolle des
		Endbenutzer-Lizenznehmers unterliegen. Der Endbenutzer-Lizenznehmer
		garantiert, dass er den autorisierten Benutzer unmissverständlich
		über die Zwecke der Verarbeitung personenbezogener Daten und
		anderer Informationen informiert.</font></font></p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>ANNAHME DER EULA
	UND BEREITSTELLUNG VON BENUTZERKONTEN</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Vor der Nutzung der
		Lizenz stimmt der Endbenutzer-Lizenznehmer den Bedingungen dieser
		Lizenz, die in dieser EULA dokumentiert sind, zu. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Der
		Endbenutzer-Lizenznehmer versichert, dass der Administrator den
		Endbenutzer-Lizenznehmer beim Abschluss dieser
		Endbenutzer-Lizenzvereinbarung durch Annahme ihrer Bedingungen vor
		der ersten Verwendung der Software vertreten darf.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Der Administrator
		muss bei der ersten Anmeldung die Bedingungen dieser EULA
		akzeptieren, indem er auf eine entsprechende Annahme-Schaltfläche
		klickt, die auf allen Geräten angezeigt wird; hierüber wird eine
		Kopie per E-Mail an den Endbenutzer-Lizenznehmer gesendet. Nach
		Annahme dieser EULA dürfen alle autorisierten Benutzer die
		Software und den Wundscanner gemäß den Bedingungen und für die
		nach Artikel&nbsp;13 festgelegte Dauer dieser Lizenz verwenden.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">Der Lizenzgeber
		stellt dem Endbenutzer-Lizenznehmer die Benutzerkonten für die
		vereinbarte Anzahl von autorisierten Benutzern auf der Grundlage
		des Unterleasingvertrags zwischen dem Endbenutzer-Lizenznehmer und
		dem Vertriebshändler zur Verfügung. Zusätzliche Konten für
		autorisierte Benutzer können über das Software-Dashboard
		angefordert werden. Die Anzahl der Konten für autorisierte
		Benutzer ist nicht begrenzt.</font></font></p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>SOFTWARE-UPDATES</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font size="2" style="font-size: 10pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt">Der
		Lizenzgeber ist berechtigt, Aktualisierungen der Software nach
		eigenem Ermessen bereitzustellen. Aktualisierungen werden
		automatisch über Push-Updates installiert. Der
		Endbenutzer-Lizenznehmer kann Software-Updates nicht ablehnen oder
		aufschieben. </font></font></font></font>
		</p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0in">
<br/>

</p>
<ol start="4">
	<ol start="2">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font size="2" style="font-size: 10pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt">Unbeschadet
		des Vorstehenden hat der Endbenutzer-Lizenznehmer weder Anspruch
		auf die in Artikel&nbsp;8.1 beschriebenen Aktualisierungen noch das
		Recht, spezifische oder allgemeine Aktualisierungen zu verlangen. </font></font></font></font>
		</p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>RECHTE AN
	GEISTIGEM EIGENTUM UND SCHADLOSHALTUNG</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a409880_1"></a>
		<font color="#000000"><font face="Arial, serif">Der
		Endbenutzer-Lizenznehmer erkennt an und stimmt zu, dass alle
		gewerblichen Rechte und geistigen Eigentumsrechte (einschließlich
		der exklusiven Rechte zur wirtschaftlichen Verwertung) an der
		Software und den Dokumenten überall auf der Welt das
		ausschließliche Eigentum des Lizenzgebers sind und bleiben. Keine
		Bestimmung in der EULA zielt darauf ab oder ist dahingehend
		auszulegen, dass eines dieser Rechte auf den
		Endbenutzer-Lizenznehmer oder Dritte übertragen wird. Unbeschadet
		des in der EULA ausdrücklich gewährten Rechts zur Nutzung der
		Software macht der Endbenutzer-Lizenznehmer keine Rechte geltend,
		darunter Urheberrechte an der Software und ihren Komponenten oder
		andere damit verbundene gewerbliche oder geistige Eigentumsrechte.
		Der Endbenutzer-Lizenznehmer verpflichtet sich ausdrücklich, alle
		angemessenen Maßnahmen zu ergreifen, um den Inhalt der Software zu
		schützen und deren nicht autorisierte Offenlegung zur Nutzung,
		einschließlich durch autorisierte Benutzer, zu verhindern. Der
		Endbenutzer-Lizenznehmer erkennt an, dass der Lizenzgeber das Recht
		hat, im Falle einer Verletzung seiner Rechte an der Software, die
		durch den Endbenutzer-Lizenznehmer und seine autorisierten Benutzer
		erfolgt oder von ihnen zugelassen wird, direkte und sofortige
		Maßnahmen zu ergreifen, und berechtigt ist, diese Rechte auf die
		am besten geeignete Art und mit den am besten geeigneten Mitteln
		durchzusetzen. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a352428_1"></a>
		<font color="#000000"><font face="Arial, serif">Der
		Endbenutzer-Lizenznehmer stimmt zu, dass die Rechte an der Software
		an ihn lizenziert (nicht verkauft) werden und dass er und seine
		autorisierten Benutzer keine sonstigen Rechte an der Software oder
		den Dokumenten besitzen, die über das Nutzungsrecht gemäß den
		Bedingungen dieser Lizenz hinausgehen.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Zur Klarstellung:
		Sämtliche Ideen, Erfindungen, Verbesserungen, Methoden, Verfahren,
		urheberrechtlich geschützten Werke und alle anderen Formen von
		geistigem Eigentum, die der Endbenutzer-Lizenznehmer allein oder
		zusammen mit anderen in Verbindung mit oder basierend auf dem
		Wissen oder den Informationen, die aus der Software und den
		Dokumenten gewonnen werden, konzipiert, in der Praxis einsetzt oder
		entwickelt, sind das alleinige und ausschließliche Eigentum des
		Lizenzgebers und/oder eines vom Lizenzgeber benannten Dritten. Der
		Endbenutzer-Lizenznehmer erklärt sich damit einverstanden, das
		gesamte geistige Eigentum an den Lizenzgeber und/oder einen vom
		Lizenzgeber benannten Dritten abzutreten und auf alle sonstigen
		Rechte zu verzichten, die er daran haben könnte. Der
		Endbenutzer-Lizenznehmer erklärt, weder während der Laufzeit noch
		zu irgendeinem Zeitpunkt nach Beendigung dieser Vereinbarung das
		geistige Eigentumsrecht in irgendeiner Weise infrage zu stellen
		oder zu bestreiten. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Der
		Endbenutzer-Lizenznehmer erkennt an, dass weder er noch seine
		autorisierten Benutzer ein wie auch immer gestaltetes Zugriffsrecht
		für die Software in Quellcodeform besitzen.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Der Lizenzgeber
		versichert, dass nach seinem Wissensstand gegen ihn keine Ansprüche
		geltend gemacht werden und keine Klagen anhängig oder angedroht
		sind, weil die Software angeblich geistige Eigentumsrechte Dritter
		verletzt. Wenn dem Endbenutzer-Lizenznehmer Ansprüche oder
		(angedrohte) Klagen gegen den Lizenzgeber in Bezug auf geistige
		Eigentumsrechte Dritter zur Kenntnis gelangen, wird er den
		Lizenzgeber unverzüglich dahingehend informieren. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Bei Ansprüchen
		Dritter gegenüber dem Endbenutzer-Lizenznehmer, die darauf
		beruhen, dass die Software die geistigen Eigentumsrechte Dritter
		verletzt, wird der Endbenutzer-Lizenznehmer den Lizenzgeber
		unverzüglich davon in Kenntnis setzen und dem Lizenzgeber die
		alleinige Kontrolle oder die Abwehr und/oder Regelung des
		betreffenden Anspruchs überlassen und mit dem Lizenzgeber bei der
		Abwehr oder Regelung des Anspruchs uneingeschränkt kooperieren.
		Der Endbenutzer-Lizenznehmer darf keine Maßnahmen ergreifen, die
		die Möglichkeiten des Lizenzgebers zur Abwehr des Anspruchs
		einschränken könnten. Der Lizenzgeber gibt dem
		Endbenutzer-Lizenznehmer Gelegenheit, das Vorgehen zu überwachen.
		Wenn nach Ansicht des Endbenutzer-Lizenznehmers ein Eingreifen
		erforderlich ist, um Nachteile für den Endbenutzer-Lizenznehmer zu
		vermeiden, wird er den Lizenzgeber entsprechend informieren; die
		Parteien prüfen dann die geeignete Vorgehensweise. Ungeachtet des
		Vorstehenden liegt die endgültige Entscheidung über die
		Maßnahmen, die im Zusammenhang mit einer (mutmaßlichen)
		Verletzung von geistigen Eigentumsrechten zu ergreifen sind, beim
		Lizenzgeber. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Unbeschadet des
		Vorstehenden gilt: Falls die gesamte Software oder ein Teil davon
		Gegenstand eines Anspruchs wegen Rechtsverletzung ist oder nach
		Ansicht des Lizenzgebers wahrscheinlich wird, kann der Lizenzgeber
		auf eigene Kosten, nach eigenem Ermessen und ohne weitere Haftung
		gegenüber dem Endbenutzer-Lizenznehmer auf jedes der folgenden
		praktikablen Rechtsmittel zurückgreifen: (i) dem
		Endbenutzer-Lizenznehmer das Recht zur Nutzung der Software
		verschaffen; (ii) die Software durch nicht rechtsverletzende
		Software ersetzen, die hinsichtlich der Funktion mindestens
		gleichwertig ist, (iii) die Software so modifizieren, dass sie
		nicht rechtsverletzend ist.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">Der Lizenzgeber ist
		nicht zur Schadloshaltung verpflichtet und übernimmt keine Haftung
		oder sonstigen Verpflichtungen in Bezug auf Ansprüche, die
		basieren auf: (i) der Kombination oder Verwendung der Software mit
		anderer Software, die nicht vom Lizenzgeber genehmigt und/oder
		bereitgestellt wurde, (ii) der Nutzung der Software durch den
		Endbenutzer-Lizenznehmer oder einen autorisierten Benutzer auf eine
		Weise, die nicht mit der EULA und/oder der Softwarebeschreibung in
		Einklang steht, oder (iii) der Nichtverwendung eines vom
		Lizenzgeber bereitgestellten Release, wenn eine Rechtsverletzung
		durch Verwendung des Release oder der neuesten verfügbaren Version
		der Software hätte vermieden werden können.</font></font></p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a683549_1"></a>
	<font color="#000000"><font face="Arial, serif"><b>GARANTIE</b></font></font></p>
</ol>
<ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Die
	Garantiebedingungen werden im Unterleasingvertrag beschrieben. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Der Lizenzgeber
	haftet nicht für Funktionseinschränkungen oder Mängel der
	Software, die ganz oder teilweise verursacht wurden durch nicht
	autorisiertes Verhalten, das vom Endbenutzer-Lizenznehmer und/oder
	seinen autorisierten Benutzern gezeigt oder zugelassen wurde, eine
	Verwendung der Software, die nicht mit dem beabsichtigten Zweck der
	Software oder der Gebrauchsanweisung übereinstimmt, eine Änderung
	oder den Ausfall der Software, die bzw. der sich aus Software oder
	anderen Produkten, die nicht vom Lizenzgeber geliefert wurden,
	ergibt. Wenn die Fehlfunktion der Software auf einen Fehler
	zurückzuführen ist, muss der Endbenutzer-Lizenznehmer dem
	Lizenzgeber dies unverzüglich mitteilen. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
	<font color="#000000"><font face="Arial, serif">Der Lizenzgeber
	haftet nicht für Funktionseinschränkungen, Nichtverfügbarkeit der
	Software oder Nichterfüllung seiner Pflichten aus diesem Vertrag,
	die durch das Internet, die IT-Systeme oder die Systeme des
	Endbenutzer-Lizenznehmers verursacht werden. </font></font>
	</p>
</ol>
<p align="justify" style="line-height: 115%; text-indent: -0.5in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a914493_1"></a><a name="co_anchor_a676389_1"></a><a name="co_anchor_a796999_1"></a><a name="co_anchor_a946537_1"></a>
	<font color="#000000"><font face="Arial, serif"><b>HAFTUNGSBESCHRÄNKUNG</b></font></font></p>
</ol>
<ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Unbeschadet des
	vorstehenden Artikels&nbsp;10 liefert der Lizenzgeber die Software
	an den Endbenutzer-Lizenznehmer ohne Mängelgewähr und gibt dem
	Endbenutzer-Lizenznehmer keine ausdrückliche oder stillschweigende
	Garantie hinsichtlich ihrer Eignung für einen bestimmten Zweck,
	u.&nbsp;a. ihrer Eignung für einen bestimmten Zweck, der nicht
	ausdrücklich in dieser EULA oder der Gebrauchsanweisung angegeben
	ist. Der Endbenutzer-Lizenznehmer muss sicherzustellen, dass die
	Einrichtungen und die Funktionen der Software, die in den Dokumenten
	beschrieben sind, die Anforderungen erfüllen.</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Sofern nicht anders
	zwischen den Parteien vereinbart, haftet der Lizenzgeber im
	gesetzlich zulässigen Umfang nicht für Schäden, die aus den
	folgenden Ursachen hervorgehen: (i) einer Nutzung der Software, die
	nicht der EULA und der Gebrauchsanweisung entspricht, (ii)
	Fehlfunktionen der Software, die auf das unter (i) beschriebene
	Verhalten zurückzuführen sind, (iii) Unklarheiten oder
	Ungenauigkeiten der Software oder der Dokumente, (iv) Fehler in der
	Software oder den Dokumenten, (v) Beschädigung von Daten oder
	Informationen. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; orphans: 0; widows: 0; margin-right: 0.05in; margin-bottom: 0.14in">
	<font color="#000000"><font face="Arial, serif">Sofern in dieser
	EULA nichts Gegenteiliges festgelegt ist, haftet der Lizenzgeber
	gegenüber dem Endbenutzer-Lizenznehmer unter keinen Umständen für
	direkte oder indirekte Verluste oder Schäden, u.&nbsp;a. entgangene
	Gewinne, Verlust von Firmenwert, Rufschädigung, Geschäftsverlust,
	entgangene Geschäftsmöglichkeiten, Nichtrealisierung erwarteter
	Einsparungen, Verlust im Zusammenhang mit der Beschädigung von
	Daten oder Informationen oder für sonstige indirekte, weit
	entfernte oder Folgeschäden oder bezifferbare Vermögensschäden,
	die sich aus oder in Verbindung mit dieser Lizenz ergeben, sei es
	aus Vertrag, unerlaubter Handlung (einschließlich Fahrlässigkeit),
	Verletzung gesetzlicher Pflichten oder anderweitig. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Ungeachtet des
	Vorstehenden haftet der Lizenzgeber uneingeschränkt für Schäden,
	die durch Vorsatz oder grobe Fahrlässigkeit seitens des
	Lizenzgebers verursacht wurden.</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a879858_1"></a><a name="co_anchor_a570100_1"></a><a name="co_anchor_a1003989_1"></a>
	<font color="#000000"><font face="Arial, serif">Der
	Endbenutzer-Lizenznehmer akzeptiert und stimmt zu, dass Ansprüche,
	die im Zusammenhang mit der Bereitstellung der Software oder der
	Dokumente existieren oder entstehen, nur gegenüber dem Unternehmen
	geltend gemacht werden können, an das der Endbenutzer-Lizenznehmer
	die Zahlung für den Kauf der Software gemäß den Bedingungen der
	Vereinbarung zwischen dem Endbenutzer-Lizenznehmer und dem
	betreffenden Unternehmen geleistet hat. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a104167_1"></a>
	<font color="#000000"><font face="Arial, serif">Für die folgenden
	Fälle wird die Haftung des Lizenzgebers durch diese Lizenz weder
	beschränkt noch ausgeschlossen:</font></font></p>
	<ol type="a">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Tod oder
		Körperverletzung aufgrund unserer Fahrlässigkeit,</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a437879_1"></a>
		<font color="#000000"><font face="Arial, serif">Betrug oder
		betrügerische Täuschung.</font></font></p>
	</ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
	<font color="#000000"><font face="Arial, serif">Diese Lizenz legt
	den vollen Umfang der Pflichten und Haftungen des Lizenzgebers in
	Bezug auf die Lieferung der Software und Dokumente fest. Sofern
	nicht ausdrücklich in dieser Lizenz angegeben, sind darüber hinaus
	keine Klauseln, Garantien, Zusicherungen oder sonstigen Bedingungen,
	weder ausdrücklich noch stillschweigend, für den Lizenzgeber
	bindend. Alle Klauseln, Garantien, Zusicherungen oder sonstigen
	Bedingungen bezüglich der Lieferung der Software und Dokumente, die
	anderweitig in dieser Lizenz impliziert oder enthalten sein könnten,
	sei es durch Gesetz oder anderweitig, werden im größtmöglichen
	gesetzlich zulässigen Umfang ausgeschlossen.</font></font></p>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>VERTRAULICHKEIT</b></font></font></p>
</ol>
<ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Während der
	Laufzeit der EULA tauschen Lizenzgeber und Endbenutzer-Lizenznehmer
	vertrauliche Informationen untereinander aus. Jede Partei
	verpflichtet sich, diese Informationen nicht ohne die vorherige
	ausdrückliche Zustimmung der anderen Partei an Dritte
	weiterzugeben. Vertrauliche Informationen sind in den relevanten
	schriftlichen Unterlagen als vertraulich zu kennzeichnen oder bei
	mündlicher Weitergabe ausdrücklich als vertraulich anzugeben.
	Informationen gelten nicht als vertraulich, wenn sie zum Zeitpunkt
	der Offenlegung: (i) öffentlich zugänglich sind, (ii) der nicht
	offenlegenden Partei bereits bekannt sind oder (iii) dem
	Endbenutzer-Lizenznehmer zu einem späteren Zeitpunkt und auf eine
	Weise, die nicht unter diesen Artikel fällt, zur Kenntnis gelangen.
	Der Endbenutzer-Lizenznehmer muss vertrauliche Informationen unter
	Anwendung der besten aktuellen kommerziellen Sicherheitspraktiken
	schützen. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Beide Parteien
	dürfen ohne die vorherige schriftliche Zustimmung der anderen
	Partei vertrauliche Informationen, auch nicht in Teilen, nicht an
	Dritte weitergeben, ausgenommen an:</font></font></p>
	<ol type="a">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">ihre eigenen
		Mitarbeiter und Berater, dann jedoch nur an diejenigen Mitarbeiter
		und Berater, die die vertraulichen Informationen kennen müssen,</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">ein zuständiges
		Gericht, eine staatliche Stelle oder eine zuständige
		Aufsichtsbehörde sowie alle anderen Personen oder Stellen, die das
		Recht oder die Pflicht haben, die Geschäftstätigkeit der anderen
		Partei zu kennen, jedoch nur zur Erfüllung dieses Rechts oder
		dieser Pflicht.</font></font></p>
	</ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Die hierin
	festgelegten Pflichten in Bezug auf vertrauliche Informationen
	bleiben für einen Zeitraum von zehn (10) Jahren nach dem Datum der
	Offenlegung vertraulicher Informationen in vollem Umfang in Kraft. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Unbeschadet des
	Vorstehenden vereinbaren die Parteien, dass in Bezug auf die
	Verarbeitung personenbezogener Daten gemäß DSGVO stattdessen die
	im Datenverarbeitungsvertrag zwischen Lizenzgeber und
	Endbenutzer-Lizenznehmer festgelegten Vertraulichkeitsbestimmungen
	gelten. Dieser Artikel&nbsp;12 gilt daher nicht für die
	Vertraulichkeit personenbezogener Daten, die im
	Datenverarbeitungsvertrag zwischen den Parteien gesondert geregelt
	wird, sowie für Daten, die gemäß dem Datenverarbeitungsvertrag
	zwischen den Parteien anonymisiert werden. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="_Toc520913491"></a>
	<font color="#000000"><font face="Arial, serif">Jede Partei dieser
	Vereinbarung benachrichtigt die andere Partei unverzüglich, wenn
	sie Kenntnis von einer Verletzung der Vertraulichkeit durch eine
	Person erhält, an die sie die Informationen ganz oder teilweise
	weitergibt, und leistet gegenüber der anderen Partei jegliche
	angemessene Unterstützung im Zusammenhang mit Verfahren, die die
	andere Partei unter Umständen gegen diese Person wegen
	Vertrauensbruchs einleitet.</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
	<font color="#000000"><font face="Arial, serif">Die Rechte und
	Pflichten aus diesem Artikel überdauern die Beendigung dieser
	Vereinbarung ohne zeitliche Begrenzung, gelten jedoch nicht mehr für
	Informationen oder Wissen, die möglicherweise öffentlich
	zugänglich sind. </font></font>
	</p>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a534011_1"></a>
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>KÜNDIGUNG</b></font></font></p>
</ol>
<ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Diese Lizenz beginnt
	mit der Annahme der Lizenzbedingungen und bleibt bis zur Kündigung
	des Unterleasingvertrags zwischen dem Endbenutzer-Lizenznehmer und
	dem Vertriebshändler, der dem Endbenutzer-Lizenznehmer die Nutzung
	des Wundscanners ermöglicht, in Kraft. Dabei ist es unerheblich, ob
	der Endbenutzer-Lizenznehmer diesen Vertrag mit dem Lizenzgeber oder
	einem Vertriebshändler abgeschlossen hat. Der
	Endbenutzer-Lizenznehmer ist verpflichtet, den Lizenzgeber über die
	Kündigung oder den Ablauf des Leasing- oder Servicevertrags zu
	informieren, der dem Endbenutzer-Lizenznehmer die Nutzung des
	Wundscanners ermöglicht. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Der Lizenzgeber kann
	diese Lizenz in den folgenden Fällen mit sofortiger Wirkung durch
	schriftliche Mitteilung an den Endbenutzer-Lizenznehmer kündigen:</font></font></p>
	<ol type="a">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Der
		Endbenutzer-Lizenznehmer begeht einen wesentlichen oder anhaltenden
		Verstoß gegen die Bedingungen dieser Lizenz, der entweder nicht
		behoben werden kann oder den der Endbenutzer-Lizenznehmer nicht
		innerhalb von vierzehn (14) Tagen nach einer schriftlichen
		Mitteilung, in der der Verstoß angegeben ist und die Behebung des
		Verstoßes verlangt wird, behebt. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Der
		Endbenutzer-Lizenznehmer ist nicht in der Lage, seine ausstehenden
		Schulden zu begleichen, wird zahlungsunfähig oder ist Gegenstand
		einer Anordnung oder eines Beschlusses zu seiner Liquidation,
		Zwangsverwaltung, Abwicklung oder Auflösung (außer zum Zwecke
		einer Fusion oder Umstrukturierung mit daraus resultierender
		Zahlungsfähigkeit). </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Für den
		Endbenutzer-Lizenznehmer wird ein Zwangsverwalter oder sonstiger
		Verwalter, Geschäftsführer, Treuhänder, Liquidator,
		Nachlassverwalter oder ein ähnlicher Beauftragter für das gesamte
		Vermögen des Endbenutzer-Lizenznehmers oder einen wesentlichen
		Teil davon bestellt oder der Endbenutzer-Lizenznehmer schließt
		allgemein einen Vergleich oder eine Vereinbarung mit seinen
		Gläubigern oder macht einen entsprechenden Vorschlag. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Der
		Endbenutzer-Lizenznehmer stellt seine Geschäftstätigkeit ein oder
		droht mit Einstellung. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Der
		Endbenutzer-Lizenznehmer ist in einer beliebigen Jurisdiktion
		Gegenstand eines entsprechenden Ereignisses oder Verfahrens. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Der
		Endbenutzer-Lizenznehmer übergibt ohne die vorherige ausdrückliche
		schriftliche Zustimmung des Lizenzgebers ein physisches Medium, auf
		dem sich die Software und/oder eine Kopie davon befindet
		(einschließlich aller nach nationalem Recht zulässigen
		Sicherungskopien), an Dritte.  </font></font>
		</p>
	</ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Alle vertraglichen
	Bestimmungen der EULA, deren Wirksamkeit durch die Kündigung oder
	den Ablauf dieser EULA nicht berührt wird, bleiben zwischen den
	Parteien nach Kündigung oder Ablauf der EULA in vollem Umfang in
	Kraft. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Nach Kündigung oder
	Ablauf der EULA aus beliebigem Grund:</font></font></p>
	<ol type="a">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">erlöschen alle dem
		Endbenutzer-Lizenznehmer im Rahmen dieser Lizenz gewährten Rechte,</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">stellt der
		Endbenutzer-Lizenznehmer unverzüglich alle durch diese Lizenz
		autorisierten Aktivitäten ein, </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="_Hlk95062074"></a><a name="co_anchor_a666232_1"></a>
		<font color="#000000"><font face="Arial, serif">verpflichtet sich
		der Endbenutzer-Lizenznehmer, die Software unverzüglich und
		dauerhaft von allen in seinem Besitz befindlichen Computergeräten
		zu löschen oder zu entfernen und alle Kopien der Software und
		Dokumente, die sich zu diesem Zeitpunkt im Besitz, in der Obhut
		oder unter der Kontrolle des Endbenutzer-Lizenznehmers befinden,
		unverzüglich zu vernichten oder an den Lizenzgeber zurückzugeben
		(nach seinem Ermessen). Im Falle der Vernichtung muss der
		Endbenutzer-Lizenznehmer dem Lizenzgeber bestätigen, dass die
		Software und die Dokumente vernichtet wurden.</font></font></p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.75in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>MITTEILUNGEN
	ZWISCHEN DEN PARTEIEN</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Der Lizenzgeber
		kann die Bedingungen dieser Lizenz jederzeit durch Mitteilung an
		den Endbenutzer-Lizenznehmer gemäß diesem Artikel&nbsp;14
		aktualisieren. Die fortgesetzte Nutzung der Software und der
		Dokumente durch den Endbenutzer-Lizenznehmer nach 30&nbsp;Tagen
		nach der in diesem Artikel&nbsp;14 beschriebenen Benachrichtigung
		stellt die Zustimmung des Endbenutzer-Lizenznehmers zu den
		Bedingungen dieser Lizenz und allen aktualisierten Bedingungen dar.
		Wenn der Endbenutzer-Lizenznehmer die Bedingungen der Lizenz (in
		ihrer jeweiligen Fassung) nicht akzeptieren möchte, stellen er
		und/oder seine autorisierten Benutzer nach Erhalt und Zustellung
		der Mitteilung unverzüglich die Nutzung der Software und der
		Dokumente ein und greifen nicht mehr darauf zu. Der
		Endbenutzer-Lizenznehmer informiert den Lizenzgeber dementsprechend
		unter Verwendung der Kontaktdaten, die dem Endbenutzer-Lizenznehmer
		über das Dashboard der Software zur Verfügung gestellt werden.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a510664_1"></a>
		<font color="#000000"><font face="Arial, serif">Der Lizenzgeber
		darf mit dem Endbenutzer-Lizenznehmer nur über die Kontaktdaten
		Verbindung aufnehmen, die vom Endbenutzer-Lizenznehmer vor Annahme
		der EULA angegeben wurden. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Jede Mitteilung:</font></font></p>
	</ol>
</ol>
<ol>
	<ol type="a">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">die vom Lizenzgeber
		an den Endbenutzer-Lizenznehmer ergeht, gilt 24&nbsp;Stunden nach
		der ersten Veröffentlichung auf der Website und im Dashboard des
		Lizenzgebers, 24&nbsp;Stunden nach dem Versand einer E-Mail oder
		drei Tage nach dem Datum der Aufgabe eines Briefes als erhalten und
		ordnungsgemäß zugestellt.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">die vom
		Endbenutzer-Lizenznehmer an den Lizenzgeber ergeht, gilt 24&nbsp;Stunden
		nach dem Versand einer E-Mail oder drei Tage nach dem Datum der
		Aufgabe eines Briefes als erhalten und ordnungsgemäß zugestellt.</font></font></p>
	</ol>
</ol>
<ol start="4">
	<ol start="4">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">Für die Zustellung
		einer Mitteilung genügt folgender Nachweis: im Falle eines
		Briefes, dass der Brief ordnungsgemäß adressiert, frankiert und
		an die zu diesem Zweck angegebene Adresse des Empfängers bei der
		Post aufgegeben wurde, und im Falle einer E-Mail, dass die E-Mail
		an die zu diesen Zwecken angegebene E-Mail-Adresse des Empfängers
		gesendet wurde. Bei Bereitstellung an den Endbenutzer-Lizenznehmer
		über das Dashboard der Software gilt die Mitteilung zwei Tage nach
		ihrer Bereitstellung als zugestellt.</font></font></p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>HÖHERE GEWALT</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">Falls der
		Lizenzgeber aufgrund von Streik, Aussperrung, Arbeitskämpfen,
		Terrorismus, der Unmöglichkeit, Materialien oder Dienstleistungen
		zu beschaffen, Stromausfall oder restriktiven behördlichen oder
		gerichtlichen Anordnungen oder Erlässen, Aufruhr, Aufstand, Krieg,
		Naturkatastrophen, Erdbeben oder infolge anderer Gründe oder
		Ursachen, die außerhalb seiner Kontrolle liegen, und zwar
		unabhängig davon, ob ein solcher Grund oder eine solche Ursache
		derzeit vorhersehbar ist (z.&nbsp;B. ein erneuter Lockdown aufgrund
		von Corona oder einer anderen Pandemie), daran gehindert wird, eine
		Handlung (rechtzeitig) durchzuführen, u.&nbsp;a. die
		Bereitstellung der Software im Rahmen dieser EULA, ist der
		Lizenzgeber für die Dauer der Verzögerung von der Durchführung
		dieser Handlung befreit, sofern er angemessene Anstrengungen
		unternimmt, um eine solche Verzögerung oder Behinderung zu
		beheben<font size="2" style="font-size: 9pt"><span style="background: #ffffff">.
		</span></font>Der Lizenzgeber ist nicht haftbar oder verantwortlich
		für die Nichterfüllung oder verzögerte Erfüllung einer unserer
		Pflichten aus dieser Lizenz aufgrund höherer Gewalt gemäß diesem
		Artikel&nbsp;15.1.</font></font></p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"> <font face="Arial, serif"><b>	</b><b>MEDIZINPRODUKT
	</b></font></font>
	</p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Der Lizenzgeber
		identifiziert nach Treu und Glauben und in Übereinstimmung mit den
		lokalen Vorschriften diejenigen Produkte, die gemäß der
		Verordnung über Medizinprodukte (Verordnung (EU) 2017/745) als
		Medizinprodukt gelten. Die Software ist ein Medizinprodukt im Sinne
		der Medizinprodukteverordnung.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Es liegt in der
		Verantwortung des Endbenutzer-Lizenznehmers, zu überwachen, dass
		die Software in Übereinstimmung mit den lokalen Gesetzen und
		Vorschriften ordnungsgemäß verwendet wird. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Wenn dem
		Endbenutzer-Lizenznehmer Qualitätsprobleme, Vorfälle oder
		Informationen zur Kenntnis gelangen, die berechtigterweise darauf
		hindeuten, dass die Software oder die Dokumente nicht der
		EU-Verordnung für Medizinprodukte oder der nationalen Gesetzgebung
		für Medizinprodukte entsprechen, muss er dem Lizenzgeber alle zur
		Feststellung des Problems benötigten Informationen zur Verfügung
		stellen und ihm Zugang zu dem betreffenden Gerät gewähren. Der
		Endbenutzer-Lizenznehmer verwendet die Software erst wieder nach
		Freigabe durch den Lizenzgeber. Der Endbenutzer-Lizenznehmer muss
		innerhalb von 24&nbsp;Stunden, nachdem er Kenntnis von einem
		Qualitätsproblem, einem Vorfall oder Informationen Kenntnis
		erlangt hat, die die Einhaltung der geltenden
		Medizinproduktegesetzgebung betreffen, dies erstmals melden. Er
		reicht unverzüglich alle Informationen nach, die zum Zeitpunkt der
		ersten Benachrichtigung noch nicht verfügbar waren. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Der
		Endbenutzer-Lizenznehmer leitet diese Informationen umgehend,
		spätestens jedoch 24&nbsp;Stunden nach Kenntniserlangung an den
		Lizenzgeber weiter.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Wenn der
		Lizenzgeber dem Endbenutzer-Lizenznehmer mitteilt, dass eine
		Abhilfemaßnahme in Bezug auf die Sicherheit vor Ort oder ein
		Produktrückruf erforderlich ist, muss der Endbenutzer-Lizenznehmer
		den Erhalt angemessen bestätigen und mit dem Lizenzgeber auf
		eigene Kosten bei der Umsetzung der Abhilfemaßnahmen
		zusammenarbeiten. Unter keinen Umständen darf der
		Endbenutzer-Lizenznehmer ohne die ausdrückliche Zustimmung des
		Lizenzgebers Abhilfemaßnahmen durchführen. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Der
		Endbenutzer-Lizenznehmer verpflichtet sich, den Lizenzgeber über
		alle Maßnahmen von Behörden oder Dritten in Bezug auf die
		Software zu informieren, sobald er Kenntnis von einer solchen
		Maßnahme erlangt, und ihn dabei zu unterstützen.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">Wenn der
		Endbenutzer-Lizenznehmer Grund zu der Annahme hat, dass die
		Software ein ernsthaftes Risiko darstellen oder gefälscht sein
		könnte, informiert er die zuständige Behörde nicht, bevor er den
		Lizenzgeber benachrichtigt und mit ihm die Einzelheiten der an die
		zuständige Behörde zu übermittelnden Informationen vereinbart
		hat. </font></font>
		</p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"> <font face="Arial, serif"><b>	VERSCHIEDENES</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><u>Übertragung von
		Rechten durch den Lizenzgeber.</u> Der Endbenutzer-Lizenznehmer
		erteilt dem Lizenzgeber hiermit vorab die Erlaubnis, seine Rechte
		und Pflichten aus dieser Vereinbarung auf Dritte zu übertragen. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><u>Übertragung von
		Rechten durch den Endbenutzer-Lizenznehmer.</u> Der
		Endbenutzer-Lizenznehmer darf seine Rechte und Pflichten aus dieser
		Lizenz nur nach vorheriger ausdrücklicher schriftlicher Zustimmung
		des Lizenzgebers auf eine andere Person übertragen.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Diese Lizenz und
		jedes Dokument, auf das in der Lizenz ausdrücklich Bezug genommen
		wird, stellen die gesamte Vereinbarung zwischen den Parteien dar.
		Sie ersetzen alle früheren schriftlichen oder mündlichen
		Vereinbarungen, Zusagen, Übereinkünfte, Garantien, Zusicherungen
		und Absprachen zwischen uns in Bezug auf den Gegenstand dieser
		Lizenz, die damit nichtig werden.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Wenn der
		Lizenzgeber nicht darauf besteht, dass der Endbenutzer-Lizenznehmer
		eine seiner Pflichten aus dieser Lizenz erfüllt, oder wenn der
		Lizenzgeber seine Rechte gegenüber dem Endbenutzer-Lizenznehmer
		nicht oder verzögert durchsetzt, bedeutet dies nicht, dass der
		Lizenzgeber auf seine Rechte gegenüber dem
		Endbenutzer-Lizenznehmer verzichtet hat und der
		Endbenutzer-Lizenznehmer diese Pflichten nicht erfüllen muss.
		Jeder Verzicht auf Erfüllung durch den Endbenutzer-Lizenznehmer
		bedarf der Schriftform. Der Lizenzgeber ist nicht verpflichtet, auf
		eine Erfüllung durch den Endbenutzer-Lizenznehmer zu verzichten.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">Jeder Artikel
		dieser Lizenz gilt für sich. Sollte ein Gericht oder eine
		zuständige Behörde entscheiden, dass einer dieser Artikel
		rechtswidrig oder nicht durchsetzbar ist, bleiben die übrigen
		Artikel in vollem Umfang in Kraft und wirksam.</font></font></p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"> <font face="Arial, serif"><b>GELTENDES RECHT
	UND STREITIGKEITEN</b></font></font></p>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.49in; text-indent: -0.49in; margin-bottom: 0.08in">
<font color="#000000"><font face="Arial, serif">18.1 	Diese Lizenz,
ihr Gegenstand und ihr Zustandekommen unterliegen dem deutschen Recht
unter Ausschluss des Kollisionsrechts, der Kollisionsnormen und des
UN-Übereinkommens über Verträge über den internationalen
Warenverkauf vom 11.&nbsp;April 1980. </font></font>
</p>
<p align="justify" style="line-height: 115%; margin-left: 0.49in; text-indent: -0.49in; margin-bottom: 0.08in"><a name="kh_relatedContentOffset_1"></a><a name="co_anchor_a840535_1"></a>
<font color="#000000"><font face="Arial, serif">18.2	Alle
Streitigkeiten in Bezug auf diese EULA und die Lizenz unterliegen der
ausschließlichen Zuständigkeit der Gerichte des Landes, in dem der
Beschuldigte seinen Firmensitz hat.</font></font></p>
<p align="center" style="line-height: 150%; margin-bottom: 0.08in; page-break-before: always">
<font color="#000000"><font face="Arial, serif"><b>Datenschutzhinweis</b></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif">Willkommen beim
Vintens™ Gerät für digitales Wundmanagement und der Software. Wir
(KroniKare Ltd.) fungieren als Verantwortlicher für alle
personenbezogenen Daten in Bezug auf die mit KroniKare Ltd.
geschlossenen Verträge, im Allgemeinen in Bezug auf personenbezogene
Daten des Endbenutzer-Lizenznehmers und, um Zweifel auszuschließen,
insbesondere wenn der Endbenutzer-Lizenznehmer eine Einzelperson oder
ein Einzelunternehmer ist, z.&nbsp;B. praktizierender oder
unabhängiger Arzt oder unabhängige oder selbstständige
Pflegekraft.</font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.14in">
<font color="#000000"><font face="Arial, serif">Bevor Sie das Gerät
und die Software verwenden, möchten wir Sie daher (durch
Bereitstellung dieser Datenschutzerklärung) daran erinnern, dass wir
in begrenztem Umfang bestimmte personenbezogene Daten über Sie
erfassen (die „personenbezogenen Daten“). Wir bitten Sie, diese
Datenschutzerklärung aufmerksam zu lesen und zu bestätigen, dass
Sie sie gelesen haben. </font></font>
</p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><b>1) Wer ist für
die Verarbeitung personenbezogener Daten verantwortlich und wer ist
der Beauftragte in der EU?</b></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif">Für die Verarbeitung
personenbezogener Daten verantwortlich ist KroniKare Ltd., The Wenta
Business Centre, Watford, Hertfordshire, England, WD24 7ND,
Vereinigtes Königreich.</font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif">Die Kontaktdaten des
Beauftragten in der Europäischen Union für alle Anliegen in
Zusammenhang mit der Verarbeitung personenbezogener Daten gemäß
Art.&nbsp;27 DSGVO lauten wie folgt: Kaleidoscope Data Privacy
Consultants SL (KDPC ES), Calle Balmes 173, 4-2, Barcelona 08006,
Spanien (</font></font><font color="#000000"><font face="Arial, serif"><i><u><a class="western" href="mailto:dpr.kronikare@kdpc.es"><font color="#000000">dpr.kronikare@kdpc.es</font></a></u></i></font></font><font color="#000000"><font face="Arial, serif">)
im Auftrag von KroniKare Ltd.</font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif">Dieser Beauftragte
agiert entsprechend eines schriftlichen Mandats von KroniKare Ltd.,
das den Beauftragten bevollmächtigt, mit den Aufsichtsbehörden
zusammenzuarbeiten und als Ansprechpartner für betroffene Personen
zu fungieren.</font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif">Die Kontaktdaten sind
nachstehend in Abschnitt&nbsp;8) aufgeführt.</font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><b>2) Welche Art
personenbezogener Daten werden verarbeitet und zu welchem ​​Zweck
und auf welcher gesetzlichen Grundlage werden diese verarbeitet?</b></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif">Wir erheben
insbesondere die folgenden grundlegenden personenbezogenen Daten
sowie relevante Nutzungsdaten über Sie: z.&nbsp;B. Benutzername,
E-Mail-Adresse, Telefonnummer, Vorname, zweiter Vorname, Nachname,
Geschlecht, Standort, Titel, Kategorie, Funktion,
Stellenbeschreibung, Anmeldedaten (Protokolldateien) und IP-Adresse. 
Zweck der Datenverarbeitung ist die Vertragserfüllung, insbesondere
um die Registrierung, Anmeldung oder Administration sowie die Nutzung
des Geräts für digitales Wundmanagement und der Software auf der
Grundlage von Art.&nbsp;6&nbsp;Abs.&nbsp;1 Buchstabe&nbsp;b) DSGVO zu
ermöglichen.  </font></font>
</p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><b>3) Wer erhält
meine personenbezogenen Daten?</b></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.14in">
<font color="#000000"><font face="Arial, serif">Wir verarbeiten Ihre
personenbezogenen Daten grundsätzlich nur intern. Falls wir Ihre
personenbezogenen Daten an andere Personen oder Unternehmen oder
sonstige Dritte übermitteln oder diesen anderweitig Zugriff auf die
personenbezogenen Daten gewähren, erfolgt dies ausschließlich auf
der Grundlage einer gesetzlichen Erlaubnis. Sofern wir Dritte mit der
Verarbeitung personenbezogener Daten im Rahmen eines
Auftragsverarbeitungsvertrags beauftragen, geschieht dies auf der
Grundlage von Art.&nbsp;28 DSGVO. Für die Rechtmäßigkeit der
Datenverarbeitung sind wir jedoch nach wie vor Ihnen gegenüber
verantwortlich. </font></font>
</p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><b>4) Wo und wie
lange werden meine personenbezogenen Daten verarbeitet und
gespeichert?</b></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.14in">
<font color="#000000"><font face="Arial, serif">Soweit möglich,
werden wir die Verarbeitung Ihrer personenbezogenen Daten nur
innerhalb des Europäischen Wirtschaftsraums durchführen. Sollte
jedoch eine Verarbeitung Ihrer personenbezogenen Daten in
Drittstaaten erforderlich sein, stellen wir sicher, dass die
spezifischen gesetzlichen Anforderungen für die betreffenden
Verarbeitungsvorgänge erfüllt sind und im jeweiligen Drittland ein
angemessenes Datenschutzniveau gegeben ist. </font></font>
</p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif">Deshalb</font><font face="Arial, serif">
stellen wir sicher, dass geeignete </font><font face="Arial, serif">Sicherheitsvorkehrungen
und Maßnahmen gemäß Art.&nbsp;46 DSGVO vorhanden sind (sofern kein
Angemessenheitsbeschluss vorliegt). Hierzu gehört insbesondere die
Umsetzung von Standardvertragsklauseln. Da KroniKare Ltd. seinen Sitz
im Vereinigten Königreich hat und das Datenschutzniveau im
Vereinigten Königreich nach Ansicht der Europäischen Kommission im
Wesentlichen mit dem der Europäischen Union vergleichbar ist (vgl.
Art.&nbsp;45 DSGVO), erfolgen Übermittlungen personenbezogener Daten
aus der </font><font face="Arial, serif">Europäischen Union</font><font face="Arial, serif">
in dieses Drittland unter Umständen ohne weitere </font><font face="Arial, serif">geeignete</font><font face="Arial, serif">
Sicherheitsvorkehrungen und Maßnahmen.</font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif">Wir speichern Ihre
personenbezogenen Daten grundsätzlich für die Dauer des
Vertragsverhältnisses. Die vorstehenden Hinweise zur Löschung
treffen nicht zu, wenn unter anderem gesetzlich vorgeschriebene
Aufbewahrungsfristen einer sofortigen Löschung entgegenstehen (vgl.
Art.&nbsp;17 Abs.&nbsp;3 DSGVO) oder ein neuer Zweck die weitere
Verarbeitung rechtfertigt.</font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><b>5) Welche Rechte
habe ich als betroffene Person?</b></font></font></p>
<ul>
	<li><p align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Gemäß Art.&nbsp;15
	DSGVO können Sie Auskunft über Ihre von uns verarbeiteten
	personenbezogenen Daten verlangen.</font></font></p>
	<li><p align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Gemäß Art.&nbsp;16
	DSGVO können Sie die unverzügliche Berichtigung unrichtiger oder
	die Vervollständigung unvollständiger personenbezogener Daten, die
	von uns gespeichert werden, verlangen.</font></font></p>
	<li><p align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Gemäß Art.&nbsp;17
	DSGVO können Sie die Löschung Ihrer von uns gespeicherten
	personenbezogenen Daten entsprechend den dort genannten Bedingungen
	verlangen, soweit gesetzlich vorgeschriebene Aufbewahrungsfristen
	einer sofortigen Löschung nicht entgegenstehen (vgl. Art.&nbsp;17
	Abs.&nbsp;3 DSGVO) und/oder ein anderer Fall nach Art.&nbsp;17
	Abs.&nbsp;3 DSGVO vorliegt und/oder ein neuer Zweck die weitere
	Verarbeitung rechtfertigt.</font></font></p>
	<li><p align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Gemäß Art.&nbsp;18
	Abs.&nbsp;1 DSGVO können Sie die Einschränkung der
	Datenverarbeitung verlangen, sofern eine oder mehrere
	Voraussetzungen nach Art.&nbsp;18 Abs.&nbsp;1 Buchstaben&nbsp;a) bis
	d) DSGVO erfüllt sind.</font></font></p>
	<li><p align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Gemäß Art.&nbsp;20
	Abs.&nbsp;1 DSGVO können Sie die von uns verarbeiteten
	personenbezogenen Daten in einem strukturierten, gängigen und
	maschinenlesbaren Format erhalten und diese personenbezogenen Daten
	einem anderen Verantwortlichen ohne Behinderung durch uns
	übermitteln.</font></font></p>
	<li><p align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Darüber hinaus
	können Sie der Verarbeitung Ihrer personenbezogenen Daten gemäß
	Art.&nbsp;21 Abs.&nbsp;1 DSGVO widersprechen. Im Falle eines
	Widerspruchs werden wir Ihre personenbezogenen Daten nicht mehr
	verarbeiten. Das Widerspruchsrecht besteht jedoch nur unter
	besonderen Umständen, die sich aus Ihrer persönlichen Situation
	ergeben. Des Weiteren können zwingende schutzwürdige Gründe
	überwiegen, die für eine Verarbeitung sprechen. Ferner können
	bestimmte Verarbeitungszwecke Ihrem Widerspruchsrecht
	entgegenstehen. </font></font>
	</p>
	<li><p align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Unbeschadet eines
	sonstigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs
	steht Ihnen außerdem ein Recht auf Beschwerde bei der zuständigen
	Aufsichtsbehörde (vgl. Art.&nbsp;77 DSGVO) zu, wenn Sie der Ansicht
	sind, dass die Verarbeitung Ihrer personenbezogenen Daten gegen
	datenschutzrechtliche Bestimmungen verstößt. In diesem
	Zusammenhang bitten wir Sie jedoch, etwaige Beschwerden zunächst an
	die unten unter 8) angegebenen Kontaktdaten zu richten. </font></font>
	</p>
</ul>
<p align="justify" style="line-height: 150%; margin-left: 0.2in; margin-bottom: 0in">
<br/>

</p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><b>6) Bin ich zur
Bereitstellung meiner personenbezogenen Daten verpflichtet?</b></font><font face="Arial, serif">
</font></font>
</p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif">Sie sind lediglich
verpflichtet, uns die personenbezogenen Daten bereitzustellen, die
für die Anbahnung, Durchführung und Beendigung eines
Vertragsverhältnisses erforderlich sind oder die wir grundsätzlich
für die Erbringung unserer Dienstleistungen benötigen. Ohne diese
personenbezogenen Daten können wir in der Regel keinen Vertrag mit
Ihnen abschließen und ausführen und unsere Dienstleistungen nicht
für Sie erbringen. </font></font>
</p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><b>7) Nutzen wir
automatisierte Entscheidungsfindung?</b></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif">Wir verwenden keine
rein automatisierten Entscheidungsfindungsverfahren gemäß Art.&nbsp;22
DSGVO. Sollten wir zukünftig in Einzelfällen dennoch ein solches
Verfahren einsetzen, werden wir Sie darüber gesondert informieren,
sofern dies gesetzlich vorgeschrieben ist.</font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.11in"><font color="#000000"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><b>8)
Haben Sie weitere Fragen?</b></font></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.14in">
<font color="#000000"><font face="Arial, serif">Bitte wenden Sie sich
an uns, um mehr zum Datenschutz zu erfahren</font><font face="Arial, serif">
oder falls Sie weitere Fragen insbesondere zur Wahrnehmung Ihrer
Rechte als betroffene Person </font><font face="Arial, serif">bei
KroniKare Ltd. haben. Datenschutzbeauftragter für das Vereinigte
Königreich (dpo.kronikare@kdpc.uk) und Vertreter in der Europäischen
Union (dpr.kronikare@kdpc.es).</font> </font>
</p>
</body>
</html>
  `,
}

export default { ...main }
