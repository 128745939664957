//FR
const main = {
  ValidationMessages_UsernameRequired_display: true,
  ValidationMessages_UsernameRequired_text: "Le nom d'utilisateur est requis",
  ValidationMessages_PreferredUsernameRequired_display: true,
  ValidationMessages_PreferredUsernameRequired_text: "Le nom d'utilisateur préféré est requis",
  ValidationMessages_PasswordRequired_display: true,
  ValidationMessages_PasswordRequired_text: 'Le mot de passe est requis',
  ValidationMessages_CurrentPasswordRequired_display: true,
  ValidationMessages_CurrentPasswordRequired_text: 'Le mot de passe actuel est requis',
  ValidationMessages_NewPasswordRequired_display: true,
  ValidationMessages_NewPasswordRequired_text: 'Un nouveau mot de passe est nécessaire',
  ValidationMessages_ConfirmNewPasswordRequired_display: true,
  ValidationMessages_ConfirmNewPasswordRequired_text:
    'Confirmer le nouveau mot de passe est nécessaire',
  ValidationMessages_PasswordDoNotMatch_display: true,
  ValidationMessages_PasswordDoNotMatch_text:
    'Les mots de passe sont différents! Veuillez saisir des mots de passe identiques.',
  ValidationMessages_PasswordCriteria_display: true,
  ValidationMessages_PasswordCriteria_text:
    "Doit contenir au moins 8 caractères (12+ sont recommandés), dont une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial ( !”#$%&'()*+,-./:;<=> ?",
  ValidationMessages_verificationCodeRequired_display: true,
  ValidationMessages_verificationCodeRequired_text: 'Le code de vérification est requis',
}

export default { ...main }
