//basic
const main = {
  Eula_title_display: true,
  Eula_title_text: `CONTRAT DE LICENCE D'UTILISATEUR FINAL`,
  Eula_labelAgreeButton_display: true,
  Eula_labelAgreeButton_text: 'Accepter',
  Eula_display: true,
  Eula_text: `
 <!DOCTYPE html>
<html>
<head>
	<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
	<title></title>
	<meta name="generator" content="LibreOffice 7.4.4.2 (Linux)"/>
	<meta name="author" content="Bilgin Berksoy"/>
	<meta name="created" content="2022-05-11T21:47:00"/>
	<meta name="changed" content="2023-08-22T19:02:13.451815412"/>
	<meta name="AppVersion" content="16.0000"/>
	<meta name="ComplianceAssetId" content=""/>
	<meta name="ContentTypeId" content="0x010100476224809ABE224A900BF4DFEFD0895F"/>
	<meta name="Order" content="349700"/>
	<meta name="SharedWithUsers" content="17;#Wypior Sebastian;#13;#Rouais Nicolas;#107;#Jung Alexander;#12;#Bilgin Berksoy"/>
	<meta name="TemplateUrl" content=""/>
	<meta name="TriggerFlowInfo" content=""/>
	<meta name="_ExtendedDescription" content=""/>
	<meta name="xd_ProgID" content=""/>
	<meta name="xd_Signature" content="false"/>
	<style type="text/css">
		@page { size: 8.27in 11.69in; margin-left: 0.98in; margin-right: 0.98in; margin-top: 0.98in; margin-bottom: 0.79in }
		p { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }
		p.western { font-family: "Calibri", serif }
		a:link { color: #000000; text-decoration: underline }
		a.western:link { font-family: "Arial", serif; font-style: italic }
		a.cjk:link { font-family: "Arial"; font-style: italic }
		a.ctl:link { font-family: "Arial" }
	</style>
</head>
<body lang="fr-FR" link="#000000" vlink="#800000" dir="ltr"><p align="center" style="line-height: 115%; margin-bottom: 0.08in">

</p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif">EN
UTILISANT CE LOGICIEL ET SES MISES À JOUR (COLLECTIVEMENT DÉNOMMÉS
«&nbsp;LOGICIEL&nbsp;»), L’UTILISATEUR FINAL TITULAIRE DE LA
LICENCE CONFIRME QUE VOUS DÉTENEZ UNE COPIE DE CE CONTRAT DE LICENCE
D’UTILISATEUR FINAL (CLUF) ET ACCEPTEZ LES CONDITIONS DE LICENCE
ÉNONCÉES CI-DESSOUS. SI L’UTILISATEUR FINAL DÉTENTEUR DE LA
LICENCE N’ACCEPTE PAS LES CONDITIONS DE CE CONTRAT, IL EST INTERDIT
D’UTILISER CE LOGICIEL. LES CONDITIONS DE CETTE LICENCE COMPRENNENT
NOTAMMENT LES LIMITES DE RESPONSABILITÉ STIPULÉES DANS L’ARTICLE&nbsp;11
DE LA LICENCE</font></font></p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif">Ce
Contrat de licence d'utilisateur final prend effet lorsque
l'Utilisateur final titulaire de la licence en accepte les conditions
via la procédure documentée dans ce CLUF et il constitue un accord
juridique entre&nbsp;:</font></font></p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif">KroniKare
Pte. Ltd. dont le siège social est situé 75 Ayer Rajah Crescent
#03-12, Singapour 139953, (dénommé ci-après «&nbsp;Concédant&nbsp;»),
</font></font>
</p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif">et</font></font></p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif">l'Organisation
(dénommée ci-après «&nbsp;Utilisateur final détenteur de la
licence&nbsp;»). </font></font>
</p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif">Le
Concédant accorde à l'Utilisateur final titulaire de la licence un
droit d'utilisation limité, non exclusif et non transférable&nbsp;:
</font></font>
</p>
<ul>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">du logiciel du
	Système numérique de soin des plaies Vintens™
	<b>(«&nbsp;Analyseur/Scanner&nbsp;»)</b> et des informations
	fournies avec celui-ci, et le logiciel associé/auxiliaire
	comprenant l'application du système, le tableau de bord et le
	logiciel d'arrière-plan<b> («&nbsp;Logiciel&nbsp;»)&nbsp;; </b>et</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">des Documents</font></font></p>
</ul>
<p align="justify" style="line-height: 115%; margin-bottom: 0in"><font color="#000000"><font face="Arial, serif">aux
fins explicitement établies dans cette Licence («&nbsp;Licence
d'utilisateur final&nbsp;»). Tout droit non accordé explicitement
dans cette Licence est réservé par le Concédant. Le Concédant
accorde à l'Utilisateur final détenteur de la licence le droit
d'utiliser le Logiciel et les Documents sur la base de cette Licence.
Le Concédant ne vend ni le Logiciel ni les Documents à
l'Utilisateur final détenteur de la licence. </font></font>
</p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif"><b>PRÉAMBULE</b></font></font></p>
<ol type="A">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Le Concédant est le
	fabricant de l'Analyseur/Scanner et du Logiciel et le concepteur des
	Documents&nbsp;; le Concédant a conclu un contrat de location avec
	ses distributeurs concernant la fourniture de l'Analyseur/Scanner à
	l'Utilisateur final détenteur de la licence&nbsp;; </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">L'Utilisateur final
	détenteur de la licence a conclu un contrat de sous-location avec
	les distributeurs du Concédant afin de louer l'Analyseur/Scanner
	(«&nbsp;Contrat de sous-location&nbsp;») et il souhaite utiliser
	le Logiciel et les Documents pour se servir de l'Analyseur/Scanner
	conformément à son utilisation prévue&nbsp;;</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">L'Utilisateur final
	détenteur de la licence souhaite utiliser l'Analyseur/Scanner, le
	Logiciel et les Documents dans le cadre de son activité de soins
	des patients, et il souhaite rendre le Logiciel accessible à des
	utilisateurs spécifiques sélectionnés de son organisation
	(Utilisateurs autorisés)&nbsp;; </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Considérant les
	éléments ci-dessus, l'Utilisateur final détenteur de la licence
	accepte d'utiliser le Logiciel et les services associés
	conformément aux dispositions de ce CLUF. </font></font>
	</p>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in; page-break-before: always">
<font color="#000000"><font face="Arial, serif">Il a été convenu
des éléments suivants&nbsp;: </font></font>
</p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif"><b>1.	Définitions</b></font></font></p>
<p class="western" align="justify" style="line-height: 115%; orphans: 0; widows: 0; margin-left: 0.49in; text-indent: -0.49in; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif">1,1.</font>	<font face="Arial, serif">Aux
fins de ce CLUF, les définitions suivantes, lorsqu'elles sont en
majuscules, qu'elles soient au singulier ou au pluriel, s'appliquent
au sens mentionné ci-dessous&nbsp;:</font></font></p>
<ol type="a">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Le terme
	«&nbsp;<b>Utilisateur admin&nbsp;»</b> désigne la personne
	appartenant à l'organisation de l'Utilisateur final détenteur de
	la licence désignée par celui-ci comme <span style="background: #ffffff">étant
	responsable de l'entretien, de la configuration et du bon
	fonctionnement du Logiciel, et désignée comme représentant de
	l'Utilisateur final détenteur de la licence conformément au droit
	du pays où est établi l'Utilisateur final détenteur de la licence
	chargée d'accepter ce CLUF au nom de l'Utilisateur final détenteur
	de la licence. </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Le terme <b>«&nbsp;Appareil
	agréé&nbsp;»</b> désigne l'Analyseur/Scanner. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Le terme
	<b>«&nbsp;Utilisateur autorisé&nbsp;»</b> désigne toute personne
	appartenant à l'organisation de l'Utilisateur final détenteur de
	la licence (c'est-à-dire les employés directs de l'Utilisateur
	final détenteur de la licence ou les professionnels de santé qui
	exercent avec l'Utilisateur final détenteur de la licence) qui peut
	obtenir un accès légitime au Logiciel et est autorisée à
	l'utiliser sur la base d'une ou plusieurs Licences personnelles et
	non transférables obtenues par l'Utilisateur final détenteur de la
	licence. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Le terme
	<b>«&nbsp;Documents&nbsp;»</b> désigne tous les documents
	imprimés et électroniques liés à l'Analyseur/Scanner et au
	Logiciel, y compris sans s'y limiter, les Instructions d'utilisation
	et les accords éventuels concernant l'utilisation de
	l'Analyseur/Scanner. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Le terme
	«&nbsp;<b>Distributeurs&nbsp;</b>» désigne les<span style="background: #ffffff">
	personnes légales de la chaîne logistique, autres que le fabricant
	ou l'importateur, qui mettent l'</span>Analyseur/Scanner<span style="background: #ffffff">
	à la disposition de l'Utilisateur final</span> détenteur de la
	licence<span style="background: #ffffff">. </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Le terme <b>«&nbsp;Date
	d'entrée en vigueur&nbsp;»</b> désigne la date mentionnée dans
	le haut de ce CLUF.</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Le terme
	<b>«&nbsp;Utilisateur final</b> <b>détenteur de la licence&nbsp;»</b>
	désigne la partie contractuelle identifiée dans le CLUF à
	laquelle le Concédant accorde une licence pour le Logiciel, pour un
	usage interne uniquement (et non pour la redistribution, la
	re-commercialisation ou la multipropriété), et qui a conclu un
	Contrat de sous-location avec les distributeurs du Concédant pour
	l'utilisation du dispositif Analyseur/Scanner. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Le terme<b>
	«&nbsp;Perturbation&nbsp;»</b> désigne tout dysfonctionnement ou
	toute détérioration des caractéristiques ou des performances d'un
	appareil disponible sur le marché, y compris les erreurs
	d'utilisation inhérentes aux caractéristiques ergonomiques, les
	inadéquations des informations fournies par le fabricant et les
	effets secondaires indésirable.</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Le terme
	<b>«&nbsp;Instructions d'utilisation&nbsp;»</b> désigne les
	informations données par le fabricant pour informer l'utilisateur
	de l'utilisation prévue et de l'utilisation correcte d'un appareil
	et de toutes les précautions indispensables.<span style="background: #ffffff">
	</span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Le terme<b> «&nbsp;Droits
	de propriété intellectuelle&nbsp;»</b> désigne les brevets,
	marques commerciales, modèles d'utilité, secrets commerciaux, noms
	commerciaux, marques de service, noms de domaine, droits d'auteur,
	droits sur les bases de données (y compris les droits visant à
	empêcher l'extraction ou la réutilisation d'informations d'une
	base de données), droits de conception, droits de topographie, la
	conception du Logiciel, l'interface utilisateur (IU), la conception
	de l'IU, les droits de marque en vertu des lois sur l'exclusivité
	des données, l'extension de ces droits et tous les droits ou formes
	de protection de nature similaire ou ayant un effet équivalent ou
	semblable à l'un d'entre eux qui peuvent subsister dans le monde
	entier, qu'ils soient enregistrés ou non, ainsi que les demandes et
	le droit de demander l'enregistrement de l'un des biens et droits
	susmentionnés, et les droits semblables ou analogues dans le monde
	entier. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Le terme<b>
	«&nbsp;Partie&nbsp;»</b> désigne chacune des parties
	contractantes de ce CLUF. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Le terme<b>
	«&nbsp;Parties&nbsp;»</b> désigne conjointement les deux parties
	contractantes. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Le terme <b>«&nbsp;Données
	Personnelles&nbsp;»</b> désigne toute information concernant une
	personne physique identifiée ou identifiable&nbsp;; une personne
	physique identifiable est une personne qui peut être identifiée,
	directement ou indirectement, notamment par référence à un
	identifiant de type nom, numéro d'identification, données de
	localisation, identifiant en ligne ou un ou plusieurs éléments
	spécifiques à l'identité physique, physiologique, génétique,
	psychique, économique, culturelle ou sociale de cette personne
	physique&nbsp;;</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Le terme<b>
	«&nbsp;Version&nbsp;»</b> désigne les modifications, les mises à
	jour et/ou les mises à niveau du Logiciel que le Concédant choisit
	de mettre à la disposition générale de l'Utilisateur final
	détenteur de la licence.</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
	<font color="#000000"><font face="Arial, serif">Le<b> </b>terme<b>
	«&nbsp;Territoire&nbsp;»</b> désigne le pays dans lequel
	l'Utilisateur final détenteur de la licence est établi.</font></font></p>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif"><b>2.&nbsp;	CONCESSION
ET ÉTENDUE DE LA LICENCE</b></font></font></p>
<ol start="2">
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a944711_1"></a>
		<font color="#000000"><font face="Arial, serif">Le Concédant
		accorde à l'Utilisateur final détenteur de la licence, pour la
		durée du Contrat de sous-location de l'Analyseur/Scanner (sauf
		résiliation anticipée conformément aux dispositions du présent
		accord), que ce contrat soit conclu avec le Concédant ou avec un
		Distributeur, et, pour le Territoire, une licence d'utilisation du
		Logiciel et des Documents non exclusive, non transférable et
		limitée conformément aux conditions de la présente Licence. Les
		droits accordés par le présent CLUF seront exercés par
		l'Utilisateur final détenteur de la licence à partir de la Date
		d'entrée en vigueur.</font></font></p>
	</ol>
</ol>
<ol>
	<ol type="a">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Le CLUF est réputé
		valablement exécuté et effectif entre les Parties à compter de
		la Date d'entrée en vigueur. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">L'Utilisateur final
		licencié et ses employés dûment autorisés (Utilisateurs agréés)
		sont autorisés, en vertu des conditions de la présente Licence,
		à&nbsp;:</font></font></p>
		<ol type="i">
			<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a298333_1"></a>
			<font color="#000000"><font face="Arial, serif">télécharger,
			installer et utiliser le Logiciel à des fins professionnelles
			internes uniquement, conformément aux Documents et à
			l'utilisation prévue de l'Analyseur/Scanner, et à utiliser le
			Logiciel conformément aux Instructions d'utilisation de
			l'Analyseur/Scanner pour le nombre d'Utilisateurs agréés convenu
			dans le Contrat de sous-location</font></font></p>
			<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
			<font color="#000000"><font face="Arial, serif">recevoir et
			utiliser tout code logiciel supplémentaire gratuit et toute mise
			à jour du Logiciel contenant des «&nbsp;correctifs&nbsp;» et
			des rectificatifs d'erreurs fournis par le Concédant de temps à
			autre&nbsp;; et à</font></font></p>
			<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a397482_1"></a>
			<font color="#000000"><font face="Arial, serif">utiliser tout
			Document en soutien à l'utilisation autorisée par la présente
			licence, comme spécifié à l'article 2, et à faire les copies
			des Documents raisonnablement nécessaires à une utilisation
			légale.</font></font></p>
		</ol>
	</ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Sans préjudice des
	autres articles du présent CLUF, si, à tout moment pendant la
	durée du présent CLUF, le nombre d'utilisateurs du Logiciel est
	supérieur au nombre d'Utilisateurs agréés accordé à
	l'Utilisateur final détenteur de la licence selon l'article
	2.1(b)(i) ci-dessus, le Concédant est en droit de résilier le
	présent CLUF sans préavis. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
	<font color="#000000"><font face="Arial, serif">Les éléments
	suivants sont expressément exclus du champ d'application du présent
	CLUF&nbsp;: (i) les licences concernant les logiciels de base de
	tiers (de type, mais sans limitation, systèmes d'exploitation,
	Microsoft ® Excel ®, Adobe Reader ®, etc.) et (ii) les logiciels
	fournis à l'Utilisateur final détenteur de la licence par des
	tiers (partenaires d'intégration) pour intégrer le Logiciel dans
	les systèmes et/ou le système de dossiers médicaux électroniques
	de l'Utilisateur final détenteur de la licence (iii) tout ce qui
	n'est pas expressément énuméré par le Concédant dans le présent
	CLUF. </font></font>
	</p>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><br/>
<br/>

</p>
<ol start="2">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>PROPRIÉTÉ</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">L'Utilisateur final
		détenteur de la licence accepte que le Concédant conserve tous
		les droits, titres et intérêts sur le Logiciel. </font></font>
		</p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>RESTRICTIONS</b></font></font></p>
</ol>
<ol start="4">
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Sauf disposition
		expresse dans la présente Licence, l'Utilisateur final détenteur
		de la licence accepte de ne pas&nbsp;: </font></font>
		</p>
	</ol>
</ol>
<ol type="a">
	<ol type="a">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a618882_1"></a>
		<font color="#000000"><font face="Arial, serif">copier le Logiciel
		ni les Documents&nbsp;;</font></font></p>
	</ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">louer, louer à
	bail, accorder une sous-licence le concernant, prêter, traduire,
	fusionner, adapter, varier ni modifier le Logiciel et les
	Documents&nbsp;;</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a168255_1"></a>
	<font color="#000000"><font face="Arial, serif">apporter des
	altérations ou des modifications à l'ensemble du Logiciel ou à
	une partie de celui-ci, ni permettre de combiner ou d'incorporer le
	Logiciel ou une partie de celui-ci dans un autre programme, quel
	qu'il soit&nbsp;;</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">désassembler,
	décompiler, soumettre à une ingénierie inverse ou créer des
	travaux dérivés basés sur tout ou partie du Logiciel, ni tenter
	de le faire, sauf s'il est impossible d'interdire ces actions car
	elles sont essentielles pour réaliser l'interopérabilité du
	Logiciel avec un autre programme logiciel, et à condition que les
	informations obtenues par l'Utilisateur final détenteur de la
	licence et/ou l'Utilisateur agréé pour ces activités soient
	utilisées uniquement pour réaliser l'interopérabilité du
	Logiciel avec un autre programme logiciel&nbsp;; et</font></font></p>
	<ol type="a">
		<ol type="i">
			<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
			<font color="#000000"><font face="Arial, serif">n'est pas
			inutilement divulgué ni communiqué à un tiers sans le
			consentement écrit préalable du Concédant&nbsp;; et</font></font></p>
			<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a146892_1"></a>
			<font color="#000000"><font face="Arial, serif">n'est pas utilisé
			pour créer un logiciel semblable pour l'essentiel au Logiciel&nbsp;;</font></font></p>
		</ol>
	</ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">fournir ni mettre à
	disposition de toute autre manière que ce soit le Logiciel en
	partie ou dans son intégralité (y compris, mais sans s'y limiter,
	les listes de programmes, les listes de programmes objet et source,
	le code objet et le code source), sous quelque forme que ce soit, à
	toute personne sans le consentement écrit préalable&nbsp;du
	Concédant&nbsp;; et </font></font>
	</p>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.89in; margin-bottom: 0.08in">
<font color="#000000"><font face="Arial, serif">télécharger,
installer ou utiliser le Logiciel sur des appareils non autorisés.</font></font></p>
<ol start="4">
	<ol start="2">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Sans préjudice de
		ce qui précède, l'Utilisateur final détenteur de la licence est
		autorisé à faire une copie des Documents aux fins de la gestion
		du Contrat (CLUF) et, lorsque cela est impératif, aux fins des
		soins aux patients. L'Utilisateur final détenteur de la licence
		peut, par l'intermédiaire de son Utilisateur admin, télécharger
		des rapports de statut de type journaux d'utilisateur ou
		d'appareils, statistiques de haut niveau relatives aux patients et
		autres informations pertinentes concernant directement les soins
		aux patients pour les obligations de soins, la gestion des
		ressources et les rapports de l'Utilisateur final détenteur de la
		licence.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">L'Utilisateur final
		détenteur de la licence utilise le Logiciel seulement tel qu'il
		est installé sur les Appareils agréés. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">L'Utilisateur final
		détenteur de la licence doit superviser et contrôler
		l'utilisation du Logiciel par les Utilisateurs agréés et
		s'assurer que le Logiciel est utilisé par les Utilisateurs agréés
		conformément aux conditions de la présente Licence&nbsp;;</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">L'Utilisateur final
		détenteur de la licence doit se conformer à toutes les lois et
		réglementations applicables en matière de contrôle des
		technologies ou de l'exportation.</font></font></p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.49in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>SERVICES
	D'ASSISTANCE</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="_Hlk74843159"></a>
		<font color="#000000"><font size="2" style="font-size: 10pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt">Par
		l'intermédiaire de ses Distributeurs, le Concédant fournit les
		services d'assistance inhérents au Logiciel raisonnablement
		demandés à l'Utilisateur final Détenteur de la licence pendant
		la durée du présent contrat. L'Utilisateur final détenteur de la
		licence peut demander une assistance via le système de tickets du
		Distributeur, celle-ci étant soumise aux conditions du Contrat de
		sous-location.</font></font></font></font></p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>DONNÉES
	PERSONNELLES</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><font color="#000000">L'utilisation
		du Logiciel implique le traitement de Données Personnelles
		concernant les patients et le personnel de l'Utilisateur final
		détenteur de la licence tel qu'il est défini dans le Règlement
		(UE) 2016/679 - Règlement général sur la protection des données
		(RGPD). Le traitement des Données Personnelles sera effectué par
		la société affiliée du Concédant, KroniKare Limited, dont le
		siège social se trouve à Watford, à l'adresse suivante&nbsp;:
		The Wenta Business Centre, Watford, Hertfordshire, Angleterre, WD24
		7ND, Royaume-Uni. Le représentant dans l'Union Européenne pour le
		compte de KroniKare Ltd. sur tout ce qui concerne le traitement des
		Données Personnelles conformément à l'art. 27 du RGPD est
		joignable aux coordonnées suivantes&nbsp;: Kaleidoscope Data
		Privacy Consultants SL (KDPC ES), Calle Balmes 173, 4-2, Barcelona
		08006, Espagne (</font><font color="#000000"><font face="Arial, serif"><i><u><a class="western" href="mailto:dpr.kronikare@kdpc.es"><font color="#000000">dpr.kronikare@kdpc.es</font></a></u></i></font></font><font color="#000000">).
		Ce représentant agit dans les limites d'un mandat écrit que
		KroniKare Ltd. lui a attribué, qui autorise le représentant à
		coopérer avec les autorités de surveillances et de faire office
		de point de contact pour tout ce qui concerne les Données.</font></font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">L'Utilisateur final<i>
		</i>détenteur de la licence, en sa capacité de contrôleur en
		vertu du RGPD (voir art. 4 (7) RGPD), accepte de conclure un accord
		de sous-traitance de traitement des Données conforme à l'art. 28
		RGPD avec KroniKare Limited (en tant que responsable du traitement
		des Données, voir art. 4 (8) RGPD) portant sur le traitement des
		Données Personnelles destiné à fournir des &quot;<i>Soins des
		plaies par le Responsable du traitement</i>&quot;. Les
		Distributeurs se chargent de l'exécution des conclusions. D'après
		la Commission Européenne, le Royaume Uni offre un niveau de
		protection équivalent à celui de l'Union Européenne (cf. Art. 45
		RGPD), le transfert de Données Personnelles de l'Union Européenne
		dans ce pays tiers peut par conséquent avoir lieu sans précautions
		ni mesures de sécurité supplémentaires.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="_Hlk94996667"></a><a name="_Hlk94996298"></a>
		<font color="#000000"><font face="Arial, serif">Sans préjudice de
		l'article 6.2, KroniKare Limited fera office de responsable du
		traitement de toutes les Données Personnelles inhérentes aux
		accords conclus avec KroniKare Limited, généralement concernant
		les Données Personnelles de l'Utilisateur final détenteur de la
		licence et pour éviter toute ambiguïté, en particulier lorsque
		l'Utilisateur final détenteur de la licence est un individu ou un
		propriétaire unique, par exemple un médecin praticien ou
		indépendant ou un infirmier indépendant ou auto-entrepreneur.
		Cette responsabilité du Concédant ne couvre en aucun cas les
		Données des patients fournies par l'Utilisateur final détenteur
		de la licence. Une Politique de confidentialité correspondante
		conforme aux Art. 13 et 14 du RGPD se trouve à la fin de ce CLUF.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">Le traitement des
		Données Personnelles en vertu de l'article 6.2 destiné à fournir
		des soins de santé exige de stocker et d'accéder à des
		informations, y compris des Données Personnelles concernant
		l'Utilisateur agréé et stockées dans l'équipement terminal
		(Analyseur/Scanner et, lorsqu'ils sont disponibles, autres
		dispositifs sur lesquels le Logiciel peut être utilisé), et
		d'obtenir un accès aux informations concernant la fourniture de
		services de traitement soumis au contrôle de l'Utilisateur final
		détenteur de la licence. L'Utilisateur final détenteur de la
		licence garantit qu'il fournira à l'Utilisateur agréé des
		informations claires concernant les objectifs du traitement des
		Données Personnelles et autres informations requises.</font></font></p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>ACCEPTATION DU
	CLUF ET MISE À DISPOSITION DE COMPTES D'UTILISATEURS</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Avant d'utiliser la
		Licence, l'Utilisateur final détenteur de la licence accepte les
		conditions de la Licence telles que documentées dans le présent
		CLUF. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">L'Utilisateur final
		détenteur de la licence garantit que l'Utilisateur admin est
		autorisé à le représenter pour conclure le présent Contrat de
		licence d'utilisateur final, en acceptant les conditions de
		celui-ci avant la première utilisation du Logiciel.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Lors de sa première
		connexion, l'Utilisateur admin doit accepter les conditions du
		présent CLUF en cliquant sur un bouton d'acceptation qui s'affiche
		sur chacun des appareils, et il doit en fournir une copie par
		courrier électronique à l'Utilisateur final détenteur de la
		licence. Dès l'acceptation du présent CLUF, tous les Utilisateurs
		agréés sont autorisés à utiliser le Logiciel et
		l'Analyseur/Scanner conformément aux conditions et pendant la
		durée de la présente Licence, comme mentionné à l'article 13 de
		cet accord.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">Le Concédant
		fournira à l'Utilisateur final détenteur de la licence les
		comptes d'utilisateurs pour le nombre d'Utilisateurs agréés
		convenu, conformément au Contrat de sous-location existant entre
		l'Utilisateur final détenteur de la licence et le Distributeur. Il
		est possible de demander des comptes supplémentaires pour des
		Utilisateurs agréés via le tableau de bord du Logiciel. Il
		n'existe aucune limite en matière de nombre de comptes
		d'Utilisateurs agréés.</font></font></p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>MISES À JOUR DU
	LOGICIEL</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font size="2" style="font-size: 10pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt">Le
		Concédant est en droit de fournir des mises à jour du Logiciel à
		sa discrétion. Les mises à jour</font></font> <font face="Arial, serif"><font size="2" style="font-size: 11pt">sont
		installées automatiquement via des mises à jour en mode push.
		L'Utilisateur final détenteur de la licence ne peut refuser ni
		reporter les mises à jour du Logiciel. </font></font></font></font>
		</p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0in">
<br/>

</p>
<ol start="4">
	<ol start="2">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font size="2" style="font-size: 10pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt">Sans
		préjudice de ce qui précède, l'Utilisateur final détenteur de
		la licence n'a pas le droit de recevoir des mises à jour telles
		que décrites à l'article 8.1 ni n'a le droit de demander des
		mises à jour spécifiques ou générales. </font></font></font></font>
		</p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>DROITS DE
	PROPRIÉTÉ INTELLECTUELLE&nbsp;ET INDEMNISATION</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a409880_1"></a>
		<font color="#000000"><font face="Arial, serif">L'Utilisateur final
		détenteur de la licence reconnaît et accepte que tous les droits
		industriels et les droits de propriété intellectuelle (y compris
		les droits exclusifs d'exploitation économique) du Logiciel et des
		Documents dans le monde entier sont et resteront la propriété
		exclusive du Concédant. Aucune disposition de ce CLUF ne vise à
		ou ne doit être interprétée de manière à transférer aucun de
		ces droits à l'Utilisateur final détenteur de la licence ou à un
		tiers. Sans préjudice du droit d'utilisation du Logiciel
		expressément accordé dans le CLUF, l'Utilisateur final détenteur
		de la licence ne revendique aucun droit, y compris les droits de
		copie sur le Logiciel et sur aucun de ses composants, ou tout autre
		droit de propriété industrielle ou intellectuelle afférent.
		L'Utilisateur final détenteur de la licence accepte expressément
		d'adopter toutes les mesures adéquates de protection du contenu du
		Logiciel qui permettent d'empêcher la divulgation ou l'utilisation
		non autorisée, y compris par les Utilisateurs agréés.
		L'Utilisateur final détenteur de la licence reconnaît que le
		Concédant a le droit de prendre des mesures directes et immédiates
		en cas de violation de ses droits sur le Logiciel par, ou autorisée
		par, l'Utilisateur final détenteur de la licence et ses
		Utilisateurs agréés, et qu'il a le droit de faire valoir ces
		droits en choisissant les moyens appropriés. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a352428_1"></a>
		<font color="#000000"><font face="Arial, serif">L'Utilisateur final
		détenteur de la licence accepte le fait que les droits sur le
		Logiciel sont concédés sous licence (et non vendus) à
		l'Utilisateur final détenteur de la licence, et que l'Utilisateur
		final détenteur de la licence et ses Utilisateurs agréés n'ont
		aucun droit sur le Logiciel ou les Documents autre que le droit de
		les utiliser conformément aux conditions de la présente Licence.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Afin d'éviter
		toute ambiguïté, toutes les idées, inventions, améliorations,
		méthodes, processus, œuvres d'auteur et autres formes de
		propriété intellectuelle que l'Utilisateur final détenteur de la
		licence conçoit, met en pratique ou développe, seul ou
		conjointement avec d'autres, en relation avec ou sur la base des
		connaissances ou des informations apprises ou obtenues à partir du
		Logiciel et des Documents, seront la propriété unique et
		exclusive du Concédant, d'un tiers désigné par le Concédant ou
		conjointement du Concédant et d'un tiers désigné par le
		Concédant. L'Utilisateur final détenteur de la licence accepte
		par la présente de céder l'ensemble de cette propriété
		intellectuelle au Concédant et/ou à un tiers désigné par le
		Concédant et il renonce à tout autre droit que l'Utilisateur
		final détenteur de la licence pourrait avoir sur celle-ci.
		L'Utilisateur final détenteur de la licence s'engage, pendant la
		durée du présent Contrat ou à tout moment suivant son
		expiration, à ne pas remettre en question ni à contester la
		propriété intellectuelle, de quelque manière que ce soit. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">L'Utilisateur final
		détenteur de la licence reconnaît que ni lui ni son/ses
		Utilisateur(s) autorisé(s) n'ont un quelconque droit d'accès au
		Logiciel sous forme de code source.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Le Concédant
		déclare et garantit qu'à sa connaissance, aucune réclamation n'a
		été déposée et qu'aucune action n'est en instance ni ne menace
		le Concédant, selon laquelle le Logiciel violerait les Droits de
		propriété intellectuelle de tiers. Si l'Utilisateur final
		détenteur de la licence a connaissance de réclamations ou de
		(menaces de) poursuites contre le Concédant concernant des Droits
		de propriété intellectuelle de tiers, l'Utilisateur final
		détenteur de la licence en informera le Concédant sans délai. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">En cas de
		réclamation d'un tiers à l'encontre de l'Utilisateur final
		détenteur de la licence au motif que le Logiciel porte atteinte
		aux Droits de propriété Intellectuelle d'un tiers, l'Utilisateur
		final détenteur de la licence informe le Concédant sans délai et
		donne au Concédant le contrôle exclusif de la défense et/ou du
		règlement de la réclamation et coopérera pleinement avec le
		Concédant dans la défense ou le règlement de la réclamation.
		L'Utilisateur final détenteur de la licence ne prend aucune mesure
		susceptible de limiter la capacité du Concédant à défendre la
		réclamation. Le Concédant donne à l'Utilisateur final licencié
		la possibilité de surveiller la procédure. Si l'Utilisateur final
		détenteur de la licence estime qu'une intervention est nécessaire
		pour éviter tout préjudice pour lui-même, l'Utilisateur final
		détenteur de la licence en informe le Concédant et les Parties
		examinent le plan d'action approprié. Nonobstant ce qui précède,
		la décision finale concernant toute action à entreprendre en
		relation avec une violation (présumée) des Droits de propriété
		Intellectuelle revient au Concédant. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Sans préjudice de
		ce qui précède, si tout ou partie du Logiciel fait l'objet ou, de
		l'avis du Concédant, est susceptible de faire l'objet d'une
		plainte pour contrefaçon, le Concédant, à ses frais, à sa seule
		discrétion et sans autre responsabilité vis-à-vis de
		l'Utilisateur final détenteur de la licence, peut opter pour l'une
		des solutions suivantes, dans la mesure du possible&nbsp;: (i)
		procurer à l'Utilisateur final détenteur de la licence le droit
		d'utiliser le Logiciel&nbsp;; (ii) remplacer le Logiciel par un
		logiciel non contrefaisant et au moins équivalent sur le plan
		fonctionnel (iii) modifier le Logiciel pour le rendre non
		contrefaisant.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">Le Concédant n'est
		pas tenu d'indemniser et n'a aucune responsabilité ni autre
		obligation à l'égard des réclamations fondées sur&nbsp;: (i) la
		combinaison ou l'utilisation du Logiciel avec un autre logiciel non
		approuvé et/ou fourni par le Concédant (ii) l'utilisation du
		Logiciel par l'Utilisateur final détenteur de la licence ou
		l'Utilisateur autorisé de manière incompatible avec le CLUF et/ou
		la description du Logiciel&nbsp;; ou (iii) le défaut d'utilisation
		d'une version fournie par le Concédant, si la violation aurait pu
		être évitée par l'utilisation de la mise à jour ou de la
		dernière version disponible du Logiciel.</font></font></p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a683549_1"></a>
	<font color="#000000"><font face="Arial, serif"><b>GARANTIE</b></font></font></p>
</ol>
<ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Les conditions de
	garantie seront couvertes par le Contrat de sous-location. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Le Concédant n'est
	pas responsable des limitations de fonctionnement ni des défauts du
	Logiciel lorsqu'ils ont été causés, en tout ou en partie, par un
	comportement non autorisé ou permis de l'Utilisateur final
	détenteur de la licence et/ou son/ses Utilisateur(s) agréé(s),
	par une utilisation du Logiciel non conforme à l'utilisation prévue
	du Logiciel ou aux Instructions d'utilisation, par une altération
	ou un non-fonctionnement du Logiciel dérivés d'un logiciel ou
	d'autres produits non fournis par le Concédant. Si le
	dysfonctionnement du Logiciel est la conséquence d'une erreur
	quelconque, l'Utilisateur final détenteur de la licence doit
	immédiatement en informer le Concédant. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
	<font color="#000000"><font face="Arial, serif">Le Concédant n'est
	pas responsable des limitations de fonctionnement, de
	l'indisponibilité du Logiciel ou des manquements aux obligations en
	vertu du présent Contrat, causés par l'Internet, les systèmes
	informatiques ou les systèmes de l'Utilisateur final détenteur de
	la licence. </font></font>
	</p>
</ol>
<p align="justify" style="line-height: 115%; text-indent: -0.5in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a914493_1"></a><a name="co_anchor_a676389_1"></a><a name="co_anchor_a796999_1"></a><a name="co_anchor_a946537_1"></a>
	<font color="#000000"><font face="Arial, serif"><b>LIMITATION DE
	RESPONSABILITÉ</b></font></font></p>
</ol>
<ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Sans préjudice de
	l'article 10 ci-dessus, le Concédant livre le Logiciel à
	l'Utilisateur final détenteur de la licence «&nbsp;en l'état&nbsp;»
	et n'accorde à l'Utilisateur final détenteur de la licence aucune
	garantie, expresse ou implicite, concernant l'adéquation du
	Logiciel à un usage particulier, y compris, mais sans s'y limiter,
	une garantie concernant l'adéquation du Logiciel à un usage
	particulier autre que ce qui est expressément prévu dans le
	présent CLUF ou dans les Instructions d'utilisation. L'Utilisateur
	final détenteur de la licence est tenu de s'assurer que les
	installations et les fonctions du Logiciel décrites dans les
	Documents répondent à ses besoins.</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Il est entendu que,
	dans la mesure permise par toute loi en vigueur, le Concédant n'est
	pas responsable des dommages résultant de (i) toute utilisation du
	Logiciel non conforme au CLUF et aux Instructions d'utilisation&nbsp;;
	tout dysfonctionnement du Logiciel découlant des comportements
	décrits au point (i)&nbsp;; (iii) toute absence de clarté ou
	inexactitude du Logiciel ou des Documents&nbsp;; (iv) des erreurs
	dans le Logiciel ou les Documents&nbsp;; (v) de données ou
	d'informations corrompues, sauf accord contraire entre les Parties. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; orphans: 0; widows: 0; margin-right: 0.05in; margin-bottom: 0.14in">
	<font color="#000000"><font face="Arial, serif">À l'exception des
	dispositions du présent CLUF<i>,</i> le Concédant ne sera en aucun
	cas responsable envers l'Utilisateur final détenteur de la licence
	de toute perte ou de tout dommage direct ou indirect, y compris,
	mais sans s'y limiter, la perte de bénéfices, la perte de
	clientèle, la perte de réputation, la perte d'activité, la perte
	d'opportunités commerciales, la perte d'économies anticipées, la
	perte subie en rapport avec la corruption de données ou
	d'informations ou tout autre dommage spécial, indirect, éloigné
	ou consécutif découlant de la présente Licence ou en rapport avec
	celle-ci, que ce soit dans le cadre d'un contrat, d'un délit (y
	compris la négligence), d'un manquement à une obligation légale
	ou autre. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Nonobstant ce qui
	précède, le Concédant est responsable sans limitation de tout
	dommage résultant d'une faute intentionnelle ou d'une négligence
	grave du Concédant.</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a879858_1"></a><a name="co_anchor_a570100_1"></a><a name="co_anchor_a1003989_1"></a>
	<font color="#000000"><font face="Arial, serif">L'Utilisateur final
	détenteur de la licence accepte et convient que, dans la mesure où
	une réclamation existe ou survient en relation avec la fourniture
	du Logiciel ou des Documents, cette réclamation ne peut être
	intentée que contre l'entité à laquelle l'Utilisateur final
	détenteur de la licence a payé l'achat du Logiciel, conformément
	aux conditions de l'accord entre l'Utilisateur final détenteur de
	la licence et cette entité. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a104167_1"></a>
	<font color="#000000"><font face="Arial, serif">Aucune disposition
	de la présente Licence ne limite ou n'exclut la responsabilité du
	Concédant pour&nbsp;:</font></font></p>
	<ol type="a">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">la mort ou les
		dommages corporels résultant de notre négligence&nbsp;;</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a437879_1"></a>
		<font color="#000000"><font face="Arial, serif">une fraude ou
		allégation frauduleuse.</font></font></p>
	</ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
	<font color="#000000"><font face="Arial, serif">La présente Licence
	définit l'étendue complète des obligations et responsabilités du
	Concédant en ce qui concerne la fourniture du Logiciel et des
	Documents. Sauf mention expresse dans la présente Licence, il
	n'existe aucun article, aucune garantie, représentation ou autre
	condition, expresse ou implicite, qui lie le Concédant. Tout
	article, toute garantie, représentation ou autre condition
	concernant la fourniture du Logiciel et des Documents qui pourrait
	être implicite ou compris dans cette Licence, que ce soit par la
	loi, le droit commun ou autre, est exclu dans toute la mesure
	permise par la loi.</font></font></p>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>CONFIDENTIALITÉ</b></font></font></p>
</ol>
<ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Pendant la durée du
	CLUF, le Concédant et l'Utilisateur final détenteur de la Licence
	échangent des informations confidentielles. Chaque Partie s'engage
	à ne pas divulguer ces informations à un tiers sans le
	consentement exprès préalable de l'autre Partie. Toute information
	confidentielle doit être identifiée comme telle sur les documents
	écrits correspondants ou, si l'information est divulguée
	verbalement, elle doit être expressément identifiée comme
	confidentielle. Ne sont pas considérées comme informations
	confidentielles les informations qui, à la date de leur
	divulgation, sont&nbsp;: (i) dans le domaine public, (ii) déjà
	connues de la Partie non divulgatrice ou (iii) connues de
	l'Utilisateur final détenteur de la licence à une date ultérieure
	autre que celle résultant du présent article. L'Utilisateur final
	détenteur de la licence doit protéger les informations
	confidentielles conformément aux meilleures pratiques actuelles en
	matière de sécurité commerciale. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Les deux Parties
	s'interdisent sans accord écrit préalable de l'autre Partie, de
	divulguer toute partie des informations confidentielles à toute
	personne, sauf&nbsp;:</font></font></p>
	<ol type="a">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">à leurs propres
		employés et consultants, puis uniquement aux employés et
		consultants ayant besoin de les connaître&nbsp;;</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">à un tribunal ou
		une juridiction compétent, à un organisme gouvernemental ou à
		une autorité réglementaire applicable et à toute autre personne
		ou organisme ayant le droit, le devoir ou l'obligation de connaître
		les affaires de l'autre Partie, et ce uniquement dans le cadre de
		ce droit, de ce devoir ou de cette obligation.</font></font></p>
	</ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Les obligations
	énoncées dans les présentes en ce qui concerne les informations
	confidentielles demeurent pleinement en vigueur pendant une période
	de dix (10) ans après la date de divulgation des informations
	confidentielles. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Sans préjudice de
	ce qui précède, les Parties conviennent qu'en ce qui concerne le
	traitement des Données Personnelles soumises au RGPD, les articles
	de confidentialité prévus dans l'accord de sous-traitance de
	traitement des Données entre le Concédant et l'Utilisateur final
	détenteur de la Licence s'appliquent en lieu et place. Le présent
	article 12 ne s'applique donc pas à la confidentialité des Données
	Personnelles qui doit être réglée séparément dans l'accord de
	sous-traitance du traitement des Données entre les Parties, ni aux
	Données anonymisées conformément à l'accord de sous-traitance du
	traitement des Données entre les Parties. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="_Toc520913491"></a>
	<font color="#000000"><font face="Arial, serif">Chaque Partie au
	présent Contrat notifie dans les meilleurs délais à l'autre
	Partie si elle a connaissance d'une violation de la confidentialité
	par toute personne à laquelle elle divulgue tout ou partie des
	informations et apportera à l'autre Partie toute l'assistance
	raisonnable dans le cadre de toute procédure que l'autre Partie
	pourrait engager contre cette personne pour violation de la
	confidentialité.</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
	<font color="#000000"><font face="Arial, serif">Les droits et
	obligations prévus par le présent article demeurent en vigueur
	après la résiliation du présent Contrat sans limite dans le
	temps, mais cessent de s'appliquer aux informations ou connaissances
	susceptibles de tomber dans le domaine public. </font></font>
	</p>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a534011_1"></a>
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>RÉSILIATION</b></font></font></p>
</ol>
<ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">La présente Licence
	prend effet dès l'acceptation des conditions de Licence et reste en
	vigueur jusqu'à la résiliation du Contrat de sous-location entre
	l'Utilisateur final détenteur de la licence et le Distributeur qui
	permet à l'Utilisateur final détenteur de la licence d'utiliser
	l'Analyseur/Scanner, que l'Utilisateur final détenteur de la
	licence ait conclu un tel contrat avec le Concédant ou avec un
	Distributeur. L'Utilisateur final licencié est tenu d'informer le
	Concédant de la résiliation ou de l'expiration du contrat de
	location ou de service qui permet à l'Utilisateur final détenteur
	de la licence d'utiliser l'Analyseur/Scanner. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Le Concédant peut
	mettre fin à la présente Licence avec effet immédiat en adressant
	une notification écrite à l'Utilisateur final détenteur de la
	licence si ce dernier&nbsp;:</font></font></p>
	<ol type="a">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">commet une
		violation substantielle ou persistante des conditions de cette
		Licence, à laquelle il est impossible de trouver une solution ou à
		laquelle l'Utilisateur final détenteur de la licence ne remédie
		pas dans les quatorze (14) jours suivant une notification écrite
		spécifiant la violation et exigeant qu'il y soit remédié&nbsp;; </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">n'est pas en mesure
		de payer ses dettes impayées ou devient insolvable, ou bien fait
		l'objet d'une ordonnance ou d'une résolution de liquidation,
		d'administration ou de dissolution (autrement qu'à des fins de
		fusion ou de reconstruction solvable)&nbsp;; </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">se voit désigner
		un administrateur judiciaire ou autre, un gestionnaire, un
		fiduciaire, un liquidateur, un administrateur ou un agent similaire
		pour la totalité ou une partie substantielle des actifs de
		l'Utilisateur final détenteur de la licence&nbsp;; ou conclut ou
		propose un concordat ou un arrangement avec ses créanciers en
		général&nbsp;; </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">cesse ou menace de
		cesser ses activités&nbsp;; </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">est soumis à tout
		événement ou procédure analogue dans toute juridiction </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">transfère ou cède
		à un tiers, sans le consentement explicite écrit préalable du
		Concédant, tout support physique comportant le Logiciel et/ou
		toute copie de celui-ci (y compris toute copie de sauvegarde
		autorisée par la législation nationale).  </font></font>
		</p>
	</ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Toutes les
	dispositions contractuelles du CLUF dont l'efficacité n'est pas
	affectée par la résiliation ou l'expiration du présent CLUF
	restent pleinement en vigueur entre les Parties après la
	résiliation ou l'expiration du CLUF. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Après la
	résiliation ou l'expiration du CLUF pour quelque raison que ce
	soit&nbsp;:</font></font></p>
	<ol type="a">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Tous les droits
		accordés à l'Utilisateur final détenteur de la licence en vertu
		de la présente Licence cessent d'exister&nbsp;;</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">L'Utilisateur final
		détenteur de la licence doit cesser immédiatement toutes les
		activités autorisées par la présente Licence&nbsp;; </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="_Hlk95062074"></a><a name="co_anchor_a666232_1"></a>
		<font color="#000000"><font face="Arial, serif">L'Utilisateur final
		détenteur de la Licence s'engage à supprimer ou à retirer
		immédiatement et définitivement le Logiciel de tout équipement
		informatique en sa possession, et à détruire immédiatement ou à
		renvoyer au Concédant (à son choix) toutes les copies du Logiciel
		et des Documents alors en sa possession, sous la garde ou le
		contrôle de l'Utilisateur final détenteur de la licence et, en
		cas de destruction, l'Utilisateur final détenteur de la licence
		certifie au Concédant que le Logiciel et les Documents ont été
		détruits.</font></font></p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.75in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>COMMUNICATION
	ENTRE LES PARTIES</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Le Concédant peut
		mettre à jour les conditions de cette Licence à tout moment, il
		doit en informer l'Utilisateur final détenteur de la licence
		conformément à cet article 14. L'utilisation continue du Logiciel
		et des Documents par l'Utilisateur final détenteur de la licence
		dans les 30&nbsp;jours suivant l'émission de la notification
		décrite dans cet article 14 constitue l'acceptation par
		l'Utilisateur final détenteur de la licence des conditions de
		cette Licence et de toute mise à jour de ses conditions. Si
		l'Utilisateur final détenteur de la licence ne souhaite pas
		accepter les conditions de la Licence (telles que modifiées),
		l'Utilisateur final détenteur de la licence cesse immédiatement
		d'utiliser et d'accéder au Logiciel et aux Documents, lui-même
		et/ou son (ses) Utilisateur(s) agréé(s), dès facturation et
		notification de l'avis et il en informe le Concédant en utilisant
		les coordonnées mises à la disposition de l'Utilisateur final
		détenteur de la licence dans le tableau de bord du Logiciel.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a510664_1"></a>
		<font color="#000000"><font face="Arial, serif">Le Concédant ne
		peut contacter l'Utilisateur final détenteur de la licence qu'en
		utilisant les coordonnées fournies par l'Utilisateur final
		détenteur de la licence avant l'acceptation du CLUF. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Toute
		notification&nbsp;:</font></font></p>
	</ol>
</ol>
<ol>
	<ol type="a">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">donnée par le
		Concédant à l'Utilisateur final détenteur de la licence est
		considérée comme reçue et correctement signifiée 24&nbsp;heures
		après sa première publication sur le site Web et le tableau de
		bord du Concédant, 24&nbsp;heures après l'envoi d'un courriel, ou
		trois&nbsp;jours après la date d'envoi publication d'une lettre&nbsp;;
		et</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">donnée par
		l'Utilisateur final détenteur de la licence au Concédant est
		considérée comme reçue et correctement signifiée 24&nbsp;heures
		après l'envoi d'un courriel ou trois&nbsp;jours après la date de
		publication d'une lettre.</font></font></p>
	</ol>
</ol>
<ol start="4">
	<ol start="4">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">Pour prouver la
		signification de toute notification, il suffit de prouver&nbsp;:
		dans le cas d'une lettre, que cette lettre a été correctement
		adressée, affranchie et mise à la poste à l'adresse du
		destinataire indiquée à ces fins&nbsp;; en cas de remise à
		l'Utilisateur final détenteur de la licence via le tableau de bord
		du Logiciel, deux&nbsp;jours après la remise de l'avis&nbsp;; et,
		dans le cas d'un courriel, que ce courriel a été envoyé à
		l'adresse électronique du destinataire indiquée à ces fins.</font></font></p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>FORCE MAJEURE</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">Au cas où le
		Concédant est retardé, gêné ou empêché dans l'exécution de
		tout acte, y compris, mais sans s'y limiter, de la mise à
		disposition du Logiciel requis en vertu des présentes, en raison
		d'une grève, d'une fermeture, de troubles sociaux, de terrorisme,
		d'incapacité à se procurer du matériel ou des services, d'une
		panne d'électricité ou d'ordonnances restrictives du gouvernement
		ou de la justice, ou de décrets, émeutes, insurrection, guerre,
		cas de force majeure, tremblements de terre ou toute autre raison
		ou cause indépendante de la volonté de cette Partie, prévisible
		ou non à l'heure actuelle (par exemple, un nouveau confinement
		pour Covid ou une autre pandémie), l'exécution de l'acte en
		question sera excusée pour la durée du retard, à condition que
		le Concédant fasse des efforts raisonnables pour remédier à ce
		retard, à cette entrave ou à cet empêchement<font size="2" style="font-size: 9pt"><span style="background: #ffffff">.
		</span></font>Le Concédant ne sera pas tenu pour responsable de
		l'inexécution ou du retard dans l'exécution de l'une de ses
		obligations en vertu de la présente Licence en cas de force
		majeure décrit dans le présent article 15.1.</font></font></p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"> <font face="Arial, serif"><b>	</b><b>DISPOSITIF
	MÉDICAL </b></font></font>
	</p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Le Concédant
		identifiera de bonne foi et conformément aux réglementations
		locales les Produits qui sont qualifiés de dispositif médical en
		vertu du Règlement relatif aux dispositifs médicaux (Règlement
		(UE) 2017/745). Le Logiciel est un dispositif médical en vertu du
		Règlement relatif aux dispositifs médicaux.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">L'Utilisateur final
		détenteur de la licence est responsable de la surveillance de
		l'utilisation correcte du Logiciel en conformité avec les lois et
		réglementations locales en vigueur. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Lorsque
		l'Utilisateur final détenteur de la licence a connaissance de
		problèmes de qualité, de perturbations ou d'informations qui
		suggèrent raisonnablement que le Logiciel ou les Documents ne sont
		pas conformes au Règlement européen relatif aux dispositifs
		médicaux ou à la législation nationale relative aux dispositifs
		médicaux en vigueur, l'Utilisateur final détenteur de la licence
		doit fournir au Concédant toutes les informations et l'accès au
		dispositif concerné nécessaires pour déterminer le problème et
		n'utilisera pas le Logiciel tant que le Concédant n'aura pas donné
		son accord. L'Utilisateur final détenteur de la licence est tenu
		de fournir une première notification de ce problème de qualité,
		de cette perturbation ou de toute information concernant la
		conformité avec la législation applicable aux dispositifs
		médicaux dans les 24&nbsp;heures après en avoir eu connaissance,
		et il doit assurer un suivi en fournissant dans un délai
		raisonnable toutes les informations qui n'étaient pas encore
		disponibles au moment de la notification initiale. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">L'Utilisateur final
		détenteur de la licence transmet ces informations au Concédant
		sans délai et, en tout état de cause, au plus tard 24&nbsp;heures
		après en avoir eu connaissance.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Si le Concédant
		notifie à l'Utilisateur final détenteur de la licence la
		nécessité d'une action corrective de sécurité sur le terrain ou
		un rappel de Produit, l'Utilisateur final détenteur de la licence
		doit en accuser réception dans les plus brefs délais et coopérer
		avec le Concédant, à ses propres frais, pour mettre en œuvre les
		actions correctives requises. En aucun cas, l'Utilisateur final
		détenteur de la licence ne doit mettre en œuvre une action
		corrective sans le consentement explicite du Concédant. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">L'Utilisateur final
		détenteur de la licence s'engage à informer le Concédant et à
		l'assister dans toute action gouvernementale ou de tiers concernant
		le Logiciel, dès que l'Utilisateur final détenteur de la licence
		en a connaissance.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">Si l'Utilisateur
		final détenteur de la licence a des raisons de croire que le
		Logiciel peut présenter un risque grave ou être falsifié, il ne
		doit informer aucune autorité compétente avant d'avoir informé
		le Concédant et d'avoir convenu avec lui des spécificités des
		informations à communiquer à l'autorité compétente. </font></font>
		</p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<p align="justify" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<p align="justify" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"> <font face="Arial, serif"><b>	DIVERS</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><u>Transfert </u><u>de
		droits par le Concédant.</u> Par avance, l'Utilisateur final
		détenteur de la licence autorise par la présente le Concédant à
		transférer ses droits et obligations à un tiers en vertu du
		présent Contrat. </font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><u>Transfert de
		droits par l'Utilisateur final détenteur de la licence.</u> Dans
		le cadre de la présente Licence, l'Utilisateur final détenteur de
		la licence peut transférer ses droits et obligations à une autre
		personne uniquement lorsqu'il en a reçu l'accord explicite écrit
		préalable du Concédant.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">La présente
		Licence et tout document auquel elle fait expressément référence
		constituent l'intégralité de l'accord entre les Parties et
		remplacent et annulent tous les accords, promesses, assurances,
		garanties, représentations et ententes antérieurs entre nous,
		qu'ils soient écrits ou oraux, relatifs à son objet.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Si le Concédant
		n'insiste pas pour que l'Utilisateur final détenteur de la licence
		s'acquitte de l'une de ses obligations en vertu de la présente
		Licence, ou si le Concédant ne fait pas valoir ses droits à
		l'encontre de l'Utilisateur final licencié ou tarde à le faire,
		cela ne signifie pas que le Concédant a renoncé à ses droits à
		l'encontre de l'Utilisateur final détenteur de la licence, ni que
		l'Utilisateur final détenteur de la licence n'est pas tenu de se
		conformer à ces obligations. Toute renonciation à un manquement
		de la part de l'Utilisateur final détenteur de la licence doit
		être mentionnée par écrit. Le Concédant n'est pas tenu de
		renoncer à tout manquement de l'Utilisateur final détenteur de la
		licence.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">Chacun des articles
		de cette Licence s'applique séparément. Si un tribunal ou une
		autorité compétente décide que l'un d'entre eux est illégal ou
		inapplicable, les autres articles demeurent en vigueur et de plein
		effet<b>.</b></font></font></p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"> <font face="Arial, serif"><b>DROIT APPLICABLE
	ET LITIGES</b></font></font></p>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.49in; text-indent: -0.49in; margin-bottom: 0.08in">
<font color="#000000"><font face="Arial, serif">18.1 	La présente
Licence, son objet et sa formation sont régis par le droit allemand,
à l'exclusion des conflits de lois, des règles et de la Convention
des Nations Unies sur les contrats de vente internationale de
marchandises du 11&nbsp;avril&nbsp;1980. </font></font>
</p>
<p align="justify" style="line-height: 115%; margin-left: 0.49in; text-indent: -0.49in; margin-bottom: 0.08in"><a name="kh_relatedContentOffset_1"></a><a name="co_anchor_a840535_1"></a>
<font color="#000000"><font face="Arial, serif">18.2	Tout litige
concernant le présent CLUF et la Licence relève exclusivement de la
compétence de la juridiction du siège social du défendeur.</font></font></p>
<p align="center" style="line-height: 150%; margin-bottom: 0.08in; page-break-before: always">
<font color="#000000"><font face="Arial, serif"><b>Politique de
confidentialité</b></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif">Bienvenue dans le
Système numérique de gestion des plaies et le logiciel Vintens™.
Nous (KroniKare Ltd) tenons à vous informer que nous ferons office
de responsable de traitement de toutes les Données Personnelles
inhérentes aux accords conclus avec KroniKare Ltd, généralement en
ce qui concerne les Données Personnelles de l'Utilisateur final
détenteur de la licence et pour éviter toute ambiguïté, en
particulier lorsque l'Utilisateur final détenteur de la licence est
un individu ou un propriétaire unique, par exemple un médecin
praticien ou indépendant, ou bien un infirmier indépendant ou
auto-entrepreneur.</font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.14in">
<font color="#000000"><font face="Arial, serif">Par conséquent,
avant que vous ne commenciez à utiliser le Système et le Logiciel,
nous souhaitons vous rappeler (en vous fournissant la présente
Politique de de confidentialité) que nous recueillons certaines
Données Personnelles limitées vous concernant (les «&nbsp;Données
Personnelles&nbsp;»). Nous vous demandons de lire attentivement
cette Politique de confidentialité et de confirmer que vous l'avez
lue. </font></font>
</p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><b>1) Qui est
responsable du traitement des Données Personnelles, représentant
dans l'UE&nbsp;?</b></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif">KroniKare Ltd, The
Wenta Business Centre, Watford, Hertfordshire, England, WD24 7ND,
Royaume Uni, est responsable du traitement des Données Personnelles.</font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif">Le représentant dans
l'Union Européenne pour le compte de KroniKare Ltd. sur tout ce qui
concerne le traitement des Données Personnelles conformément à
l'art. 27 du RGPD est joignable aux coordonnées suivantes&nbsp;:
Kaleidoscope Data Privacy Consultants SL (KDPC ES), Calle Balmes 173,
4-2, Barcelona 08006, Espagne (</font></font><font color="#000000"><font face="Arial, serif"><i><u><a class="western" href="mailto:dpr.kronikare@kdpc.es"><font color="#000000">dpr.kronikare@kdpc.es</font></a></u></i></font></font><font color="#000000"><font face="Arial, serif">).</font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif">Ce représentant agit
dans les limites d'un mandat écrit que KroniKare Ltd. lui a
attribué, qui autorise le représentant à coopérer avec les
autorités de surveillance et de faire office de point de contact
pour tout ce qui concerne les Données.</font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif">Les coordonnées
précises de contact se trouvent ci-dessous, au point 8).</font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><b>2) Quelles
catégories de Données Personnelles sont-elles traitées, dans quel
but&nbsp;et sur quelle base légale&nbsp;?</b></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif">Nous collectons
notamment les Données Personnelles de base suivantes ainsi que les
Données d'utilisation pertinentes vous concernant&nbsp;: nom
d'utilisateur, adresse électronique, numéro de téléphone, prénom,
second prénom, nom de famille, sexe, domiciliation, titre,
catégorie, rôle, description de poste, données de connexion
(fichiers journaux) et adresse IP.  Les finalités du traitement des
Données sont de respecter les termes de l'accord, en particulier de
permettre l'inscription, la connexion ou l'administration et
l'utilisation du Système numérique de gestion des plaies et du
Logiciel sur la base de l'art.&nbsp;6 (1) b du RGPD.  </font></font>
</p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><b>3) Qui reçoit mes
Données Personnelles&nbsp;?</b></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.14in">
<font color="#000000"><font face="Arial, serif">En règle générale,
nous traitons vos Données Personnelles uniquement en interne. Si
nous transmettons vos Données Personnelles à d'autres personnes,
entreprises ou d'autres tiers ou leur accordons un autre accès aux
Données Personnelles, cela se déroule uniquement sur la base d'une
autorisation légale. Si nous chargeons des tiers du traitement des
Données Personnelles sur la base d'un Accord de sous-traitance du
traitement des données, cela se déroule sur la base de l'art.&nbsp;28
du RGPD. Cependant, nous restons responsables envers vous de la
légalité du traitement des Données. </font></font>
</p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><b>4) Où et pendant
combien de temps mes Données Personnelles seront-elles traitées ou
conservées&nbsp;?</b></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.14in">
<font color="#000000"><font face="Arial, serif">Dans la mesure du
possible, nous traitons vos Données Personnelles uniquement dans
l'Espace économique européen. Toutefois, si le traitement de vos
Données Personnelles dans des pays tiers est nécessaire, nous nous
assurerons que les exigences légales spécifiques à ces opérations
de traitement sont respectées et qu'un niveau adéquat de protection
des Données prévaut dans le pays tiers concerné. </font></font>
</p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif">Nous nous assurons
donc de l'existence des protections et des mesures appropriées
définies dans l'art. </font><font face="Arial, serif">46 du RGPD (si
aucune décision concernant l'adéquation n'a été adopté), nous
appliquerons en particulier les clauses contractuelles type. Étant
donné que KroniKare Ltd. est établie au Royaume Uni et selon la
Commission Européenne, le niveau de protection du Royaume Uni est
pratiquement équivalent à celui de l'Union Européenne (cf. Art. 45
RGPD), le transfert de Données Personnelles de l'Union Européenne
dans ce pays tiers peut par conséquent avoir lieu sans précautions
ni mesures de sécurité supplémentaires adaptées.</font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif">Nous conservons vos
Données Personnelles en principe pour la durée de la relation
contractuelle. Les informations ci-dessus concernant l'effacement ne
s'appliquent pas si, entre autres, les délais de conservation
prescrits par la loi empêchent un effacement immédiat (cf. art.&nbsp;17
(3) du RGPD) ou si une nouvelle finalité justifie la poursuite du
traitement.</font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><b>5) Quels sont mes
droits de personne concernée&nbsp;?</b></font></font></p>
<ul>
	<li><p align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Conformément à
	l'art.&nbsp;15 du RGPD, vous pouvez demander à être informé(e)
	des Données Personnelles que nous traitons.</font></font></p>
	<li><p align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Conformément à
	l'art.&nbsp;16 du RGPD, vous pouvez exiger la correction immédiate
	des Données Personnelles incorrectes ou incomplètes que nous
	stockons.</font></font></p>
	<li><p align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Conformément à
	l'art.&nbsp;17 du RGPD, vous pouvez demander l'effacement des
	Données Personnelles que nous conservons conformément aux
	conditions énoncées ici, à moins que les périodes de
	conservation prescrites par la loi n'empêchent l'effacement
	immédiat (voir l'art.&nbsp;17 (3) du RGPD) et/ou qu'il existe un
	autre cas de l'art.&nbsp;17 (3) du RGPD et/ou qu'une nouvelle
	finalité justifie la poursuite de leur traitement.</font></font></p>
	<li><p align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Conformément à
	l'art.&nbsp;18 (1) du RGPD, vous pouvez demander la restriction du
	traitement des Données si une ou plusieurs conditions en vertu de
	l'art.&nbsp;18 (1) du RGPD points a) à d) est/sont remplie/s.</font></font></p>
	<li><p align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Conformément à
	l'art.&nbsp;20 (1) du RGPD, vous pouvez recevoir les Données
	Personnelles que nous traitons en un format structuré, courant et
	lisible par machine et transférer ces Données Personnelles à un
	autre responsable sans que nous y fassions obstacle.</font></font></p>
	<li><p align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Vous pouvez
	également vous opposer au traitement de vos Données Personnelles
	conformément à l'art.&nbsp;21 (1) du RGPD. En cas d'objection,
	nous mettons fin au traitement de vos Données Personnelles.
	Toutefois, le droit d'opposition ne s'applique qu'en présence de
	circonstances particulières découlant de votre situation
	personnelle. En outre, des raisons impérieuses dignes de protection
	qui plaident en faveur du traitement peuvent prévaloir. Certaines
	finalités de traitement peuvent elles aussi entrer en conflit avec
	votre droit d'opposition. </font></font>
	</p>
	<li><p align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Sans préjudice de
	tout autre recours administratif ou judiciaire, vous êtes également
	en droit de faire appel à l'autorité de surveillance compétente
	(voir art.&nbsp;77 du RGPD) si vous estimez que le traitement de vos
	Données Personnelles viole les dispositions relatives à la
	protection des données. Dans ce contexte, nous vous demandons
	toutefois d'adresser votre réclamation d'abord aux coordonnées
	indiquées au point 8) ci-dessus. </font></font>
	</p>
</ul>
<p align="justify" style="line-height: 150%; margin-left: 0.2in; margin-bottom: 0in">
<br/>

</p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><b>6) Suis-je
obligé(e) de fournir mes Données Personnelles&nbsp;?</b></font><font face="Arial, serif">
</font></font>
</p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif">Vous n'êtes tenu(e)
de nous fournir que les Données Personnelles nécessaires à
l'élaboration, l'exécution et la résiliation d'une relation
contractuelle, ou qui sont généralement indispensables pour
l'exécution de nos services. Sans ces Données Personnelles, nous ne
sommes généralement pas en mesure de conclure ni de réaliser la
relation contractuelle avec vous, ni de vous fournir nos services. </font></font>
</p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><b>7) Avons-nous
recours à une prise de décision automatisée&nbsp;?</b></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif">Nous n'utilisons pas
de procédure de prise de décision purement automatisée
conformément à l'art.&nbsp;22 du RGPD. Si nous devions néanmoins
être amenés à utiliser à l'avenir une telle procédure, dans
certains cas individuels, nous vous en informerions à part si la loi
l'exige.</font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.11in"><font color="#000000"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><b>8)
Avez-vous d'autres questions&nbsp;?</b></font></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.14in">
<font color="#000000"><font face="Arial, serif">Veuillez nous
contacter pour en savoir plus sur la protection des Données ou en
cas de questions supplémentaires, notamment en ce qui concerne
l'exercice de vos droits de la personne à </font><font face="Arial, serif">KroniKare</font><font face="Arial, serif">
Ltd.</font><font face="Arial, serif"> DPO pour le Royaume Uni
(dpo.kronikare@kdpc.uk) et représentant dans l'Union Européenne
(dpr.kronikare@kdpc.es).</font> </font>
</p>
</body>
</html>
`,
}

export default { ...main }
