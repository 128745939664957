//DE
const main = {
    Login_titleLogin_display: true,
    Login_titleLogin_text: 'Login',
    Login_labelUsername_display: true,
    Login_labelUsername_text: 'Benutzername',
    Login_labelPassword_display: true,
    Login_labelPassword_text: 'Passwort',
    Login_labelLoginButton_display: true,
    Login_labelLoginButton_text: 'Login',
    Login_labelForgotPasswordLink_display: true,
    Login_labelForgotPasswordLink_text: 'Passwort vergessen?',
    Login_validationMessageUsernameRequired_display: true,
    Login_validationMessageUsernameRequired_text: 'Benutzername ist erforderlich',
    Login_validationMessagePasswordRequired_display: true,
    Login_validationMessagePasswordRequired_text: 'Passwort ist erforderlich',
    Login_errorMessageIncorrectCredentials_display: true,
    Login_errorMessageIncorrectCredentials_text: 'Falscher Benutzername oder falsches Passwort! Bitte versuchen Sie es erneut.',
    Login_errorMessageNetworkIssues_display: true,
    Login_errorMessageNetworkIssues_text: 'Bitte überprüfen Sie Ihr Netzwerk!',
    Login_errorMessageServerIssues_display: true,
    Login_errorMessageServerIssues_text: 'Sie haben keine Antwort erhalten! Bitte versuchen Sie es erneut.',
}

export default {
    ...main
}
