//ES
const main = {
  Eula_title_display: true,
  Eula_title_text: 'ACUERDO DE LICENCIA DEL USUARIO FINAL',
  Eula_labelAgreeButton_display: true,
  Eula_labelAgreeButton_text: 'Aceptar',
  Eula_display: true,
  Eula_text: `
 <!DOCTYPE html>
<html>
<head>
	<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
	<title></title>
	<meta name="generator" content="LibreOffice 7.4.4.2 (Linux)"/>
	<meta name="author" content="Bilgin Berksoy"/>
	<meta name="created" content="2022-03-04T12:10:00"/>
	<meta name="changed" content="2023-08-22T18:57:13.255713311"/>
	<meta name="AppVersion" content="16.0000"/>
	<meta name="ComplianceAssetId" content=""/>
	<meta name="ContentTypeId" content="0x010100476224809ABE224A900BF4DFEFD0895F"/>
	<meta name="Order" content="288300"/>
	<meta name="SharedWithUsers" content="17;#Wypior Sebastian;#13;#Rouais Nicolas;#107;#Jung Alexander;#12;#Bilgin Berksoy"/>
	<meta name="TemplateUrl" content=""/>
	<meta name="TriggerFlowInfo" content=""/>
	<meta name="_ExtendedDescription" content=""/>
	<meta name="xd_ProgID" content=""/>
	<meta name="xd_Signature" content="false"/>
	<style type="text/css">
		@page { size: 8.27in 11.69in; margin-left: 0.98in; margin-right: 0.98in; margin-top: 0.98in; margin-bottom: 0.79in }
		p { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }
		p.western { font-family: "Calibri", serif }
		a:link { color: #000000; text-decoration: underline }
		a.western:link { font-family: "Arial", serif; font-style: italic }
		a.cjk:link { font-family: "Arial"; font-style: italic }
		a.ctl:link { font-family: "Arial" }
		a:visited { color: #800000; text-decoration: underline }
	</style>
</head>
<body lang="de-DE" link="#000000" vlink="#800000" dir="ltr"><p align="center" style="line-height: 115%; margin-bottom: 0.08in">

</p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif"><span lang="en-US">AL
UTILIZAR ESTE SOFTWARE Y CUALQUIERA DE SUS ACTUALIZACIONES (EN
CONJUNTO: “EL SOFTWARE”), EL USUARIO FINAL LICENCIATARIO CONFIRMA
QUE SE LE HA PROPORCIONADO UNA COPIA DE ESTE ACUERDO; ADEMÁS, ACEPTA
LOS TÉRMINOS DE ESTA LICENCIA TAL Y COMO SE ESTABLECE A
CONTINUACIÓN. SI EL USUARIO FINAL LICENCIATARIO NO ACEPTA LOS
TÉRMINOS DE ESTE ACUERDO, QUEDA PROHIBIDO EL USO DE ESTE SOFTWARE.
LOS TÉRMINOS DE ESTA LICENCIA CONSTAN, EN PARTICULAR, DE LAS
LIMITACIONES DE RESPONSABILIDAD DEL ARTÍCULO&nbsp;11</span></font></font></p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif"><span lang="en-US">El
presente Acuerdo de Licencia del Usuario Final (en lo sucesivo: el
“Acuerdo”) entra en vigor a partir del momento en que el Usuario
final licenciatario acepta sus términos a través del procedimiento
documentado en este Acuerdo, y es un acuerdo legal entre las Partes:</span></font></font></p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif"><span lang="en-US">KroniKare
Pte. Ltd. con domicilio social en 75 Ayer Rajah Crescent #03-12,
Singapur 139953, (en lo sucesivo: el “Licenciante”), </span></font></font>
</p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif"><span lang="en-US">y</span></font></font></p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif"><span lang="en-US">Organización
(en lo sucesivo: el “Usuario final licenciatario”). </span></font></font>
</p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif"><span lang="en-US">Por
la presente, el Licenciante concede al Usuario final licenciatario un
derecho no exclusivo, intransferible y limitado para utilizar: </span></font></font>
</p>
<ul>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">el
	software del dispositivo digital para la gestión de heridas
	Vintens™ </span><span lang="en-US"><b>(el “Escáner de heridas”)
	</b></span><span lang="en-US">y los datos suministrados por el
	software y software relacionado/auxiliar que consiste en la
	aplicación del dispositivo, el panel de control y el software de
	backend </span><span lang="en-US"><b>(el “Software”), </b></span><span lang="en-US">y</span></font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">la Documentación</font></font></p>
</ul>
<p align="justify" style="line-height: 115%; margin-bottom: 0in"><font color="#000000"><font face="Arial, serif"><span lang="en-US">para
los fines expresamente establecidos en esta licencia (la “Licencia
de usuario final”). Todos los derechos no concedidos expresamente
en esta Licencia están reservados por el Licenciante. El Licenciante
concede el derecho a utilizar el Software y la Documentación al
Usuario final licenciatario sobre la base de la presente Licencia. El
Licenciante no vende el Software ni la Documentación al Usuario
final licenciatario. </span></font></font>
</p>
<p lang="en-US" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif"><b>CONSIDERANDOS</b></font></font></p>
<ol type="A">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
	Licenciante es el fabricante del Escáner de heridas y del Software
	y el autor de la Documentación; el Licenciante ha firmado un
	contrato de arrendamiento con sus distribuidores para el suministro
	del Escáner de heridas al Usuario final licenciatario. </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
	Usuario final licenciatario ha suscrito un contrato de
	subarrendamiento con los distribuidores del Licenciante para
	alquilar el Escáner de heridas (el “Contrato de
	subarrendamiento”) y desea utilizar el Software y la Documentación
	para el uso del Escáner de heridas de acuerdo con su uso previsto.</span></font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
	Usuario final licenciatario desea utilizar el Escáner de heridas,
	el Software y la Documentación dentro de su actividad con el fin de
	proporcionar atención a sus pacientes, y desea poner el Software a
	disposición de un grupo concreto de usuarios (los “Usuarios
	autorizados”) de su organización. </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">En
	relación con lo anterior, el Usuario final licenciatario está
	dispuesto a utilizar el Software y los servicios relacionados de
	acuerdo con los términos de este Acuerdo. </span></font></font>
	</p>
</ol>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in; page-break-before: always">
<font color="#000000"><font face="Arial, serif"><span lang="en-US">Se
ha acordado lo siguiente: </span></font></font>
</p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif"><span lang="en-US"><b>1.	Definiciones</b></span></font></font></p>
<p class="western" align="justify" style="line-height: 115%; orphans: 0; widows: 0; margin-left: 0.49in; text-indent: -0.49in; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US">1,1.</span></font><span lang="en-US">	</span><font face="Arial, serif"><span lang="en-US">A
los efectos de este Acuerdo, cuando estos términos aparecen en
mayúsculas (en forma singular o plural), se utilizarán de acuerdo
con el significado que se indica a continuación:</span></font></font></p>
<ol type="a">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><span lang="en-US"><b>Usuario
	administrador</b></span><span lang="en-US">” es aquella persona de
	la organización del Usuario final licenciatario que este designa
	como </span><span lang="en-US"><span style="background: #ffffff">responsable
	del mantenimiento, la configuración y el funcionamiento fiable del
	Software, y como su representante a fin de aceptar este Acuerdo en
	nombre del Usuario final licenciatario de acuerdo con la legislación
	del país en el que tiene su sede el Usuario final licenciatario. </span></span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><b>Dispositivo
	autorizado</b>” es el Escáner de heridas. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><span lang="en-US"><b>Usuario
	autorizado”</b></span><span lang="en-US"> es cualquier persona
	perteneciente a la organización del Usuario final licenciatario (es
	decir, empleados directos del Usuario final licenciatario o
	consultores que tengan una relación comercial con el Usuario final
	licenciatario) que pueda obtener un acceso legítimo al Software y
	esté autorizada a utilizarlo de acuerdo con una o varias Licencias
	personales e intransferibles obtenidas por el Usuario final
	licenciatario. </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><span lang="en-US"><b>Documentación”
	</b></span><span lang="en-US">se refiere</span><span lang="en-US"> a
	todo el material impreso y los documentos electrónicos relacionados
	con el Escáner de heridas y el Software, lo que incluye (de forma
	no exhaustiva) las Instrucciones de uso y todo acuerdo relativo al
	uso del Escáner de heridas. </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><span lang="en-US"><b>Distribuidores”
	</b></span><span lang="en-US">son las personas jurídicas de la
	cadena de suministro, distintas del fabricante o del importador, que
	ponen al Escáner de heridas a disposición del </span><span lang="en-US"><span style="background: #ffffff">Usuario
	final licenciatario. </span></span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><b>Fecha de
	entrada en vigor”</b> es la fecha indicada en el encabezado de
	este Acuerdo.</font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><span lang="en-US"><b>Usuario
	final licenciatario” </b></span><span lang="en-US">es la parte
	contractual identificada en el Acuerdo, a la que el Licenciante
	concede la licencia del Software únicamente para su uso interno (y
	no para su redistribución, recomercialización ni uso compartido) y
	que ha suscrito un Contrato de subarrendamiento con los
	distribuidores del Licenciante para el uso del dispositivo de
	Escáner de heridas. </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><span lang="en-US"><b>Incidente”
	</b></span><span lang="en-US">se refiere a cualquier falla de
	funcionamiento o deterioro de las características o prestaciones de
	un dispositivo comercializado, incluido el error de uso debido a las
	características ergonómicas, así como cualquier insuficiencia en
	la información suministrada por el fabricante y cualquier efecto
	secundario no deseado.</span></font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><span lang="en-US"><b>Instrucciones
	de uso”</b></span><span lang="en-US"> </span><span lang="en-US"><span style="background: #ffffff">es
	la información facilitada por el fabricante para informar al
	usuario del uso previsto y del uso adecuado de un producto, así
	como de las precauciones que deben tomarse. </span></span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><span lang="en-US"><b>Derechos
	de propiedad intelectual”</b></span><span lang="en-US"> son las
	patentes, marcas comerciales, modelos de utilidad, secretos
	comerciales, nombres comerciales, marcas de servicio, nombres de
	dominio, copyrights, derechos sobre bases de datos (incluidos los
	derechos para impedir la extracción o la reutilización de
	información de una base de datos), derechos de diseño, derechos de
	topografía, diseño de software, interfaz de usuario (IU), diseño
	de IU, derechos de marca en virtud de leyes de exclusividad de
	datos, ampliación de dichos derechos y todos los derechos o formas
	de protección de naturaleza similar o que tengan un efecto
	equivalente o análogo a cualquiera de ellos que puedan subsistir en
	cualquier parte del mundo, estén o no registrados, y las
	solicitudes y el derecho a solicitar cualquiera de los anteriores
	bienes y derechos registrados, así como derechos similares o
	análogos en cualquier lugar del mundo.</span><span lang="en-US"> </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><b>Parte”</b>
	se refiere a cada una de las partes de este Acuerdo. </font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><span lang="en-US"><b>Partes”</b></span><span lang="en-US">
	se refiere a las dos partes de este acuerdo de manera conjunta. </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><span lang="en-US"><b>Datos
	personales”</b></span><span lang="en-US"> son toda información
	sobre una persona física identificada o identificable; se
	considerará persona física identificable toda persona cuya
	identidad pueda determinarse, directa o indirectamente, en
	particular mediante un identificador, como por ejemplo un nombre, un
	número de identificación, datos de localización, un identificador
	en línea o uno o varios elementos propios de la identidad física,
	fisiológica, genética, psíquica, económica, cultural o social de
	dicha persona.</span></font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><span lang="en-US"><b>Lanzamiento”</b></span><span lang="en-US">
	se refiere a las modificaciones, actualizaciones y/o mejoras del
	Software que el Licenciante decide poner a disposición general del
	Usuario final licenciatario.</span></font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
	<font color="#000000">“<font face="Arial, serif"><span lang="en-US"><b>Territorio”</b></span><span lang="en-US">
	es el país en el que reside el Usuario final licenciatario.</span></font></font></p>
</ol>
<p lang="en-US" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><font color="#000000"><font face="Arial, serif"><span lang="en-US"><b>2.	CONCESIÓN
Y ALCANCE DE LA LICENCIA</b></span></font></font></p>
<ol start="2">
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a944711_1"></a>
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
		Licenciante concede al Usuario final licenciatario una licencia no
		exclusiva, intransferible, limitada y para el Territorio para
		utilizar el Software y la Documentación de acuerdo con los
		términos de esta Licencia, mientras dure el Contrato de
		subarrendamiento del Escáner de heridas (excepto si se rescinde
		antes de acuerdo con los términos aquí expuestos),
		independientemente de que este acuerdo se celebre con el
		Licenciante o con un Distribuidor. El Usuario final licenciatario
		ejercerá los derechos concedidos en virtud del Acuerdo a partir de
		la Fecha de entrada en vigor.</span></font></font></p>
	</ol>
</ol>
<ol>
	<ol type="a">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
		Acuerdo se considerará válidamente ejecutado y efectivo entre las
		Partes a partir de la Fecha de entrada en vigor. </span></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">En
		virtud de los términos de esta Licencia, el Usuario final
		licenciatario y sus empleados autorizados (Usuarios autorizados)
		podrán:</span></font></font></p>
		<ol type="i">
			<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a298333_1"></a>
			<font color="#000000"><font face="Arial, serif"><span lang="en-US">descargar,
			instalar y utilizar el Software para sus fines empresariales
			internos únicamente de acuerdo con la Documentación y el uso
			previsto del Escáner de heridas y utilizar el Software de acuerdo
			con las Instrucciones de uso del Escáner de heridas para el
			número de Usuarios autorizados acordado, tal y como se especifica
			en el Contrato de subarrendamiento;</span></font></font></p>
			<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
			<font color="#000000"><font face="Arial, serif"><span lang="en-US">recibir
			y utilizar cualquier código de software complementario gratuito o
			actualización del Software que incorpore “parches” y
			correcciones de errores que pueda proporcionar el Licenciante de
			vez en cuando, y</span></font></font></p>
			<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a397482_1"></a>
			<font color="#000000"><font face="Arial, serif"><span lang="en-US">utilizar
			cualquier Documentación en respaldo del uso permitido en virtud
			de esta Licencia, tal y como se especifica en el artículo&nbsp;2,
			y realizar las copias de la Documentación que sean razonablemente
			necesarias para su uso lícito.</span></font></font></p>
		</ol>
	</ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">Sin
	perjuicio de los demás artículos de este Acuerdo, si en cualquier
	momento durante la vigencia de este Acuerdo el número de usuarios
	del Software es superior al número de Usuarios autorizados
	concedido al Usuario final licenciatario de acuerdo con el
	artículo&nbsp;2.1(b)(i) anterior, el Licenciante tendrá derecho a
	rescindir este Acuerdo con notificación inmediata. </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">Quedan
	expresamente excluidos del ámbito de aplicación del presente
	Acuerdo: (i) las licencias de software básico de terceros (por
	ejemplo, sistemas operativos, Microsoft® Excel®, Adobe Reader®,
	etc.), (ii) el software suministrado al Usuario final </span><span lang="en-US">licenciatario
	</span><span lang="en-US">a través de terceros (socios de
	integración) para la integración del Software en los sistemas del
	Usuario final</span><span lang="en-US"> licenciatario</span><span lang="en-US">
	y/o en el sistema electrónico de historias clínicas, y (iii) todo
	aquello que no esté expresamente enumerado por el Licenciante en el
	presente Acuerdo. </span></font></font>
	</p>
</ol>
<p lang="en-US" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="2">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>PROPIEDAD</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
		Usuario final </span><span lang="en-US">licenciatario </span><span lang="en-US">acepta
		que el Licenciante conserve todos los derechos, títulos e
		intereses sobre el Software. </span></font></font>
		</p>
	</ol>
</ol>
<p lang="en-US" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>RESTRICCIONES</b></font></font></p>
</ol>
<ol start="4">
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">Salvo
		que se establezca de manera expresa en esta Licencia, el Usuario
		final licenciatario se compromete a no hacer lo siguiente: </span></font></font>
		</p>
	</ol>
</ol>
<ol type="a">
	<ol type="a">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a618882_1"></a>
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">copiar
		el Software o la Documentación;</span></font></font></p>
	</ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">alquilar,
	arrendar, sublicenciar, prestar, traducir, fusionar, adaptar, variar
	o modificar el Software o la Documentación;</span></font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a168255_1"></a>
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">realizar
	alteraciones o modificaciones de la totalidad o parte del Software,
	o permitir que el Software o cualquiera de sus partes se combine con
	otros programas o se incorpore a ellos;</span></font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">desensamblar,
	descompilar, aplicar ingeniería inversa o crear trabajos derivados
	basados en la totalidad o cualquier parte del Software, ni intentar
	hacer tal cosa, excepto en la medida en que tales acciones no puedan
	prohibirse porque sean esenciales para la interoperabilidad del
	Software con otro programa de software, y siempre que la información
	que el Usuario final licenciatario y/o los Usuarios autorizados
	obtengan durante tales actividades se utilice únicamente con el fin
	de lograr la interoperabilidad del Software con otro programa de
	software, y</span></font></font></p>
	<ol type="a">
		<ol type="i">
			<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
			<font color="#000000"><font face="Arial, serif"><span lang="en-US">no
			se divulgue o comunique innecesariamente sin nuestro
			consentimiento previo por escrito a ningún tercero, y</span></font></font></p>
			<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a146892_1"></a>
			<font color="#000000"><font face="Arial, serif"><span lang="en-US">no
			se utilice para crear ningún software que sea sustancialmente
			similar al Software;</span></font></font></p>
		</ol>
	</ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">proporcionar
	o poner a disposición el Software en su totalidad o en parte (lo
	que incluye, de forma no exhaustiva, listados de programas, listados
	de programas destino y fuente, código destino y fuente), en
	cualquier forma a cualquier persona sin nuestro consentimiento
	previo por escrito, y</span><span lang="en-US"> </span></font></font>
	</p>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.89in; margin-bottom: 0.08in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US">descargar,
instalar o utilizar el Software en dispositivos no autorizados.</span></font></font></p>
<ol start="4">
	<ol start="2">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">Sin
		perjuicio de lo anterior, el Usuario final </span><span lang="en-US">licenciatario
		</span><span lang="en-US">está autorizado a realizar una copia de
		la Documentación a efectos de gestión del contrato y, cuando sea
		estrictamente necesario, para la atención al paciente. El Usuario
		final </span><span lang="en-US">licenciatario </span><span lang="en-US">podrá,
		a través de su Usuario administrador, descargar informes de
		estado, tales como registros de usuarios o dispositivos,
		estadísticas de alto nivel de pacientes y demás información
		relevante directamente relacionada con la atención al paciente
		para las obligaciones de atención, la gestión de recursos y la
		elaboración de informes del Usuario final</span><span lang="en-US">
		licenciatario</span><span lang="en-US">.</span></font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
		Usuario final </span><span lang="en-US">licenciatario </span><span lang="en-US">solamente
		podrá utilizar el Software tal y como esté instalado en los
		Dispositivos autorizados. </span></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
		Usuario final licenciatario supervisará y controlará el uso del
		Software por parte de los Usuarios autorizados y se asegurará de
		que los Usuarios autorizados utilicen el Software de acuerdo con
		los términos de esta Licencia.</span></font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
		Usuario final </span><span lang="en-US">licenciatario </span><span lang="en-US">deberá
		cumplir con todas las leyes y normativas de control tecnológico o
		de exportación aplicables.</span></font></font></p>
	</ol>
</ol>
<p lang="en-US" align="justify" style="line-height: 115%; margin-left: 0.49in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>SERVICIOS DE
	ASISTENCIA</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="_Hlk74843159"></a>
		<font color="#000000"><font size="2" style="font-size: 10pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span lang="en-US">El
		Licenciante proporcionará los servicios de asistencia que se
		soliciten dentro de lo razonable al Usuario final licenciatario en
		relación con el Software durante la vigencia de este Acuerdo a
		través de sus Distribuidores. El Usuario final licenciatario puede
		solicitar asistencia a través del sistema de tickets del
		Distribuidor y está sujeta a los términos del Contrato de
		subarrendamiento.</span></font></font></font></font></p>
	</ol>
</ol>
<p lang="en-US" align="justify" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>DATOS PERSONALES</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><font color="#000000"><span lang="en-US">El
		uso del Software requiere el tratamiento de Datos personales
		relativos a pacientes y personal del Usuario final licenciatario,
		tal y como se define en el Reglamento (UE) 2016/679 - Reglamento
		general de protección de datos (RGPD). El tratamiento de los Datos
		personales se llevará a cargo de la filial del Licenciante,
		KroniKare Limited, que tiene su domicilio social en Watford (Reino
		Unido), The Wenta Business Centre, Watford, Hertfordshire,
		Inglaterra, WD24 7ND (Reino Unido). El representante en la Unión
		Europea para todas las cuestiones relacionadas con el tratamiento
		de Datos personales según el art.&nbsp;27 del RGPD puede ser
		contactado de la siguiente manera: Kaleidoscope Data Privacy
		Consultants&nbsp;SL (KDPC ES), Calle Balmes 173, 4-2, Barcelona
		08006, España (</span></font><font color="#000000"><font face="Arial, serif"><i><u><a class="western" href="mailto:dpr.kronikare@kdpc.es"><font color="#000000"><span lang="en-US">dpr.kronikare@kdpc.es</span></font></a></u></i></font></font><font color="#000000"><span lang="en-US">)
		en nombre de KroniKare Ltd. KroniKare Ltd. ha encomendado por
		escrito al representante que atienda a las consultas de las
		autoridades de control y a actuar como punto de contacto para los
		interesados.</span></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
		Usuario final licenciatario, en calidad de responsable del
		tratamiento en virtud del RGPD (véase art.&nbsp;4 (7) RGPD),
		acepta celebrar un acuerdo de tratamiento de datos en virtud del
		art.&nbsp;28 del RGPD con KroniKare Limited (el “encargado del
		tratamiento”, véase art.&nbsp;4 (8) RGPD) para el tratamiento de
		Datos personales con el fin de “</span><span lang="en-US"><i>Proporcionar
		cuidado de heridas por parte del responsable del tratamiento”</i></span><span lang="en-US">.
		Los encargados de ejecutar esta celebración del contrato serán
		los Distribuidores. Según la Comisión Europea, el Reino Unido
		ofrece un nivel de protección sustancialmente equivalente al de la
		Unión Europea (véase el art.&nbsp;45 del RGPD), por lo que las
		transferencias de Datos personales desde la Unión Europea a este
		tercer país pueden llevarse a cabo sin más garantías y medidas
		adecuadas.</span></font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="_Hlk94996667"></a><a name="_Hlk94996298"></a>
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">Sin
		perjuicio de lo dispuesto en el artículo&nbsp;6.2, KroniKare
		Limited actuará como responsable del tratamiento de todos los
		Datos personales relativos a los acuerdos suscritos con KroniKare
		Limited, en general con respecto a los Datos Personales del Usuario
		final licenciatario y, para evitar dudas, en especial cuando el
		Usuario final licenciatario sea una persona física o un
		propietario único (por ejemplo, un médico en ejercicio o
		independiente o un enfermero independiente o autónomo). Dicha
		responsabilidad del Licenciante no se extenderá en ningún caso a
		los datos de los pacientes proporcionados por el Usuario final
		licenciatario. Al final de este Acuerdo puede consultar un aviso de
		privacidad pertinente, de acuerdo con los art.&nbsp;13 y 14 del
		RGPD.</span></font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
		tratamiento de Datos personales en virtud del artículo 6.2 para la
		prestación de servicios sanitarios requiere la conservación y el
		acceso a información, incluidos Datos personales, sobre el Usuario
		autorizado que se almacenen en el equipo terminal (el Escáner de
		heridas y, en su caso, otros dispositivos en los que se pueda
		utilizar el Software) y la concesión de acceso a la información
		para la prestación de servicios de tratamiento sujetos al control
		del Usuario final licenciatario. El Usuario final licenciatario
		garantiza que proporcionará información clara sobre los fines del
		tratamiento de Datos personales y otra información al Usuario
		autorizado.</span></font></font></p>
	</ol>
</ol>
<p lang="en-US" align="justify" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US"><b>ACEPTACIÓN
	DEL ACUERDO Y PROVISIÓN DE CUENTAS DE USUARIO</b></span></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">Antes
		de utilizar la Licencia, el Usuario final licenciatario aceptará
		los términos de esta Licencia tal y como se documenta en este
		Acuerdo. </span></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
		Usuario final licenciatario garantiza que el Usuario administrador
		está autorizado a representar al Usuario final licenciatario para
		la celebración de este Acuerdo de Licencia del Usuario Final, al
		aceptar sus términos antes de usar el Software por primera vez.</span></font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
		Usuario administrador deberá aceptar los términos de este Acuerdo
		por primera vez haciendo clic en un botón de aceptación que
		aparecerá en todos los dispositivos y proporcionará una copia por
		correo electrónico al Usuario final licenciatario. Una vez
		aceptado este Acuerdo, todos los Usuarios autorizados tienen
		permiso para utilizar el Software y el Escáner de heridas de
		acuerdo con los términos y la duración de esta Licencia, tal y
		como se especifica en su artículo&nbsp;13.</span></font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
		Licenciante proporcionará al Usuario final licenciatario las
		cuentas de usuario para el número acordado de Usuarios autorizados
		de acuerdo con el Contrato de subarrendamiento entre el Usuario
		final licenciatario y el Distribuidor. Se pueden solicitar cuentas
		adicionales para los Usuarios autorizados a través del panel de
		control del Software. No hay límite en cuanto al número de
		cuentas de Usuarios autorizados.</span></font></font></p>
	</ol>
</ol>
<p lang="en-US" align="justify" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>ACTUALIZACIONES
	DE SOFTWARE</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font size="2" style="font-size: 10pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span lang="en-US">El
		Licenciante tiene derecho a proporcionar actualizaciones del
		Software a su discreción. Las</span></font></font><span lang="en-US">
		</span><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span lang="en-US">actualizaciones
		se instalarán automáticamente mediante actualizaciones push. El
		Usuario final licenciatario no puede rechazar ni posponer las
		actualizaciones del software. </span></font></font></font></font>
		</p>
	</ol>
</ol>
<p lang="en-US" align="justify" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0in">
<br/>

</p>
<ol start="4">
	<ol start="2">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font size="2" style="font-size: 10pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span lang="en-US">Sin
		perjuicio de lo anterior, el Usuario final licenciatario no tendrá
		derecho a que se le proporcionen actualizaciones según lo
		estipulado en el artículo&nbsp;8.1 ni a solicitar actualizaciones
		concretas o generales. </span></font></font></font></font>
		</p>
	</ol>
</ol>
<p lang="en-US" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>DERECHOS DE
	PROPIEDAD INTELECTUAL E INDEMNIZACIONES</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a409880_1"></a>
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
		Usuario final licenciatario reconoce y acepta que todos los
		derechos industriales y de propiedad intelectual (incluidos los
		derechos exclusivos de explotación económica) sobre el Software y
		la Documentación en cualquier parte del mundo son y seguirán
		siendo propiedad exclusiva del Licenciante. Ninguna disposición
		contenida en el Acuerdo tiene por objeto transferir cualquiera de
		dichos derechos al Usuario final licenciatario ni a un tercero, ni
		debe interpretarse que lo tenga. </span><span lang="en-US">Sin
		perjuicio del derecho de uso del Software expresamente concedido en
		el Acuerdo, el Usuario final licenciatario no podrá reclamar
		ningún derecho, incluidos los derechos de copia sobre el Software
		y sobre cualquiera de sus componentes, ni cualquier otro derecho de
		propiedad industrial o intelectual relacionado con él.</span><span lang="en-US">
		El Usuario final licenciatario se compromete expresamente a adoptar
		todas las medidas adecuadas para proteger el contenido del Software
		e impedir su uso no autorizado, incluso por parte de los Usuarios
		autorizados. El Usuario final licenciatario reconoce que el
		Licenciante tendrá derecho a tomar medidas directas e inmediatas
		en caso de que el Usuario final licenciatario y sus Usuarios
		autorizados infrinjan sus derechos sobre el Software o lo permitan,
		y tendrá derecho a hacer valer dichos derechos eligiendo las
		formas y los medios más adecuados. </span></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a352428_1"></a>
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
		Usuario final licenciatario acepta que los derechos sobre el
		Software se conceden bajo licencia (no se venden) al Usuario final
		licenciatario, y que el Usuario final licenciatario y sus Usuarios
		autorizados no tienen ningún derecho sobre el Software ni la
		Documentación, salvo el derecho a utilizarlos de acuerdo con los
		términos de esta Licencia.</span></font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">Para
		evitar cualquier duda, todas las ideas, inventos, mejoras, métodos,
		procesos, obras de autoría y demás formas de propiedad
		intelectual que el Usuario final </span><span lang="en-US">licenciatario
		</span><span lang="en-US">conciba, reduzca a la práctica o
		desarrolle, solo o en colaboración con otros, en relación con el
		conocimiento o la información adquiridos u obtenidos del Software
		y de la Documentación, o basados en ellos, serán propiedad única
		y exclusiva del Licenciante o de un tercero designado por este, o
		conjuntamente por el Licenciante y un tercero designado por este. 
		El Usuario final </span><span lang="en-US">licenciatario </span><span lang="en-US">se
		compromete a ceder dicha propiedad intelectual al Licenciante y/o a
		un tercero designado por el Licenciante y a renunciar a cualquier
		otro derecho que el Usuario final licenciatario pueda tener sobre
		ella. El Usuario final </span><span lang="en-US">licenciatario </span><span lang="en-US">se
		compromete a no cuestionar ni disputar de manera alguna la
		titularidad de la propiedad intelectual durante la vigencia del
		presente Acuerdo ni en ningún momento posterior a su rescisión. </span></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
		Usuario final licenciatario reconoce que ni el Usuario final
		licenciatario ni sus Usuarios autorizados tienen derecho alguno a
		disponer del Software en forma de código fuente.</span></font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
		Licenciante declara y garantiza que, según su conocimiento, no
		existen reclamaciones ni acciones pendientes o anunciadas contra el
		Licenciante de que el Software infrinja los Derechos de propiedad
		intelectual de terceros. Si el Usuario final licenciatario tiene
		conocimiento de cualquier reclamación o (amenaza de) acción
		contra el Licenciante en relación con Derechos de propiedad
		intelectual de terceros, el Usuario final licenciatario informará
		de ello al Licenciante sin dilación. </span></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">En
		caso de que un tercero reclame al Usuario final licenciatario por
		considerar que el Software infringe los Derechos de propiedad
		intelectual de un tercero, el Usuario final licenciatario lo
		notificará al Licenciante sin dilación y le otorgará el control
		exclusivo de la defensa y/o resolución de la reclamación y
		cooperará plenamente con el Licenciante en dicha defensa o
		resolución de la reclamación. El Usuario final licenciatario no
		tomará ninguna medida que pueda reducir la capacidad del
		Licenciante para defender la reclamación. El Licenciante dará al
		Usuario final licenciatario la oportunidad de supervisar el
		procedimiento. En caso de que el Usuario final licenciatario
		considere que es necesaria una intervención para evitar cualquier
		desventaja para el Usuario final licenciatario, el Usuario final
		licenciatario informará al Licenciante al respecto y las Partes
		revisarán la línea de actuación apropiada. Sin perjuicio de lo
		anterior, el Licenciante tendrá la decisión final en relación
		con cualquier medida que emprender en relación con una (presunta)
		infracción de los Derechos de propiedad intelectual. </span></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">Sin
		perjuicio de lo anterior, si la totalidad o parte del Software es
		objeto de una reclamación por infracción o es susceptible de
		serlo en opinión del Licenciante, el Licenciante, a su propia
		costa y a su entera discreción, y sin más responsabilidad ante el
		Usuario final </span><span lang="en-US">licenciatario</span><span lang="en-US">,
		podrá procurar cualquiera de los siguientes remedios que sean
		factibles: (i) obtener para el Usuario final </span><span lang="en-US">licenciatario
		</span><span lang="en-US">el derecho a utilizar el Software; (ii)
		sustituir el Software por un software no infractor que sea al menos
		funcionalmente equivalente, o (iii) modificar el Software para que
		no haya infracción.</span></font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
		Licenciante no estará obligado a indemnizar ni tendrá ninguna
		responsabilidad u otras obligaciones con respecto a las
		reclamaciones basadas en: (i) la combinación o el uso del Software
		con otro software no aprobado y/o proporcionado por el Licenciante;
		(ii) el uso del Software por parte del Licenciatario o del Usuario
		autorizado de cualquier forma incompatible con el Acuerdo y/o la
		descripción del Software, o (iii) la no utilización de un
		Lanzamiento proporcionado por el Licenciante, si la infracción
		podría haberse evitado mediante el uso del Lanzamiento o de la
		última versión disponible del Software.</span></font></font></p>
	</ol>
</ol>
<p lang="en-US" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a683549_1"></a>
	<font color="#000000"><font face="Arial, serif"><b>GARANTÍA</b></font></font></p>
</ol>
<ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">Las
	condiciones de garantía se cubrirán mediante el Contrato de
	subarrendamiento. </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
	Licenciante no será responsable de las limitaciones de
	funcionamiento ni de los defectos del Software, si estos han sido
	causados en todo o en parte por cualquier comportamiento no
	autorizado llevado a cabo o permitido por el Usuario final
	licenciatario y/o sus Usuarios autorizados, cualquier uso del
	Software que no se corresponda con su uso previsto o con las
	Instrucciones de uso, cualquier alteración o no funcionamiento del
	Software derivado de software u otros productos no suministrados por
	el Licenciante. Si el mal funcionamiento del Software se deriva de
	algún error, el Usuario final licenciatario deberá notificar
	inmediatamente tal efecto al Licenciante. </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
	Licenciante no será responsable de las limitaciones de
	funcionamiento, de la no disponibilidad del Software o de los fallos
	en el cumplimiento de las obligaciones derivadas del presente
	contrato, causados por sistemas informáticos o por la conexión a
	Internet o los sistemas del Usuario final licenciatario. </span></font></font>
	</p>
</ol>
<p lang="en-US" align="justify" style="line-height: 115%; text-indent: -0.5in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a914493_1"></a><a name="co_anchor_a676389_1"></a><a name="co_anchor_a796999_1"></a><a name="co_anchor_a946537_1"></a>
	<font color="#000000"><font face="Arial, serif"><b>LIMITACIÓN DE LA
	RESPONSABILIDAD</b></font></font></p>
</ol>
<ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">Sin
	perjuicio de lo dispuesto en el artículo&nbsp;10 anterior, el
	Licenciante entrega el Software al Usuario final licenciatario “tal
	cual” y no concede al Usuario final licenciatario ninguna
	garantía, expresa ni implícita, relativa a su adecuación para un
	fin determinado, lo que incluye, de forma no exhaustiva, la garantía
	relativa a su adecuación para un fin determinado distinta de la que
	se establece expresamente en este Acuerdo o en las Instrucciones de
	uso. El Usuario final licenciatario es responsable de garantizar que
	las instalaciones y funciones del Software cumplen sus requisitos,
	tal y como se detallan en la Documentación.</span></font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">Queda
	entendido que, en la medida en que lo permita la legislación
	aplicable, el Licenciante no será responsable de los daños y
	perjuicios derivados de (i) un uso del Software que no se adecue al
	Acuerdo y a las Instrucciones de Uso; cualquier mal funcionamiento
	del Software derivado de los comportamientos detallados en (i);
	(iii) cualquier falta de claridad o inexactitud del Software o de la
	Documentación; (iv) errores en el Software o en la Documentación,
	o (v) corrupción de datos o información, salvo que las Partes
	acuerden lo contrario. </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; orphans: 0; widows: 0; margin-right: 0.05in; margin-bottom: 0.14in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">Salvo
	lo dispuesto en el presente Acuerdo,</span><span lang="en-US"><i> </i></span><span lang="en-US">el
	Licenciante no será responsable en ningún caso ante el Usuario
	final </span><span lang="en-US">licenciatario </span><span lang="en-US">de
	pérdida o daño directo o indirecto algunos, lo que incluye, de
	forma no exhaustiva, la pérdida de beneficios, la pérdida de fondo
	de comercio, la pérdida de reputación, la pérdida de negocio, la
	pérdida de oportunidades de negocio, la pérdida de ahorros
	previstos, la pérdida sufrida en relación con la corrupción de
	datos o información o cualquier otro daño especial, indirecto,
	remoto o consecuente que surja en virtud de la presente Licencia o
	en relación con ella, ya sea por contrato, responsabilidad
	extracontractual (inclusive negligencia), incumplimiento de
	obligaciones legales o de cualquier otra forma. </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">Sin
	perjuicio de lo anterior, el Licenciante será responsable, sin
	limitación alguna, de los daños causados por la conducta dolosa o
	la negligencia grave del Licenciante.</span></font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a879858_1"></a><a name="co_anchor_a570100_1"></a><a name="co_anchor_a1003989_1"></a>
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
	Usuario final licenciatario acepta y está de acuerdo en que, en la
	medida en que exista o surja cualquier reclamación en relación con
	el suministro del Software o de la Documentación, dicha reclamación
	solo podrá presentarse contra la entidad a la que el Usuario final
	licenciatario pagó la compra del Software de conformidad con los
	términos del acuerdo entre el Usuario final licenciatario y dicha
	entidad. </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a104167_1"></a>
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">Nada
	de lo dispuesto en esta Licencia limitará o excluirá la
	responsabilidad del Licenciante en estos casos:</span></font></font></p>
	<ol type="a">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">muerte o daños
		personales resultantes de nuestra negligencia, y</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a437879_1"></a>
		<font color="#000000"><font face="Arial, serif">fraude o
		declaración fraudulenta.</font></font></p>
	</ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">La
	presente Licencia establece la totalidad de las obligaciones y
	responsabilidades del Licenciante en relación con el suministro del
	Software y la Documentación. Salvo que se indique expresamente en
	esta Licencia, no existen artículos, garantías, observaciones ni
	otros términos, expresos o implícitos, que sean vinculantes para
	el Licenciante. Cualquier artículo, garantía, observación u otro
	término relativo al suministro del Software y la Documentación que
	pudieran estar implícitos o incorporados en esta Licencia, ya sea
	por ley, derecho común o de otro modo, quedan excluidos en la mayor
	medida permitida por la ley.</span></font></font></p>
</ol>
<p lang="en-US" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>CONFIDENCIALIDAD</b></font></font></p>
</ol>
<ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">Durante
	la vigencia del Acuerdo, el Licenciante y el Usuario final
	licenciatario intercambiarán información confidencial entre sí.
	Cada Parte se compromete a no revelar dicha información a terceros
	sin el previo consentimiento expreso de la otra Parte. Cualquier
	información confidencial se identificará como confidencial en los
	documentos escritos correspondientes o, si la información se
	comunica verbalmente, se señalará su carácter confidencial de
	manera expresa. No se considerará información confidencial si, en
	la fecha de su divulgación, la información es: (i) de dominio
	público, (ii) ya conocida por la parte no divulgadora o (iii) llega
	a ser conocida por el Usuario final licenciatario en una fecha
	posterior de una forma que no sea como resultado de este artículo.
	El Usuario final licenciatario protegerá la información
	confidencial de acuerdo con las mejores prácticas comerciales de
	seguridad del momento. </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">Ambas
	Partes no divulgarán, sin el previo consentimiento por escrito de
	la otra Parte, ninguna parte de la información confidencial a
	ninguna persona sin el consentimiento de la otra Parte, excepto en
	los siguientes casos:</span></font></font></p>
	<ol type="a">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">a
		sus propios empleados y consultores, y en tal caso solo cuando
		aquellos empleados y consultores tengan necesidad de saber;</span></font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">a
		un tribunal de jurisdicción competente, a un organismo público o
		a una autoridad reguladora aplicable y a cualquier otra persona u
		organismo que tenga el derecho, el deber o la obligación de
		conocer los negocios de la otra Parte, y tal caso solo en
		cumplimiento de dicho derecho, deber u obligación.</span></font></font></p>
	</ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">Las
	obligaciones establecidas en el presente documento con respecto a la
	información confidencial continuarán en pleno vigor y efecto
	durante un período de diez (10) años después de la fecha de
	divulgación de la información confidencial. </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">Sin
	perjuicio de lo anterior, las Partes acuerdan que, en relación con
	el tratamiento de Datos personales sujetos al RGPD, se aplicarán en
	su lugar los </span><span lang="en-US">artículos</span><span lang="en-US">
	de confidencialidad establecidos en el acuerdo de tratamiento de
	datos entre el Licenciante y el Usuario final </span><span lang="en-US">licenciatario</span><span lang="en-US">.
	Por lo tanto, el presente artículo&nbsp;12 no se aplicará a la
	confidencialidad de los Datos personales, que se dispondrá por
	separado en el acuerdo de tratamiento de datos entre las Partes, ni
	a los datos anonimizados de conformidad con el acuerdo de
	tratamiento de datos entre las Partes. </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="_Toc520913491"></a>
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">Cada
	una de las Partes del presente Acuerdo notificará sin dilación a
	la otra Parte si tiene conocimiento de cualquier infracción de la
	confidencialidad por parte de cualquier persona a la que comunique
	toda o parte de la Información, y prestará a la otra Parte toda la
	asistencia razonable en relación con cualquier procedimiento que la
	otra Parte pueda iniciar contra dicha persona por infracción de la
	confidencialidad.</span></font></font></p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">Los
	derechos y obligaciones de este artículo sobrevivirán a la
	rescisión del presente Acuerdo sin límite de tiempo, pero dejarán
	de aplicarse a la información o los conocimientos que puedan pasar
	a ser de dominio público. </span></font></font>
	</p>
</ol>
<p lang="en-US" align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a534011_1"></a>
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>RESCISIÓN</b></font></font></p>
</ol>
<ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">Esta
	Licencia entrará en vigor en el momento de la aceptación de sus
	términos y permanecerá en vigor hasta la rescisión del Contrato
	de subarrendamiento entre el Usuario final licenciatario y el
	Distribuidor que permite al Usuario final licenciatario utilizar el
	Escáner de heridas, independientemente de que el Usuario final
	licenciatario haya suscrito dicho contrato con el Licenciante o con
	un Distribuidor. El Usuario final licenciatario está obligado a
	informar al Licenciante de la rescisión o el vencimiento del
	contrato de alquiler o de servicio que permite al Usuario final
	licenciatario utilizar el Escáner de heridas. </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
	Licenciante podrá rescindir esta Licencia con efecto inmediato
	mediante notificación por escrito al Usuario final licenciatario si
	este:</span></font></font></p>
	<ol type="a">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">comete
		un incumplimiento sustancial o persistente de los términos de esta
		Licencia que no pueda ser subsanado o que el Usuario final
		licenciatario no subsane en un plazo de catorce (14) días a partir
		de una notificación por escrito en la que se especifique el
		incumplimiento y se exija su subsanación; </span></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">es
		incapaz de pagar sus deudas pendientes; o se declare insolvente; o
		esté sujeto a una orden o a una resolución de liquidación,
		administración, cierre o disolución (salvo con el fin de la
		fusión o reconstrucción solvente); </span></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">tiene
		un administrador u otro depositario, gestor, fiduciario, síndico u
		otro representante similar para hacerse cargo de la totalidad o una
		parte sustancial de sus activos, o firma o se propone cualquier
		composición o acuerdo con sus acreedores en general; </span></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">suspende
		o anuncia que va a suspender su actividad; </span></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">es
		objeto de cualquier acto o procedimiento análogo en cualquier
		jurisdicción, o </span></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">transfiere
		o cede a un tercero sin el previo consentimiento explícito y por
		escrito del Licenciante cualquier soporte físico que incorpore el
		software y/o cualquier copia del mismo (inclusive cualquier copia
		de seguridad permitida por la legislación nacional).  </span></font></font>
		</p>
	</ol>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">Todas
	las disposiciones contractuales del Acuerdo cuya eficacia no se vea
	perjudicada por la rescisión o el vencimiento del presente Acuerdo
	seguirán siendo plenamente vigentes entre las Partes tras la
	rescisión o el vencimiento del Acuerdo. </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">Tras
	la rescisión o el vencimiento del Acuerdo por cualquier motivo:</span></font></font></p>
	<ol type="a">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">cesarán
		todos los derechos concedidos al Usuario final licenciatario en
		virtud de esta Licencia;</span></font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">el
		Usuario final licenciatario deberá suspender inmediatamente todas
		las actividades autorizadas por esta Licencia, y </span></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="_Hlk95062074"></a><a name="co_anchor_a666232_1"></a>
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">el
		Usuario final licenciatario se compromete a eliminar o retirar de
		forma inmediata y permanente el Software de todos los equipos
		informáticos que posea, y a destruir o devolver inmediatamente al
		Licenciante (a su elección) todas las copias del Software y de la
		Documentación que en ese momento estén en posesión, custodia o
		control del Usuario final licenciatario y, en caso de destrucción,
		el Usuario final licenciatario deberá certificar al Licenciante
		que el Software y la Documentación se han destruido.</span></font></font></p>
	</ol>
</ol>
<p lang="en-US" align="justify" style="line-height: 115%; margin-left: 0.75in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>COMUNICACIÓN
	ENTRE LAS PARTES</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
		Licenciante podrá modificar las condiciones de esta Licencia en
		cualquier momento mediante notificación al Usuario final
		licenciatario de acuerdo con este artículo&nbsp;14. El uso
		continuado del Software y de la Documentación por parte del
		Usuario final licenciatario en los 30&nbsp;días posteriores a la
		emisión de la notificación detallada en este artículo&nbsp;14
		constituirá la aceptación por parte del Usuario final
		licenciatario de los términos de esta Licencia y de sus términos
		modificados. Si el Usuario final licenciatario no desea aceptar los
		términos de la Licencia (en su versión modificada), el Usuario
		final licenciatario interrumpirá inmediatamente el uso y el acceso
		al Software y a la Documentación por parte del Usuario final
		licenciatario y/o sus Usuarios autorizados al recibir y notificar
		la notificación e informará de ello al Licenciante a través de
		los datos de contacto puestos a disposición del Usuario final
		licenciatario en el panel de control del Software.</span></font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a510664_1"></a>
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
		Licenciante únicamente se pondrá en contacto con el Usuario final
		licenciatario a través de los datos de contacto facilitados por
		este antes de la aceptación del Acuerdo. </span></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Toda notificación:</font></font></p>
	</ol>
</ol>
<ol>
	<ol type="a">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">dada
		por el Licenciante al Usuario final </span><span lang="en-US">licenciatario
		</span><span lang="en-US">se considerará recibida y debidamente
		notificada 24&nbsp;horas después de que se publique por primera
		vez en el panel de control y en el sitio web del Licenciante,
		24&nbsp;horas después de que se envíe un correo electrónico, o
		tres días después de la fecha del envío postal de cualquier
		carta, y</span></font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">dada
		por el Usuario final licenciatario al Licenciante se considerará
		recibida y debidamente notificada 24&nbsp;horas después de que se
		envíe un correo electrónico, o tres días después de la fecha
		del envío postal de cualquier carta.</span></font></font></p>
	</ol>
</ol>
<ol start="4">
	<ol start="4">
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">Para
		probar la entrega de una notificación, bastará con probar: en el
		caso de una carta, que dicha carta fue debidamente dirigida,
		sellada y enviada por correo a la dirección del destinatario
		facilitada a estos efectos; en el caso de la entrega al Usuario
		final </span><span lang="en-US">licenciatario </span><span lang="en-US">a
		través del panel de control del Software, dos días después de la
		entrega de la notificación; y, en el caso de un correo
		electrónico, que dicho correo se remitió a la dirección de
		correo electrónico del destinatario facilitada a estos efectos.</span></font></font></p>
	</ol>
</ol>
<p lang="en-US" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>FUERZA MAYOR</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">En
		el caso de que el Licenciante se vea retrasado u obstaculizado en
		la realización de cualquier acto (inclusive, de forma no
		exhaustiva, la provisión del software requerido en virtud del
		presente acuerdo) por razones de huelga, cierres patronales,
		conflictos laborales, terrorismo, incapacidad para obtener
		materiales o servicios, cortes de energía, decretos u órdenes
		gubernamentales o judiciales restrictivas, disturbios,
		insurrección, guerra, casos fortuitos, terremotos, o cualquier
		otra razón o causa que escape del control de dicha Parte,
		independientemente de que dicha razón o causa pueda preverse en la
		actualidad (como un nuevo confinamiento por COVID u otra pandemia),
		el cumplimiento de dicho acto quedará excusado durante el periodo
		de la demora, siempre y cuando el Licenciante realice esfuerzos
		razonables para remediar dicha demora, obstáculo o impedimento</span><font size="2" style="font-size: 9pt"><span lang="en-US"><span style="background: #ffffff">.
		</span></span></font><span lang="en-US">El Licenciante no será
		responsable de ningún incumplimiento o retraso en el cumplimiento
		de cualquiera de las obligaciones contraídas en virtud de la
		presente Licencia por causa de fuerza mayor, tal y como se detalla
		en el presente artículo&nbsp;15.1.</span></font></font></p>
	</ol>
</ol>
<p lang="en-US" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"> <font face="Arial, serif"><span lang="en-US"><b>	</b></span><b>PRODUCTO
	SANITARIO </b></font></font>
	</p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
		Licenciante identificará de buena fe y de acuerdo con la normativa
		local aquellos productos que se califican como productos sanitarios
		de acuerdo con el Reglamento sobre los productos sanitarios
		(Reglamento (UE) 2017/745). </span>El Software es un producto
		sanitario de acuerdo con el Reglamento sobre los productos
		sanitarios.</font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
		Usuario final licenciatario es responsable de controlar que el
		Software se utilice correctamente de conformidad con la legislación
		y las normativas locales. </span></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">Siempre
		que el Usuario final </span><span lang="en-US">licenciatario </span><span lang="en-US">tenga
		conocimiento de problemas de calidad, incidentes o informaciones
		que sugieran de forma razonable que el Software o la Documentación
		no cumplen con el Reglamento sobre los productos sanitarios de la
		UE o con la legislación nacional sobre productos sanitarios, el
		Usuario final </span><span lang="en-US">licenciatario </span><span lang="en-US">proporcionará
		al Licenciante toda la información y el acceso al dispositivo en
		cuestión que sean necesarios para determinar el problema, y no
		utilizará el Software hasta que el Licenciante dé su
		autorización. El Usuario final </span><span lang="en-US">licenciatario
		</span><span lang="en-US">proporcionará una primera notificación
		de dicho problema de calidad, incidencia o información sobre el
		cumplimiento de la legislación de productos sanitarios aplicable
		en un plazo de 24&nbsp;horas desde que tenga conocimiento de ello,
		y realizará un seguimiento con toda información que aún no
		estuviera disponible en el momento de la notificación inicial sin
		demora indebida. </span></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
		Usuario final licenciatario remitirá dicha información al
		Licenciante sin demora y en ningún caso más tarde de 24&nbsp;horas
		después de tener conocimiento de ella.</span></font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">Si
		el Licenciante notifica al Usuario final </span><span lang="en-US">licenciatario
		</span><span lang="en-US">que es necesario tomar medidas
		correctivas de seguridad sobre el terreno o una retirada de
		productos, el Usuario final </span><span lang="en-US">licenciatario
		</span><span lang="en-US">deberá confirmar rápidamente la
		recepción de la notificación y cooperar con el Licenciante,
		corriendo con los gastos, para implementar dichas medidas. Bajo
		ninguna circunstancia el Usuario final </span><span lang="en-US">licenciatario
		</span><span lang="en-US">podrá implementar medida correctiva
		alguna sin el consentimiento expreso del Licenciante. </span></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
		Usuario final </span><span lang="en-US">licenciatario </span><span lang="en-US">se
		compromete a notificar al Licenciante cualquier actuación
		gubernamental o de terceros en relación con el Software y a</span><span lang="en-US">
		</span><span lang="en-US">colaborar con él en cuanto tenga
		conocimiento de dicha actuación.</span></font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">Si
		el Usuario final </span><span lang="en-US">licenciatario </span><span lang="en-US">tiene
		motivos para creer que el Software puede suponer un riesgo grave o
		puede ser falsificado, no informará a ninguna autoridad competente
		antes de haber informado al Licenciante y de haber acordado con él
		los detalles de la información que se comunicará a la autoridad
		competente. </span></font></font>
		</p>
	</ol>
</ol>
<p lang="en-US" align="justify" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"> <font face="Arial, serif"><span lang="en-US"><b>	</b></span><b>VARIOS</b></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US"><u>Transferencia
		de derechos por parte del Licenciante</u></span><span lang="en-US"><u>.</u></span><span lang="en-US">
		Por el presente, el Usuario final licenciatario autoriza de
		antemano al Licenciante a transferir sus derechos y obligaciones en
		virtud del presente Acuerdo a un tercero. </span></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US"><u>Transferencia
		de derechos por parte del Usuario final licenciatario.</u></span><span lang="en-US">
		El Usuario final licenciatario solamente podrá transferir sus
		derechos y obligaciones en virtud de la presente Licencia a otra
		persona previa recepción de un consentimiento explícito y por
		escrito por parte del Licenciante.</span></font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">La
		presente Licencia y cualquier documento al que se haga referencia
		expresa en ella constituyen el acuerdo completo entre las Partes y
		sustituyen y extinguen todos los acuerdos, promesas, garantías,
		observaciones y entendimientos previos entre nosotros, ya sean
		escritos o verbales, que estuvieran relacionados con su objeto.</span></font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">Si
		el Licenciante no insiste en que el Usuario final </span><span lang="en-US">licenciatario
		</span><span lang="en-US">cumpla con alguna de sus obligaciones en
		virtud de esta Licencia, o si el Licenciante no hace valer los
		derechos del Licenciante frente al Usuario final </span><span lang="en-US">licenciatario
		</span><span lang="en-US">o se retrasa en hacerlo, eso no
		significará que el Licenciante haya renunciado a sus derechos
		frente al Usuario final </span><span lang="en-US">licenciatario </span><span lang="en-US">ni
		significará que el Usuario final </span><span lang="en-US">licenciatario
		</span><span lang="en-US">no tenga que cumplir con esas
		obligaciones. Cualquier dispensa de incumplimiento por parte del
		Usuario final</span><span lang="en-US"> licenciatario</span><span lang="en-US">
		se hará por escrito. El Licenciante no está obligado a dispensar
		ningún incumplimiento por parte del Usuario final</span><span lang="en-US">
		licenciatario</span><span lang="en-US">.</span></font></font></p>
		<li><p align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">Cada
		uno de los artículos de esta Licencia funciona por separado. Si un
		tribunal o una autoridad competente deciden que alguno de ellos es
		ilícito o inaplicable, el resto de los artículos seguirán
		teniendo plena vigencia</span><span lang="en-US"><b>.</b></span></font></font></p>
	</ol>
</ol>
<p lang="en-US" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"> <font face="Arial, serif"><b>DERECHO
	APLICABLE Y LITIGIOS</b></font></font></p>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.49in; text-indent: -0.49in; margin-bottom: 0.08in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US">18.1
	La presente Licencia, su objeto y su formación se rigen por las
leyes de Alemania, con exclusión de los conflictos de leyes, las
normas y la Convención de las Naciones Unidas sobre los contratos de
compraventa internacional de mercaderías del 11 de abril de 1980. </span></font></font>
</p>
<p align="justify" style="line-height: 115%; margin-left: 0.49in; text-indent: -0.49in; margin-bottom: 0.08in"><a name="kh_relatedContentOffset_1"></a><a name="co_anchor_a840535_1"></a>
<font color="#000000"><font face="Arial, serif"><span lang="en-US">18.2	</span><span lang="en-US">Cualquier
disputa en relación con este Acuerdo y la Licencia estará sujeta a
la jurisdicción exclusiva de los tribunales del domicilio legal de
la parte demandada.</span></font></font></p>
<p align="center" style="line-height: 150%; margin-bottom: 0.08in; page-break-before: always">
<font color="#000000"><font face="Arial, serif"><span lang="en-US"><b>Aviso
de privacidad</b></span></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US">Gracias
por adquirir el software y el dispositivo digital para la gestión de
heridas Vintens™. Nosotros (KroniKare Ltd) queremos informarle de
que actuaremos como responsables del tratamiento de todos los Datos
Personales relativos a los acuerdos suscritos con KroniKare Ltd, en
general con respecto a los Datos Personales del Usuario final
licenciatario y, para evitar dudas, cuando el Usuario final
licenciatario sea una persona física o un propietario único (por
ejemplo, un médico en ejercicio o independiente o un enfermero
independiente o autónomo).</span></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.14in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US">Por
lo tanto, antes de que comience a utilizar el Dispositivo y el
Software, nos gustaría recordarle (mediante este aviso de
privacidad) que recogemos ciertos Datos personales limitados sobre
usted (los “Datos personales”). Le rogamos que lea atentamente
este aviso de privacidad y confirme su lectura. </span></font></font>
</p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US"><b>1)
¿Quién es responsable del tratamiento de los Datos personales,
representante en la UE?</b></span></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US">KroniKare
Ltd, The Wenta Business Centre, Watford, Hertfordshire, Inglaterra,
WD24 7ND (Reino Unido) es responsable del tratamiento de Datos
Personales.</span></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US">El
representante en la Unión Europea para todas las cuestiones
relacionadas con el tratamiento de Datos personales según el art.&nbsp;27
del RGPD puede ser contactado de la siguiente manera: Kaleidoscope
Data Privacy Consultants&nbsp;SL (KDPC ES), Calle Balmes 173, 4-2,
Barcelona 08006, España (</span></font></font><font color="#000000"><font face="Arial, serif"><i><u><a class="western" href="mailto:dpr.kronikare@kdpc.es"><font color="#000000"><span lang="en-CA">dpr.kronikare@kdpc.es</span></font></a></u></i></font></font><font color="#000000"><font face="Arial, serif"><span lang="en-US">)
en nombre de KroniKare Ltd.</span></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US">KroniKare
Ltd. ha encomendado por escrito al representante que atienda a las
consultas de las autoridades de control y a actuar como punto de
contacto para los interesados.</span></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US">Para
obtener más detalles de contacto, consulte el punto 8), a
continuación.</span></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US"><b>2)
¿Qué tipo de Datos personales se tratan y cuál es la finalidad y
la base legal?</b></span></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US">Recogemos,
en particular, los siguientes Datos personales básicos, así como
datos de uso pertinentes sobre usted: Nombre de usuario, Dirección
de correo electrónico, Número de teléfono, Nombre, Segundo nombre,
Apellidos, Sexo, Localidad, Tratamiento, Categoría, Puesto,
Descripción del trabajo, Datos de inicio de sesión (Archivos de
registro), Dirección IP, por ejemplo.  La finalidad del tratamiento
de los datos es el cumplimiento del acuerdo, en especial, permitir el
registro, el inicio de sesión o la administración y el uso del
software y el dispositivo digital para la gestión de heridas, de
acuerdo con el art.&nbsp;6 (1) b del RGPD.  </span></font></font>
</p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US"><b>3)
¿Quién recibe mis Datos personales?</b></span></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.14in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US">Por
regla general, solamente tratamos sus Datos personales internamente.
Si transferimos sus Datos personales a otras personas, empresas o
terceros o les concedemos otro acceso a ellos, esto solo se hará
sobre la base de un permiso legal. Si encargamos a terceros el
tratamiento de Datos personales sobre la base de un acuerdo de
tratamiento de datos, esto se hace de acuerdo con el art.&nbsp;28 del
RGPD. Sin embargo, seguimos siendo responsables ante usted de la
licitud del tratamiento de datos. </span></font></font>
</p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US"><b>4)
¿Dónde se tratarán y conservarán mis Datos personales y durante
cuánto tiempo?</b></span></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.14in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US">En
la medida de lo posible, solamente realizaremos el tratamiento de sus
Datos personales dentro del Espacio Económico Europeo. Sin embargo,
si es necesario el tratamiento de sus Datos personales en terceros
países, nos aseguraremos de que se cumplan los requisitos legales
específicos para dichas operaciones de tratamiento y de que
prevalezca un nivel de protección de datos adecuado en el tercer
país respectivo. </span></font></font>
</p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US">En
consecuencia, garantizaremos la existencia de garantías y medidas
adecuadas de conformidad con el art.&nbsp;46 del RGPD (si no se ha
adoptado una decisión de adecuación), en particular, podemos
aplicar las cláusulas contractuales tipo (CCT). Dado que KroniKare
Ltd. tiene su sede en Reino Unido y que, según la Comisión Europea,
el Reino Unido ofrece un nivel de protección sustancialmente
equivalente al de la Unión Europea (véase el art.&nbsp;45 del
RGPD), las transferencias de Datos personales desde la Unión Europea
a este tercer país pueden llevarse a cabo sin más garantías y
medidas adecuadas.</span></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US">En
principio, conservamos sus Datos personales mientras tenga vigencia
la relación contractual. La información anterior sobre la supresión
no se aplica si, entre otras cosas, los períodos de retención
legalmente prescritos impiden la supresión inmediata (cfr. art. 17
(3) del RGPD) o si una nueva finalidad justifica la continuación del
tratamiento.</span></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><b>5) ¿Qué derechos
tienen los interesados?</b></font></font></p>
<ul>
	<li><p align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">De acuerdo con el
	art. 15 del RGPD, puede solicitar información sobre sus Datos
	personales que nosotros tratamos.</font></font></p>
	<li><p align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">De
	acuerdo con el art.&nbsp;16 del RGPD, puede exigir la corrección
	inmediata de los Datos personales incorrectos o incompletos
	conservados por nosotros.</span></font></font></p>
	<li><p align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">De
	acuerdo con el art.&nbsp;17 del RGPD, puede solicitar que se
	supriman sus Datos personales conservados por nosotros de acuerdo
	con las condiciones establecidas en ese Reglamento, a menos que los
	períodos de retención legalmente prescritos impidan la eliminación
	inmediata (véase el art.&nbsp;17 (3) del RGPD) y/o exista otro caso
	del art. 17 (3) del RGPD y/o una nueva finalidad justifique la
	continuación del tratamiento.</span></font></font></p>
	<li><p align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">De
	acuerdo con el art.&nbsp;18 (1) del RGPD, puede solicitar que se
	limite el tratamiento de datos si se cumplen una o más condiciones,
	de conformidad con el art.&nbsp;18 (1) del RGPD de la letra “a”
	a la “d”.</span></font></font></p>
	<li><p align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">De
	acuerdo con el art.&nbsp;20 (1) del RGPD, puede recibir los Datos
	personales tratados por nosotros en un formato estructurado, de uso
	común y de lectura mecánica, y transmitir estos Datos personales a
	otro responsable del tratamiento sin que nosotros se lo impidamos.</span></font></font></p>
	<li><p align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">Además,
	puede oponerse al tratamiento de sus Datos personales de acuerdo con
	el art.&nbsp;21 (1) del RGPD. En caso de oposición, pondremos fin
	al tratamiento de sus Datos personales. Sin embargo, el derecho de
	objeción solamente se aplica en caso de circunstancias especiales
	derivadas de su situación personal. Además, pueden prevalecer
	razones imperiosas de protección que hablen a favor del
	tratamiento. Por otra parte, determinadas finalidades del
	tratamiento pueden entrar en conflicto con su derecho de objeción. </span></font></font>
	</p>
	<li><p align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">Sin
	perjuicio de cualquier otra tutela judicial o administrativa, usted
	también tiene derecho a recurrir a la autoridad de control
	competente (cfr. art.&nbsp;77 del RGPD) si considera que el
	tratamiento de sus Datos personales infringe las disposiciones en
	materia de protección de datos. En este contexto, sin embargo, le
	rogamos que primero dirija cualquier queja a los datos de contacto
	indicados en el punto 8), a continuación. </span></font></font>
	</p>
</ul>
<p lang="en-US" align="justify" style="line-height: 150%; margin-left: 0.2in; margin-bottom: 0in">
<br/>

</p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US"><b>6)
¿Estoy obligado a facilitar mis Datos personales?</b></span></font><font face="Arial, serif"><span lang="en-US">
</span></font></font>
</p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US">Usted
solamente está obligado a facilitarnos los Datos personales que sean
necesarios para el inicio, la ejecución y la rescisión de una
relación contractual o que, en general, necesitemos para llevar a
cabo nuestros servicios. Sin estos Datos personales, por lo general
no podremos concluir ni llevar a cabo la relación contractual con
usted o prestarle nuestros servicios. </span></font></font>
</p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US"><b>7)
¿Utilizamos decisiones automatizadas?</b></span></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US">No
utilizamos procedimientos de decisiones puramente automatizadas, de
acuerdo con el art.&nbsp;22 del RGPD. No obstante, si en el futuro
utilizamos dicho procedimiento en casos individuales, le informaremos
por separado si así lo exige la ley.</span></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.11in"><font color="#000000"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><span lang="en-CA"><b>8)
¿Tiene alguna pregunta?</b></span></font></font></font></p>
<p class="western" align="justify" style="line-height: 150%; margin-bottom: 0.14in">
<font color="#000000"><font face="Arial, serif"><span lang="en-CA">Póngase
en contacto con nosotros para obtener más información sobre la
protección de datos o en caso de que tenga más preguntas, en
particular sobre el ejercicio de sus derechos como interesado en
</span></font><font face="Arial, serif"><span lang="en-CA">KroniKare</span></font><font face="Arial, serif"><span lang="en-CA">&nbsp;Ltd.</span></font><font face="Arial, serif"><span lang="en-CA">
</span></font><font face="Arial, serif"><span lang="en-US">Delegado
de protección de datos para el Reino Unido    
(dpo.kronikare@kdpc.uk) y representante en la Unión Europea
(dpr.kronikare@kdpc.es).</span></font><span lang="en-US"> </span></font>
</p>
</body>
</html>
  `,
}

export default { ...main }
