import Locale from './Locale'
import SetInitialPassword from '../EN/SetInitialPassword'
import SetNewPassword  from '../EN/SetNewPassword'
import RegisterPIN from '../EN/RegisterPIN'
import RegisterTOTP from '../EN/RegisterTOTP'
import ErrorSuccessMessages from '../EN/ErrorSuccessMessages'
import ValidationMessages from '../EN/ValidationMessages'

export default {
  name: 'PHUK',
...Locale,
...SetInitialPassword,
...SetNewPassword,
...RegisterPIN,
...RegisterTOTP,
...ErrorSuccessMessages,
...ValidationMessages,
}