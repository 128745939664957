import URIs from '../constants/endpoints'
import apirequest from '../utils/api-request'

const accountService = () => {
  return {
    login: (unique_id, password) => {
      return apirequest({
        method: 'POST',
        url: URIs.LOGIN,
        data: {
          unique_id,
          password,
        },
        headers: {
          withCredentials: true,
          'Referrer-Policy': 'strict-origin',
        },
      })
    },
    forgotPassword: (unique_id) => {
      return apirequest({
        method: 'GET',
        url: URIs.FORGOT_PASSWORD,
        params: {
          unique_id,
        },
        headers: {
          withCredentials: true,
          'Referrer-Policy': 'strict-origin',
        },
      })
    },
    requestSecretKey: () => {
      return apirequest({
        method: 'GET',
        url: URIs.REQUEST_SECRET_KEY,
        headers: {
          withCredentials: true,
          'Referrer-Policy': 'strict-origin',
        },
      })
    },
    validateTotp: (totp) => {
      return apirequest({
        method: 'POST',
        url: URIs.VALIDATE_TOTP,
        data: {
          totp,
        },
        headers: {
          withCredentials: true,
          'Referrer-Policy': 'strict-origin',
        },
      })
    },
    resetPassword: (unique_id, verification_code, new_password) => {
      return apirequest({
        method: 'POST',
        url: URIs.RESET_PASSWORD,
        data: {
          unique_id,
          verification_code,
          new_password,
        },
        headers: {
          withCredentials: true,
          'Referrer-Policy': 'strict-origin',
        },
      })
    },
    passwordChange: (current_password, new_password) => {
      return apirequest({
        method: 'POST',
        url: URIs.PASSWORD_CHANGE,
        data: {
          password: current_password,
          new_password,
        },
        headers: {
          withCredentials: true,
          'Referrer-Policy': 'strict-origin',
        },
      })
    },
    initialPasswordChange: (preferred_username, password) => {
      return apirequest({
        method: 'POST',
        url: URIs.INITIAL_PASSWORD_CHANGE,
        data: {
          preferred_username,
          password,
        },
        headers: {
          withCredentials: true,
          'Referrer-Policy': 'strict-origin',
        },
      })
    },
    setPin: (pin) => {
      return apirequest({
        method: 'POST',
        url: URIs.SET_PIN,
        data: {
          pin,
        },
        headers: {
          withCredentials: true,
          'Referrer-Policy': 'strict-origin',
        },
      })
    },
    confirmSecretKey: (totp) => {
      return apirequest({
        method: 'POST',
        url: URIs.CONFIRM_SECRET_KEY,
        data: {
          totp,
        },
        headers: {
          withCredentials: true,
          'Referrer-Policy': 'strict-origin',
        },
      })
    },
    register: (data) => {
      return apirequest({
        method: data && data.id ? 'PUT' : 'POST',
        url: URIs.ACCOUNT,
        data,
        headers: {
          withCredentials: true,
          'Referrer-Policy': 'strict-origin',
        },
      })
    },
  }
}
export default accountService
