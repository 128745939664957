export const UI = {
  SET_LOCALE_THEME: "SET_LOCALE_THEME",
  SHOW_SNACKBAR: "SHOW_SNACKBAR",
  HIDE_SNACKBAR: "HIDE_SNACKBAR",
  SHOW_LOADER: "SHOW_LOADER",
  HIDE_LOADER: "HIDE_LOADER",
  FETCH_REFERENCE_DATA: "FETCH_REFERENCE_DATA",
};

export const DATA = {
  FETCH_LIST: "FETCH_LIST",
  RESET_LIST: 'RESET_LIST',
};

export const DASHBOARD = {
  FETCH_OVERVIEW_DATA: "FETCH_OVERVIEW_DATA",
  FETCH_OVERVIEW_REPORTS: "FETCH_OVERVIEW_REPORTS",
};

export const ACCOUNT = {
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGOUT: "USERS_LOGOUT",
};

export const ORGANIZATION = {
  FETCH_ORGANIZATION_ADMISSION: 'FETCH_ORGANIZATION_ADMISSION',
  SET_SELECTED_ORGANIZATION: 'SET_SELECTED_ORGANIZATION',
}