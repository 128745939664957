import Locale from './Locale'
import SetInitialPassword from '../ES/SetInitialPassword'
import SetNewPassword  from '../ES/SetNewPassword'
import RegisterPIN from '../ES/RegisterPIN'
import RegisterTOTP from '../ES/RegisterTOTP'
import ErrorSuccessMessages from '../ES/ErrorSuccessMessages'
import ValidationMessages from '../ES/ValidationMessages'

export default {
  name: 'PHSP',
...Locale,
...SetInitialPassword,
...SetNewPassword,
...RegisterPIN,
...RegisterTOTP,
...ErrorSuccessMessages,
...ValidationMessages,
}