import React, { forwardRef } from "react";
import ListItem from "@material-ui/core/ListItem";
import { NavLink, NavLinkProps } from "react-router-dom";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    mainActiveLink: {
      borderLeft: "5px solid #4299D9",
      color: "#4299D9 !important",
      "& *": {
        color: "#4299D9 !important",
      },
    },
  })
);
const AppMenuItemComponent = (props) => {
  const { className, onClick, link, children } = props;
  const classes = useStyles();
  // If link is not set return the orinary ListItem
  if (!link || typeof link !== "string") {
    return (
      <ListItem
        button
        className={className}
        children={children}
        onClick={onClick}
      />
    );
  }

  // Return a LitItem with a link component
  return (
    <ListItem
      button
      className={className}
      children={children}
      component={forwardRef((props, ref) => (
        <NavLink
          // exact
          {...props}
          innerRef={ref}
          activeClassName={classes.mainActiveLink}
        />
      ))}
      to={link}
    />
  );
};

export default AppMenuItemComponent;
