//basic
const main = {
  ForgotPassword_titleForgotPassword_display: true,
  ForgotPassword_titleForgotPassword_text: 'Password dimenticata?',
  ForgotPassword_labelProcessInfo_display: true,
  ForgotPassword_labelProcessInfo_text:
    'Inserisci il tuo nome utente registrato e ti invieremo un link per reimpostare la password.',
  ForgotPassword_labelUsername_display: true,
  ForgotPassword_labelUsername_text: 'Nome utente',
  ForgotPassword_labelSubmitButton_display: true,
  ForgotPassword_labelSubmitButton_text: 'Invia',
  ForgotPassword_labelBackToLoginLink_display: true,
  ForgotPassword_labelBackToLoginLink_text: 'Torna al login',
  ForgotPassword_validationMessageUsernameRequired_display: true,
  ForgotPassword_validationMessageUsernameRequired_text: 'Il nome utente è obbligatorio',
  ForgotPassword_validationMessageEmailInvalid_display: true,
  ForgotPassword_validationMessageEmailInvalid_text: 'Formato email non valido!',
  ForgotPassword_errorMessageUsernameNotExist_display: true,
  ForgotPassword_errorMessageUsernameNotExist_text:
    'The username does not exist in the database. Please key in the correct registered username.',
  ForgotPassword_errorMessageProcessFailed_display: true,
  ForgotPassword_errorMessageProcessFailed_text: 'Process failed. Please try again!',
  ForgotPassword_errorMessageNetworkIssues_display: true,
  ForgotPassword_errorMessageNetworkIssues_text: 'Controlla la tua connessione di rete!',
  ForgotPassword_errorMessageServerIssues_display: true,
  ForgotPassword_errorMessageServerIssues_text:
    'Impossibile ottenere una risposta! Per favore riprova.',
}

export default {
  ...main,
}
