//FR
const main = {
    SetInitialPassword_titleSetInitialPassword_display: true,
    SetInitialPassword_titleSetInitialPassword_text: 'Définir le mot de passe initial',
    SetInitialPassword_labelPreferredUsername_display: true,
    SetInitialPassword_labelPreferredUsername_text: "Nom d'utilisateur préféré",
    SetInitialPassword_labelNewPassword_display: true,
    SetInitialPassword_labelNewPassword_text: 'Nouveau mot de passe',
    SetInitialPassword_labelConfirmNewPassword_display: true,
    SetInitialPassword_labelConfirmNewPassword_text: 'Confirmer le nouveau mot de passe',
    SetInitialPassword_labelSubmitButton_display: true,
    SetInitialPassword_labelSubmitButton_text: 'Soumettre',
    SetInitialPassword_validationMessagePreferredUsernameRequired_display: true,
    SetInitialPassword_validationMessagePreferredUsernameRequired_text: "Le nom d'utilisateur préféré est requis",
    SetInitialPassword_validationMessagePasswordRequired_display: true,
    SetInitialPassword_validationMessagePasswordRequired_text: 'Le mot de passe est requis',
    SetInitialPassword_validationMessageNewPasswordRequired_display: true,
    SetInitialPassword_validationMessageNewPasswordRequired_text: 'Confirmer le nouveau mot de passe est nécessaire',
    SetInitialPassword_validationMessagePasswordDoNotMatch_display: true,
    SetInitialPassword_validationMessagePasswordDoNotMatch_text: 'Les mots de passe sont différents! Veuillez saisir des mots de passe identiques.',
    SetInitialPassword_validationMessagePasswordCriteria_display: true,
    SetInitialPassword_validationMessagePasswordCriteria_text: 'Doit contenir 8 caractères, dont une majuscule, une minuscule, un chiffre et un caractère spécial.',
    SetInitialPassword_errorMessageProcessFailed_display: true,
    SetInitialPassword_errorMessageProcessFailed_text: 'La définition du mot de passe initial a échoué. Veuillez vous connecter et réessayer.',
    SetInitialPassword_errorMessageNetworkIssues_display: true,
    SetInitialPassword_errorMessageNetworkIssues_text: 'Veuillez vérifier votre réseau',
    SetInitialPassword_errorMessageServerIssues_display: true,
    SetInitialPassword_errorMessageServerIssues_text: "Impossible d'obtenir une réponse. Veuillez réessayer.",
}

export default {...main}
