//basic
const main = {
  ResetPassword_titleResetPassword_display: true,
  ResetPassword_titleResetPassword_text: 'Reimposta la password',
  ResetPassword_labelNewPassword_display: true,
  ResetPassword_labelNewPassword_text: 'Nuova password',
  ResetPassword_labelConfirmNewPassword_display: true,
  ResetPassword_labelConfirmNewPassword_text: 'Conferma nuova password',
  ResetPassword_labelVerificationCode_display: true,
  ResetPassword_labelVerificationCode_text: 'Codice di verifica',
  ResetPassword_labelSubmitButton_display: true,
  ResetPassword_labelSubmitButton_text: 'Invia',
  ResetPassword_validationMessageNewPasswordRequired_display: true,
  ResetPassword_validationMessageNewPasswordRequired_text: 'Una nuova password è obbligatoria',
  ResetPassword_validationMessageConfirmNewPasswordRequired_display: true,
  ResetPassword_validationMessageConfirmNewPasswordRequired_text:
    'La conferma della nuova password è obbligatoria',
  ResetPassword_validationMessagePasswordDoNotMatch_display: true,
  ResetPassword_validationMessagePasswordDoNotMatch_text: 'Le password non corrispondono!',
  ResetPassword_errorMessageProcessFailed_display: true,
  ResetPassword_errorMessageProcessFailed_text:
    'Reimpostazione della password fallita. Per favore riprova.',
  ResetPassword_errorMessageNetworkIssues_display: true,
  ResetPassword_errorMessageNetworkIssues_text: 'Controlla la tua connessione di rete!',
  ResetPassword_errorMessageServerIssues_display: true,
  ResetPassword_errorMessageServerIssues_text:
    'Impossibile ottenere una risposta! Per favore riprova.',
}

export default { ...main }
