import API from "./api";
const apirequest = async (options) => {
  if (options.method === 'POST' || options.method === 'PUT')
    options.headers = { ...options.headers, 'X-Requested-With': 'Frontend' }
  try {
    return await API(options);
  } catch (exception) {
    throw exception;
  }
};

export default apirequest;
