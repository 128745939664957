//FR
const main = {
    ForgotPassword_titleForgotPassword_display: true,
    ForgotPassword_titleForgotPassword_text: 'Mot de passe oublié ?',
    ForgotPassword_labelProcessInfo_display: true,
    ForgotPassword_labelProcessInfo_text: "Saisissez votre nom d'utilisateur enregistré et nous vous enverrons un lien pour réinitialiser votre mot de passe.",
    ForgotPassword_labelUsername_display: true,
    ForgotPassword_labelUsername_text: "Nom d'utilisateur",
    ForgotPassword_labelSubmitButton_display: true,
    ForgotPassword_labelSubmitButton_text: 'Soumettre',
    ForgotPassword_labelBackToLoginLink_display: true,
    ForgotPassword_labelBackToLoginLink_text: 'Retour à la page de connexion',
    ForgotPassword_validationMessageUsernameRequired_display: true,
    ForgotPassword_validationMessageUsernameRequired_text: "Le nom d'utilisateur est requis",
    ForgotPassword_validationMessageEmailInvalid_display: true,
    ForgotPassword_validationMessageEmailInvalid_text: "Format d'adresse électronique non valide",
    ForgotPassword_errorMessageUsernameNotExist_display: true,
    ForgotPassword_errorMessageUsernameNotExist_text: "Le nom d'utilisateur n'existe pas dans la base de données. Veuillez saisir le nom d'utilisateur enregistré correct.",
    ForgotPassword_errorMessageProcessFailed_display: true,
    ForgotPassword_errorMessageProcessFailed_text: 'Le processus a échoué. Veuillez réessayer !',
    ForgotPassword_errorMessageNetworkIssues_display: true,
    ForgotPassword_errorMessageNetworkIssues_text: 'Veuillez vérifier votre réseau !',
    ForgotPassword_errorMessageServerIssues_display: true,
    ForgotPassword_errorMessageServerIssues_text: "Impossible d'obtenir une réponse ! Veuillez réessayer.",
}

export default {
    ...main
}
