//ES
const main = {
  RegisterPIN_titleRegisterPIN_display: true,
  RegisterPIN_titleRegisterPIN_text: 'Registrar PIN',
  RegisterPIN_label6DigitPin_display: true,
  RegisterPIN_label6DigitPin_text: 'PIN de 6 dígitos para aplicación móvil',
  RegisterPIN_labelSubmitButton_display: true,
  RegisterPIN_labelSubmitButton_text: 'Enviar',
  RegisterPIN_labelBackToDashboard_display: true,
  RegisterPIN_labelBackToDashboard_text: 'Volver al Panel',
  RegisterPIN_validationMessagePinRequired_display: true,
  RegisterPIN_validationMessagePinRequired_text: 'Se requiere el PIN',
  RegisterPIN_validationMessagePinOnlyDigits_display: true,
  RegisterPIN_validationMessagePinOnlyDigits_text: 'Deben ser sólo dígitos',
  RegisterPIN_validationMessagePinSixDigit_display: true,
  RegisterPIN_validationMessagePinSixDigit_text: 'Debe tener exactamente 6 dígitos',
  RegisterPIN_errorMessageProcessFailed_display: true,
  RegisterPIN_errorMessageProcessFailed_text:
    'La configuración del PIN ha fallado. Por favor, inténtelo de nuevo.',
  RegisterPIN_errorMessageNetworkIssues_display: true,
  RegisterPIN_errorMessageNetworkIssues_text: 'Por favor, compruebe su red.',
  RegisterPIN_errorMessageServerIssues_display: true,
  RegisterPIN_errorMessageServerIssues_text:
    'No se ha podido obtener una respuesta. Por favor, inténtelo de nuevo.',
}

export default { ...main }
