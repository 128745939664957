//ES
const main = {
    Login_titleLogin_display: true,
    Login_titleLogin_text: 'Iniciar sesión',
    Login_labelUsername_display: true,
    Login_labelUsername_text: 'Nombre de usuario',
    Login_labelPassword_display: true,
    Login_labelPassword_text: 'Contraseña',
    Login_labelLoginButton_display: true,
    Login_labelLoginButton_text: 'Iniciar sesión',
    Login_labelForgotPasswordLink_display: true,
    Login_labelForgotPasswordLink_text: '¿Ha olvidado la contraseña?',
    Login_validationMessageUsernameRequired_display: true,
    Login_validationMessageUsernameRequired_text: 'Se requiere el nombre de usuario',
    Login_validationMessagePasswordRequired_display: true,
    Login_validationMessagePasswordRequired_text: 'Se requiere una contraseña',
    Login_errorMessageIncorrectCredentials_display: true,
    Login_errorMessageIncorrectCredentials_text: '¡Nombre de usuario/contraseña incorrectos! Inténtelo de nuevo.',
    Login_errorMessageNetworkIssues_display: true,
    Login_errorMessageNetworkIssues_text: 'Por favor, compruebe su red.',
    Login_errorMessageServerIssues_display: true,
    Login_errorMessageServerIssues_text: 'No se ha podido obtener una respuesta. Por favor, inténtelo de nuevo.',
}

export default {
    ...main
}
