import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Field, Form } from 'formik'
import { TextField } from 'formik-material-ui'
import useViewport from '../hooks/useViewport'
import * as Yup from 'yup'
import { forgotPassword } from '../store/actions/account-action'
import routeConstants from '../constants/route-constants'
// import { setThemeLocale } from "../store/actions/ui-actions";
import customization from '../utils/customization-utils'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  demo1: {
    // backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
  },
  inputField: {
    marginBottom: 32,
  },
  input: {
    backgroundColor: 'white',
    border: '1px solid #C2C5C7',
    borderRadius: 8,
    padding: '8px 8px',
    [theme.breakpoints.down('md')]: {
      padding: '8px 8px',
    },
  },
  label: {
    color: 'black',
  },
  menuPaper: {
    maxHeight: 500,
  },
  link: {
    color: '#0D3082',
    marginTop: 16,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
  },
  submitButton: {
    whiteSpace: 'nowrap',
    minWidth: '100%',
    // textTransform: "none",
    marginTop: 9,
    marginBottom: 16,
    borderRadius: 8,
    padding: '8px 8px',
    [theme.breakpoints.down('md')]: {
      padding: '5px',
      fontSize: 14,
    },
    height: 48,
    fontSize: 16,
  },
}))

const ForgotPassword = () => {
  const { width } = useViewport()
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const selectedLocale = useSelector((state) => state.ui.locale)
  // const userLocale =
  // navigator.languages && navigator.languages.length
  //   ? navigator.languages[0]
  //   : navigator.language;

  // useEffect(() => {
  //   if (/^de\b/.test(userLocale)) {
  //     dispatch(setThemeLocale('DE'))
  //   } else if(/^es\b/.test(userLocale)){
  //     dispatch(setThemeLocale('ES'))
  //   } else if(/^fr\b/.test(userLocale)){
  //     dispatch(setThemeLocale('FR'))
  //   } else {
  //     dispatch(setThemeLocale('EN'))
  //   }
  // },[])

  const c = customization(selectedLocale)

  const validationSchema = Yup.object({
    uniqueId: Yup.string().required(c.ForgotPassword_validationMessageUsernameRequired_text),
  })

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="column"
        >
          <Box>
            <Typography
              variant="h4"
              style={{ fontWeight: 500, marginBottom: 37, marginTop: 72, textAlign: 'center' }}
            >
              {c.ForgotPassword_titleForgotPassword_text}
            </Typography>
            <Typography style={{ marginBottom: 16 }}>
              {c.ForgotPassword_labelProcessInfo_text}
            </Typography>
            <Formik
              enableReinitialize={true}
              initialValues={{
                uniqueId: '',
              }}
              onSubmit={(values, { setSubmitting }) => {
                dispatch(
                  forgotPassword({
                    unique_id: values.uniqueId,
                    selectedLocale: selectedLocale,
                    onSuccessCallback: () => {
                      history.push(`${routeConstants.RESETPASSWORD}?unique_id=${values.uniqueId}`)
                    },
                  })
                )
                // history.push(routeConstants.RESETPASSWORD);
              }}
              validationSchema={validationSchema}
            >
              {({ errors, touched, isSubmitting, values, setFieldValue }) => (
                <Form>
                  <Field
                    component={TextField}
                    className={classes.inputField}
                    label={c.ForgotPassword_labelUsername_text}
                    name="uniqueId"
                    type="text"
                    fullWidth
                    error={errors.uniqueId && touched.uniqueId}
                    helperText={errors.uniqueId && touched.uniqueId ? errors.uniqueId : null}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}
                  >
                    {c.ForgotPassword_labelSubmitButton_text}
                  </Button>
                  <Link to={routeConstants.LOGIN} className={classes.link}>
                    <ArrowBackIosIcon />
                    <Typography style={{ marginLeft: 10 }}>
                      {c.ForgotPassword_labelBackToLoginLink_text}
                    </Typography>
                  </Link>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </div>
    </div>
  )
}

export default ForgotPassword
