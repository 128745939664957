//basic
const main = {
  Eula_title_display: true,
  Eula_title_text: `ACCORDO DI LICENZA CON L'UTENTE FINALE`,
  Eula_labelAgreeButton_display: true,
  Eula_labelAgreeButton_text: 'Accetto',
  Eula_display: true,
  Eula_text: `
 <html xmlns:o="urn:schemas-microsoft-com:office:office"
xmlns:w="urn:schemas-microsoft-com:office:word"
xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
xmlns="http://www.w3.org/TR/REC-html40">

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=ProgId content=Word.Document>
<meta name=Generator content="Microsoft Word 15">
<meta name=Originator content="Microsoft Word 15">
<link rel=File-List href="aaaa.fld/filelist.xml">
<!--[if gte mso 9]><xml>
 <o:DocumentProperties>
  <o:Author>Pietro Gramaglia</o:Author>
  <o:LastAuthor>rpg</o:LastAuthor>
  <o:Revision>2</o:Revision>
  <o:TotalTime>2</o:TotalTime>
  <o:Created>2024-06-19T07:48:00Z</o:Created>
  <o:LastSaved>2024-06-19T07:48:00Z</o:LastSaved>
  <o:Pages>1</o:Pages>
  <o:Words>6976</o:Words>
  <o:Characters>39766</o:Characters>
  <o:Lines>331</o:Lines>
  <o:Paragraphs>93</o:Paragraphs>
  <o:CharactersWithSpaces>46649</o:CharactersWithSpaces>
  <o:Version>16.00</o:Version>
 </o:DocumentProperties>
</xml><![endif]-->
<link rel=dataStoreItem href="aaaa.fld/item0006.xml"
target="aaaa.fld/props007.xml">
<link rel=themeData href="aaaa.fld/themedata.thmx">
<link rel=colorSchemeMapping href="aaaa.fld/colorschememapping.xml">
<!--[if gte mso 9]><xml>
 <w:WordDocument>
  <w:SpellingState>Clean</w:SpellingState>
  <w:GrammarState>Clean</w:GrammarState>
  <w:TrackMoves>false</w:TrackMoves>
  <w:TrackFormatting/>
  <w:PunctuationKerning/>
  <w:ValidateAgainstSchemas/>
  <w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid>
  <w:IgnoreMixedContent>false</w:IgnoreMixedContent>
  <w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText>
  <w:DoNotPromoteQF/>
  <w:LidThemeOther>en-IR</w:LidThemeOther>
  <w:LidThemeAsian>X-NONE</w:LidThemeAsian>
  <w:LidThemeComplexScript>AR-SA</w:LidThemeComplexScript>
  <w:Compatibility>
   <w:BreakWrappedTables/>
   <w:SnapToGridInCell/>
   <w:WrapTextWithPunct/>
   <w:UseAsianBreakRules/>
   <w:DontGrowAutofit/>
   <w:SplitPgBreakAndParaMark/>
   <w:EnableOpenTypeKerning/>
   <w:DontFlipMirrorIndents/>
   <w:OverrideTableStyleHps/>
  </w:Compatibility>
  <w:DoNotOptimizeForBrowser/>
  <m:mathPr>
   <m:mathFont m:val="Cambria Math"/>
   <m:brkBin m:val="before"/>
   <m:brkBinSub m:val="&#45;-"/>
   <m:smallFrac m:val="off"/>
   <m:dispDef/>
   <m:lMargin m:val="0"/>
   <m:rMargin m:val="0"/>
   <m:defJc m:val="centerGroup"/>
   <m:wrapIndent m:val="1440"/>
   <m:intLim m:val="subSup"/>
   <m:naryLim m:val="undOvr"/>
  </m:mathPr></w:WordDocument>
</xml><![endif]--><!--[if gte mso 9]><xml>
 <w:LatentStyles DefLockedState="false" DefUnhideWhenUsed="false"
  DefSemiHidden="false" DefQFormat="false" DefPriority="99"
  LatentStyleCount="376">
  <w:LsdException Locked="false" Priority="0" QFormat="true" Name="Normal"/>
  <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 1"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 2"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 3"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 4"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 5"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 6"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 7"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 8"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 9"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 9"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 1"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 2"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 3"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 4"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 5"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 6"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 7"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 8"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 9"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footnote text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="header"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footer"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index heading"/>
  <w:LsdException Locked="false" Priority="35" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="caption"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="table of figures"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="envelope address"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="envelope return"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footnote reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="line number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="page number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="endnote reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="endnote text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="table of authorities"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="macro"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="toa heading"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 5"/>
  <w:LsdException Locked="false" Priority="10" QFormat="true" Name="Title"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Closing"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Signature"/>
  <w:LsdException Locked="false" Priority="1" SemiHidden="true"
   UnhideWhenUsed="true" Name="Default Paragraph Font"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Message Header"/>
  <w:LsdException Locked="false" Priority="11" QFormat="true" Name="Subtitle"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Salutation"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Date"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text First Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text First Indent 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Note Heading"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Block Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Hyperlink"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="FollowedHyperlink"/>
  <w:LsdException Locked="false" Priority="22" QFormat="true" Name="Strong"/>
  <w:LsdException Locked="false" Priority="20" QFormat="true" Name="Emphasis"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Document Map"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Plain Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="E-mail Signature"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Top of Form"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Bottom of Form"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal (Web)"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Acronym"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Address"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Cite"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Code"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Definition"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Keyboard"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Preformatted"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Sample"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Typewriter"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Variable"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal Table"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation subject"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="No List"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Contemporary"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Elegant"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Professional"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Subtle 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Subtle 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Balloon Text"/>
  <w:LsdException Locked="false" Priority="39" Name="Table Grid"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Theme"/>
  <w:LsdException Locked="false" SemiHidden="true" Name="Placeholder Text"/>
  <w:LsdException Locked="false" Priority="1" QFormat="true" Name="No Spacing"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 1"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 1"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 1"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 1"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 1"/>
  <w:LsdException Locked="false" SemiHidden="true" Name="Revision"/>
  <w:LsdException Locked="false" Priority="34" QFormat="true"
   Name="List Paragraph"/>
  <w:LsdException Locked="false" Priority="29" QFormat="true" Name="Quote"/>
  <w:LsdException Locked="false" Priority="30" QFormat="true"
   Name="Intense Quote"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 1"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 1"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 1"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 1"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 1"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 2"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 2"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 2"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 2"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 2"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 2"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 2"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 3"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 3"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 3"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 3"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 3"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 3"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 3"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 4"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 4"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 4"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 4"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 4"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 4"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 4"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 5"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 5"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 5"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 5"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 5"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 5"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 5"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 6"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 6"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 6"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 6"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 6"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 6"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 6"/>
  <w:LsdException Locked="false" Priority="19" QFormat="true"
   Name="Subtle Emphasis"/>
  <w:LsdException Locked="false" Priority="21" QFormat="true"
   Name="Intense Emphasis"/>
  <w:LsdException Locked="false" Priority="31" QFormat="true"
   Name="Subtle Reference"/>
  <w:LsdException Locked="false" Priority="32" QFormat="true"
   Name="Intense Reference"/>
  <w:LsdException Locked="false" Priority="33" QFormat="true" Name="Book Title"/>
  <w:LsdException Locked="false" Priority="37" SemiHidden="true"
   UnhideWhenUsed="true" Name="Bibliography"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="TOC Heading"/>
  <w:LsdException Locked="false" Priority="41" Name="Plain Table 1"/>
  <w:LsdException Locked="false" Priority="42" Name="Plain Table 2"/>
  <w:LsdException Locked="false" Priority="43" Name="Plain Table 3"/>
  <w:LsdException Locked="false" Priority="44" Name="Plain Table 4"/>
  <w:LsdException Locked="false" Priority="45" Name="Plain Table 5"/>
  <w:LsdException Locked="false" Priority="40" Name="Grid Table Light"/>
  <w:LsdException Locked="false" Priority="46" Name="Grid Table 1 Light"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark"/>
  <w:LsdException Locked="false" Priority="51" Name="Grid Table 6 Colorful"/>
  <w:LsdException Locked="false" Priority="52" Name="Grid Table 7 Colorful"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 1"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 1"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 1"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 2"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 2"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 2"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 3"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 3"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 3"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 4"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 4"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 4"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 5"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 5"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 5"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 6"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 6"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 6"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="46" Name="List Table 1 Light"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark"/>
  <w:LsdException Locked="false" Priority="51" Name="List Table 6 Colorful"/>
  <w:LsdException Locked="false" Priority="52" Name="List Table 7 Colorful"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 1"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 1"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 1"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 2"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 2"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 2"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 3"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 3"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 3"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 4"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 4"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 4"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 5"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 5"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 5"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 6"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 6"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 6"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Mention"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Hyperlink"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Hashtag"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Unresolved Mention"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Link"/>
 </w:LatentStyles>
</xml><![endif]-->
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;
	mso-font-charset:0;
	mso-generic-font-family:roman;
	mso-font-pitch:variable;
	mso-font-signature:-536870145 1107305727 0 0 415 0;}
@font-face
	{font-family:Aptos;
	panose-1:2 11 0 4 2 2 2 2 2 4;
	mso-font-charset:0;
	mso-generic-font-family:swiss;
	mso-font-pitch:variable;
	mso-font-signature:536871559 3 0 0 415 0;}
@font-face
	{font-family:"Noto Sans Symbols";
	mso-font-charset:0;
	mso-generic-font-family:auto;
	mso-font-pitch:auto;
	mso-font-signature:0 0 0 0 0 0;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:"";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	line-height:115%;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Aptos",sans-serif;
	mso-fareast-font-family:Aptos;
	mso-bidi-font-family:Aptos;
	mso-ansi-language:IT;
	mso-fareast-language:EN-US;}
h1
	{mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:"Heading 1 Char";
	mso-style-next:Normal;
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:4.0pt;
	margin-left:0cm;
	line-height:115%;
	mso-pagination:widow-orphan lines-together;
	page-break-after:avoid;
	mso-outline-level:1;
	font-size:20.0pt;
	font-family:"Aptos Display",sans-serif;
	mso-ascii-font-family:"Aptos Display";
	mso-ascii-theme-font:major-latin;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-hansi-font-family:"Aptos Display";
	mso-hansi-theme-font:major-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	color:#0F4761;
	mso-themecolor:accent1;
	mso-themeshade:191;
	mso-font-kerning:0pt;
	mso-ansi-language:IT;
	mso-fareast-language:EN-US;
	font-weight:normal;}
h2
	{mso-style-noshow:yes;
	mso-style-priority:9;
	mso-style-qformat:yes;
	mso-style-link:"Heading 2 Char";
	mso-style-next:Normal;
	margin-top:8.0pt;
	margin-right:0cm;
	margin-bottom:4.0pt;
	margin-left:0cm;
	line-height:115%;
	mso-pagination:widow-orphan lines-together;
	page-break-after:avoid;
	mso-outline-level:2;
	font-size:16.0pt;
	font-family:"Aptos Display",sans-serif;
	mso-ascii-font-family:"Aptos Display";
	mso-ascii-theme-font:major-latin;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-hansi-font-family:"Aptos Display";
	mso-hansi-theme-font:major-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	color:#0F4761;
	mso-themecolor:accent1;
	mso-themeshade:191;
	mso-ansi-language:IT;
	mso-fareast-language:EN-US;
	font-weight:normal;}
h3
	{mso-style-noshow:yes;
	mso-style-priority:9;
	mso-style-qformat:yes;
	mso-style-link:"Heading 3 Char";
	mso-style-next:Normal;
	margin-top:8.0pt;
	margin-right:0cm;
	margin-bottom:4.0pt;
	margin-left:0cm;
	line-height:115%;
	mso-pagination:widow-orphan lines-together;
	page-break-after:avoid;
	mso-outline-level:3;
	font-size:14.0pt;
	font-family:"Aptos",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	color:#0F4761;
	mso-themecolor:accent1;
	mso-themeshade:191;
	mso-ansi-language:IT;
	mso-fareast-language:EN-US;
	font-weight:normal;}
h4
	{mso-style-noshow:yes;
	mso-style-priority:9;
	mso-style-qformat:yes;
	mso-style-link:"Heading 4 Char";
	mso-style-next:Normal;
	margin-top:4.0pt;
	margin-right:0cm;
	margin-bottom:2.0pt;
	margin-left:0cm;
	line-height:115%;
	mso-pagination:widow-orphan lines-together;
	page-break-after:avoid;
	mso-outline-level:4;
	font-size:12.0pt;
	font-family:"Aptos",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	color:#0F4761;
	mso-themecolor:accent1;
	mso-themeshade:191;
	mso-ansi-language:IT;
	mso-fareast-language:EN-US;
	font-weight:normal;
	font-style:italic;}
h5
	{mso-style-noshow:yes;
	mso-style-priority:9;
	mso-style-qformat:yes;
	mso-style-link:"Heading 5 Char";
	mso-style-next:Normal;
	margin-top:4.0pt;
	margin-right:0cm;
	margin-bottom:2.0pt;
	margin-left:0cm;
	line-height:115%;
	mso-pagination:widow-orphan lines-together;
	page-break-after:avoid;
	mso-outline-level:5;
	font-size:12.0pt;
	font-family:"Aptos",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	color:#0F4761;
	mso-themecolor:accent1;
	mso-themeshade:191;
	mso-ansi-language:IT;
	mso-fareast-language:EN-US;
	font-weight:normal;}
h6
	{mso-style-noshow:yes;
	mso-style-priority:9;
	mso-style-qformat:yes;
	mso-style-link:"Heading 6 Char";
	mso-style-next:Normal;
	margin-top:2.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	line-height:115%;
	mso-pagination:widow-orphan lines-together;
	page-break-after:avoid;
	mso-outline-level:6;
	font-size:12.0pt;
	font-family:"Aptos",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	color:#595959;
	mso-themecolor:text1;
	mso-themetint:166;
	mso-ansi-language:IT;
	mso-fareast-language:EN-US;
	font-weight:normal;
	font-style:italic;}
p.MsoHeading7, li.MsoHeading7, div.MsoHeading7
	{mso-style-noshow:yes;
	mso-style-priority:9;
	mso-style-qformat:yes;
	mso-style-link:"Heading 7 Char";
	mso-style-next:Normal;
	margin-top:2.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	line-height:115%;
	mso-pagination:widow-orphan lines-together;
	page-break-after:avoid;
	mso-outline-level:7;
	font-size:12.0pt;
	font-family:"Aptos",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	color:#595959;
	mso-themecolor:text1;
	mso-themetint:166;
	mso-ansi-language:IT;
	mso-fareast-language:EN-US;}
p.MsoHeading8, li.MsoHeading8, div.MsoHeading8
	{mso-style-noshow:yes;
	mso-style-priority:9;
	mso-style-qformat:yes;
	mso-style-link:"Heading 8 Char";
	mso-style-next:Normal;
	margin:0cm;
	line-height:115%;
	mso-pagination:widow-orphan lines-together;
	page-break-after:avoid;
	mso-outline-level:8;
	font-size:12.0pt;
	font-family:"Aptos",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	color:#272727;
	mso-themecolor:text1;
	mso-themetint:216;
	mso-ansi-language:IT;
	mso-fareast-language:EN-US;
	font-style:italic;}
p.MsoHeading9, li.MsoHeading9, div.MsoHeading9
	{mso-style-noshow:yes;
	mso-style-priority:9;
	mso-style-qformat:yes;
	mso-style-link:"Heading 9 Char";
	mso-style-next:Normal;
	margin:0cm;
	line-height:115%;
	mso-pagination:widow-orphan lines-together;
	page-break-after:avoid;
	mso-outline-level:9;
	font-size:12.0pt;
	font-family:"Aptos",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	color:#272727;
	mso-themecolor:text1;
	mso-themetint:216;
	mso-ansi-language:IT;
	mso-fareast-language:EN-US;}
p.MsoTitle, li.MsoTitle, div.MsoTitle
	{mso-style-priority:10;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:"Title Char";
	mso-style-next:Normal;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:4.0pt;
	margin-left:0cm;
	mso-add-space:auto;
	mso-pagination:widow-orphan;
	font-size:28.0pt;
	font-family:"Aptos Display",sans-serif;
	mso-ascii-font-family:"Aptos Display";
	mso-ascii-theme-font:major-latin;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-hansi-font-family:"Aptos Display";
	mso-hansi-theme-font:major-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	letter-spacing:-.5pt;
	mso-font-kerning:14.0pt;
	mso-ansi-language:IT;
	mso-fareast-language:EN-US;}
p.MsoTitleCxSpFirst, li.MsoTitleCxSpFirst, div.MsoTitleCxSpFirst
	{mso-style-priority:10;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:"Title Char";
	mso-style-next:Normal;
	mso-style-type:export-only;
	margin:0cm;
	mso-add-space:auto;
	mso-pagination:widow-orphan;
	font-size:28.0pt;
	font-family:"Aptos Display",sans-serif;
	mso-ascii-font-family:"Aptos Display";
	mso-ascii-theme-font:major-latin;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-hansi-font-family:"Aptos Display";
	mso-hansi-theme-font:major-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	letter-spacing:-.5pt;
	mso-font-kerning:14.0pt;
	mso-ansi-language:IT;
	mso-fareast-language:EN-US;}
p.MsoTitleCxSpMiddle, li.MsoTitleCxSpMiddle, div.MsoTitleCxSpMiddle
	{mso-style-priority:10;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:"Title Char";
	mso-style-next:Normal;
	mso-style-type:export-only;
	margin:0cm;
	mso-add-space:auto;
	mso-pagination:widow-orphan;
	font-size:28.0pt;
	font-family:"Aptos Display",sans-serif;
	mso-ascii-font-family:"Aptos Display";
	mso-ascii-theme-font:major-latin;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-hansi-font-family:"Aptos Display";
	mso-hansi-theme-font:major-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	letter-spacing:-.5pt;
	mso-font-kerning:14.0pt;
	mso-ansi-language:IT;
	mso-fareast-language:EN-US;}
p.MsoTitleCxSpLast, li.MsoTitleCxSpLast, div.MsoTitleCxSpLast
	{mso-style-priority:10;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:"Title Char";
	mso-style-next:Normal;
	mso-style-type:export-only;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:4.0pt;
	margin-left:0cm;
	mso-add-space:auto;
	mso-pagination:widow-orphan;
	font-size:28.0pt;
	font-family:"Aptos Display",sans-serif;
	mso-ascii-font-family:"Aptos Display";
	mso-ascii-theme-font:major-latin;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-hansi-font-family:"Aptos Display";
	mso-hansi-theme-font:major-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	letter-spacing:-.5pt;
	mso-font-kerning:14.0pt;
	mso-ansi-language:IT;
	mso-fareast-language:EN-US;}
p.MsoSubtitle, li.MsoSubtitle, div.MsoSubtitle
	{mso-style-priority:11;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:"Subtitle Char";
	mso-style-next:Normal;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	line-height:115%;
	mso-pagination:widow-orphan;
	font-size:14.0pt;
	font-family:"Aptos",sans-serif;
	mso-fareast-font-family:Aptos;
	mso-bidi-font-family:Aptos;
	color:#595959;
	mso-ansi-language:IT;
	mso-fareast-language:EN-US;}
p
	{mso-style-priority:99;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:"Times New Roman";
	mso-ansi-language:IT;
	mso-fareast-language:IT;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:36.0pt;
	mso-add-space:auto;
	line-height:115%;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Aptos",sans-serif;
	mso-fareast-font-family:Aptos;
	mso-bidi-font-family:Aptos;
	mso-ansi-language:IT;
	mso-fareast-language:EN-US;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-type:export-only;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	mso-add-space:auto;
	line-height:115%;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Aptos",sans-serif;
	mso-fareast-font-family:Aptos;
	mso-bidi-font-family:Aptos;
	mso-ansi-language:IT;
	mso-fareast-language:EN-US;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-type:export-only;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	mso-add-space:auto;
	line-height:115%;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Aptos",sans-serif;
	mso-fareast-font-family:Aptos;
	mso-bidi-font-family:Aptos;
	mso-ansi-language:IT;
	mso-fareast-language:EN-US;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-type:export-only;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:36.0pt;
	mso-add-space:auto;
	line-height:115%;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Aptos",sans-serif;
	mso-fareast-font-family:Aptos;
	mso-bidi-font-family:Aptos;
	mso-ansi-language:IT;
	mso-fareast-language:EN-US;}
p.MsoQuote, li.MsoQuote, div.MsoQuote
	{mso-style-priority:29;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:"Quote Char";
	mso-style-next:Normal;
	margin-top:8.0pt;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:center;
	line-height:115%;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Aptos",sans-serif;
	mso-fareast-font-family:Aptos;
	mso-bidi-font-family:Aptos;
	color:#404040;
	mso-themecolor:text1;
	mso-themetint:191;
	mso-ansi-language:IT;
	mso-fareast-language:EN-US;
	font-style:italic;}
p.MsoIntenseQuote, li.MsoIntenseQuote, div.MsoIntenseQuote
	{mso-style-priority:30;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:"Intense Quote Char";
	mso-style-next:Normal;
	margin-top:18.0pt;
	margin-right:43.2pt;
	margin-bottom:18.0pt;
	margin-left:43.2pt;
	text-align:center;
	line-height:115%;
	mso-pagination:widow-orphan;
	border:none;
	mso-border-top-alt:solid #0F4761 .5pt;
	mso-border-top-themecolor:accent1;
	mso-border-top-themeshade:191;
	mso-border-bottom-alt:solid #0F4761 .5pt;
	mso-border-bottom-themecolor:accent1;
	mso-border-bottom-themeshade:191;
	padding:0cm;
	mso-padding-alt:10.0pt 0cm 10.0pt 0cm;
	font-size:12.0pt;
	font-family:"Aptos",sans-serif;
	mso-fareast-font-family:Aptos;
	mso-bidi-font-family:Aptos;
	color:#0F4761;
	mso-themecolor:accent1;
	mso-themeshade:191;
	mso-ansi-language:IT;
	mso-fareast-language:EN-US;
	font-style:italic;}
span.MsoIntenseEmphasis
	{mso-style-priority:21;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	color:#0F4761;
	mso-themecolor:accent1;
	mso-themeshade:191;
	font-style:italic;}
span.MsoIntenseReference
	{mso-style-priority:32;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	font-variant:small-caps;
	color:#0F4761;
	mso-themecolor:accent1;
	mso-themeshade:191;
	letter-spacing:.25pt;
	font-weight:bold;}
span.Heading1Char
	{mso-style-name:"Heading 1 Char";
	mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Heading 1";
	mso-ansi-font-size:20.0pt;
	mso-bidi-font-size:20.0pt;
	font-family:"Aptos Display",sans-serif;
	mso-ascii-font-family:"Aptos Display";
	mso-ascii-theme-font:major-latin;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-hansi-font-family:"Aptos Display";
	mso-hansi-theme-font:major-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	color:#0F4761;
	mso-themecolor:accent1;
	mso-themeshade:191;}
span.Heading2Char
	{mso-style-name:"Heading 2 Char";
	mso-style-noshow:yes;
	mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Heading 2";
	mso-ansi-font-size:16.0pt;
	mso-bidi-font-size:16.0pt;
	font-family:"Aptos Display",sans-serif;
	mso-ascii-font-family:"Aptos Display";
	mso-ascii-theme-font:major-latin;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-hansi-font-family:"Aptos Display";
	mso-hansi-theme-font:major-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	color:#0F4761;
	mso-themecolor:accent1;
	mso-themeshade:191;}
span.Heading3Char
	{mso-style-name:"Heading 3 Char";
	mso-style-noshow:yes;
	mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Heading 3";
	mso-ansi-font-size:14.0pt;
	mso-bidi-font-size:14.0pt;
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	color:#0F4761;
	mso-themecolor:accent1;
	mso-themeshade:191;}
span.Heading4Char
	{mso-style-name:"Heading 4 Char";
	mso-style-noshow:yes;
	mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Heading 4";
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	color:#0F4761;
	mso-themecolor:accent1;
	mso-themeshade:191;
	font-style:italic;}
span.Heading5Char
	{mso-style-name:"Heading 5 Char";
	mso-style-noshow:yes;
	mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Heading 5";
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	color:#0F4761;
	mso-themecolor:accent1;
	mso-themeshade:191;}
span.Heading6Char
	{mso-style-name:"Heading 6 Char";
	mso-style-noshow:yes;
	mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Heading 6";
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	color:#595959;
	mso-themecolor:text1;
	mso-themetint:166;
	font-style:italic;}
span.Heading7Char
	{mso-style-name:"Heading 7 Char";
	mso-style-noshow:yes;
	mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Heading 7";
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	color:#595959;
	mso-themecolor:text1;
	mso-themetint:166;}
span.Heading8Char
	{mso-style-name:"Heading 8 Char";
	mso-style-noshow:yes;
	mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Heading 8";
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	color:#272727;
	mso-themecolor:text1;
	mso-themetint:216;
	font-style:italic;}
span.Heading9Char
	{mso-style-name:"Heading 9 Char";
	mso-style-noshow:yes;
	mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Heading 9";
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	color:#272727;
	mso-themecolor:text1;
	mso-themetint:216;}
span.TitleChar
	{mso-style-name:"Title Char";
	mso-style-priority:10;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:Title;
	mso-ansi-font-size:28.0pt;
	mso-bidi-font-size:28.0pt;
	font-family:"Aptos Display",sans-serif;
	mso-ascii-font-family:"Aptos Display";
	mso-ascii-theme-font:major-latin;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-hansi-font-family:"Aptos Display";
	mso-hansi-theme-font:major-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	letter-spacing:-.5pt;
	mso-font-kerning:14.0pt;}
span.SubtitleChar
	{mso-style-name:"Subtitle Char";
	mso-style-priority:11;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:Subtitle;
	mso-ansi-font-size:14.0pt;
	mso-bidi-font-size:14.0pt;
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:"Times New Roman";
	mso-fareast-theme-font:major-fareast;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:major-bidi;
	color:#595959;
	mso-themecolor:text1;
	mso-themetint:166;
	letter-spacing:.75pt;}
span.QuoteChar
	{mso-style-name:"Quote Char";
	mso-style-priority:29;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:Quote;
	color:#404040;
	mso-themecolor:text1;
	mso-themetint:191;
	font-style:italic;}
span.IntenseQuoteChar
	{mso-style-name:"Intense Quote Char";
	mso-style-priority:30;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Intense Quote";
	color:#0F4761;
	mso-themecolor:accent1;
	mso-themeshade:191;
	font-style:italic;}
span.SpellE
	{mso-style-name:"";
	mso-spl-e:yes;}
span.GramE
	{mso-style-name:"";
	mso-gram-e:yes;}
.MsoChpDefault
	{mso-style-type:export-only;
	mso-default-props:yes;
	font-family:"Aptos",sans-serif;
	mso-ascii-font-family:Aptos;
	mso-fareast-font-family:Aptos;
	mso-hansi-font-family:Aptos;
	mso-bidi-font-family:Aptos;
	mso-font-kerning:0pt;
	mso-ligatures:none;
	mso-ansi-language:IT;}
.MsoPapDefault
	{mso-style-type:export-only;
	margin-bottom:8.0pt;
	line-height:115%;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:70.9pt 70.9pt 2.0cm 70.9pt;
	mso-header-margin:35.45pt;
	mso-footer-margin:35.45pt;
	mso-page-numbers:1;
	mso-paper-source:0;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 @list l0
	{mso-list-id:63726143;
	mso-list-template-ids:-1326028066;}
@list l0:level1
	{mso-level-text:"8\.%1\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l0:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l0:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:54.0pt;
	text-indent:-9.0pt;}
@list l0:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:90.0pt;
	text-indent:-18.0pt;}
@list l0:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:126.0pt;
	text-indent:-18.0pt;}
@list l0:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:162.0pt;
	text-indent:-9.0pt;}
@list l0:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:198.0pt;
	text-indent:-18.0pt;}
@list l0:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:234.0pt;
	text-indent:-18.0pt;}
@list l0:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:270.0pt;
	text-indent:-9.0pt;}
@list l1
	{mso-list-id:84960861;
	mso-list-template-ids:-1937724000;}
@list l1:level1
	{mso-level-text:"17\.%1\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l1:level2
	{mso-level-text:"%2\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:54.0pt;
	text-indent:-18.0pt;
	mso-ansi-font-weight:bold;}
@list l1:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:90.0pt;
	text-indent:-9.0pt;}
@list l1:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:126.0pt;
	text-indent:-18.0pt;}
@list l1:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-18.0pt;}
@list l1:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:198.0pt;
	text-indent:-9.0pt;}
@list l1:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:234.0pt;
	text-indent:-18.0pt;}
@list l1:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:270.0pt;
	text-indent:-18.0pt;}
@list l1:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:306.0pt;
	text-indent:-9.0pt;}
@list l2
	{mso-list-id:195431598;
	mso-list-template-ids:1349932164;}
@list l2:level1
	{mso-level-text:"16\.%1\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l2:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:54.0pt;
	text-indent:-18.0pt;}
@list l2:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:90.0pt;
	text-indent:-9.0pt;}
@list l2:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:126.0pt;
	text-indent:-18.0pt;}
@list l2:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-18.0pt;}
@list l2:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:198.0pt;
	text-indent:-9.0pt;}
@list l2:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:234.0pt;
	text-indent:-18.0pt;}
@list l2:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:270.0pt;
	text-indent:-18.0pt;}
@list l2:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:306.0pt;
	text-indent:-9.0pt;}
@list l3
	{mso-list-id:217517836;
	mso-list-template-ids:-856791900;}
@list l3:level1
	{mso-level-text:"11\.%1\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l3:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:-36.0pt;
	text-indent:-18.0pt;}
@list l3:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:0cm;
	text-indent:-9.0pt;}
@list l3:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:36.0pt;
	text-indent:-18.0pt;}
@list l3:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:72.0pt;
	text-indent:-18.0pt;}
@list l3:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:108.0pt;
	text-indent:-9.0pt;}
@list l3:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:144.0pt;
	text-indent:-18.0pt;}
@list l3:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:180.0pt;
	text-indent:-18.0pt;}
@list l3:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:216.0pt;
	text-indent:-9.0pt;}
@list l4
	{mso-list-id:221332387;
	mso-list-template-ids:171765446;}
@list l4:level1
	{mso-level-text:"12\.%1\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l4:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:54.0pt;
	text-indent:-18.0pt;}
@list l4:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:90.0pt;
	text-indent:-9.0pt;}
@list l4:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:126.0pt;
	text-indent:-18.0pt;}
@list l4:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-18.0pt;}
@list l4:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:198.0pt;
	text-indent:-9.0pt;}
@list l4:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:234.0pt;
	text-indent:-18.0pt;}
@list l4:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:270.0pt;
	text-indent:-18.0pt;}
@list l4:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:306.0pt;
	text-indent:-9.0pt;}
@list l5
	{mso-list-id:296882334;
	mso-list-template-ids:1878047876;}
@list l5:level1
	{mso-level-start-at:5;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l5:level2
	{mso-level-text:"%1\.%2\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:39.6pt;
	text-indent:-21.6pt;}
@list l5:level3
	{mso-level-text:"%1\.%2\.%3\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:61.2pt;
	text-indent:-25.2pt;}
@list l5:level4
	{mso-level-text:"%1\.%2\.%3\.%4\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:86.4pt;
	text-indent:-32.35pt;}
@list l5:level5
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:111.6pt;
	text-indent:-39.6pt;}
@list l5:level6
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:136.8pt;
	text-indent:-46.75pt;}
@list l5:level7
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-54.0pt;}
@list l5:level8
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:187.2pt;
	text-indent:-61.2pt;}
@list l5:level9
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.%9\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:216.0pt;
	text-indent:-72.0pt;}
@list l6
	{mso-list-id:299767058;
	mso-list-template-ids:531779742;}
@list l6:level1
	{mso-level-start-at:15;
	mso-level-number-format:bullet;
	mso-level-text:•;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;
	mso-ascii-font-family:Arial;
	mso-fareast-font-family:Arial;
	mso-hansi-font-family:Arial;
	mso-bidi-font-family:Arial;}
@list l6:level2
	{mso-level-number-format:bullet;
	mso-level-text:o;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:54.0pt;
	text-indent:-18.0pt;
	mso-ascii-font-family:"Courier New";
	mso-fareast-font-family:"Courier New";
	mso-hansi-font-family:"Courier New";
	mso-bidi-font-family:"Courier New";}
@list l6:level3
	{mso-level-number-format:bullet;
	mso-level-text:▪;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:90.0pt;
	text-indent:-18.0pt;
	mso-ascii-font-family:"Noto Sans Symbols";
	mso-fareast-font-family:"Noto Sans Symbols";
	mso-hansi-font-family:"Noto Sans Symbols";
	mso-bidi-font-family:"Noto Sans Symbols";}
@list l6:level4
	{mso-level-number-format:bullet;
	mso-level-text:●;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:126.0pt;
	text-indent:-18.0pt;
	mso-ascii-font-family:"Noto Sans Symbols";
	mso-fareast-font-family:"Noto Sans Symbols";
	mso-hansi-font-family:"Noto Sans Symbols";
	mso-bidi-font-family:"Noto Sans Symbols";}
@list l6:level5
	{mso-level-number-format:bullet;
	mso-level-text:o;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-18.0pt;
	mso-ascii-font-family:"Courier New";
	mso-fareast-font-family:"Courier New";
	mso-hansi-font-family:"Courier New";
	mso-bidi-font-family:"Courier New";}
@list l6:level6
	{mso-level-number-format:bullet;
	mso-level-text:▪;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:198.0pt;
	text-indent:-18.0pt;
	mso-ascii-font-family:"Noto Sans Symbols";
	mso-fareast-font-family:"Noto Sans Symbols";
	mso-hansi-font-family:"Noto Sans Symbols";
	mso-bidi-font-family:"Noto Sans Symbols";}
@list l6:level7
	{mso-level-number-format:bullet;
	mso-level-text:●;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:234.0pt;
	text-indent:-18.0pt;
	mso-ascii-font-family:"Noto Sans Symbols";
	mso-fareast-font-family:"Noto Sans Symbols";
	mso-hansi-font-family:"Noto Sans Symbols";
	mso-bidi-font-family:"Noto Sans Symbols";}
@list l6:level8
	{mso-level-number-format:bullet;
	mso-level-text:o;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:270.0pt;
	text-indent:-18.0pt;
	mso-ascii-font-family:"Courier New";
	mso-fareast-font-family:"Courier New";
	mso-hansi-font-family:"Courier New";
	mso-bidi-font-family:"Courier New";}
@list l6:level9
	{mso-level-number-format:bullet;
	mso-level-text:▪;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:306.0pt;
	text-indent:-18.0pt;
	mso-ascii-font-family:"Noto Sans Symbols";
	mso-fareast-font-family:"Noto Sans Symbols";
	mso-hansi-font-family:"Noto Sans Symbols";
	mso-bidi-font-family:"Noto Sans Symbols";}
@list l7
	{mso-list-id:485437854;
	mso-list-template-ids:-1856722554;}
@list l7:level1
	{mso-level-text:"6\.%1\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l7:level2
	{mso-level-text:"%1\.%2\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:19.0pt;
	text-indent:-4.8pt;
	font-variant:normal !important;
	mso-ansi-font-weight:bold;
	mso-ansi-font-style:normal;
	text-decoration:none;
	text-underline:none;
	text-decoration:none;
	text-line-through:none;
	vertical-align:baseline;}
@list l7:level3
	{mso-level-text:"%1\.%2\.%3\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:19.85pt;
	text-indent:8.5pt;
	mso-ansi-font-size:12.0pt;
	mso-bidi-font-size:12.0pt;
	font-variant:normal !important;
	color:#0F4761;
	mso-ansi-font-weight:normal;
	mso-ansi-font-style:normal;
	text-decoration:none;
	text-underline:none;
	text-decoration:none;
	text-line-through:none;
	vertical-align:baseline;}
@list l7:level4
	{mso-level-text:"%1\.%2\.%3\.%4\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:80.7pt;
	text-indent:-32.35pt;
	mso-ansi-font-size:11.0pt;
	mso-bidi-font-size:11.0pt;
	font-variant:normal !important;
	color:#156082;
	mso-ansi-font-weight:normal;
	mso-ansi-font-style:normal;
	text-decoration:none;
	text-underline:none;
	text-decoration:none;
	text-line-through:none;
	vertical-align:baseline;}
@list l7:level5
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:105.9pt;
	text-indent:-29.3pt;}
@list l7:level6
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:131.1pt;
	text-indent:-46.8pt;}
@list l7:level7
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:156.3pt;
	text-indent:-53.95pt;}
@list l7:level8
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:181.5pt;
	text-indent:-61.2pt;}
@list l7:level9
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.%9\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:210.3pt;
	text-indent:-72.0pt;}
@list l8
	{mso-list-id:539977420;
	mso-list-template-ids:-597153694;}
@list l8:level1
	{mso-level-start-at:4;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l8:level2
	{mso-level-text:"%1\.%2\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:39.6pt;
	text-indent:-21.6pt;
	mso-ansi-font-weight:normal;}
@list l8:level3
	{mso-level-text:"%1\.%2\.%3\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:61.2pt;
	text-indent:-25.2pt;}
@list l8:level4
	{mso-level-text:"%1\.%2\.%3\.%4\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:86.4pt;
	text-indent:-32.35pt;}
@list l8:level5
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:111.6pt;
	text-indent:-39.6pt;}
@list l8:level6
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:136.8pt;
	text-indent:-46.75pt;}
@list l8:level7
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-54.0pt;}
@list l8:level8
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:187.2pt;
	text-indent:-61.2pt;}
@list l8:level9
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.%9\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:216.0pt;
	text-indent:-72.0pt;}
@list l9
	{mso-list-id:541862834;
	mso-list-template-ids:-302210748;}
@list l9:level1
	{mso-level-text:"10\.%1\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l9:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:-18.0pt;
	text-indent:-18.0pt;}
@list l9:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:18.0pt;
	text-indent:-9.0pt;}
@list l9:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:54.0pt;
	text-indent:-18.0pt;}
@list l9:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:90.0pt;
	text-indent:-18.0pt;}
@list l9:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:126.0pt;
	text-indent:-9.0pt;}
@list l9:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-18.0pt;}
@list l9:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:198.0pt;
	text-indent:-18.0pt;}
@list l9:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:234.0pt;
	text-indent:-9.0pt;}
@list l10
	{mso-list-id:580262351;
	mso-list-template-ids:-1387856636;}
@list l10:level1
	{mso-level-number-format:alpha-upper;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l10:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l10:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l10:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l10:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l10:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l10:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l10:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l10:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l11
	{mso-list-id:604116253;
	mso-list-template-ids:2065063504;}
@list l11:level1
	{mso-level-number-format:alpha-lower;
	mso-level-text:"%1\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l11:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l11:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l11:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l11:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l11:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l11:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l11:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l11:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l12
	{mso-list-id:714081495;
	mso-list-template-ids:-989929564;}
@list l12:level1
	{mso-level-start-at:2;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l12:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l12:level3
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l12:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l12:level5
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l12:level6
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l12:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l12:level8
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l12:level9
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l13
	{mso-list-id:776216390;
	mso-list-template-ids:267282400;}
@list l13:level1
	{mso-level-start-at:7;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l13:level2
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l13:level3
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l13:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l13:level5
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l13:level6
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l13:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l13:level8
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l13:level9
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l14
	{mso-list-id:846601130;
	mso-list-template-ids:323020520;}
@list l14:level1
	{mso-level-text:"%1\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l14:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:54.0pt;
	text-indent:-18.0pt;}
@list l14:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:90.0pt;
	text-indent:-9.0pt;}
@list l14:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:126.0pt;
	text-indent:-18.0pt;}
@list l14:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-18.0pt;}
@list l14:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:198.0pt;
	text-indent:-9.0pt;}
@list l14:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:234.0pt;
	text-indent:-18.0pt;}
@list l14:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:270.0pt;
	text-indent:-18.0pt;}
@list l14:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:306.0pt;
	text-indent:-9.0pt;}
@list l15
	{mso-list-id:942766389;
	mso-list-template-ids:2019586158;}
@list l15:level1
	{mso-level-start-at:5;
	mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:53.4pt;
	text-indent:-18.0pt;}
@list l15:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:89.4pt;
	text-indent:-18.0pt;}
@list l15:level3
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:125.4pt;
	text-indent:-18.0pt;}
@list l15:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:161.4pt;
	text-indent:-18.0pt;}
@list l15:level5
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:197.4pt;
	text-indent:-18.0pt;}
@list l15:level6
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:233.4pt;
	text-indent:-18.0pt;}
@list l15:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:269.4pt;
	text-indent:-18.0pt;}
@list l15:level8
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:305.4pt;
	text-indent:-18.0pt;}
@list l15:level9
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:341.4pt;
	text-indent:-18.0pt;}
@list l16
	{mso-list-id:962611390;
	mso-list-template-ids:-470267256;}
@list l16:level1
	{mso-level-start-at:5;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l16:level2
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l16:level3
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l16:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l16:level5
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l16:level6
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l16:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l16:level8
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l16:level9
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l17
	{mso-list-id:963804014;
	mso-list-template-ids:-89228946;}
@list l17:level1
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l17:level2
	{mso-level-text:"%1\.%2\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:39.6pt;
	text-indent:-21.6pt;}
@list l17:level3
	{mso-level-text:"%1\.%2\.%3\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:61.2pt;
	text-indent:-25.2pt;}
@list l17:level4
	{mso-level-text:"%1\.%2\.%3\.%4\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:86.4pt;
	text-indent:-32.35pt;}
@list l17:level5
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:111.6pt;
	text-indent:-39.6pt;}
@list l17:level6
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:136.8pt;
	text-indent:-46.75pt;}
@list l17:level7
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-54.0pt;}
@list l17:level8
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:187.2pt;
	text-indent:-61.2pt;}
@list l17:level9
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.%9\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:216.0pt;
	text-indent:-72.0pt;}
@list l18
	{mso-list-id:979504354;
	mso-list-template-ids:-182041550;}
@list l18:level1
	{mso-level-start-at:12;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l18:level2
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l18:level3
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l18:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l18:level5
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l18:level6
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l18:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l18:level8
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l18:level9
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l19
	{mso-list-id:1019628267;
	mso-list-template-ids:-704326938;}
@list l19:level1
	{mso-level-text:"%1\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l19:level2
	{mso-level-number-format:alpha-lower;
	mso-level-text:"%2\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:36.0pt;
	text-indent:-18.0pt;}
@list l19:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:54.0pt;
	text-indent:-18.0pt;}
@list l19:level4
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:72.0pt;
	text-indent:-18.0pt;}
@list l19:level5
	{mso-level-number-format:alpha-lower;
	mso-level-text:"\(%5\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:90.0pt;
	text-indent:-18.0pt;}
@list l19:level6
	{mso-level-number-format:roman-lower;
	mso-level-text:"\(%6\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:108.0pt;
	text-indent:-18.0pt;}
@list l19:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:126.0pt;
	text-indent:-18.0pt;}
@list l19:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:144.0pt;
	text-indent:-18.0pt;}
@list l19:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-18.0pt;}
@list l20
	{mso-list-id:1093429189;
	mso-list-template-ids:1890383286;}
@list l20:level1
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:54.0pt;
	text-indent:-18.0pt;}
@list l20:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:90.0pt;
	text-indent:-18.0pt;}
@list l20:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:126.0pt;
	text-indent:-9.0pt;}
@list l20:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-18.0pt;}
@list l20:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:198.0pt;
	text-indent:-18.0pt;}
@list l20:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:234.0pt;
	text-indent:-9.0pt;}
@list l20:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:270.0pt;
	text-indent:-18.0pt;}
@list l20:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:306.0pt;
	text-indent:-18.0pt;}
@list l20:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:342.0pt;
	text-indent:-9.0pt;}
@list l21
	{mso-list-id:1096824574;
	mso-list-template-ids:-338150188;}
@list l21:level1
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:88.8pt;
	text-indent:-18.0pt;}
@list l21:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:124.8pt;
	text-indent:-18.0pt;}
@list l21:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:160.8pt;
	text-indent:-9.0pt;}
@list l21:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:196.8pt;
	text-indent:-18.0pt;}
@list l21:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:232.8pt;
	text-indent:-18.0pt;}
@list l21:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:268.8pt;
	text-indent:-9.0pt;}
@list l21:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:304.8pt;
	text-indent:-18.0pt;}
@list l21:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:340.8pt;
	text-indent:-18.0pt;}
@list l21:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:376.8pt;
	text-indent:-9.0pt;}
@list l22
	{mso-list-id:1118181611;
	mso-list-template-ids:1845374388;}
@list l22:level1
	{mso-level-text:"9\.%1\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l22:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:0cm;
	text-indent:-18.0pt;}
@list l22:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:36.0pt;
	text-indent:-9.0pt;}
@list l22:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:72.0pt;
	text-indent:-18.0pt;}
@list l22:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:108.0pt;
	text-indent:-18.0pt;}
@list l22:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:144.0pt;
	text-indent:-9.0pt;}
@list l22:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:180.0pt;
	text-indent:-18.0pt;}
@list l22:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:216.0pt;
	text-indent:-18.0pt;}
@list l22:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:252.0pt;
	text-indent:-9.0pt;}
@list l23
	{mso-list-id:1156458156;
	mso-list-template-ids:-1264530338;}
@list l23:level1
	{mso-level-text:"13\.%1\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l23:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:54.0pt;
	text-indent:-18.0pt;}
@list l23:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:90.0pt;
	text-indent:-9.0pt;}
@list l23:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:126.0pt;
	text-indent:-18.0pt;}
@list l23:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-18.0pt;}
@list l23:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:198.0pt;
	text-indent:-9.0pt;}
@list l23:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:234.0pt;
	text-indent:-18.0pt;}
@list l23:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:270.0pt;
	text-indent:-18.0pt;}
@list l23:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:306.0pt;
	text-indent:-9.0pt;}
@list l24
	{mso-list-id:1176918389;
	mso-list-template-ids:1880905294;}
@list l24:level1
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l24:level2
	{mso-level-text:"1\.1";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:54.0pt;
	text-indent:-18.0pt;}
@list l24:level3
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:90.0pt;
	text-indent:-18.0pt;}
@list l24:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:126.0pt;
	text-indent:-18.0pt;}
@list l24:level5
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-18.0pt;}
@list l24:level6
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:198.0pt;
	text-indent:-18.0pt;}
@list l24:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:234.0pt;
	text-indent:-18.0pt;}
@list l24:level8
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:270.0pt;
	text-indent:-18.0pt;}
@list l24:level9
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:306.0pt;
	text-indent:-18.0pt;}
@list l25
	{mso-list-id:1180657132;
	mso-list-template-ids:-1550054774;}
@list l25:level1
	{mso-level-start-at:3;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l25:level2
	{mso-level-text:"%1\.%2\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:39.6pt;
	text-indent:-21.6pt;}
@list l25:level3
	{mso-level-text:"%1\.%2\.%3\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:61.2pt;
	text-indent:-25.2pt;}
@list l25:level4
	{mso-level-text:"%1\.%2\.%3\.%4\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:86.4pt;
	text-indent:-32.35pt;}
@list l25:level5
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:111.6pt;
	text-indent:-39.6pt;}
@list l25:level6
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:136.8pt;
	text-indent:-46.75pt;}
@list l25:level7
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-54.0pt;}
@list l25:level8
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:187.2pt;
	text-indent:-61.2pt;}
@list l25:level9
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.%9\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:216.0pt;
	text-indent:-72.0pt;}
@list l26
	{mso-list-id:1229419624;
	mso-list-template-ids:-37961672;}
@list l26:level1
	{mso-level-start-at:15;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l26:level2
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:54.0pt;
	text-indent:-18.0pt;}
@list l26:level3
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:90.0pt;
	text-indent:-18.0pt;}
@list l26:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:126.0pt;
	text-indent:-18.0pt;}
@list l26:level5
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-18.0pt;}
@list l26:level6
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:198.0pt;
	text-indent:-18.0pt;}
@list l26:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:234.0pt;
	text-indent:-18.0pt;}
@list l26:level8
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:270.0pt;
	text-indent:-18.0pt;}
@list l26:level9
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:306.0pt;
	text-indent:-18.0pt;}
@list l27
	{mso-list-id:1372345057;
	mso-list-template-ids:1883917804;}
@list l27:level1
	{mso-level-text:"18\.%1\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l27:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:54.0pt;
	text-indent:-18.0pt;}
@list l27:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:90.0pt;
	text-indent:-9.0pt;}
@list l27:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:126.0pt;
	text-indent:-18.0pt;}
@list l27:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-18.0pt;}
@list l27:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:198.0pt;
	text-indent:-9.0pt;}
@list l27:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:234.0pt;
	text-indent:-18.0pt;}
@list l27:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:270.0pt;
	text-indent:-18.0pt;}
@list l27:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:306.0pt;
	text-indent:-9.0pt;}
@list l28
	{mso-list-id:1391347291;
	mso-list-template-ids:-1028857960;}
@list l28:level1
	{mso-level-number-format:bullet;
	mso-level-text:●;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;
	mso-ansi-font-size:10.0pt;
	mso-bidi-font-size:10.0pt;
	mso-ascii-font-family:"Noto Sans Symbols";
	mso-fareast-font-family:"Noto Sans Symbols";
	mso-hansi-font-family:"Noto Sans Symbols";
	mso-bidi-font-family:"Noto Sans Symbols";}
@list l28:level2
	{mso-level-number-format:bullet;
	mso-level-text:o;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;
	mso-ansi-font-size:10.0pt;
	mso-bidi-font-size:10.0pt;
	mso-ascii-font-family:"Courier New";
	mso-fareast-font-family:"Courier New";
	mso-hansi-font-family:"Courier New";
	mso-bidi-font-family:"Courier New";}
@list l28:level3
	{mso-level-number-format:bullet;
	mso-level-text:▪;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;
	mso-ansi-font-size:10.0pt;
	mso-bidi-font-size:10.0pt;
	mso-ascii-font-family:"Noto Sans Symbols";
	mso-fareast-font-family:"Noto Sans Symbols";
	mso-hansi-font-family:"Noto Sans Symbols";
	mso-bidi-font-family:"Noto Sans Symbols";}
@list l28:level4
	{mso-level-number-format:bullet;
	mso-level-text:▪;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;
	mso-ansi-font-size:10.0pt;
	mso-bidi-font-size:10.0pt;
	mso-ascii-font-family:"Noto Sans Symbols";
	mso-fareast-font-family:"Noto Sans Symbols";
	mso-hansi-font-family:"Noto Sans Symbols";
	mso-bidi-font-family:"Noto Sans Symbols";}
@list l28:level5
	{mso-level-number-format:bullet;
	mso-level-text:▪;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;
	mso-ansi-font-size:10.0pt;
	mso-bidi-font-size:10.0pt;
	mso-ascii-font-family:"Noto Sans Symbols";
	mso-fareast-font-family:"Noto Sans Symbols";
	mso-hansi-font-family:"Noto Sans Symbols";
	mso-bidi-font-family:"Noto Sans Symbols";}
@list l28:level6
	{mso-level-number-format:bullet;
	mso-level-text:▪;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;
	mso-ansi-font-size:10.0pt;
	mso-bidi-font-size:10.0pt;
	mso-ascii-font-family:"Noto Sans Symbols";
	mso-fareast-font-family:"Noto Sans Symbols";
	mso-hansi-font-family:"Noto Sans Symbols";
	mso-bidi-font-family:"Noto Sans Symbols";}
@list l28:level7
	{mso-level-number-format:bullet;
	mso-level-text:▪;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;
	mso-ansi-font-size:10.0pt;
	mso-bidi-font-size:10.0pt;
	mso-ascii-font-family:"Noto Sans Symbols";
	mso-fareast-font-family:"Noto Sans Symbols";
	mso-hansi-font-family:"Noto Sans Symbols";
	mso-bidi-font-family:"Noto Sans Symbols";}
@list l28:level8
	{mso-level-number-format:bullet;
	mso-level-text:▪;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;
	mso-ansi-font-size:10.0pt;
	mso-bidi-font-size:10.0pt;
	mso-ascii-font-family:"Noto Sans Symbols";
	mso-fareast-font-family:"Noto Sans Symbols";
	mso-hansi-font-family:"Noto Sans Symbols";
	mso-bidi-font-family:"Noto Sans Symbols";}
@list l28:level9
	{mso-level-number-format:bullet;
	mso-level-text:▪;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;
	mso-ansi-font-size:10.0pt;
	mso-bidi-font-size:10.0pt;
	mso-ascii-font-family:"Noto Sans Symbols";
	mso-fareast-font-family:"Noto Sans Symbols";
	mso-hansi-font-family:"Noto Sans Symbols";
	mso-bidi-font-family:"Noto Sans Symbols";}
@list l29
	{mso-list-id:1623998148;
	mso-list-template-ids:1350071174;}
@list l29:level1
	{mso-level-text:"15\.%1\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l29:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:54.0pt;
	text-indent:-18.0pt;}
@list l29:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:90.0pt;
	text-indent:-9.0pt;}
@list l29:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:126.0pt;
	text-indent:-18.0pt;}
@list l29:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-18.0pt;}
@list l29:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:198.0pt;
	text-indent:-9.0pt;}
@list l29:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:234.0pt;
	text-indent:-18.0pt;}
@list l29:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:270.0pt;
	text-indent:-18.0pt;}
@list l29:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:306.0pt;
	text-indent:-9.0pt;}
@list l30
	{mso-list-id:1731808276;
	mso-list-template-ids:67698717;}
@list l30:level1
	{mso-level-text:"%1\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l30:level2
	{mso-level-number-format:alpha-lower;
	mso-level-text:"%2\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:36.0pt;
	text-indent:-18.0pt;}
@list l30:level3
	{mso-level-number-format:roman-lower;
	mso-level-text:"%3\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:54.0pt;
	text-indent:-18.0pt;}
@list l30:level4
	{mso-level-text:"\(%4\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:72.0pt;
	text-indent:-18.0pt;}
@list l30:level5
	{mso-level-number-format:alpha-lower;
	mso-level-text:"\(%5\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:90.0pt;
	text-indent:-18.0pt;}
@list l30:level6
	{mso-level-number-format:roman-lower;
	mso-level-text:"\(%6\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:108.0pt;
	text-indent:-18.0pt;}
@list l30:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:126.0pt;
	text-indent:-18.0pt;}
@list l30:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:144.0pt;
	text-indent:-18.0pt;}
@list l30:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-18.0pt;}
@list l31
	{mso-list-id:1839495953;
	mso-list-template-ids:869186442;}
@list l31:level1
	{mso-level-text:"%1\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l31:level2
	{mso-level-number-format:alpha-lower;
	mso-level-text:"%2\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:36.0pt;
	text-indent:-18.0pt;}
@list l31:level3
	{mso-level-number-format:alpha-lower;
	mso-level-text:"%3\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:54.0pt;
	text-indent:-18.0pt;}
@list l31:level4
	{mso-level-text:"\(%4\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:72.0pt;
	text-indent:-18.0pt;}
@list l31:level5
	{mso-level-number-format:alpha-lower;
	mso-level-text:"\(%5\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:90.0pt;
	text-indent:-18.0pt;}
@list l31:level6
	{mso-level-number-format:roman-lower;
	mso-level-text:"\(%6\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:108.0pt;
	text-indent:-18.0pt;}
@list l31:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:126.0pt;
	text-indent:-18.0pt;}
@list l31:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:144.0pt;
	text-indent:-18.0pt;}
@list l31:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-18.0pt;}
@list l32
	{mso-list-id:1846941563;
	mso-list-template-ids:85203476;}
@list l32:level1
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l32:level2
	{mso-level-text:"%1\.%2\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:39.6pt;
	text-indent:-21.6pt;}
@list l32:level3
	{mso-level-text:"%1\.%2\.%3\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:61.2pt;
	text-indent:-25.2pt;}
@list l32:level4
	{mso-level-text:"%1\.%2\.%3\.%4\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:86.4pt;
	text-indent:-32.35pt;}
@list l32:level5
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:111.6pt;
	text-indent:-39.6pt;}
@list l32:level6
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:136.8pt;
	text-indent:-46.75pt;}
@list l32:level7
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-54.0pt;}
@list l32:level8
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:187.2pt;
	text-indent:-61.2pt;}
@list l32:level9
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.%9\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:216.0pt;
	text-indent:-72.0pt;}
@list l33
	{mso-list-id:1890264542;
	mso-list-template-ids:-400129134;}
@list l33:level1
	{mso-level-start-at:4;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l33:level2
	{mso-level-start-at:2;
	mso-level-text:"%1\.%2\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:39.6pt;
	text-indent:-21.6pt;}
@list l33:level3
	{mso-level-text:"%1\.%2\.%3\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:61.2pt;
	text-indent:-25.2pt;}
@list l33:level4
	{mso-level-text:"%1\.%2\.%3\.%4\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:86.4pt;
	text-indent:-32.35pt;}
@list l33:level5
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:111.6pt;
	text-indent:-39.6pt;}
@list l33:level6
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:136.8pt;
	text-indent:-46.75pt;}
@list l33:level7
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-54.0pt;}
@list l33:level8
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:187.2pt;
	text-indent:-61.2pt;}
@list l33:level9
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.%9\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:216.0pt;
	text-indent:-72.0pt;}
@list l34
	{mso-list-id:1893883339;
	mso-list-template-ids:472127390;}
@list l34:level1
	{mso-level-text:"7\.%1\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l34:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:36.0pt;
	text-indent:-18.0pt;}
@list l34:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:72.0pt;
	text-indent:-9.0pt;}
@list l34:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:108.0pt;
	text-indent:-18.0pt;}
@list l34:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:144.0pt;
	text-indent:-18.0pt;}
@list l34:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:180.0pt;
	text-indent:-9.0pt;}
@list l34:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:216.0pt;
	text-indent:-18.0pt;}
@list l34:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:252.0pt;
	text-indent:-18.0pt;}
@list l34:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:288.0pt;
	text-indent:-9.0pt;}
@list l35
	{mso-list-id:1915358148;
	mso-list-template-ids:653267710;}
@list l35:level1
	{mso-level-start-at:10;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l35:level2
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l35:level3
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l35:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l35:level5
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l35:level6
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l35:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l35:level8
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l35:level9
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l36
	{mso-list-id:1942638710;
	mso-list-template-ids:260193050;}
@list l36:level1
	{mso-level-text:"14\.%1\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l36:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:54.0pt;
	text-indent:-18.0pt;}
@list l36:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:90.0pt;
	text-indent:-9.0pt;}
@list l36:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:126.0pt;
	text-indent:-18.0pt;}
@list l36:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-18.0pt;}
@list l36:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:198.0pt;
	text-indent:-9.0pt;}
@list l36:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:234.0pt;
	text-indent:-18.0pt;}
@list l36:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:270.0pt;
	text-indent:-18.0pt;}
@list l36:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:306.0pt;
	text-indent:-9.0pt;}
@list l37
	{mso-list-id:1975791291;
	mso-list-template-ids:-829266184;}
@list l37:level1
	{mso-level-start-at:2;
	mso-level-text:"2\.%1\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l37:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l37:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l37:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l37:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l37:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l37:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l37:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l37:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l38
	{mso-list-id:1983733093;
	mso-list-template-ids:1641077018;}
@list l38:level1
	{mso-level-start-at:14;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;}
@list l38:level2
	{mso-level-text:"%1\.%2\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:21.0pt;
	text-indent:-21.0pt;}
@list l38:level3
	{mso-level-text:"%1\.%2\.%3\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:36.0pt;
	text-indent:-36.0pt;}
@list l38:level4
	{mso-level-text:"%1\.%2\.%3\.%4\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:36.0pt;
	text-indent:-36.0pt;}
@list l38:level5
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:36.0pt;
	text-indent:-36.0pt;}
@list l38:level6
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:54.0pt;
	text-indent:-54.0pt;}
@list l38:level7
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:54.0pt;
	text-indent:-54.0pt;}
@list l38:level8
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:54.0pt;
	text-indent:-54.0pt;}
@list l38:level9
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.%9\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:72.0pt;
	text-indent:-72.0pt;}
@list l39
	{mso-list-id:2021468508;
	mso-list-template-ids:-418866654;}
@list l39:level1
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l39:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l39:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l39:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l39:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l39:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l39:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l39:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-18.0pt;}
@list l39:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l40
	{mso-list-id:2094740121;
	mso-list-template-ids:-1752400980;}
@list l40:level1
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:54.0pt;
	text-indent:-18.0pt;}
@list l40:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:90.0pt;
	text-indent:-18.0pt;}
@list l40:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:126.0pt;
	text-indent:-9.0pt;}
@list l40:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-18.0pt;}
@list l40:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:198.0pt;
	text-indent:-18.0pt;}
@list l40:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:234.0pt;
	text-indent:-9.0pt;}
@list l40:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:270.0pt;
	text-indent:-18.0pt;}
@list l40:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:306.0pt;
	text-indent:-18.0pt;}
@list l40:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:342.0pt;
	text-indent:-9.0pt;}
ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>
<!--[if gte mso 10]>
<style>
 /* Style Definitions */
 table.MsoNormalTable
	{mso-style-name:"Table Normal";
	mso-tstyle-rowband-size:0;
	mso-tstyle-colband-size:0;
	mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-parent:"";
	mso-padding-alt:0cm 5.4pt 0cm 5.4pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:0cm;
	line-height:115%;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Aptos",sans-serif;
	mso-ansi-language:IT;}
</style>
<![endif]-->
</head>

<body lang=en-IR style='tab-interval:36.0pt;word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal style='text-align:justify'><span lang=IT style='font-size:
11.0pt;line-height:115%;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>UTILIZZANDO QUESTO SOFTWARE E QUALSIASI
AGGIORNAMENTO (COLLETTIVAMENTE: &quot;SOFTWARE&quot;), L'UTENTE FINALE CONFERMA
DI AVER RICEVUTO UNA COPIA DI QUESTO EULA E ACCETTA I TERMINI DI QUESTA LICENZA
COME DI SEGUITO STABILITI. SE L'UTENTE FINALE NON ACCETTA I TERMINI DI QUESTO
CONTRATTO, L'UTILIZZO DI QUESTO SOFTWARE È PROIBITO. I TERMINI DI QUESTA
LICENZA INCLUDONO, IN PARTICOLARE, LIMITAZIONI DI RESPONSABILITÀ NELL'ARTICOLO
11 DELLO STESSO.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify'><span lang=IT style='font-size:
11.0pt;line-height:115%;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Questo Contratto di Licenza con
l'Utente Finale (EULA) è efficace dal momento in cui l'Utente Finale accetta i
termini tramite la procedura documentata in questo EULA ed è un accordo legale
tra: <o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify'><span class=SpellE><span lang=IT
style='font-size:11.0pt;line-height:115%;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>KroniKare</span></span><span
lang=IT style='font-size:11.0pt;line-height:115%;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'> <span
class=SpellE>Pte</span>. Ltd. con sede legale in 75 Ayer Rajah <span
class=SpellE>Crescent</span> #03-12, Singapore 139953, (di seguito denominato
“Licenziante”), <o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify'><span lang=IT style='font-size:
11.0pt;line-height:115%;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>e <o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify'><span lang=IT style='font-size:
11.0pt;line-height:115%;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Organizzazione (di seguito denominato
“Licenziatario”).<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify'><span lang=IT style='font-size:
11.0pt;line-height:115%;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><span
style='mso-spacerun:yes'> </span>Il Licenziante concede al Licenziatario un
diritto non esclusivo, non trasferibile e limitato di utilizzare:<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;mso-list:l6 level1 lfo15;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=IT style='font-size:11.0pt;line-height:115%;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;line-height:115%;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>il software del
Dispositivo di Gestione delle Ferite Digitale <span class=SpellE>KroniKare</span>™
(“<span class=SpellE>Wound</span> Scanner”) e i dati forniti con il software e
i software correlati/ausiliari costituiti dall'app del dispositivo, dal
pannello di controllo e dal software <span class=SpellE>backend</span>
(“Software”); e<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span lang=IT style='font-size:11.0pt;line-height:115%;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;mso-list:l6 level1 lfo15;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;line-height:115%;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><span style='mso-list:Ignore'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;line-height:115%;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'>i Documenti per gli scopi espressamente stabiliti in
questa licenza (“Licenza per l'Utente Finale”).<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify'><span lang=IT style='font-size:
11.0pt;line-height:115%;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Tutti i diritti non espressamente
concessi in questa Licenza rimangono al Licenziante. Il Licenziante concede in
licenza il diritto di utilizzare il Software e i Documenti al Licenziatario
sulla base di questa Licenza. Il Licenziante non vende il Software o i
Documenti al Licenziatario.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
14.0pt;margin-left:0cm;text-align:justify;line-height:normal'><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'>CONSIDERATO CHE <o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
0cm;margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l10 level1 lfo6;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>A.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Il Licenziante è il produttore dello
Scanner per Ferite e del Software e lo sviluppatore dei Documenti; il
Licenziante ha stipulato un contratto di locazione con i suoi distributori per
la fornitura dello Scanner per Ferite al Licenziatario;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l10 level1 lfo6;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>B.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Il <span class=GramE>Licenziatario<span
style='mso-spacerun:yes'>  </span>ha</span> stipulato un contratto di
sub-locazione con i distributori del Licenziante per noleggiare lo Scanner per
Ferite (“Contratto di Sub-locazione”) e desidera utilizzare il Software e i
Documenti per l'uso dello Scanner per Ferite in conformità con il suo scopo
previsto; <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l10 level1 lfo6;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>C.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Il Licenziatario desidera utilizzare lo
Scanner per Ferite, il Software e i Documenti all'interno della propria
attività per fornire cure ai propri pazienti e desidera rendere il Software
disponibile all'interno della propria organizzazione a utenti specifici
selezionati (Utenti Autorizzati); <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l10 level1 lfo6;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>D.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>In relazione a quanto sopra, il
Licenziatario è disposto a utilizzare il software e i servizi correlati in
conformità con i termini di questo EULA.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
14.0pt;margin-left:0cm;text-align:justify;line-height:normal'><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Le parti hanno concordato quanto segue:<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
14.0pt;margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:
normal;mso-list:l24 level1 lfo19'><![if !supportLists]><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'><span
style='mso-list:Ignore'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Definizioni <o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
0cm;margin-left:21.6pt;text-align:justify;text-indent:-21.6pt;line-height:normal;
mso-list:l32 level2 lfo9;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>1.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Ai fini di questo EULA, le seguenti
definizioni, dove scritte con la lettera maiuscola, indipendentemente dal fatto
che siano al singolare o al plurale, si applicheranno secondo il significato di
seguito indicato: <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:21.6pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l30 level2 lfo12;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>“Utente Admin”</span></b><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'> indica la persona appartenente
all'organizzazione del Licenziatario designata dal Licenziatario come
responsabile della manutenzione, configurazione e funzionamento affidabile del
Software e designata come rappresentante del Licenziatario in conformità con le
leggi del paese in cui il Licenziatario ha sede ai fini dell'accettazione di
questo EULA per conto del Licenziatario.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l30 level2 lfo12;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>“Dispositivo
Autorizzato”</span></b><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'> indica lo
Scanner per Ferite. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l30 level2 lfo12;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>“Utente
Autorizzato”</span></b><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'> indica
qualsiasi persona appartenente all'organizzazione del Licenziatario - ad
esempio, dipendenti diretti del Licenziatario o consulenti che hanno una
relazione commerciale con il Licenziatario - che possono ottenere legittimo
accesso ed avere il permesso di utilizzare il Software sulla base di una o più
Licenze personali e non trasferibili ottenute dal Licenziatario<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l30 level2 lfo12;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>“Documenti”</span></b><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'> indica tutti i materiali stampati e i
documenti elettronici relativi allo Scanner per Ferite e al Software, inclusi,
ma non limitati alle Istruzioni per l'Uso ed a qualsiasi accordo riguardante
l'uso dello Scanner per Ferite. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l30 level2 lfo12;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>“Distributori”</span></b><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'> indica le persone giuridiche nella
catena di fornitura, diverse dal produttore o dall'importatore, che rendono
disponibile lo Scanner per Ferite al Licenziatario.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l30 level2 lfo12;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>f)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>“Data di
Efficacia”</span></b><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'> indica la data
indicata all'inizio di questo EULA. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l30 level2 lfo12;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>g)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>“Licenziatario”</span></b><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'> indica la parte contrattuale
identificata nell'EULA, a cui il Licenziante concede in licenza il Software
esclusivamente per il proprio uso interno (e non per la ridistribuzione, il
re-marketing o la condivisione a tempo) e che ha stipulato un Contratto di
Sub-locazione con i distributori del Licenziante per l'uso del dispositivo
Scanner per Ferite. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l30 level2 lfo12;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>h)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>“Incidente”</span></b><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'> indica qualsiasi malfunzionamento o
deterioramento nelle caratteristiche o nelle prestazioni di un dispositivo
messo a disposizione sul mercato, inclusi errori di utilizzo dovuti a
caratteristiche ergonomiche, nonché qualsiasi inadeguatezza nelle informazioni
fornite dal produttore e qualsiasi effetto collaterale indesiderato. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l30 level2 lfo12;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>“Istruzioni per
l'Uso”</span></b><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'> indica le
informazioni fornite dal produttore per informare l'utente dello scopo previsto
e dell'uso corretto di un dispositivo e di eventuali precauzioni da prendere.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span lang=IT style='font-size:11.0pt;line-height:115%;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l30 level2 lfo12;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>j)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>“Diritti di
Proprietà Intellettuale”</span></b><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'> indica brevetti, marchi, modelli di utilità, segreti
commerciali, nomi commerciali, marchi di servizio, nomi di dominio, diritti
d'autore, diritti su e nei database (inclusi i diritti di prevenire
l'estrazione o il riutilizzo di informazioni da un database), diritti di
design, diritti di topografia, progettazione di software, interfaccia utente
(UI), progettazione di UI, diritti di branding ai sensi delle leggi
sull'esclusività dei dati, estensione di tali diritti e tutti i diritti o forme
di protezione di natura simile o aventi effetto equivalente o simile a
qualsiasi di essi che possono esistere in qualsiasi parte del mondo, che siano
o meno registrati e le domande di registrazione e il diritto di richiedere la
registrazione di qualsiasi delle suddette proprietà e diritti, nonché diritti
simili o analoghi in qualsiasi parte del mondo. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l30 level2 lfo12;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>k)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>“Parte”</span></b><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'> indica ciascuna delle parti di questo
EULA. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l30 level2 lfo12;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>l)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>“Parti”</span></b><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'> indica entrambe le parti di questo
accordo congiuntamente. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l30 level2 lfo12;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>m)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>“Dati
Personali”</span></b><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'> indica
qualsiasi informazione relativa a una persona fisica identificata o
identificabile; una persona fisica identificabile è una persona che può essere
identificata, direttamente o indirettamente, in particolare facendo riferimento
a un identificatore come un nome, un numero di identificazione, dati di
localizzazione, un identificatore online o a uno o più fattori specifici per
l'identità fisica, fisiologica, genetica, mentale, economica, culturale o
sociale di tale persona fisica; <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l30 level2 lfo12;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>n)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>“Rilascio”</span></b><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'> indica le modifiche, gli aggiornamenti
e/o i miglioramenti del Software che il Licenziante decide di rendere
generalmente disponibili al Licenziatario.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l30 level2 lfo12;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>o)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>“Territorio”</span></b><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'> indica il paese in cui è stabilito il
Licenziatario.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l12 level1 lfo20;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>CONCESSIONE E
AMBITO DELLA LICENZA <o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:21.6pt;text-align:justify;text-indent:-21.6pt;line-height:normal;
mso-list:l17 level2 lfo27;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>1.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Il Licenziante concede al
Licenziatario, per la durata del Contratto di Sub-locazione per lo Scanner per
Ferite (salvo risoluzione anticipata in conformità con i termini qui previsti),
indipendentemente dal fatto che questo accordo sia concluso con il Licenziante
o con un Distributore, e per il Territorio, una licenza non esclusiva, non
trasferibile e limitata a utilizzare il Software e i Documenti secondo i
termini di questa Licenza. I diritti concessi ai sensi dell'EULA saranno
esercitati dal Licenziatario a partire dalla Data di Efficacia. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:
normal;mso-list:l31 level3 lfo17;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>L'EULA sarà considerata validamente
eseguita ed efficace tra le Parti a partire dalla Data di Efficacia.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l31 level3 lfo17;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Il Licenziatario e i suoi dipendenti
autorizzati (Utenti Autorizzati) sono autorizzati ai sensi dei Termini di
questa Licenza a:<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:54.0pt;text-align:justify;text-indent:-54.0pt;mso-text-indent-alt:
-18.0pt;line-height:normal;mso-list:l19 level4 lfo37;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><span style='mso-list:Ignore'><span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>i.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>scaricare, installare e utilizzare il
Software per i propri scopi aziendali interni solo in conformità ai Documenti e
allo scopo previsto dello Scanner per Ferite e utilizzare il Software in
conformità con le Istruzioni per l'Uso dello Scanner per Ferite per il numero
di Utenti Autorizzati concordato come specificato nel Contratto di
Sub-locazione;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:54.0pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:54.0pt;text-align:justify;text-indent:-54.0pt;mso-text-indent-alt:
-18.0pt;line-height:normal;mso-list:l19 level4 lfo37;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><span style='mso-list:Ignore'><span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>ii.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>ricevere e utilizzare qualsiasi codice
software supplementare gratuito o aggiornamento del Software contenente “patch
correttive” e correzioni di errori come può essere fornito di volta in volta
dal Licenziante; e <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:54.0pt;text-align:justify;text-indent:-54.0pt;mso-text-indent-alt:
-18.0pt;line-height:normal;mso-list:l19 level4 lfo37;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><span style='mso-list:Ignore'><span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>iii.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>utilizzare qualsiasi Documento a
supporto dell'uso consentito ai sensi di questa Licenza come specificato
nell'articolo 2 e fare copie dei Documenti come ragionevolmente necessario per
il suo uso legale.<span style='mso-spacerun:yes'>  </span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l37 level1 lfo28;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>2.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Fermo restando gli altri articoli di
questo EULA, se, in qualsiasi momento durante la validità di questo EULA, il
numero di utenti del Software fosse superiore al numero di Utenti Autorizzati
concessi al Licenziatario Utente Finale in conformità con l'articolo 2.1(b)(i)
sopra, il Licenziante avrà il diritto di risolvere questo EULA con
comunicazione immediata. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l37 level1 lfo28;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>2.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Sono espressamente esclusi dall'ambito
di applicazione di questo EULA: (i) licenze per software di base di terze parti
(come, senza limitazione, sistemi operativi, Microsoft ® Excel ®, Adobe Reader
®, ecc.) e (ii) software forniti al Licenziatario tramite terze parti (partner
di integrazione) per l'integrazione del Software nei sistemi e/o nel sistema di
registrazione del Licenziatario e/o sistemi di cartelle cliniche elettroniche
(iii) qualsiasi cosa non espressamente elencata dal Licenziante in questo EULA.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
0cm;margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l12 level1 lfo20;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'><span
style='mso-list:Ignore'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>PROPRIETÀ <o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-left:21.6pt;text-align:justify;text-indent:
-21.6pt;line-height:normal;mso-list:l25 level2 lfo29;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><span style='mso-list:Ignore'>3.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Il
Licenziatario acconsente al fatto che il Licenziante mantenga tutti i diritti,
titoli e interessi nel e sul Software.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
0cm;margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l12 level1 lfo20;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'><span
style='mso-list:Ignore'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>RESTRIZIONI <o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:21.6pt;text-align:justify;text-indent:-21.6pt;line-height:normal;
mso-list:l8 level2 lfo30;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>4.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Salvo quanto espressamente previsto in
questa Licenza, il Licenziatario accetta di non:<b style='mso-bidi-font-weight:
normal'><o:p></o:p></b></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:39.6pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l12 level2 lfo20;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>copiare il Software o i Documenti;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:39.6pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:39.6pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l12 level2 lfo20;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>affittare, noleggiare, concedere in
sub-licenza, prestare, tradurre, unire, adattare, variare o modificare il
Software o i Documenti; <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:3.6pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span lang=IT style='font-size:11.0pt;line-height:115%;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:39.6pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l12 level2 lfo20;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>apportare alterazioni o modifiche, in
tutto o in parte, al Software, o consentire che il Software o qualsivoglia sua
parte sia combinato con, o diventi incorporato in, qualsiasi altro programma; <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:3.6pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span lang=IT style='font-size:11.0pt;line-height:115%;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:39.6pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l12 level2 lfo20;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>d.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span class=SpellE><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>disassemblare</span></span><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>, <span class=SpellE>decompilare</span>,
eseguire reverse engineering o creare opere derivate basate in tutto o in parte
sul Software, né tentare di fare tali cose, salvo nella misura in cui tali
azioni non possano essere vietate perché essenziali per ottenere
l'interoperabilità del Software con un altro programma software, e a condizione
che le informazioni ottenute dall’ Licenziatario e/o dagli Utenti Autorizzati
durante tali attività siano utilizzate al solo scopo di ottenere
l'interoperabilità del Software con un altro programma software; e <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span lang=IT style='font-size:11.0pt;line-height:115%;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:54.0pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:70.8pt;text-align:justify;text-indent:-70.8pt;mso-text-indent-alt:
-18.0pt;line-height:normal;mso-list:l21 level1 lfo10;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><span style='mso-list:Ignore'><span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>i.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>non siano divulgate o comunicate in
maniera non necessaria senza il nostro previo consenso scritto a terze parti; e
<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:70.8pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:70.8pt;text-align:justify;text-indent:-70.8pt;mso-text-indent-alt:
-18.0pt;line-height:normal;mso-list:l21 level1 lfo10;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><span style='mso-list:Ignore'><span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>ii.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>non siano utilizzate per creare alcun
software che risulti sostanzialmente simile al Software; <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l15 level1 lfo41;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>e.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>fornire o rendere altrimenti
disponibile il Software in tutto o in parte (inclusi, ma non limitati a,
elenchi di programmi, elenchi di programmi oggetto e sorgente, codice oggetto e
codice sorgente), in qualsiasi forma, a qualsiasi persona senza il previo
consenso scritto da parte nostra; e scaricare, installare o utilizzare il
Software su dispositivi non autorizzati. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:54.0pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:21.6pt;text-align:justify;text-indent:-21.6pt;line-height:normal;
mso-list:l33 level2 lfo32;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>4.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Fermo restando quanto sopra, il
Licenziatario è autorizzato a fare una copia dei Documenti ai fini della
gestione del contratto e, ove strettamente necessario, per la cura dei
pazienti. Il Licenziatario può, tramite il suo Utente Admin, scaricare report di
stato come registri degli utenti o dei dispositivi, statistiche generali dei
pazienti e altre informazioni pertinenti direttamente riguardanti gli impegni
di cura del Licenziatario, la gestione delle risorse e la reportistica. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:21.6pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:21.6pt;text-align:justify;text-indent:-21.6pt;line-height:normal;
mso-list:l33 level2 lfo32;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>4.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Il Licenziatario può utilizzare il
Software solo come installato sui Dispositivi Autorizzati. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span lang=IT style='font-size:11.0pt;line-height:115%;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:21.6pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:21.6pt;text-align:justify;text-indent:-21.6pt;line-height:normal;
mso-list:l33 level2 lfo32;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>4.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Il Licenziatario deve supervisionare e
controllare l'uso del Software da parte degli Utenti Autorizzati e garantire
che il Software sia utilizzato dagli Utenti Autorizzati in conformità con i
termini di questa Licenza;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:21.6pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:14.0pt;
margin-left:21.6pt;text-align:justify;text-indent:-21.6pt;line-height:normal;
mso-list:l33 level2 lfo32;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>4.5.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Il Licenziatario deve rispettare tutte
le leggi e i regolamenti applicabili in materia di controllo delle tecnologie o
esportazione.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l16 level1 lfo21;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>SERVIZI DI
SUPPORTO <o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-left:21.6pt;text-align:justify;text-indent:
-21.6pt;line-height:normal;mso-list:l5 level2 lfo34;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><span style='mso-list:Ignore'>5.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Il Licenziante
fornirà al Licenziatario i servizi di supporto ragionevolmente richiesti in
relazione al Software per la durata di questo accordo tramite i suoi
Distributori. Il supporto può essere richiesto dal Licenziatario tramite il
sistema di gestione delle richieste di supporto (ticketing) del Distributore ed
è soggetto ai termini del Contratto di Sub-licenza.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l16 level1 lfo21;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>DATI PERSONALI <o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:
normal;mso-list:l7 level1 lfo31;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>6.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>L'uso del Software richiede il
trattamento di dati personali riguardanti i pazienti e il personale del
Licenziatario, come definito nel Regolamento (UE) 2016/679 - Regolamento
Generale sulla Protezione dei Dati (GDPR). Il trattamento dei Dati Personali sarà
effettuato dall'affiliata del Licenziante <span class=SpellE>KroniKare</span>
Limited, che ha la sua sede legale a Watford, Regno Unito, The <span
class=SpellE>Wenta</span> Business Centre, Watford, <span class=SpellE>Hertfordshire</span>,
Inghilterra, WD24 7ND, Regno Unito. Il rappresentante nell'Unione Europea per
tutte le questioni relative al trattamento dei dati personali ai sensi
dell'Art. 27 GDPR può essere contattato come segue: <span class=SpellE>Kaleidoscope</span>
Data Privacy <span class=SpellE>Consultants</span> SL (KDPC ES), Calle <span
class=SpellE>Balmes</span> 173, 4-2, <span class=SpellE>Barcelona</span> 08006,
Spagna (dpr.kronikare@kdpc.es) per conto di <span class=SpellE>KroniKare</span>
Ltd. Questo rappresentante agisce in conformità con un mandato scritto ricevuto
da <span class=SpellE>KroniKare</span> Ltd., che autorizza il rappresentante a
cooperare con le autorità di controllo e a fungere da punto di contatto per gli
interessati. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l7 level1 lfo31;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>6.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Il Licenziatario, in qualità di
titolare del trattamento ai sensi del GDPR (cfr. Art. 4 (7) GDPR), accetta di
stipulare un accordo per il trattamento dei dati ai sensi dell'Art. 28 GDPR con
<span class=SpellE>KroniKare</span> Limited (come responsabile del trattamento
dei dati, cfr. Art. 4 (8) GDPR) per il trattamento dei Dati Personali per lo
scopo di “Fornitura di assistenza per le ferite da parte del Titolare”.
L'esecuzione della conclusione sarà effettuata dai Distributori. Secondo la
Commissione Europea, il Regno Unito fornisce un livello di protezione
sostanzialmente equivalente a quello dell'Unione Europea (cfr. Art. 45 GDPR),
pertanto i trasferimenti di dati personali dall'Unione Europea verso questo
paese terzo possono avvenire senza ulteriori garanzie e misure appropriate. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l7 level1 lfo31;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>6.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Senza pregiudizio dell'articolo 6.2, <span
class=SpellE>KroniKare</span> Limited agirà in qualità di titolare del
trattamento per tutti i dati personali relativi agli accordi stipulati con <span
class=SpellE>KroniKare</span> Limited, in genere riguardanti i dati personali
del Licenziatario e, per evitare equivoci, specialmente quando il Licenziatario
risulti essere una persona fisica o un imprenditore individuale, ad esempio
come medico o infermiere autonomo o indipendente. Tale titolarità del
Licenziante non si estenderà in nessun caso ai dati dei pazienti forniti dal
Licenziatario. Troverete una corrispondente informativa sulla privacy ai sensi
degli Articoli 13 e 14 del GDPR alla fine di questo EULA.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l7 level1 lfo31;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>6.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Il trattamento dei dati personali ai
sensi dell'articolo 6.2 per la prestazione di servizi sanitari richiede la
memorizzazione e l'accesso alle informazioni, compresi i dati personali, sugli
Utenti Autorizzati memorizzati su apparecchiature terminali (quali lo Scanner
per Ferite e – quando disponibili – altri dispositivi su cui può essere
utilizzato il Software) e l'ottenimento dell'accesso alle informazioni per la
prestazione di servizi di trattamento soggetti al controllo del Licenziatario.
Il Licenziatario garantisce di fornire chiare informazioni sugli scopi del
trattamento dei dati personali e altre informazioni agli Utenti Autorizzati.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l13 level1 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>ACCETTAZIONE
DELL'EULA E FORNITURA DI ACCOUNT UTENTE <o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l34 level1 lfo33;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>7.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Prima dell'uso della Licenza, il
Licenziatario accetterà i termini di questa Licenza come documentato in questa
EULA. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l34 level1 lfo33;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>7.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Il Licenziatario garantisce che
l'Utente Amministratore sia autorizzato a rappresentare il Licenziatario per la
stipula di questo Contratto di Licenza per l'Utente Finale accettando i
relativi termini prima del primo utilizzo del Software. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l34 level1 lfo33;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>7.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>All' Utente Amministratore verrà
richiesto, al primo accesso, di accettare i termini della presente EULA
cliccando su un pulsante di Accettazione che si propaga su tutti i dispositivi
e fornisce una copia via email al Licenziatario. Dopo l'accettazione di questa
EULA, tutti gli Utenti Autorizzati sono autorizzati a utilizzare il Software e
lo Scanner per Ferite in conformità con i termini e per la durata di questa
Licenza come specificato nell'articolo 13 della stessa. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l34 level1 lfo33;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>7.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Il Licenziante fornirà al <span
class=GramE>Licenziatario<span style='mso-spacerun:yes'>  </span>gli</span>
account utente per il numero concordato di Utenti Autorizzati sulla base del
contratto di sub-locazione tra il Licenziatario e il Distributore. Account
aggiuntivi per Utenti Autorizzati possono essere richiesti tramite <span
class=GramE>il dashboard</span> del Software. Non vi è alcun limite riguardo al
numero di account Utente Autorizzato.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l13 level1 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>AGGIORNAMENTI
DEL SOFTWARE <o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:
normal;mso-list:l0 level1 lfo35;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>8.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Il Licenziante ha il diritto di fornire
aggiornamenti al Software a sua discrezione. Gli aggiornamenti verranno
installati automaticamente tramite aggiornamenti <span class=SpellE>push</span>.
Il Licenziatario non può rifiutare o posticipare gli aggiornamenti del
software. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l0 level1 lfo35;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>8.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Fermo restando quanto sopra, il
Licenziatario non avrà il diritto di ricevere aggiornamenti come descritto
nell'articolo 8.1 né avrà il diritto di richiedere aggiornamenti specifici o
generali.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l13 level1 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>DIRITTI DI
PROPRIETÀ INTELLETTUALE E INDENNIZZO <o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:
normal;mso-list:l22 level1 lfo36;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>9.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Il Licenziatario riconosce e accetta
che tutti i diritti industriali e i Diritti di Proprietà Intellettuale (inclusi
i diritti esclusivi di sfruttamento economico) sul Software e sui Documenti, in
tutto il mondo, sono e rimarranno di esclusiva proprietà del Licenziante.
Nessuna disposizione contenuta nella EULA è volta o sarà interpretata per
trasferire tali diritti al Licenziatario o a terzi. Fermo restando il diritto
di utilizzare il Software espressamente concesso nella EULA, il Licenziatario
non rivendicherà alcun diritto, inclusi i diritti d'autore sul Software e su
qualsiasi suo componente o qualsiasi altro diritto industriale o di proprietà
intellettuale ad esso correlato. Il Licenziatario accetta espressamente di
adottare tutte le misure adeguate <span class=GramE>per</span> proteggere il
contenuto del Software e per prevenirne la divulgazione o l'uso non
autorizzati, inclusi quelli da parte degli Utenti Autorizzati. Il Licenziatario
riconosce che il Licenziante ha il diritto di intraprendere azioni dirette e
immediate in caso di qualsiasi violazione dei suoi diritti sul Software da
parte del Licenziatario e dei suoi Utenti Autorizzati, e sia autorizzato a far
valere tali diritti scegliendo i modi e i mezzi più appropriati.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l22 level1 lfo36;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>9.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Il Licenziatario accetta che i diritti
sul Software siano concessi in licenza (non venduti) al Licenziatario, e che il
Licenziatario e i suoi Utenti Autorizzati non abbiano diritti sul Software o
sui Documenti diversi dal diritto di usarli in conformità con i termini di
questa Licenza.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l22 level1 lfo36;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>9.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Per evitare dubbi, tutte le idee,
invenzioni, miglioramenti, metodi, processi, opere dell'ingegno e altre forme
di proprietà intellettuale che il Licenziatario concepisca, realizzi o
sviluppi, da solo o in collaborazione con altri, in relazione o basandosi sulla
conoscenza o sulle informazioni apprese o ottenute dal Software e dai
Documenti, siano la sola e esclusiva proprietà del Licenziante o di un terzo
designato dal Licenziante o congiuntamente dal Licenziante e da un terzo
designato dal Licenziante. Il Licenziatario accetta di assegnare tutti tali
diritti di proprietà intellettuale al Licenziante e/o a un terzo designato dal
Licenziante e rinuncia a qualsiasi altro diritto che potrebbe avere al
riguardo. Il Licenziatario accetta di non mettere in discussione o contestare
in alcun modo, durante o in qualsiasi momento dopo la risoluzione del presente
accordo, la proprietà della proprietà intellettuale.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l22 level1 lfo36;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>9.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Il Licenziatario riconosce che né il
Licenziatario né i suoi Utenti Autorizzati abbiano alcun diritto di accesso al
Software in formato codice sorgente.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l22 level1 lfo36;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>9.5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Il Licenziante dichiara e garantisce
che, per quanto a sua conoscenza, non ci sono reclami in corso e non ci sono
azioni pendenti o minacciate contro il Licenziante secondo cui il Software
violi i Diritti di Proprietà Intellettuale di terzi. Se il Licenziatario
venisse a conoscenza di qualsiasi reclamo o minaccia di azione contro il
Licenziante in merito ai Diritti di Proprietà Intellettuale di terzi, informerà
immediatamente il Licenziante.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l22 level1 lfo36;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>9.6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>In caso di qualsiasi reclamo da parte
di terzi contro il Licenziatario sulla base del fatto che il Software violi i
Diritti di Proprietà Intellettuale di terzi, il Licenziatario notificherà
immediatamente il Licenziante e darà al Licenziante il controllo esclusivo
della difesa e/o del regolamento del reclamo e coopererà pienamente con il
Licenziante nella difesa o nel regolamento del reclamo. Il Licenziatario non
intraprenderà alcuna azione che possa limitare la capacità del Licenziante di
difendere il reclamo. Il Licenziante darà al Licenziatario l'opportunità di
monitorare il procedimento. Qualora il Licenziatario ritenga che un intervento
sia necessario per evitare qualsiasi svantaggio per il Licenziatario, informerà
il Licenziante e le Parti esamineranno il corso d'azione appropriato.
Nonostante quanto sopra, il Licenziante avrà la decisione finale in merito a
qualsiasi azione da intraprendere in relazione a una sospetta violazione dei
Diritti di Proprietà Intellettuale.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l22 level1 lfo36;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>9.7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Fermo restando quanto sopra, se tutto o
parte del Software diventa, o a giudizio del Licenziante è probabile che
diventi, oggetto di un reclamo per violazione, il Licenziante, a proprie spese,
a sua esclusiva discrezione e senza ulteriori responsabilità verso il
Licenziatario, può adottare uno dei seguenti rimedi praticabili: (i) procurare
per il Licenziatario Utente Finale il diritto di utilizzare il Software; (ii)
sostituire il Software con un software non violante che sia almeno
funzionalmente equivalente; (iii) modificare il Software per renderlo non
violante<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l22 level1 lfo36;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>9.8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Il Licenziante non sarà tenuto a
indennizzare e non avrà alcuna responsabilità o altri obblighi in merito a
reclami basati su: (i) combinazione o uso del Software con altri software non
approvati e/o forniti dal Licenziante; (ii) uso del Software da parte del
Licenziatario o degli Utenti Autorizzati in qualsiasi modo non conforme alla
EULA e/o alla descrizione del Software; o (iii) mancato utilizzo di una
versione aggiornata fornita dal Licenziante, se la violazione avrebbe potuto
essere evitata utilizzando quella versione aggiornata e o l'ultima versione
disponibile del Software.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l35 level1 lfo2;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span></b><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>GARANZIA <o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:
normal;mso-list:l9 level1 lfo22;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>10.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Le condizioni
di garanzia saranno coperte tramite il contratto di sub-locazione. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l9 level1 lfo22;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>10.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Il Licenziante
non sarà responsabile per limitazioni funzionali o difetti del Software, se gli
stessi sono stati causati, in tutto o in parte, da comportamenti non
autorizzati effettuati o consentiti dal Licenziatario e/o dai suoi Utenti
Autorizzati, da un uso del Software non conforme allo scopo previsto o alle
Istruzioni per l'uso, o da qualsiasi modifica o malfunzionamento derivante da
software o altri prodotti non forniti dal Licenziante. Se il malfunzionamento
del Software deriva da un errore, il Licenziatario dovrà notificare
immediatamente tale effetto al Licenziante.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l9 level1 lfo22;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>10.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Il Licenziante
non sarà responsabile per limitazioni funzionali, indisponibilità del Software
o inadempimenti delle sue responsabilità ai sensi del presente accordo causati
dalla connessione internet, dai sistemi informatici o dalle infrastrutture del
Licenziatario.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l35 level1 lfo2;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>11.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span></b><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>LIMITAZIONE
DELLA RESPONSABILITÀ <o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:
normal;mso-list:l3 level1 lfo23;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>11.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Fatto salvo
l’articolo 10 di cui sopra, il Licenziante fornisce il Software al
Licenziatario &quot;così com'è&quot; e non concede al Licenziatario alcuna
garanzia, espressa o implicita, riguardo alla sua idoneità per uno scopo
particolare, compresa, senza limitazioni, la garanzia riguardante la sua
idoneità per uno scopo particolare diverso da quello espressamente stabilito in
questa EULA o nelle Istruzioni per l'uso. Il Licenziatario è responsabile di
garantire che le funzionalità del Software come descritte nei Documenti
soddisfino le sue esigenze.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l3 level1 lfo23;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>11.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Resta inteso
che, nella misura consentita da qualsiasi legge applicabile, il Licenziante non
sarà responsabile per danni derivanti da (i) qualsiasi uso del Software non
conforme alla EULA e alle Istruzioni per l'uso; (ii) qualsiasi malfunzionamento
del Software derivato dai comportamenti descritti al punto (i); (iii) eventuali
imprecisioni o <span class=SpellE>inaccuratezze</span> del Software o dei
Documenti; (iv) errori nel Software o nei documenti; (v) corruzione di dati o
informazioni, salvo diversamente concordato tra le Parti. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l3 level1 lfo23;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>11.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Ad eccezione di
quanto stabilito in questa EULA, il Licenziante non sarà in nessun caso
responsabile nei confronti del Licenziatario per qualsiasi perdita o danni
diretti o indiretti, compresi, a titolo esemplificativo ma non esaustivo,
perdita di profitti, perdita di reputazione, perdita di affari, perdita di
opportunità di affari, perdita subita in connessione con la corruzione di dati
o informazioni o qualsiasi altro danno speciale, indiretto, remoto o
conseguenziale derivante da o in connessione con questa Licenza, che sia in
contratto, illecito (inclusa la negligenza), violazione del dovere legale, o in
altro modo. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l3 level1 lfo23;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>11.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Fermo restando
quanto sopra, il Licenziante sarà responsabile senza limitazioni per eventuali
danni causati da dolo o colpa grave del Licenziante. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l3 level1 lfo23;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>11.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Il
Licenziatario accetta e conviene che nella misura in cui sussiste o sorge un
reclamo in relazione alla fornitura del Software o dei Documenti, tale reclamo
può essere portato solo contro l'entità a cui il Licenziatario ha pagato
l'acquisto del Software in conformità con i termini dell'accordo tra il
Licenziatario Utente Finale e tale entità. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l3 level1 lfo23;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>11.6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Nulla in questa
Licenza limiterà o escluderà la responsabilità del Licenziante per: <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:
normal;mso-list:l11 level1 lfo25;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>morte o lesioni personali derivanti
dalla nostra negligenza;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l11 level1 lfo25;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>frode o dichiarazione fraudolenta. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
0cm;margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l3 level1 lfo23;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>11.7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Questa Licenza
stabilisce l'intera estensione degli obblighi e delle responsabilità del
Licenziante riguardo alla fornitura del Software e dei Documenti. Salvo quanto
espressamente stabilito in questa Licenza, non vi sono articoli, garanzie,
rappresentazioni o altri termini, espressi o impliciti, vincolanti per il
Licenziante. Qualsiasi articolo, garanzia, rappresentazione o altro termine
riguardante la fornitura del Software e dei Documenti che potrebbe altrimenti
essere implicato o incorporato in questa Licenza, che sia per legge,
consuetudine o in altro modo, è escluso nella massima misura consentita dalla
legge.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l18 level1 lfo4;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span></b><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>CONFIDENZIALITÀ
<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:
normal;mso-list:l4 level1 lfo24;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>12.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Durante il
periodo di validità della EULA, Licenziante e Licenziatario scambieranno
informazioni confidenziali tra loro. Ciascuna delle parti si impegna a non
divulgare tali informazioni a terzi senza il preventivo consenso espresso
dell'altra parte. Qualsiasi informazione confidenziale deve essere identificata
come tale nei documenti scritti pertinenti o, se l'informazione è divulgata
verbalmente, deve essere espressamente identificata come confidenziale. Non
saranno considerate informazioni confidenziali le seguenti se, alla data di
divulgazione, l'informazione è: (i) di dominio pubblico (ii) già nota alla
parte non divulgante o (iii) diventata nota al Licenziatario in una data
successiva diversa dalla data di divulgazione. Il Licenziatario deve proteggere
le informazioni confidenziali in conformità alle migliori pratiche di sicurezza
commerciale attuali. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l4 level1 lfo24;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>12.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Entrambe le
parti non devono, senza il preventivo consenso scritto dell'altra parte,
divulgare alcuna parte delle Informazioni Confidenziali a qualsiasi persona,
tranne: <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:54.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l4 level2 lfo24;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>ai propri dipendenti e consulenti, e
solo a quelli che ne abbiano necessità;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:54.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l4 level2 lfo24;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>a un tribunale competente, a un
organismo governativo o a un'autorità di regolamentazione applicabile, e a
tutte le altre persone o enti che abbiano il diritto, il dovere o l'obbligo di
conoscere l'attività dell'altra parte, e solo in ottemperanza a tale diritto,
dovere o obbligo.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l4 level1 lfo24;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>12.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Gli obblighi
previsti nel presente articolo relativi alle informazioni confidenziali
rimarranno pienamente in vigore per un periodo di dieci (10) anni dalla data di
divulgazione delle informazioni confidenziali. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l4 level1 lfo24;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>12.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Fermo restando
quanto sopra, le Parti convengono che, in relazione al trattamento dei dati
personali soggetti al GDPR, si applicheranno invece gli articoli sulla
riservatezza stabiliti nell'accordo di trattamento dei dati tra il Licenziante
e il Licenziatario. Pertanto, il presente articolo 12 non si applica alla
riservatezza dei dati personali, che sarà regolata separatamente nell'accordo
di trattamento dei dati tra le Parti, né ai dati anonimizzati in conformità con
l'accordo di trattamento dei dati tra le Parti.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l4 level1 lfo24;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>12.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Ciascuna Parte
di questo Accordo dovrà notificare tempestivamente all'altra Parte qualsiasi
violazione della riservatezza da parte di qualsiasi persona a cui abbia
divulgato tutto o parte delle Informazioni e fornire all'altra Parte tutta
l'assistenza ragionevole in relazione a qualsiasi procedimento che quest'ultima
possa intraprendere contro tale persona per violazione della riservatezza. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l4 level1 lfo24;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>12.6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>I diritti e gli
obblighi di cui al presente articolo sopravviveranno alla cessazione di questo
Accordo senza limiti di tempo, ma cesseranno di applicarsi alle informazioni o
conoscenze che diventano di dominio pubblico.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l18 level1 lfo4;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>13.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span></b><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>RISOLUZIONE<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:
normal;mso-list:l23 level1 lfo3;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>13.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>La presente
licenza entrerà in vigore al momento dell'accettazione dei suoi termini e
resterà valida fino alla risoluzione del contratto di sublocazione tra il
Licenziatario e il Distributore che consente al Licenziatario di utilizzare lo
Scanner per Ferite, indipendentemente dal fatto che il Licenziatario abbia
stipulato tale accordo con il Licenziante o con un Distributore. Il
Licenziatario è tenuto a informare il Licenziante della risoluzione o della
scadenza del contratto di locazione o di servizio che permette al Licenziatario
di utilizzare lo Scanner per Ferite.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l23 level1 lfo3;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>13.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Il Licenziante
può risolvere la presente Licenza con effetto immediato mediante comunicazione
scritta al Licenziatario se quest'ultimo: <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:54.0pt;text-align:justify;text-indent:-18.0pt;line-height:
normal;mso-list:l40 level1 lfo5;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>commette una violazione sostanziale o
persistente dei termini della presente Licenza che sia irrimediabile o che il
Licenziatario non riesca a sanare entro quattordici (14) giorni dalla ricezione
di una comunicazione scritta che specifichi la violazione e richieda di porvi
rimedio; <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:54.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l40 level1 lfo5;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>non è in grado di pagare i propri
debiti; diventa insolvente; o è soggetto a un ordine o a una risoluzione per la
sua liquidazione, amministrazione, scioglimento o dissoluzione (diversamente
che per gli scopi di una fusione o ristrutturazione solvente); <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:54.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l40 level1 lfo5;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>ha un amministratore, un curatore, un
gestore, un trustee, un liquidatore, un commissario o un ufficiale simile
nominato su tutti o su una parte sostanziale dei propri beni; o entra o propone
qualsiasi composizione o accordo generale con i propri creditori;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:54.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l40 level1 lfo5;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>d.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>cessa o minaccia di cessare l'attività;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:54.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l40 level1 lfo5;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>e.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>è soggetto a qualsiasi evento o
procedimento analogo in qualsiasi giurisdizione; <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:54.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l40 level1 lfo5;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>f.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>trasferisce o assegna a qualsiasi terza
parte, senza il preventivo consenso esplicito e scritto del Licenziante,
qualsiasi supporto fisico che incorpori il software e/o qualsiasi sua copia
(compresa qualsiasi copia di backup consentita dalla legge nazionale)<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:
normal;mso-list:l23 level1 lfo3;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>13.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Tutte le
disposizioni contrattuali il cui effetto non sia pregiudicato dalla cessazione
o dalla scadenza del presente EULA rimarranno pienamente valide tra le Parti
anche dopo la cessazione o la scadenza del medesimo.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l23 level1 lfo3;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>13.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>In seguito alla
cessazione o alla scadenza del presente EULA per qualsiasi motivo:<o:p></o:p></span></p>

<ol style='margin-top:0cm' start=1 type=a>
 <li class=MsoNormal style='color:black;mso-themecolor:text1;margin-top:14.0pt;
     margin-bottom:12.0pt;text-align:justify;line-height:normal;mso-list:l39 level1 lfo7;
     border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
     yes'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
     mso-fareast-font-family:Arial'>Tutti i diritti concessi al Licenziatario
     ai sensi di questa Licenza cesseranno; <o:p></o:p></span></li>
 <li class=MsoNormal style='color:black;mso-themecolor:text1;margin-bottom:
     12.0pt;text-align:justify;line-height:normal;mso-list:l39 level1 lfo7;
     border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
     yes'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
     mso-fareast-font-family:Arial'>Il Licenziatario cesserà immediatamente
     tutte le attività autorizzate da questa Licenza; <o:p></o:p></span></li>
 <li class=MsoNormal style='color:black;mso-themecolor:text1;margin-bottom:
     12.0pt;text-align:justify;line-height:normal;mso-list:l39 level1 lfo7;
     border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
     yes'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
     mso-fareast-font-family:Arial'>Il Licenziatario si impegna a eliminare o
     rimuovere immediatamente e permanentemente il Software da tutte le
     apparecchiature informatiche in suo possesso, e a distruggere o restituire
     immediatamente al Licenziante (a sua discrezione) tutte le copie del Software
     e dei Documenti in suo possesso, custodia o controllo e, in caso di
     distruzione, il Licenziatario certificherà al Licenziante che il Software
     e i Documenti sono stati distrutti.<o:p></o:p></span></li>
</ol>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l38 level1 lfo11;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><span style='mso-list:Ignore'>14.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></span><![endif]><span
dir=LTR></span><b style='mso-bidi-font-weight:normal'><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>COMUNICAZIONI TRA LE PARTI</span></b><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
8.0pt;margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:
normal;mso-list:l36 level1 lfo13;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>14.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Il Licenziante
può aggiornare i termini di questa Licenza in qualsiasi momento, notificando il
Licenziatario in conformità con questo articolo 14. L'uso continuato del
Software e dei Documenti da parte del Licenziatario per 30 giorni successivi
alla notifica, come descritto in questo articolo 14, costituirà accettazione da
parte del Licenziatario dei termini di questa Licenza e dei suoi eventuali
aggiornamenti. Se il Licenziatario non desidera accettare i termini della
Licenza (così modificati), dovrà interrompere immediatamente l'uso e l'accesso
al Software e ai Documenti da parte del Licenziatario e/o dei suoi Utenti
Autorizzati alla ricezione della notifica e dovrà informare il Licenziante
utilizzando i dettagli di contatto resi disponibili tramite il pannello di
controllo del Software.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l36 level1 lfo13;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><span style='mso-list:Ignore'>14.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Il Licenziante
contatterà esclusivamente il Licenziatario tramite i dettagli di contatto
forniti dal Licenziatario prima dell'accettazione dell'EULA.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l36 level1 lfo13;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>14.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Ogni
comunicazione: <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l20 level1 lfo16;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>data dal Licenziante al Licenziatario
sarà considerata ricevuta e correttamente effettuata 24 ore dopo essere stata
pubblicata per la prima volta sul sito web e sul pannello di controllo del
Licenziante, oppure 24 ore dopo l'invio di un’e-mail, o tre giorni dopo la data
di invio di qualsiasi lettera; <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>e <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l20 level1 lfo16;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>data dal Licenziatario al Licenziante
sarà considerata ricevuta e correttamente effettuata 24 ore dopo l'invio di
un’e-mail, o tre giorni dopo la data di invio di qualsiasi lettera.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l36 level1 lfo13;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><span style='mso-list:Ignore'>14.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Per provare la
ricezione di qualsiasi comunicazione, sarà sufficiente dimostrare:<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
8.0pt;margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:
normal;mso-list:l28 level1 lfo14'><![if !supportLists]><span lang=IT
style='font-size:10.0pt;font-family:"Noto Sans Symbols";mso-fareast-font-family:
"Noto Sans Symbols";mso-bidi-font-family:"Noto Sans Symbols";color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Nel caso di una lettera, che essa fosse
correttamente indirizzata, affrancata e inviata per posta all'indirizzo del
destinatario fornito per tali scopi;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l28 level1 lfo14'><![if !supportLists]><span
lang=IT style='font-size:10.0pt;font-family:"Noto Sans Symbols";mso-fareast-font-family:
"Noto Sans Symbols";mso-bidi-font-family:"Noto Sans Symbols";color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Nel caso di una comunicazione al
Licenziatario tramite il pannello di controllo del Software, questa sarà
considerata ricevuta due giorni dopo la sua pubblicazione;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l28 level1 lfo14'><![if !supportLists]><span
lang=IT style='font-size:10.0pt;font-family:"Noto Sans Symbols";mso-fareast-font-family:
"Noto Sans Symbols";mso-bidi-font-family:"Noto Sans Symbols";color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>E nel caso di un’e-mail, che essa sia
stata inviata all'indirizzo email del destinatario fornito per tali scopi.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
14.0pt;margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:
normal;mso-list:l26 level1 lfo8'><![if !supportLists]><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><span style='mso-list:Ignore'>15.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></span><![endif]><span
dir=LTR></span><b style='mso-bidi-font-weight:normal'><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>FORZA MAGGIORE</span></b><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
14.0pt;margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:
normal;mso-list:l29 level1 lfo18;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>15.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Nel caso in cui
il Licenziante venga ritardato, ostacolato o impedito nell'esecuzione di
qualsiasi atto, incluso ma non limitato alla fornitura del software richiesto
ai sensi del presente Contratto, per motivi di sciopero, blocchi, problemi
lavorativi, terrorismo, impossibilità di procurarsi materiali o servizi,
mancanza di energia o per ordini restrittivi governativi o giudiziari, decreti,
sommosse, insurrezioni, guerra, cause di forza maggiore, terremoti, o per
qualsiasi altro motivo o causa al di là del controllo di tale Parte,
indipendentemente dal fatto che tale motivo o causa possa essere attualmente
previsto (come un nuovo lockdown per Covid o altre pandemie), l'esecuzione di
tale atto sarà scusata per il periodo di ritardo, a condizione che il Licenziante
utilizzi ragionevoli sforzi per rimediare a tale ritardo, ostacolo o
impedimento. Il Licenziante non sarà responsabile per eventuali mancate
esecuzioni o ritardi nell'esecuzione di alcuna delle nostre obbligazioni ai
sensi di questo Contratto dovuti a forza maggiore come descritto in questo
articolo 15.1.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'>16. DISPOSITIVO MEDICO</span></b><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:
normal;mso-list:l2 level1 lfo38;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>16.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Il Licenziante
identificherà in buona fede e in conformità alle normative locali i Prodotti
che rientrano nella definizione di dispositivo medico ai sensi del Regolamento
sui Dispositivi Medici (Regolamento (UE) 2017/745). Il Software è considerato
un dispositivo medico ai sensi del Regolamento sui Dispositivi Medici.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l2 level1 lfo38;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>16.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Il
Licenziatario è responsabile di verificare che il Software sia utilizzato
correttamente in conformità alle leggi e alle normative locali. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l2 level1 lfo38;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>16.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Ogniqualvolta
il Licenziatario diventi consapevole di problemi di qualità, Incidenti o
informazioni che ragionevolmente suggeriscono che il Software o i Documenti non
siano conformi al Regolamento sui Dispositivi Medici dell'UE o alla
legislazione nazionale sui dispositivi medici, dovrà fornire al Licenziante
tutte le informazioni e l'accesso al dispositivo interessato necessari per
determinare il problema e non utilizzerà il Software fino a quando il
Licenziante non avrà fornito l'autorizzazione. Il Licenziatario dovrà inviare
una prima notifica di tali problemi di qualità, Incidenti o informazioni
riguardanti la conformità alla legislazione sui dispositivi medici applicabile
entro 24 ore dalla scoperta e fornirà eventuali informazioni non ancora
disponibili al momento della notifica iniziale senza indugi indebiti.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l2 level1 lfo38;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>16.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Il
Licenziatario dovrà trasmettere tali informazioni al Licenziante senza indugi e
in nessun caso oltre 24 ore dalla scoperta di tali informazioni. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l2 level1 lfo38;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>16.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Se il
Licenziante notifica al Licenziatario che è necessaria un'azione correttiva
urgente per la sicurezza o il richiamo del Prodotto, il Licenziatario
confermerà prontamente la ricezione della notifica e coopererà con il
Licenziante a proprie spese per attuare le azioni correttive. In nessun caso il
Licenziatario attuerà alcuna azione correttiva senza il consenso esplicito del
Licenziante.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l2 level1 lfo38;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>16.6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Il
Licenziatario si impegna a notificare al Licenziante e ad assistere
quest'ultimo con qualsiasi azione da parte del governo o di terze parti
riguardante il Software non appena ne diventa consapevole. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l2 level1 lfo38;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>16.7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Se il
Licenziatario ha motivo di ritenere che il Software possa presentare un rischio
serio o possa essere falsificato, non informerà alcuna autorità competente
prima di aver avvisato il Licenziante e aver concordato con quest'ultimo i
dettagli delle informazioni da comunicare all'autorità competente.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'>17. VARIE</span></b><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:
normal;mso-list:l1 level1 lfo39;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>17.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Trasferimento
dei diritti da parte del Licenziante. Il Licenziatario fornisce anticipatamente
il permesso al Licenziante di trasferire i suoi diritti e obblighi ai sensi di
questo Contratto a un terzo. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l1 level1 lfo39;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>17.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Trasferimento
dei diritti da parte del Licenziatario. Il Licenziatario può trasferire i suoi
diritti e obblighi ai sensi di questo Contratto a un'altra persona solo previa
esplicita autorizzazione scritta del Licenziante. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l1 level1 lfo39;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>17.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Questa Licenza
e qualsiasi documento espressamente citato costituiscono l'intero accordo tra
le Parti e sostituiscono e estinguono tutti gli accordi precedenti, promesse,
assicurazioni, garanzie, rappresentazioni e intese tra di noi, sia scritti che
verbali, relativi alla sua materia. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l1 level1 lfo39;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>17.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Se il
Licenziante non insiste affinché il Licenziatario adempia a uno qualsiasi dei
suoi obblighi ai sensi della presente Licenza, o se il Licenziante non esercita
i suoi diritti contro di esso o ritarda nel farlo, ciò non significherà che il
Licenziante abbia rinunciato ai suoi diritti e non significa che il
Licenziatario non debba ottemperare a tali obblighi. Qualsiasi rinuncia
all'inadempimento da parte del Licenziatario sarà data per iscritto. Il
Licenziante non è obbligato a rinunciare a qualsiasi inadempimento da parte del
Licenziatario.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l1 level1 lfo39;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>17.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Ciascun
articolo della presente Licenza opera separatamente. Se un tribunale o
un'autorità competente dovesse decidere che uno qualsiasi di essi è illegale o
inapplicabile, gli articoli rimanenti rimarranno in pieno vigore ed effetto.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'>18. LEGGE APPLICABILE E CONTROVERSI<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:
normal;mso-list:l27 level1 lfo40;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>18.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>La presente
Licenza, il suo oggetto e la sua formazione sono disciplinati dalle leggi della
Germania, con esclusione delle norme sulla conflittualità delle leggi e della
Convenzione delle Nazioni Unite sugli Accordi per la Vendita Internazionale di
Beni del 11 aprile 1980.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l27 level1 lfo40;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>18.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
dir=LTR></span><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Qualsiasi
controversia in relazione a questo EULA e alla Licenza sarà soggetta alla
giurisdizione esclusiva della sede legale del convenuto.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'>Informativa sulla Privacy<o:p></o:p></span></b></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Benvenuti nel Dispositivo e Software
per la Gestione Digitale delle Ferite <span class=SpellE>KroniKare</span>®. Noi
(<span class=SpellE>KroniKare</span> Ltd) desideriamo informarVi che agiremo
come responsabili del trattamento di tutti i dati personali relativi ai
contratti stipulati con <span class=SpellE>KroniKare</span> Ltd, in generale
per quanto riguarda i dati personali del Licenziatario e, per evitare equivoci,
specialmente nel caso in cui il licenziatario sia una persona fisica o unico
proprietario, ad esempio come medico o infermiere indipendente o autonomo.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Pertanto, prima di iniziare a
utilizzare il Dispositivo e il Software, vorremmo ricordarvi (fornendo questo
avviso sulla privacy) che raccogliamo alcuni dati personali limitati su di voi
(i &quot;Dati Personali&quot;). Vi chiediamo di leggere attentamente questo
avviso sulla privacy e di confermare di averlo letto.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l14 level1 lfo26;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>1)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Chi è
responsabile del trattamento dei Dati Personali, rappresentante nell'UE?<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;line-height:
normal;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span class=SpellE><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>KroniKare</span></span><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'> Ltd, The <span class=SpellE>Wenta</span>
Business Centre, Watford, <span class=SpellE>Hertfordshire</span>, Inghilterra,
WD24 7ND, Regno Unito, è responsabile del trattamento dei Dati Personali. Il
rappresentante nell'Unione Europea per tutte le questioni relative al
trattamento dei dati personali secondo l'Art. 27 GDPR può essere contattato al
seguente indirizzo: <span class=SpellE>Kaleidoscope</span> Data Privacy <span
class=SpellE>Consultants</span> SL (KDPC ES), Calle <span class=SpellE>Balmes</span>
173, 4-2, Barcellona 08006, Spagna (dpr.kronikare@kdpc.es) per conto di <span
class=SpellE>KroniKare</span> Ltd.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;line-height:
normal;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Questo
rappresentante agisce in conformità con un mandato scritto ricevuto da <span
class=SpellE>KroniKare</span> Ltd., che autorizza il rappresentante a cooperare
con le autorità di vigilanza e ad agire come punto di contatto per gli
interessati.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;line-height:
normal;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Per ulteriori
informazioni di contatto si prega di vedere il punto 8) sotto.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l14 level1 lfo26;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>2)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Che tipo di
dati personali vengono trattati e qual è lo scopo e la base giuridica?<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;line-height:
normal;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Raccogliamo in
particolare i seguenti dati personali di base, nonché dati di utilizzo
pertinenti su di voi: Nome utente, Indirizzo email, Numero di telefono, Nome,
Secondo nome, Cognome, Genere, Luogo, Titolo, Categoria, Ruolo, Descrizione del
lavoro, Dati di accesso (File di registro) e Indirizzo IP. Lo scopo del
trattamento dei dati è quello di adempiere all'accordo, in particolare per
consentire la gestione, l'accesso, l'amministrazione e l'uso del Dispositivo e
del Software di Gestione Digitale delle Ferite basato sull'Art. 6 (1) b GDPR.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l14 level1 lfo26;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>3)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Chi riceve i
miei dati personali?<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;line-height:
normal;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Di regola,
elaboriamo solo internamente i tuoi dati personali. Se trasferiamo i tuoi dati
personali ad altre persone, aziende o altri terzi o concediamo loro altro
accesso ai dati personali, ciò avviene solo sulla base di un'autorizzazione
legale. Se incarichiamo terzi del trattamento dei dati personali sulla base di
un Accordo di Elaborazione Dati, ciò avviene sulla base dell'Art. 28 GDPR.
Tuttavia, restiamo responsabili nei tuoi confronti per la liceità del
trattamento dei dati.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l14 level1 lfo26;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>4)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Dove e per
quanto tempo verranno elaborati o archiviati i miei dati personali?<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;line-height:
normal;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Per quanto
possibile, effettueremo l'elaborazione dei Vostri dati personali solo
all'interno dello Spazio Economico Europeo. Tuttavia, se il trattamento dei
Vostri dati personali in paesi terzi è necessario, garantiremo che siano
soddisfatti i requisiti legali specifici per tali operazioni di trattamento e
che in detto paese terzo sia garantito un adeguato livello di protezione dei
dati. Pertanto, garantiremo l'esistenza di adeguate garanzie e misure ai sensi
dell'Art. 46 GDPR (se non è stata adottata alcuna decisione di adeguatezza), in
particolare potremmo applicare le clausole contrattuali standard (SCC). Poiché <span
class=SpellE>KroniKare</span> Ltd. è stabilita nel Regno Unito e secondo la
Commissione europea il livello di protezione è sostanzialmente equivalente in
Regno Unito rispetto a quello dell'Unione europea (cfr. Art. 45 GDPR), i
trasferimenti di dati personali dall'Unione europea a questo paese terzo
potrebbero avvenire senza ulteriori adeguate garanzie e misure.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;line-height:
normal;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Archiviamo i
Vostri dati personali principalmente per la durata del rapporto contrattuale.
Le informazioni sopra riportate sulla cancellazione non si applicano se, tra le
altre cose, i termini di conservazione legalmente prescritti impediscono la
cancellazione immediata (cfr. Art. 17 (3) GDPR) o se un nuovo scopo giustifica
ulteriori trattamenti.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l14 level1 lfo26;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>5)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Quali sono i
diritti dei soggetti interessati?<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
0cm;margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l6 level1 lfo15;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>In conformità all'Art. 15 GDPR, puoi
richiedere informazioni sui tuoi dati personali elaborati da noi.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l6 level1 lfo15;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>In conformità all'Art. 16 GDPR, puoi
richiedere la correzione immediata dei dati personali errati o incompleti da
noi memorizzati.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l6 level1 lfo15;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>In conformità all'Art. 17 GDPR, puoi
richiedere la cancellazione dei tuoi dati personali da noi memorizzati in
conformità con le condizioni ivi indicate, a meno che i termini di
conservazione legalmente prescritti impediscano la cancellazione immediata (cfr.
Art. 17 (3) GDPR) e/o sussista un altro caso espresso nell’Art. 17 (3) e/o una
nuova finalità giustifichi un ulteriore trattamento.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l6 level1 lfo15;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>In base all'Art. 18 (1) GDPR, puoi
richiedere la limitazione del trattamento dei dati se si verificano una o più
condizioni ai sensi dell'Art. 18 (1) GDPR lett. a) a d).<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l6 level1 lfo15;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>In conformità all'Art. 20 (1) GDPR,
puoi ricevere i dati personali trattati da noi in un formato strutturato,
comune e leggibile da dispositivo automatico e trasferire questi dati personali
a un altro responsabile del trattamento senza impedimenti da parte nostra.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l6 level1 lfo15;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Inoltre, puoi opporti al trattamento
dei tuoi dati personali in conformità all'Art. 21 (1) GDPR. In caso di
opposizione, interromperemo il trattamento dei tuoi dati personali. Tuttavia,
il diritto di opposizione si applica solo in caso di circostanze particolari
derivanti dalla tua situazione personale. Inoltre, possono prevalere motivi di
fondamentale interesse meritevoli di tutela che giustifichino il trattamento.
Inoltre, determinati scopi di trattamento possono entrare in conflitto con il
tuo diritto di opposizione.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
mso-list:l6 level1 lfo15;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><span lang=IT style='font-size:
11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=IT
style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'>Indipendentemente da qualsiasi altro
mezzo di ricorso amministrativo o giudiziario, hai anche il diritto di fare
reclamo all'autorità di controllo competente (cfr. Art. 77 GDPR) se ritieni che
il trattamento dei tuoi dati personali violi le disposizioni sulla protezione
dei dati. Tuttavia, in questo contesto, ti chiediamo di indirizzare eventuali
reclami prima ai contatti forniti al punto 8) qui sotto.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:14.0pt;margin-right:0cm;margin-bottom:
0cm;margin-left:36.0pt;text-align:justify;line-height:normal;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><span
lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black;mso-themecolor:text1'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l14 level1 lfo26;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>6)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Sono obbligato
a fornire i miei dati personali?<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;line-height:
normal;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Sei tenuto solo
a fornirci i dati personali necessari per l'avvio, l'esecuzione e la <span
class=SpellE>risoluzone</span> di un rapporto contrattuale o quelli che
generalmente richiediamo per l'esecuzione dei nostri servizi. Senza questi dati
personali, in genere non saremo in grado di concludere e portare a termine il
rapporto contrattuale con te o fornirti i nostri servizi.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l14 level1 lfo26;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>7)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Utilizziamo
processi decisionali automatizzati?<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;line-height:
normal;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Non utilizziamo
procedure decisionali puramente automatizzate in conformità all'Art. 22 del
GDPR. Qualora in futuro dovessimo ricorrere a tali procedure in casi specifici,
ti informeremo separatamente se ciò fosse richiesto dalla legge.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal;mso-list:l14 level1 lfo26;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span lang=IT style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black;
mso-themecolor:text1'><span style='mso-list:Ignore'>8)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Hai altre
domande?<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;line-height:
normal;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=IT style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-themecolor:text1'>Ti preghiamo di
contattarci per saperne di più sulla protezione dei dati o per qualsiasi altra
domanda, in particolare per quanto riguarda l'esercizio dei tuoi diritti di
interessato presso <span class=SpellE>KroniKare</span> Ltd. Responsabile della
protezione dei dati per il Regno Unito (dpo.kronikare@kdpc.uk) e rappresentante
nell'Unione Europea (dpr.kronikare@kdpc.es).<b style='mso-bidi-font-weight:
normal'><o:p></o:p></b></span></p>

</div>

</body>

</html>

  `,
}

export default { ...main }
