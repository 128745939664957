import { ORGANIZATION } from '../../constants/action-constants'

const initialState = {
  organisationList: [],
  organizationSingle: '',
  selectedOrganization: '',
}
export default function (state = initialState, action) {
  switch (action.type) {
    case ORGANIZATION.FETCH_ORGANIZATIONLIST:
      return { ...state, organizationSingle: '', organizationList: action.payload }
    case ORGANIZATION.FETCH_ORGANIZATIONLIST_COMPACT:
      return { ...state, organizationSingle: '', organizationList: action.payload }
    case ORGANIZATION.FETCH_ORGANIZATION_ADMISSION:
      return { ...state, organizationSingle: action.payload }
    case ORGANIZATION.SET_SELECTED_ORGANIZATION:
      return { ...state, selectedOrganization: action.payload }
    default:
      return state
  }
}
