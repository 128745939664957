//EN
const main = {
  ErrorMessages_400_BadRequest_text: 'Qualcosa è andato storto..!',
  ErrorMessages_401_Unauthorised_text: 'Something went wrong... Unauthorised. Please login.',
  ErrorMessages_401_LoginInvalidCredentials_text: 'Wrong Username/Password! Please try again.',
  ErrorMessages_403_Forbidden_text: 'You do not have access to the requested resource!',
  ErrorMessages_404_NotFound_text: 'Something went wrong... Resource is not found!',
  ErrorMessages_405_MethodNotAllowed_text: 'Something went wrong... Method not Allowed!',
  ErrorMessages_406_NotAcceptable_text: 'Something went wrong... Content not found!',
  ErrorMessages_408_RequestTimeout_text: 'Something went wrong... Request Timeout',
  ErrorMessages_500_InternalServerError_text:
    'Something went wrong... Server Error, please contact administrator.',
  ErrorMessages_501_NotImplemented_text: 'Something went wrong... Method not supported.',
  ErrorMessages_502_BadGateway_text: 'Something went wrong... Bad Gateway.',
  ErrorMessages_503_ServiceUnavailable_text: 'Something went wrong... Service Unavailable',
  ErrorMessages_504_ServiceUnavailable_text: 'Something went wrong... Gateway Timeout',
  ErrorMessages_GeneralErrorMessage_text: 'Qualcosa è andato storto..!',
  ErrorMessages_409_Username_already_exists_text:
    'Esiste già un utente simile, si prega di cambiarlo.',

  SuccessMessages_ForgetPasswordLink_text: `
  Il codice di verifica è stato inviato all'indirizzo email registrato dell'utente.`,
  SuccessMessages_PasswordSetSuccess_text: 'La password è stata impostata con successo.',
  SuccessMessages_PasswordChangeSuccess_text: 'La password è stata cambiata con successo.',
  SuccessMessages_UsernamePasswordSetSuccess_text:
    'Nome utente e password sono stati impostati con successo.',
  SuccessMessages_PINSetSuccess_text: 'Il PIN è stato impostato con successo.',
  SuccessMessages_TOTPSetSuccess_text: 'La registrazione TOTP è avvenuta con successo.',
}

export default {
  ...main,
}
