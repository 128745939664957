export const PRIMARY = {
  light: "#4d59b2",
  main: "#0d3082",
  dark: "#000b54",
  contrastText: "#fff",
};
export const SECONDARY = {
  light: "#67ade0",
  main: "#4299d9",
  dark: "#2e6b97",
  contrastText: "#fff",
};

export const COMMON = {
  grey: "#f0f0f0",
  darkGrey: "#a9a9a9",
};
