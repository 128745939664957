import Locale from './Locale'
import SetInitialPassword from '../DE/SetInitialPassword'
import SetNewPassword  from '../DE/SetNewPassword'
import RegisterPIN from '../DE/RegisterPIN'
import RegisterTOTP from '../DE/RegisterTOTP'
import ErrorSuccessMessages from '../DE/ErrorSuccessMessages'
import ValidationMessages from '../DE/ValidationMessages'

export default {
  name: 'PHGR',
...Locale,
...SetInitialPassword,
...SetNewPassword,
...RegisterPIN,
...RegisterTOTP,
...ErrorSuccessMessages,
...ValidationMessages,
}