//FR
const main = {
    SetNewPassword_titleSetNewPassword_display: true,
    SetNewPassword_titleSetNewPassword_text: 'Nouveau mot de passe',
    SetNewPassword_labelCurrentPassword_display: true,
    SetNewPassword_labelCurrentPassword_text: 'Mot de passe actuel',
    SetNewPassword_labelNewPassword_display: true,
    SetNewPassword_labelNewPassword_text: 'Nouveau mot de passe',
    SetNewPassword_labelConfirmNewPassword_display: true,
    SetNewPassword_labelConfirmNewPassword_text: 'Confirmer le nouveau mot de passe',
    SetNewPassword_labelSubmitButton_display: true,
    SetNewPassword_labelSubmitButton_text: 'Soumettre',
    SetNewPassword_labelBackToDashboard_display: true,
    SetNewPassword_labelBackToDashboard_text: 'Retour au tableau de bord',
}

export default {...main}
