//basic
const main = {
  SetInitialPassword_titleSetInitialPassword_display: true,
  SetInitialPassword_titleSetInitialPassword_text: 'Imposta la password iniziale',
  SetInitialPassword_labelPreferredUsername_display: true,
  SetInitialPassword_labelPreferredUsername_text: 'Nome utente preferito',
  SetInitialPassword_labelNewPassword_display: true,
  SetInitialPassword_labelNewPassword_text: 'Nuova password',
  SetInitialPassword_labelConfirmNewPassword_display: true,
  SetInitialPassword_labelConfirmNewPassword_text: 'Conferma nuova password',
  SetInitialPassword_labelSubmitButton_display: true,
  SetInitialPassword_labelSubmitButton_text: 'Invia',
  SetInitialPassword_validationMessagePreferredUsernameRequired_display: true,
  SetInitialPassword_validationMessagePreferredUsernameRequired_text:
    'Il nome utente preferito è obbligatorio',
  SetInitialPassword_validationMessagePasswordRequired_display: true,
  SetInitialPassword_validationMessagePasswordRequired_text: 'La password è obbligatoria',
  SetInitialPassword_validationMessageNewPasswordRequired_display: true,
  SetInitialPassword_validationMessageNewPasswordRequired_text:
    'La conferma della nuova password è obbligatoria',
  SetInitialPassword_validationMessagePasswordDoNotMatch_display: true,
  SetInitialPassword_validationMessagePasswordDoNotMatch_text: 'Le password non corrispondono!',
  SetInitialPassword_validationMessagePasswordCriteria_display: true,
  SetInitialPassword_validationMessagePasswordCriteria_text:
    'Deve contenere 8 caratteri, inclusa una maiuscola, una minuscola, un numero e un carattere speciale',
  SetInitialPassword_errorMessageProcessFailed_display: true,
  SetInitialPassword_errorMessageProcessFailed_text:
    "L'impostazione della password iniziale è fallita. Effettua il login e riprova.",
  SetInitialPassword_errorMessageNetworkIssues_display: true,
  SetInitialPassword_errorMessageNetworkIssues_text: 'Controlla la tua connessione di rete!',
  SetInitialPassword_errorMessageServerIssues_display: true,
  SetInitialPassword_errorMessageServerIssues_text:
    'Impossibile ottenere una risposta! Per favore riprova.',
}

export default { ...main }
