import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import PageContainer from '../components/common/PageContainer'
import routeConstants from '../constants/route-constants'
import history from '../utils/history'
// Pages
import Login from '../pages/login'
import SetPassword from '../pages/set-password'
import ForgotPassword from '../pages/forgot-password'
import RegisterPin from '../pages/register-pin'
import { SetupTOTP, ValidateTOTP } from '../pages/totp'
import SetInitialPassword from '../pages/set-initial-password'
import ResetPassword from '../pages/reset-password'
import TermAndCondition from '../pages/term-condition'

const Routes = () => {
  const ENV = process.env.NODE_ENV
  return (
    <Router basename="/web/drwound/login" history={history}>
      <Switch>
        <PageContainer>
          <Route exact path="/" component={Login} />
          <Route exact path={routeConstants.FORGOTPASSWORD} component={ForgotPassword} />
          <Route exact path={routeConstants.RESETPASSWORD} component={ResetPassword} />
          <Route exact path={routeConstants.REGISTERPIN} component={RegisterPin} />
          <Route exact path={routeConstants.SETTOTP} component={SetupTOTP} />
          <Route exact path={routeConstants.VALIDATETOTP} component={ValidateTOTP} />
          <Route exact path={routeConstants.SETPASSWORD} component={SetPassword} />
          <Route exact path={routeConstants.SETINITIALPASSWORD} component={SetInitialPassword} />
          <Route exact path={routeConstants.TERMANDCONDIITON} component={TermAndCondition} />
        </PageContainer>
      </Switch>
    </Router>
  )
}

export default Routes
