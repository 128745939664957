//basic
const main = {
  TermAndCondition: `<p>
    PAUL HARTMANN AG maintains the hartmann.de service platform under the
    internet address
    <a href="https://www.hartmann.info/de-de/" rel="nofollow">
        <u>https://www.hartmann.info/de-de</u>
    </a>
    . This has the task of providing national and international as well as
    internal and external interest groups with the information relevant to them
    in a convenient way, which they need to realise marketing communication
    projects.
</p>
<ol>
    <li>
        <p>
            <strong>Content of the hartmann.de service platform</strong>
        </p>
    </li>
</ol>
<p>
    The content and design of the hartmann.de service platform are known to the
    user. <br/>
    <br/>
    Pure information content of the hartmann.de service platform, including the
    standard search, is freely accessible to users. PAUL HARTMANN AG accepts no
    responsibility for the topicality, correctness, completeness or quality of
    the information provided. Liability claims against PAUL HARTMANN AG relating
    to material or non-material damage caused by the use or non-use of the
    information provided or by the use of incorrect or incomplete information
    are excluded unless there is evidence of intent or gross negligence of PAUL
    HARTMANN AG. <br/>
    <br/>
    All offers are subject to change and non-binding. Parts of the pages or the
    complete publication including all offers and information might be extended,
    changed or partly or completely deleted by PAUL HARTMANN AG without separate
    announcement.
</p>
<ol start="2">
    <li>
        <p>
            <strong>Disclaimer</strong>
        </p>
    </li>
</ol>
<p>
    In the case of direct or indirect references to external websites ("web
    documents") that lie outside the area of responsibility of PAUL HARTMANN AG,
    a liability obligation would only come into force if PAUL HARTMANN AG had
    knowledge of the content and it would be technically possible and reasonable
    for PAUL HARTMANN AG to prevent use in the case of illegal content. <br/>
    <br/>
    Since the links can be created by individual users, the users themselves are
    responsible for ensuring that no illegal content is identifiable on the
    linked pages at the time the links are created. <br/>
    <br/>
    PAUL HARTMANN AG has no influence on the current and future design, content
    or authorship of the linked pages. Therefore, PAUL HARTMANN AG hereby
    expressly distances itself from all contents of all linked pages that were
    changed after the link was created. This statement applies to all links and
    references set within the company's own website. Liability for illegal,
    incorrect or incomplete content and in particular for damage arising from
    the use or non-use of such information lies solely with the provider of the
    page to which reference is made, and not with the party who merely refers to
    the respective publication via links.
</p>
<ol start="3">
    <li>
        <p>
            <strong>Copyright and trademark law</strong>
        </p>
    </li>
</ol>
<p>
    All content of the service platform is legally protected. Copyrights, patent
    rights, trademark rights and all other protective rights to published works
    of intellectual property created by PAUL HARTMANN AG as well as to other
    information that PAUL HARTMANN AG provides or makes accessible to the user
    within the scope of using the service platform are the sole property of PAUL
    HARTMANN AG and remain exclusively with PAUL HARTMANN AG. If third parties
    are entitled to the rights, PAUL HARTMANN AG has the corresponding
    exploitation rights.
</p>
<p>
    PAUL HARTMANN AG exclusively grants the user the following authorisations to
    publish, reproduce, use and make publicly available works of intellectual
    property provided on the service platform, such as graphics, images, sound
    documents, video sequences, layout documents and texts. Copyrights or other
    proprietary notices found on the works - for example in the captions or
    other materials - on the service platform may not be removed. PAUL HARTMANN
    AG reserves the right to revoke the authorisation to use the works and the
    information available on the service platform at any time. The user hereby
    agrees to immediately cease using the images and information if requested to
    do so by PAUL HARTMANN AG in writing (text form is sufficient). <br/>
    <br/>
    In any case, the respective work may only be used in unaltered, unedited
    form. The user also has no right to transfer the work to third parties.
    <br/>
    <br/>
</p>
<ol start="4">
    <li>
        <p>
            <strong>Registration</strong>
        </p>
    </li>
</ol>
<p>
    The use of the content on the hartmann.de service platform is free of
    charge. In some cases, protected content is only made available to users
    with a registered user e-mail address.
</p>
<ol start="5">
    <li>
        <p>
            <strong>Place of jurisdiction</strong>
        </p>
    </li>
</ol>
<p>
    The place of jurisdiction for all disputes within the scope of use is
    Munich, provided that the user is a merchant within the meaning of the
    German Commercial Code (HGB).
</p>
<ol start="5">
    <li>
        <p>
            <strong>General principles</strong>
        </p>
    </li>
</ol>
<p>
    Every user is obliged to comply with the applicable laws when using the
    hartmann.de service platform and the services of PAUL HARTMANN AG. <br/>
    <br/>
    PAUL HARTMANN AG reserves the right to change the order in its service
    platform hartmann.de within its principles.
</p>
<ol start="5">
    <li>
        <p>
            <strong>
                System integrity and disruption of the hartmann.de service
                platform
            </strong>
        </p>
    </li>
</ol>
<p>
    The user is not entitled to use mechanisms, software or other scripts in
    connection with the use of the hartmann.de service platform which may
    interfere with the functioning of the hartmann.de service platform. The user
    may not take any measures that could result in an unreasonable or excessive
    load on the hartmann.de service platform infrastructure. The user is not
    permitted to block, overwrite or modify content generated by the hartmann.de
    service platform or to interfere in any other way with the hartmann.de
    service platform. <br/>
    <br/>
    These terms of use may only be reproduced and/or used on other websites with
    the prior written consent of PAUL HARTMANN AG. <br/>
    <br/>
</p>
<p>
    The layout of the hartmann.de service platform may not be reproduced and/or
    used in any other way.
</p>
<ol start="5">
    <li>
        <p>
            <strong>
                Severability Clause, Amendment of these Terms of Use, Applicable
                Law
            </strong>
        </p>
    </li>
</ol>
<p>
    This document, which contains the terms of use and the disclaimer, is part
    of the hartmann.de service platform. If parts or individual formulations of
    the terms of use do not, no longer or do not completely comply with the
    applicable legal situation, the remaining parts of the terms of use shall
    remain unaffected in their content and validity. <br/>
    <br/>
    PAUL HARTMANN AG reserves the right to change these terms of use at any time
    and without stating reasons. These terms of use are subject to the law of
    the Federal Republic of Germany. <br/>
    <br/>
    In addition, the terms of delivery and payment of PAUL HARTMANN AG apply.
    These can be found at:
    <a href="https://www.hartmann.info/de-de/agb" rel="nofollow">
        <u>www.hartmann.info/de-de/agb. </u>
    </a>
    <br/>
</p>
`,
}

export default { ...main }
