//DE
const main = {
    RegisterTOTP_titleRegisterTOTP_display: true,
    RegisterTOTP_titleRegisterTOTP_text: 'Zeitbasiertes einmaliges Passwort registrieren',
    RegisterTOTP_labelQRCode_display: true,
    RegisterTOTP_labelQRCode_text: 'QR Code',
    RegisterTOTP_labelTimeRemaining_display: true,
    RegisterTOTP_labelTimeRemaining_text: 'Verbleibende Zeit',
    RegisterTOTP_timerUnit_display: true,
    RegisterTOTP_timerUnit_text: 's',
    RegisterTOTP_label6DigitTOTP_display: true,
    RegisterTOTP_label6DigitTOTP_text: 'Zeitbasiertes einmaliges Passwort mit 6 Ziffern',
    RegisterTOTP_labelSubmitButton_display: true,
    RegisterTOTP_labelSubmitButton_text: 'Absenden',
    RegisterTOTP_labelBackToDashboard_display: true,
    RegisterTOTP_labelBackToDashboard_text: 'Zurück zu Dashboard',
    RegisterTOTP_noticeForTimer_display: true,
    RegisterTOTP_noticeForTimer_text: 'Bitte geben Sie das zeitbasierte einmalige Passwort ein, bevor der Timer abläuft. Aktualisieren Sie die Seite, wenn der Timer abläuft.',
    RegisterTOTP_infoForTOTP_display: true,
    RegisterTOTP_infoForTOTP_text: 
        'Für die Registrierung eines zeitbasierten einmaligen Passworts ist eine Anwendung zur Authentifizierung wie Google Authenticator (verfügbar für Android und iOS) notwendig. Wenden Sie sich für Hilfestellung an Ihren Administrator.',
    RegisterTOTP_validationMessageTOTPRequired_display: true,
    RegisterTOTP_validationMessageTOTPRequired_text: 'Zeitbasiertes einmaliges Passwort mit 6 Ziffern ist erforderlich',
    RegisterTOTP_validationMessageTOTPOnlyDigits_display: true,
    RegisterTOTP_validationMessageTOTPOnlyDigits_text: 'Müssen nur Ziffern sein',
    RegisterTOTP_validationMessageTOTPSixDigit_display: true,
    RegisterTOTP_validationMessageTOTPSixDigit_text: 'Muss genau 6 Ziffern betragen',
    RegisterTOTP_errorMessageTimerExpire_display: true,
    RegisterTOTP_errorMessageTimerExpire_text: 'Die Zeit ist abgelaufen. Bitte aktualisieren Sie die Seite, um einen neuen QR-Code zu erhalten.',
    RegisterTOTP_errorMessageProcessFailed_display: true,
    RegisterTOTP_errorMessageProcessFailed_text: 'Für die Registrierung eines zeitbasierten einmaligen Passworts fehlgeschlagen. Bitte versuchen Sie es erneut.',
    RegisterTOTP_errorMessageNetworkIssues_display: true,
    RegisterTOTP_errorMessageNetworkIssues_text: 'Bitte überprüfen Sie Ihr Netzwerk!',
    RegisterTOTP_errorMessageServerIssues_display: true,
    RegisterTOTP_errorMessageServerIssues_text: 'Sie haben keine Antwort erhalten! Bitte versuchen Sie es erneut.',
}

export default {...main}
