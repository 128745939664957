//EN
const main = {
    ForgotPassword_titleForgotPassword_display: true,
    ForgotPassword_titleForgotPassword_text: 'Forgot Password?',
    ForgotPassword_labelProcessInfo_display: true,
    ForgotPassword_labelProcessInfo_text: "Enter your registered Username and we'll send you a link to reset your password.",
    ForgotPassword_labelUsername_display: true,
    ForgotPassword_labelUsername_text: 'Username',
    ForgotPassword_labelSubmitButton_display: true,
    ForgotPassword_labelSubmitButton_text: 'Submit',
    ForgotPassword_labelBackToLoginLink_display: true,
    ForgotPassword_labelBackToLoginLink_text: 'Back to Login',
    ForgotPassword_validationMessageUsernameRequired_display: true,
    ForgotPassword_validationMessageUsernameRequired_text: 'Username is required',
    ForgotPassword_validationMessageEmailInvalid_display: true,
    ForgotPassword_validationMessageEmailInvalid_text: 'Invalid email address format',
    ForgotPassword_errorMessageUsernameNotExist_display: true,
    ForgotPassword_errorMessageUsernameNotExist_text: 'The username does not exist in the database. Please key in the correct registered username.',
    ForgotPassword_errorMessageProcessFailed_display: true,
    ForgotPassword_errorMessageProcessFailed_text: 'Process failed. Please try again!',
    ForgotPassword_errorMessageNetworkIssues_display: true,
    ForgotPassword_errorMessageNetworkIssues_text: 'Please check your network!',
    ForgotPassword_errorMessageServerIssues_display: true,
    ForgotPassword_errorMessageServerIssues_text: 'Unable to get a response! Please try again.',
}

export default {
    ...main
}
