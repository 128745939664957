import URIs from "../constants/endpoints";
import apirequest from "../utils/api-request";
const globalService = () => {
  return {
    fetchGeoIPLocation: () => {
      return apirequest({
        method: "GET",
        url: URIs.GEOIP_LOCATION,
        // headers: {
        //   withCredentials: true,
        //   "Referrer-Policy": "strict-origin",
        // },
      });
    },
    fetchReferenceData: ({ url }) => {
      return apirequest({
        method: "GET",
        url: url,
        headers: {
          withCredentials: true,
          "Referrer-Policy": "strict-origin",
        },
      });
    },
  };
};
export default globalService;
