//ES
const main = {
  ErrorMessages_400_BadRequest_text: 'Algo salió mal... ¡Mal pedido!',
  ErrorMessages_401_Unauthorised_text: 'Algo salió mal... No autorizado. Por favor, inicie sesión.',
  ErrorMessages_401_LoginInvalidCredentials_text:
    '¡Nombre de usuario/contraseña incorrectos! Inténtelo de nuevo.',
  ErrorMessages_403_Forbidden_text: 'No tiene acceso al recurso solicitado.',
  ErrorMessages_404_NotFound_text: 'Algo salió mal... ¡El recurso no se encuentra!',
  ErrorMessages_405_MethodNotAllowed_text: 'Algo salió mal... ¡Método no permitido!',
  ErrorMessages_406_NotAcceptable_text: 'Algo salió mal... ¡Contenido no encontrado!',
  ErrorMessages_408_RequestTimeout_text: 'Algo salió mal... Tiempo de espera de la solicitud',
  ErrorMessages_500_InternalServerError_text:
    'Algo salió mal... Error del servidor, por favor contacte con el administrador.',
  ErrorMessages_501_NotImplemented_text: 'Algo salió mal... Método no admitido.',
  ErrorMessages_502_BadGateway_text: 'Algo salió mal... Puerta mala.',
  ErrorMessages_503_ServiceUnavailable_text: 'Algo salió mal... Servicio no disponible',
  ErrorMessages_504_ServiceUnavailable_text:
    'Algo salió mal... Tiempo de espera de la puerta de enlace',
  ErrorMessages_GeneralErrorMessage_text: 'Algo salió mal...',
  ErrorMessages_409_Username_already_exists_text:
    'Ya existe un usuario similar, por favor, cámbielo.',

  SuccessMessages_ForgetPasswordLink_text: `
El código de verificación se ha enviado a la dirección de correo electrónico registrada del usuario.`,
  SuccessMessages_PasswordSetSuccess_text: 'La contraseña se ha establecido con éxito.',
  SuccessMessages_PasswordChangeSuccess_text: 'La contraseña ha sido cambiada con éxito.',
  SuccessMessages_UsernamePasswordSetSuccess_text:
    'El nombre de usuario y la contraseña se han configurado correctamente.',
  SuccessMessages_PINSetSuccess_text: 'El PIN se ha establecido correctamente.',
  SuccessMessages_TOTPSetSuccess_text:
    'El registro de la contraseña temporal única (TOTP) es exitoso',
}

export default {
  ...main,
}
