//FR
const main = {
  ErrorMessages_400_BadRequest_text: 'Quelque chose a mal tourné... Mauvaise requête!',
  ErrorMessages_401_Unauthorised_text:
    'Quelque chose a mal tourné... Non autorisé. Veuillez vous connecter.',
  ErrorMessages_401_LoginInvalidCredentials_text:
    "Nom d'utilisateur/mot de passe incorrect! Veuillez réessayer.",
  ErrorMessages_403_Forbidden_text: "Vous n'avez pas accès à la ressource demandée !",
  ErrorMessages_404_NotFound_text: "Quelque chose a mal tourné... La ressource n'est pas trouvée !",
  ErrorMessages_405_MethodNotAllowed_text: 'Quelque chose a mal tourné... Méthode non autorisée !',
  ErrorMessages_406_NotAcceptable_text: 'Quelque chose a mal tourné... Contenu non trouvé !',
  ErrorMessages_408_RequestTimeout_text:
    "Quelque chose a mal tourné... Délai d'attente de la demande",
  ErrorMessages_500_InternalServerError_text:
    "Quelque chose a mal tourné... Erreur de serveur, veuillez contacter l'administrateur.",
  ErrorMessages_501_NotImplemented_text: "Quelque chose s'est mal passé... Méthode non supportée.",
  ErrorMessages_502_BadGateway_text: 'Quelque chose a mal tourné... Mauvaise passerelle.',
  ErrorMessages_503_ServiceUnavailable_text: 'Quelque chose a mal tourné... Service indisponible',
  ErrorMessages_504_ServiceUnavailable_text:
    "Quelque chose s'est mal passé... Délai d'attente de la passerelle",
  ErrorMessages_GeneralErrorMessage_text: 'Quelque chose a mal tourné...',
  ErrorMessages_409_Username_already_exists_text:
    'Un utilisateur similaire existe déjà, veuillez le modifier.',

  SuccessMessages_ForgetPasswordLink_text: `
Le code de vérification a été envoyé à l'adresse électronique enregistrée de l'utilisateur.`,
  SuccessMessages_PasswordSetSuccess_text: 'Le mot de passe a été défini avec succès.',
  SuccessMessages_PasswordChangeSuccess_text: 'Le mot de passe a été changé avec succès.',
  SuccessMessages_UsernamePasswordSetSuccess_text:
    "Le nom d'utilisateur et le mot de passe ont été définis avec succès.",
  SuccessMessages_PINSetSuccess_text: 'Le code PIN a été défini avec succès.',
  SuccessMessages_TOTPSetSuccess_text:
    "L'enregistrement du mot de passe à usage unique (MDPUU) est réussi.",
}

export default {
  ...main,
}
