//basic
const main = {
  SetNewPassword_titleSetNewPassword_display: true,
  SetNewPassword_titleSetNewPassword_text: 'Imposta una nuova password',
  SetNewPassword_labelCurrentPassword_display: true,
  SetNewPassword_labelCurrentPassword_text: 'Password attuale',
  SetNewPassword_labelNewPassword_display: true,
  SetNewPassword_labelNewPassword_text: 'Nuova password',
  SetNewPassword_labelConfirmNewPassword_display: true,
  SetNewPassword_labelConfirmNewPassword_text: 'Conferma nuova password',
  SetNewPassword_labelSubmitButton_display: true,
  SetNewPassword_labelSubmitButton_text: 'Invia',
  SetNewPassword_labelBackToDashboard_display: true,
  SetNewPassword_labelBackToDashboard_text: 'Torna al login',
}

export default { ...main }
