import Locale from './Locale'
import SetInitialPassword from '../FR/SetInitialPassword'
import SetNewPassword  from '../FR/SetNewPassword'
import RegisterPIN from '../FR/RegisterPIN'
import RegisterTOTP from '../FR/RegisterTOTP'
import ErrorSuccessMessages from '../FR/ErrorSuccessMessages'
import ValidationMessages from '../FR/ValidationMessages'

export default {
  name: 'PHFR',
...Locale,
...SetInitialPassword,
...SetNewPassword,
...RegisterPIN,
...RegisterTOTP,
...ErrorSuccessMessages,
...ValidationMessages,
}