import { UI } from "../../constants/action-constants";
const initialState = {
  locale: "EN",
  showSnackbar: false,
  snackbarMessage: "",
  snackbarVariant: "success",
  showLoader: false,
  referenceData: {},
};
export default function (state = initialState, action) {
  switch (action.type) {
    case UI.SET_LOCALE_THEME:
      return {
        ...state,
        locale: action.payload,
      };
    case UI.SHOW_SNACKBAR:
      return {
        ...state,
        showSnackbar: true,
        snackbarMessage: action.data.snackbarMessage,
        snackbarVariant: action.data.snackbarVariant,
      };
    case UI.HIDE_SNACKBAR:
      return {
        ...state,
        showSnackbar: false,
        snackbarMessage: "",
      };

    case UI.SHOW_LOADER:
      return {
        ...state,
        showLoader: true,
      };
    case UI.HIDE_LOADER:
      return {
        ...state,
        showLoader: false,
      };
    case UI.FETCH_REFERENCE_DATA:
      return {
        ...state,
        referenceData: {
          ...state.referenceData,
          [action.payloadType]: action.payload,
        },
      };

    default:
      return state;
  }
}
