import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import customization from '../utils/customization-utils'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  demo1: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
  },
}))

const TermAndCondition = () => {
  const classes = useStyles()
  const selectedLocale = useSelector((state) => state.ui.locale)

  const c = customization(selectedLocale)
  useEffect(() => {
    document.title = 'Terms & Conditions'

    return () => {
      document.title = 'Login'
    }
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <div dangerouslySetInnerHTML={{ __html: c.TermAndCondition }} />
      </div>
    </div>
  )
}

export default TermAndCondition
