//FR
const main = {
  ResetPassword_titleResetPassword_display: true,
  ResetPassword_titleResetPassword_text: 'Nouveau mot de passe',
  ResetPassword_labelNewPassword_display: true,
  ResetPassword_labelNewPassword_text: 'Nouveau mot de passe',
  ResetPassword_labelConfirmNewPassword_display: true,
  ResetPassword_labelConfirmNewPassword_text: 'Confirmer le nouveau mot de passe',
  ResetPassword_labelVerificationCode_display: true,
  ResetPassword_labelVerificationCode_text: 'Code de vérification',
  ResetPassword_labelSubmitButton_display: true,
  ResetPassword_labelSubmitButton_text: 'Soumettre',
  ResetPassword_validationMessageNewPasswordRequired_display: true,
  ResetPassword_validationMessageNewPasswordRequired_text: 'Un nouveau mot de passe est nécessaire',
  ResetPassword_validationMessageConfirmNewPasswordRequired_display: true,
  ResetPassword_validationMessageConfirmNewPasswordRequired_text:
    'Confirmer le nouveau mot de passe est nécessaire',
  ResetPassword_validationMessagePasswordDoNotMatch_display: true,
  ResetPassword_validationMessagePasswordDoNotMatch_text:
    'Les mots de passe sont différents! Veuillez saisir des mots de passe identiques.',
  ResetPassword_errorMessageProcessFailed_display: true,
  ResetPassword_errorMessageProcessFailed_text:
    "La définition d'un nouveau mot de passe a échoué. Veuillez réessayer.",
  ResetPassword_errorMessageNetworkIssues_display: true,
  ResetPassword_errorMessageNetworkIssues_text: 'Veuillez vérifier votre réseau',
  ResetPassword_errorMessageServerIssues_display: true,
  ResetPassword_errorMessageServerIssues_text:
    "Impossible d'obtenir une réponse. Veuillez réessayer.",
}

export default { ...main }
