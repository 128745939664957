//DE
const main = {
  RegisterPIN_titleRegisterPIN_display: true,
  RegisterPIN_titleRegisterPIN_text: 'PIN registrieren',
  RegisterPIN_label6DigitPin_display: true,
  RegisterPIN_label6DigitPin_text: '6-stellige PIN für mobile Anwendung',
  RegisterPIN_labelSubmitButton_display: true,
  RegisterPIN_labelSubmitButton_text: 'Absenden',
  RegisterPIN_labelBackToDashboard_display: true,
  RegisterPIN_labelBackToDashboard_text: 'Zurück zu Dashboard',
  RegisterPIN_validationMessagePinRequired_display: true,
  RegisterPIN_validationMessagePinRequired_text: 'PIN ist erforderlich',
  RegisterPIN_validationMessagePinOnlyDigits_display: true,
  RegisterPIN_validationMessagePinOnlyDigits_text: 'Müssen nur Ziffern sein',
  RegisterPIN_validationMessagePinSixDigit_display: true,
  RegisterPIN_validationMessagePinSixDigit_text: 'Muss genau 6 Ziffern betragen',
  RegisterPIN_errorMessageProcessFailed_display: true,
  RegisterPIN_errorMessageProcessFailed_text:
    'Die Einstellung der PIN ist fehlgeschlagen. Bitte versuchen Sie es erneut.',
  RegisterPIN_errorMessageNetworkIssues_display: true,
  RegisterPIN_errorMessageNetworkIssues_text: 'Bitte überprüfen Sie Ihr Netzwerk!',
  RegisterPIN_errorMessageServerIssues_display: true,
  RegisterPIN_errorMessageServerIssues_text:
    'Sie haben keine Antwort erhalten! Bitte versuchen Sie es erneut.',
}

export default { ...main }
