//basic
const main = {
  Eula_title_display: true,
  Eula_title_text: 'End-User License Agreement',
  Eula_labelAgreeButton_display: true,
  Eula_labelAgreeButton_text: 'Agree',
  Eula_display: true,
  Eula_text: `<!DOCTYPE html>
<html>
<head>
	<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
	<title></title>
	<meta name="generator" content="LibreOffice 7.4.4.2 (Linux)"/>
	<meta name="author" content="Bilgin Berksoy"/>
	<meta name="created" content="2023-03-07T10:02:00"/>
	<meta name="changed" content="2023-08-22T18:05:55.391196074"/>
	<meta name="AppVersion" content="16.0000"/>
	<meta name="ContentTypeId" content="0x010100476224809ABE224A900BF4DFEFD0895F"/>
	<style type="text/css">
		@page { size: 8.27in 11.69in; margin-left: 0.98in; margin-right: 0.98in; margin-top: 0.98in; margin-bottom: 0.79in }
		p { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }
		a:link { color: #000000; text-decoration: underline }
		a.western:link { font-family: "Arial", serif; font-style: italic }
		a.cjk:link { font-family: "Arial"; font-style: italic }
		a.ctl:link { font-family: "Arial" }
		a:visited { color: #800000; text-decoration: underline }
	</style>
</head>
<body lang="de-DE" link="#000000" vlink="#800000" dir="ltr">
<p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<font color="#000000"><font face="Arial, serif">BY USING THIS
SOFTWARE AND ANY UPDATES THEREOF (COLLECTIVELY: “SOFTWARE”) END
USER LICENSEE CONFIRM YOU HAVE BEEN PROVIDED WITH A COPY OF THIS
EULA; AND AGREE TO THE TERMS OF THIS LICENCE AS SET FORTH BELOW. IF
THE END USER LICENSEE DOES NOT AGREE TO THE TERMS OF THIS AGREEMENT,
THE USE OF THIS SOFTWARE IS PROHIBITED. THE TERMS OF THIS LICENCE
INCLUDE, IN PARTICULAR, LIMITATIONS ON LIABILITY IN ARTICLE 11
THEREOF</font></font></p>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<font color="#000000"><font face="Arial, serif">This End User Licence
Agreement (EULA) is effective from when the End User Licensee agrees
to the terms thereof via the procedure documented in this EULA and is
a legal agreement between:</font></font></p>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<font color="#000000"><font face="Arial, serif">KroniKare Pte. Ltd.
with its registered offices at 75 Ayer Rajah Crescent #03-12,
Singapore 139953, (hereinafter referred to as “Licensor”), </font></font>
</p>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<font color="#000000"><font face="Arial, serif">and</font></font></p>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<font color="#000000"><font face="Arial, serif">Organisation
(hereinafter referred to as “End User Licensee”). </font></font>
</p>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<font color="#000000"><font face="Arial, serif">Licensor hereby
grants the End User Licensee a non-exclusive, non-transferable,
limited right to use: </font></font>
</p>
<ul>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Vintens™ Digital
	Wound Management Device<b> (“Wound Scanner”) </b>software and
	the data supplied with the software and related/ancillary software
	consisting of device app, dashboard, and backend software<b>
	(“Software”); </b>and</font></font></p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Documents</font></font></p>
</ul>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0in">
<font color="#000000"><font face="Arial, serif">for the purposes
expressly set out in this license (“End User Licence”). Any
rights not expressly granted in this License are reserved by the
Licensor. Licensor licenses the right to use the Software and
Documents to End User Licensee on the basis of this Licence. Licensor
does not sell the Software or Documents to End User Licensee. </font></font>
</p>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<font color="#000000"><font face="Arial, serif"><b>RECITALS</b></font></font></p>
<ol type="A">
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Licensor is the
	manufacturer of the Wound Scanner and Software and developer of the
	Documents; Licensor has entered into a lease agreement with its
	distributors for the provision of Wound Scanner to End User
	Licensee; </font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">End User Licensee
	has entered into a sub-lease agreement with the Licensor’s
	distributors to lease the Wound Scanner (“Sub-lease Agreement”)
	and wishes to use the Software and the Documents for the use of the
	Wound Scanner in accordance with its intended purpose;</font></font></p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">End User Licensee
	wishes to use Wound Scanner, Software and Documents within its
	business for the purpose of provision of providing care to its
	patients and wishes to make the Software available within its
	organisation to specific selected users (Authorized Users); </font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">In relation to the
	above End User Licensee is willing to use the software and related
	services in accordance with the terms of this EULA. </font></font>
	</p>
</ol>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in; page-break-before: always">
<font color="#000000"><font face="Arial, serif">Have agreed upon the
following: </font></font>
</p>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<font color="#000000"><font face="Arial, serif"><b>1.	Definitions</b></font></font></p>
<p align="justify" style="line-height: 115%; orphans: 0; widows: 0; margin-left: 0.49in; text-indent: -0.49in; margin-bottom: 0.11in">
<font color="#000000"><font face="Arial, serif"><span lang="en-US">1.1.</span></font><span lang="en-US">	</span><font face="Arial, serif"><span lang="en-US">For
the purposes of this EULA the following definitions, where
capitalised, irrespectively of whether singular or plural, shall
apply according to the meaning here below indicated:</span></font></font></p>
<ol type="a">
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><b>Admin User</b>”
	means the person belonging to End User Licensee’s organization
	appointed by End User Licensee as <span style="background: #ffffff">responsible
	for the upkeep, configuration, and reliable operation of the
	Software and appointed as representative of End User Licensee in
	accordance with the laws of the country where End User Licensee is
	established for the purpose of accepting this EULA on behalf of End
	User Licensee. </span></font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><b>Authorized
	Device</b>” means the Wound Scanner. </font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><b>Authorized
	User”</b> means any persons belonging to the End User Licensee’s
	organization - i.e., End User Licensee’s direct employees or
	consultants who have a business relationship with End User Licensee
	– that may obtain legitimate access to and are permitted to use
	the Software on the basis of one or more personal and
	non-transferable Licences obtained by End User Licensee. </font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><b>Documents”
	</b>means all printed materials and electronic documents related to
	the Wound Scanner and the Software, including but not limited to,
	the Instructions for Use and any agreements concerning the use of
	the Wound Scanner. </font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><b>Distributors”
	</b>mean the <span style="background: #ffffff">legal persons in the
	supply chain, other than the manufacturer or the importer, that make
	the Wound Scanner available to the End User</span> Licensee<span style="background: #ffffff">.
	</span></font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><b>Effective
	Date”</b> means the date set out at the top of this EULA.</font></font></p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><b>End User</b>
	<b>Licensee” </b>means the contractual party identified in the
	EULA, to whom Licensor licenses the Software solely for its internal
	use (and not for redistribution, remarketing or timesharing) and who
	has entered into a Sub-lease Agreement with the Licensor’s
	distributors for the use of the Wound Scanner device. </font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><b>Incident”
	</b>means any malfunction or deterioration in the characteristics or
	performance of a device made available on the market, including
	use-error due to ergonomic features, as well as any inadequacy in
	the information supplied by the manufacturer and any undesirable
	side-effect.</font></font></p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><b>Instructions
	For Use”</b> <span style="background: #ffffff">means the
	information provided by the manufacturer to inform the user of a
	device's intended purpose and proper use and of any precautions to
	be taken. </span></font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><span lang="en-US"><b>Intellectual
	Property Rights”</b></span><span lang="en-US"> means patents,
	trademarks, utility models, trade secrets, trade names, service
	marks, domain names, copyrights, rights in and to databases
	(including rights to prevent the extraction or </span><span lang="en-US">reutilization</span><span lang="en-US">
	of information from a database), design rights, topography rights,
	software design, user interface (UI), UI design, branding rights
	under data exclusivity laws, extension of such rights and all rights
	or forms of protection of a similar nature or having equivalent or
	the</span>similar effect to any of them which may subsist anywhere
	in the world, whether or not any of them are registered and
	applications for and the right to apply for any of the foregoing
	registered property and rights, and similar or analogous rights
	anywhere in the world. </font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><b>Party”</b>
	means each of the parties to this EULA. </font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><b>Parties”</b>
	means both of the parties to this agreement jointly. </font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><b>Personal Data”</b>
	<span lang="nl-NL">means any information relating to an identified
	or identifiable natural person; an identifiable natural person is
	one who can be identified, directly or indirectly, in particular by
	reference to an identifier such as a name, an identification number,
	location data, an online identifier or to one or more factors
	specific to the physical, physiological, genetic, mental, economic,
	cultural or social identity of that natural person;</span></font></font></p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000">“<font face="Arial, serif"><b>Release”
	</b>means the modifications, updates and/or upgrades of the Software
	that Licensor elects to make generally available to End User
	Licensee.</font></font></p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0in">
	<font color="#000000">“<font face="Arial, serif"><b>Territory”</b>
	means the country in which the End User Licensee is established.</font></font></p>
</ol>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<font color="#000000"><font face="Arial, serif"><b>2.	GRANT AND SCOPE
OF LICENCE</b></font></font></p>
<ol start="2">
	<ol>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a944711_1"></a>
		<font color="#000000"><font face="Arial, serif">Licensor grants End
		User Licensee, for the duration of the Sub-lease Agreement for the
		Wound Scanner (except if terminated earlier in accordance with the
		terms herein), regardless of whether this agreement is concluded
		with the Licensor or with a Distributor, and for the Territory a
		non-exclusive, non-transferable, limited licence to use the
		Software and the Documents pursuant to the terms of this Licence.
		The rights granted under the EULA shall be exercised by End User
		Licensee from the Effective Date.</font></font></p>
	</ol>
</ol>
<ol>
	<ol type="a">
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">The EULA shall be
		deemed validly executed and effective between the Parties starting
		from the Effective Date. </font></font>
		</p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">End User Licensee
		and its authorized employees (Authorized Users) are allowed under
		the Terms of this License to:</font></font></p>
		<ol type="i">
			<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a298333_1"></a>
			<font color="#000000"><font face="Arial, serif">download, install
			and use the Software for their internal business purposes only in
			accordance Documents and the intended purpose of the Wound Scanner
			and to use the Software in accordance with the Instructions For
			Use for the Wound Scanner for the number agreed upon Authorized
			Users as specified in the Sub-lease Agreement</font></font></p>
			<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
			<font color="#000000"><font face="Arial, serif">receive and use
			any free supplementary software code or update of the Software
			incorporating “patches” and corrections of errors as may be
			provided by Licensor from time to time; and</font></font></p>
			<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a397482_1"></a>
			<font color="#000000"><font face="Arial, serif">use any Documents
			in support of the use permitted under this Licence as specified in
			article 2 and make copies of the Documents as are reasonably
			necessary for its lawful use.</font></font></p>
		</ol>
	</ol>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Without prejudice to
	the other articles of this EULA, if, at any time during the term of
	this EULA, the number of users of the Software is higher than the
	number of Authorized Users granted to End User Licensee in
	accordance with article 2.1(b)(i) above, Licensor shall be entitled
	to terminate this EULA with immediate notice. </font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0in">
	<font color="#000000"><font face="Arial, serif">The following are
	expressly excluded from the scope of this EULA: (i) licenses for
	basic third-party software (such as, without limitation, operating
	systems, Microsoft ® Excel ®, Adobe Reader ®, etc) and (ii)
	software provided to the End User Licensee via third parties
	(integration partners) for integration of the Software into the End
	User Licensee’s systems and / or electronic health records system
	(iii) anything not expressly listed by Licensor in this EULA. </font></font>
	</p>
</ol>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="2">
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>OWNERSHIP</b></font></font></p>
	<ol>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">End User Licensee
		agrees that the Licensor retains all rights, title and interest in
		and to the Software. </font></font>
		</p>
	</ol>
</ol>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>RESTRICTIONS</b></font></font></p>
</ol>
<ol start="4">
	<ol>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Except as expressly
		set out in this Licence, End User Licensee agrees not to: </font></font>
		</p>
	</ol>
</ol>
<ol type="a">
	<ol type="a">
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a618882_1"></a>
		<font color="#000000"><font face="Arial, serif">copy the Software
		or Documents;</font></font></p>
	</ol>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">rent, lease,
	sub-license, loan, translate, merge, adapt, vary or modify the
	Software or Documents;</font></font></p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a168255_1"></a>
	<font color="#000000"><font face="Arial, serif">make alterations to,
	or modifications of, the whole or any part of the Software, or
	permit the Software or any part of it to be combined with, or become
	incorporated in, any other programs;</font></font></p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">disassemble,
	decompile, reverse-engineer or create derivative works based on the
	whole or any part of the Software, nor attempt to do any such thing,
	except to the extent that such actions cannot be prohibited because
	they are essential for the purpose of achieving inter-operability of
	the Software with another software program, and provided that the
	information obtained by End User Licensee and/or Authorized User(s)
	during such activities is used only for the purpose of achieving
	inter-operability of the Software with another software program; and</font></font></p>
	<ol type="a">
		<ol type="i">
			<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
			<font color="#000000"><font face="Arial, serif">is not
			unnecessarily disclosed or communicated without our prior written
			consent to any third party; and</font></font></p>
			<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a146892_1"></a>
			<font color="#000000"><font face="Arial, serif">is not used to
			create any software which is substantially similar to the
			Software;</font></font></p>
		</ol>
	</ol>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">provide or otherwise
	make available the Software in whole or in part (including but not
	limited to program listings, object and source program listings,
	object code and source code), in any form to any person without
	prior written consent from us; and </font></font>
	</p>
</ol>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-left: 0.89in; margin-bottom: 0.08in">
<font color="#000000"><font face="Arial, serif">download, install or
use the Software on unauthorised devices.</font></font></p>
<ol start="4">
	<ol start="2">
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Without prejudice
		to the foregoing, End User Licensee is allowed to make a copy of
		the Documents for the purpose of contract management and, where
		strictly necessary, for patient care. End User Licensee may, via
		its Admin User download status reports such as user or device logs,
		high-level patient statistics and other relevant information
		directly concerning patient care for the End User Licensee’s care
		obligations, resource management and reporting.</font></font></p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">End User Licensee
		may use the Software only as installed on Authorized Devices. </font></font>
		</p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">End User Licensee
		shall supervise and control use of the Software by Authorized Users
		and ensure that the Software is used by Authorized Users in
		accordance with the terms of this Licence;</font></font></p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">End User Licensee
		shall comply with all applicable technology-control or export laws
		and regulations.</font></font></p>
	</ol>
</ol>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-left: 0.49in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>SUPPORT SERVICES</b></font></font></p>
	<ol>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="_Hlk74843159"></a>
		<font color="#000000"><font face="Calibri, serif"><font size="2" style="font-size: 10pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt">Licensor
		shall provide reasonably requested support services to End User
		Licensee in relation to the Software for the duration of this
		agreement via its Distributors. Support can be requested by End
		User Licensee via the Distributor’s ticketing system and is
		subject to the terms of the Sub-lease Agreement.</font></font></font></font></font></p>
	</ol>
</ol>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>PERSONAL DATA</b></font></font></p>
	<ol>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><font color="#000000">The
		use of the Software requires the processing of personal data
		concerning patients and End User Licensee’s staff as defined in
		Regulation (EU) 2016/679 - General Data Protection Regulation
		(GDPR). The processing of Personal Data shall be undertaken by
		Licensor’s affiliate KroniKare Limited, which has its registered
		offices in Watford, United Kingdom, The Wenta Business Centre,
		Watford, Hertfordshire, England, WD24 7ND, United Kingdom. The
		representative in the European Union on all issues related to the
		processing of personal data according to Art. 27 GDPR can be
		reached as follows: Kaleidoscope Data Privacy Consultants SL (KDPC
		ES), Calle Balmes 173, 4-2, Barcelona 08006, Espana
		(</font><font color="#000000"><font face="Arial, serif"><i><u><a class="western" href="mailto:dpr.kronikare@kdpc.es"><font color="#000000">dpr.kronikare@kdpc.es</font></a></u></i></font></font><font color="#000000">)
		on behalf of KroniKare Ltd. This representative acts in accordance
		with a written mandate received from KroniKare Ltd., that empowers
		the representative to cooperate with the supervisory authorities
		and to act as a contact point for data subjects.</font></font></font></p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">End User Licensee,
		in the capacity of controller under the GDPR (cf. Art. 4 (7) GDPR),
		agrees to enter into a data processing agreement according to Art.
		28 GDPR with KroniKare Limited (as the data processor, cf. Art. 4
		(8) GDPR) for the processing of Personal Data for the purpose of
		“<i>Provision of wound care by the Controller”</i>. The
		execution of the conclusion shall be carried out by the
		Distributors. According to European Commission, United Kingdom
		provides a level of protection substantially equivalent to that of
		the European Union (cf. Art. 45 GDPR), therefore transfers of
		personal data from the European Union to this third country may
		take place without further appropriate safeguards and measures.</font></font></p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="_Hlk94996667"></a><a name="_Hlk94996298"></a>
		<font color="#000000"><font face="Arial, serif">Without prejudice
		to article 6.2, KroniKare Limited shall serve as controller of all
		personal data concerning the agreements entered into with KroniKare
		Limited, generally with regards to personal data of the End User
		Licensee and for the avoidance of doubt, especially where the End
		User Licensee is an individual or sole proprietor e.g. as a
		practicing or independent physician or as an independent or
		self-employed nurse. Such controllership of Licensor shall under no
		circumstance extend to patient data provided by End User Licensee.
		A corresponding privacy notice according to Art. 13 and 14 GDPR can
		be found at the end of this EULA.</font></font></p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">The processing of
		personal data under article 6.2 for the provision of health care
		services requires storage of and access to information, including
		personal data, about the Authorized User stored on terminal
		equipment (Wound Scanner and – when available – other devices
		on which the Software can be used) and the gaining of access to the
		information for the provision of processing services subject to the
		control of End User Licensee. End User Licensee warrants that it
		will provide clear information about the purposes of the Processing
		of Personal Data and other information to the Authorized User.</font></font></p>
	</ol>
</ol>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>EULA ACCEPTANCE
	AND PROVISION OF USER ACCOUNTS</b></font></font></p>
	<ol>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Prior to the use of
		the Licence, the End User Licensee will agree to the terms of this
		Licence as documented in this EULA. </font></font>
		</p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">End User Licensee
		warrants that Admin User is allowed to represent End User Licensee
		for the entering into this End User Licence Agreement by accepting
		the terms thereof prior to the first use of the Software.</font></font></p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Admin User will
		upon first login be required to accept these EULA terms by clicking
		an Acceptance button that populates through all devices and
		provides an emailed copy to the End User Licensee. Upon acceptance
		of this EULA, all Authorized Users are allowed to use the Software
		and Wound Scanner in accordance with the terms and for the duration
		of this Licence as specified in article 13 thereof.</font></font></p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">Licensor shall
		provide End User Licensee with the user accounts for the agreed
		upon number of Authorized Users on the basis of the Sub-lease
		agreement between End User Licensee and Distributor. Additional
		accounts for Authorized Users may be requested via the Software
		dashboard. There is no limit concerning the number of Authorized
		User accounts.</font></font></p>
	</ol>
</ol>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>SOFTWARE UPDATES</b></font></font></p>
	<ol>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Calibri, serif"><font size="2" style="font-size: 10pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt">Licensor
		is entitled to provide updates to the Software at its discretion.
		Updates</font></font> <font face="Arial, serif"><font size="2" style="font-size: 11pt">shall
		be installed automatically via push updates. End User Licensee
		cannot reject or postpone software updates. </font></font></font></font></font>
		</p>
	</ol>
</ol>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0in">
<br/>

</p>
<ol start="4">
	<ol start="2">
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Calibri, serif"><font size="2" style="font-size: 10pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt">Without
		prejudice to the foregoing, End User Licensee will have no right to
		be provided with updates as described in article 8.1 nor will End
		User Licensee have the right to request specific or general
		updates. </font></font></font></font></font>
		</p>
	</ol>
</ol>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>INTELLECTUAL
	PROPERTY RIGHTS AND INDEMNIFICATION</b></font></font></p>
	<ol>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a409880_1"></a>
		<font color="#000000"><font face="Arial, serif">End User Licensee
		acknowledges and agrees that all industrial rights and Intellectual
		Property Rights (including the exclusive rights of economic
		exploitation) in the Software and the Documents anywhere in the
		world are and shall remain the exclusive property of Licensor. No
		provision contained in the EULA is aimed at or shall be construed
		in order to transfer any of such rights to End User Licensee or any
		third party. Without prejudice to the right to use the Software
		expressly granted in the EULA, End User Licensee shall not claim
		any rights, including copy rights in the Software and in any of its
		components or any other industrial or intellectual property right
		related to the same. End User Licensee expressly agrees to adopt
		all adequate measures to protect the content of the Software and to
		prevent its non-authorized disclosure of use, including by
		Authorized Users. End User Licensee acknowledges that Licensor
		shall have the right to take direct and immediate action in case of
		any breach of its rights in the Software made by, or permitted by,
		the End User Licensee and its Authorized Users, and shall be
		entitled to enforce such rights by choosing the most appropriate
		manners and means. </font></font>
		</p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a352428_1"></a>
		<font color="#000000"><font face="Arial, serif">End User Licensee
		agrees that the rights in the Software are licensed (not sold) to
		End User Licensee, and that End User Licensee and its Authorized
		User(s) have no rights in, or to, the Software or the Documents
		other than the right to use them in accordance with the terms of
		this Licence.</font></font></p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">For
		the avoidance of doubt, all ideas, inventions, improvements,
		methods, processes, works of authorship and other forms of
		intellectual property that End User </span>Licensee <span lang="en-US">conceives,
		reduces to practice or develops, alone or in conjunction with
		others, in connection with or based upon the knowledge or
		information learned or gained from the Software and Documents,
		shall be the sole and exclusive property of Licensor or a third
		party appointed by Licensor or jointly by Licensor and a third
		party appointed by Licensor. End User </span>Licensee <span lang="en-US">hereby
		agree to assign all such intellectual property to Licensor and/or a
		third party appointed by Licensor and waive any other right that
		the End User Licensee may have thereto. End User </span>Licensee
		<span lang="en-US">agrees not, during or at any time after the
		termination of this agreement, in any way to question or dispute
		the ownership of the intellectual property.</span> </font></font>
		</p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">The End User
		Licensee acknowledges that neither the End User Licensee nor its
		Authorized User(s) has any right whatsoever to have access to the
		Software in source code form.</font></font></p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Licensor represents
		and warrants that, to its knowledge, there are no claims being
		asserted and no actions pending or threatened against Licensor that
		the Software infringes third party Intellectual Property Rights. If
		End User Licensee becomes aware of any claims or (threats of)
		actions against Licensor concerning third party Intellectual
		Property Rights, End User Licensee will inform Licensor thereof
		without delay. </font></font>
		</p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">In case of any
		claim by a third party against End User Licensee on the basis that
		the Software infringes a third party’s Intellectual Property
		Rights, End User Licensee shall notify Licensor thereof without
		delay and shall give Licensor sole control or the defence and/or
		settlement of the claim and shall fully cooperate with Licensor in
		the defence or settlement of the claim. End User Licensee shall
		take no action that may limit Licensor’s ability to defend the
		claim. Licensor shall give End User Licensee the opportunity to
		monitor the proceeding. Where End User Licensee is of the opinion
		that an intervention is required to avoid any disadvantage to the
		End User Licensee, End User Licensee shall inform Licensor thereof
		and the Parties shall review the appropriate course of action.
		Notwithstanding the foregoing, Licensor shall have the final
		decision in relation to any action to undertake in relation to a
		(suspected) violation of Intellectual Property Rights. </font></font>
		</p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">Without
		prejudice to the foregoing, If all or any part of the Software is,
		or in the opinion of Licensor, is likely to become the subject of a
		claim of infringement, Licensor at its own expense and at its sole
		discretion and without further liability to the End User </span><span lang="en-US">Licensee
		</span><span lang="en-US">may procure any of the following remedies
		that is practicable: (i) procure for End User </span><span lang="en-US">Licensee
		</span><span lang="en-US">the right to use the Software; (ii)
		replace the Software with non-infringing software that is at least
		functionally equivalent (iii) modify the Software to make it
		non-infringing.</span></font></font></p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">Licensor
		shall not be required to indemnify and shall have no liability or
		other obligations in respect of claims based on: (i) combination or
		use of Software with other software not approved and/or provided by
		Licensor (ii) End User Licensee’s or Authorized User’s use of
		Software in any manner inconsistent with the EULA and/or the
		Software description; or (iii) failure to use a Release provided by
		Licensor, if infringement could have been avoided by use of the
		Release or latest available version of the Software.</span></font></font></p>
	</ol>
</ol>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a683549_1"></a>
	<font color="#000000"><font face="Arial, serif"><b>WARRANTY</b></font></font></p>
</ol>
<ol>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Warranty conditions
	will be covered via the Sub-lease agreement. </font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Licensor shall not
	be liable for functioning limitations or defects of the Software, if
	the same have been caused in whole or in part by any unauthorized
	behaviour carried out or permitted by End User Licensee and or its
	Authorized User(s), any use of the Software which is inconsistent
	with the intended purpose of the Software or the Instructions For
	Use, any alteration or non-functioning of the Software derived from
	software or other products not supplied by Licensor. If the
	malfunctioning of the Software derives from any error, End User
	Licensee shall immediately notify such effect to Licensor. </font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0in">
	<font color="#000000"><font face="Arial, serif">Licensor shall not
	be liable for any functioning limitations, unavailability of the
	Software, or failures to fulfil its obligations under this agreement
	caused by End User Licensee’s internet, IT-systems, or by End User
	Licensee’s systems. </font></font>
	</p>
</ol>
<p lang="en-GB" align="justify" style="line-height: 115%; text-indent: -0.5in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a914493_1"></a><a name="co_anchor_a676389_1"></a><a name="co_anchor_a796999_1"></a><a name="co_anchor_a946537_1"></a>
	<font color="#000000"><font face="Arial, serif"><b>LIMITATION OF
	LIABILITY</b></font></font></p>
</ol>
<ol>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Without prejudice to
	article 10 above, Licensor delivers the Software to End User
	Licensee ‘as is’ and does not grant End User Licensee any
	warranty, expressed or implied, concerning its fitness for a
	particular purpose, including without limitation, warranty
	concerning its fitness for a particular purpose other than what is
	expressly set forth in this EULA or the Instructions for Use. End
	User Licensee is responsible for ensuring that the facilities and
	functions of the Software as described in the Documents meet its
	requirements.</font></font></p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">It is understood
	that, to the extent permitted by any applicable law, Licensor shall
	not be liable for any damages arising from (i) any use of the
	Software not in compliance with the EULA and the Instructions For
	Use; any malfunctioning of the Software derived from the behaviours
	described under (i); (iii) any unclarity or inaccuracy of the
	Software or the Documents; (iv) errors in the Software or documents;
	(v) corruption of data or information), except where otherwise
	agreed between the Parties. </font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; orphans: 0; widows: 0; margin-right: 0.05in; margin-bottom: 0.14in">
	<font color="#000000"><font face="Arial, serif">Except as set out in
	this EULA<i>,</i> Licensor shall not in any circumstances whatsoever
	be liable to End User Licensee for any direct or indirect loss or
	damages, including without limitation loss of profits, loss of
	goodwill, loss of reputation, loss of business, loss of business
	opportunities, loss of anticipated saving(s), loss suffered in
	connection with corruption of data or information or any other
	special, indirect, remote or consequential damage arising under, or
	in connection with this Licence, whether in contract, tort
	(including negligence), breach of statutory duty, or otherwise. </font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Notwithstanding the
	above, Licensor shall be liable without limitation for any damage
	caused by the wilful misconduct or gross negligence by Licensor.</font></font></p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a879858_1"></a><a name="co_anchor_a570100_1"></a><a name="co_anchor_a1003989_1"></a>
	<font color="#000000"><font face="Arial, serif">End User Licensee
	accepts and agrees that to the extent any claim, exists or arises in
	relation to provision of the Software or the Documents, such claim
	may only be brought against the entity to whom End User Licensee
	paid the purchase of the Software in accordance with the terms of
	the agreement between End User Licensee and that entity. </font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a104167_1"></a>
	<font color="#000000"><font face="Arial, serif">Nothing in this
	Licence shall limit or exclude Licensor’s liability for:</font></font></p>
	<ol type="a">
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">death or personal
		injury resulting from our negligence;</font></font></p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a437879_1"></a>
		<font color="#000000"><font face="Arial, serif">fraud or fraudulent
		misrepresentation.</font></font></p>
	</ol>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0in">
	<font color="#000000"><font face="Arial, serif">This Licence sets
	out the full extent of Licensor’s obligations and liabilities in
	respect of the supply of the Software and Documents. Except as
	expressly stated in this Licence, there are no articles, warranties,
	representations or other terms, express or implied, that are binding
	on the Licensor. Any article, warranty, representation or other term
	concerning the supply of the Software and Documents which might
	otherwise be implied into, or incorporated in, this Licence whether
	by statute, common law or otherwise, is excluded to the fullest
	extent permitted by law.</font></font></p>
</ol>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>CONFIDENTIALITY</b></font></font></p>
</ol>
<ol>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">During
	the term of the EULA, Licensor and End User </span>Licensee <span lang="en-US">will
	exchange confidential information with each other. Each party
	undertakes not to disclose such information to any third party
	without the prior express consent of the other party. Any
	confidential information shall be identified as confidential on the
	relevant written documents or, if the information is disclosed
	verbally, it shall be expressly identified as confidential. The
	following shall not be deemed as confidential information if, at the
	disclosing date, the information is: (i) in the public domain (ii)
	already known by the non-disclosing party or (iii) becomes known to
	End User </span>Licensee <span lang="en-US">at a later date other
	than as a result of this </span>article<span lang="en-US">. End User
	</span>Licensee <span lang="en-US">shall protect confidential
	information in accordance with best current commercial security
	practices. </span></font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">Both
	Parties shall not, without the prior written consent of the other
	Party, divulge any part of Confidential Information to any person
	without the other Party’s consent except:</span></font></font></p>
	<ol type="a">
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">to
		their own employees and consultants, then only to those employees
		and consultants who have a need to know;</span></font></font></p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><span lang="en-US">to
		a court of competent jurisdiction, governmental body or applicable
		regulatory authority and any other persons or bodies having a
		right, duty or obligation to know the business of the other Party
		and then only in pursuance of such right duty or obligation.</span></font></font></p>
	</ol>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">The
	obligations set forth herein with respect to confidential
	information shall continue in full force and effect for a period of
	ten (10) years after the date of disclosure of confidential
	information. </span></font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">Without
	prejudice to the foregoing, the Parties agree that in relation to
	the processing of personal data subject to the GDPR, the
	confidentiality </span>articles<span lang="en-US"> laid down in the
	data processing agreement between Licensor and End User </span>Licensee
	<span lang="en-US">shall apply instead. </span><span lang="en-MY">This
	article 12 shall therefore not apply to confidentiality of personal
	data, which shall be arranged for separately in the data processing
	agreement between the Parties, nor to any data anonymized in
	accordance with the data processing agreement between the Parties. </span></font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="_Toc520913491"></a>
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">Each
	Party to this Agreement shall promptly notify the other Party if it
	becomes aware of any breach of confidentiality by any person to whom
	it divulges all or any part of the Information and shall give the
	other Party all reasonable assistance in connection with any
	proceedings which the other Party may institute against such person
	for breach of confidence.</span></font></font></p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-US">The
	rights and obligations under this article shall survive termination
	of this Agreement </span><span lang="en-MY">without limit in point
	of time but shall cease to apply to information or knowledge that
	may come into the public domain. </span></font></font>
	</p>
</ol>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a534011_1"></a>
<br/>
<br/>

</p>
<ol start="4">
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>TERMINATION</b></font></font></p>
</ol>
<ol>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">This licence shall
	commence upon acceptance of the licence terms and shall remain in
	force until termination of the Sub-lease Agreement between the End
	User Licensee and the Distributor that allows End User Licensee to
	use the Wound Scanner, regardless of whether the End User Licensee
	has entered into such agreement with Licensor or with a Distributor.
	End User Licensee is obliged to inform Licensor of the termination
	or expiration of the lease or service agreement that allows End User
	Licensee to use the Wound Scanner. </font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Licensor may
	terminate this Licence with immediate effect by written notice to
	End User Licensee if End User Licensee:</font></font></p>
	<ol type="a">
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">commits a material
		or persistent breach of the terms of this Licence which is either
		incapable of remedy or which End User Licensee fails to remedy
		within fourteen (14) days of a notice in writing specifying the
		breach and requiring such breach to be remedied; </font></font>
		</p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">is unable to pay
		its outstanding debts; or become insolvent; or are subject to an
		order or a resolution for its liquidation, administration, winding
		up or dissolution (otherwise than for the purposes of a solvent
		amalgamation or reconstruction); </font></font>
		</p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">has an
		administrative or other receiver, manager, trustee, liquidator,
		administrator or similar officer appointed over all or any
		substantial part of End User Licensee’s assets; or enters into or
		proposes any composition or arrangement with its creditors
		generally; </font></font>
		</p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">ceases or threatens
		to cease business; </font></font>
		</p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">is subject to any
		analogous event or proceeding in any jurisdiction. </font></font>
		</p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">transfers or
		assigns to any third party without Licensors prior written explicit
		consent of any physical medium incorporating the software and/or
		any copy thereof (including any back-up copy permitted by national
		law).  </font></font>
		</p>
	</ol>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">All contractual
	provisions of the EULA whose effectiveness is not prejudiced by the
	termination or expiration of this EULA shall remain in full force
	between the Parties after the termination or expiration of the EULA.
	</font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif">Following
	termination or expiration of the EULA for any reason:</font></font></p>
	<ol type="a">
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">All rights granted
		to End User Licensee under this Licence shall cease;</font></font></p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">End User Licensee
		shall immediately cease all activities authorised by this Licence; </font></font>
		</p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="_Hlk95062074"></a><a name="co_anchor_a666232_1"></a>
		<font color="#000000"><font face="Arial, serif">End User Licensee
		undertakes to immediately and permanently delete or remove the
		Software from all computer equipment in its possession, and
		immediately destroy or return to Licensor (at his option) all
		copies of the Software and Documents then in End User Licensee’s
		possession, custody or control and, in the case of destruction, End
		User Licensee shall certify to Licensor that the Software and
		Documents have been destroyed.</font></font></p>
	</ol>
</ol>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-left: 0.75in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>COMMUNICATIONS
	BETWEEN THE PARTIES</b></font></font></p>
	<ol>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Licensor may update
		the terms of this Licence at any time on notice to End User
		Licensee in accordance with this article 14. End User Licensee’s
		continued use of the Software and Documents following 30 days after
		the issuance of notice as described in this article 14 shall
		constitute the End User Licensee’s acceptance to the terms of
		this Licence and any updated terms thereof. If End User Licensee
		does not wish to accept the terms of the Licence (as varied), End
		User Licensee will immediately stop the use of and access to the
		Software and Documents by End User Licensee and/or its Authorized
		User(s) on the receipt and service of the notice and shall inform
		Licensor thereof via the contact details made available to End User
		Licensee via the Software’s dashboard.</font></font></p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in"><a name="co_anchor_a510664_1"></a>
		<font color="#000000"><font face="Arial, serif">Licensor shall only
		contact End User Licensee via the contact details provided by the
		End User Licensee prior to acceptance of the EULA. </font></font>
		</p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Any notice:</font></font></p>
	</ol>
</ol>
<ol>
	<ol type="a">
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">given by Licensor
		to End User Licensee will be deemed received and properly served 24
		hours after it is first posted on Licensor’s website and
		dashboard, 24 hours after an email is sent, or three days after the
		date of posting of any letter; and</font></font></p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">given by End User
		Licensee to Licensor will be deemed received and properly served 24
		hours after an email is sent, or three days after the date of
		posting of any letter.</font></font></p>
	</ol>
</ol>
<ol start="4">
	<ol start="4">
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">In proving the
		service of any notice, it will be sufficient to prove: In the case
		of a letter, that such letter was properly addressed, stamped and
		placed in the post to the address of the recipient given for these
		purposes; In case of provision to the End User Licensee via the
		dashboard of the Software, two days after provision of the notice;
		and, in the case of an email, that such email was sent to the email
		address of the recipient given for these purposes.</font></font></p>
	</ol>
</ol>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><b>FORCE MAJEURE</b></font></font></p>
	<ol>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">In the event that
		Licensor shall be delayed or hindered in or prevented from the
		performance of any act, including but not limited the provision of
		the software required hereunder by reasons of strike, lockouts,
		labour troubles, terrorism, inability to procure materials or
		services, failure of power or restrictive government or judicial
		orders, or decrees, riots, insurrection, war, Acts of God,
		earthquakes, or any other reason or cause beyond that Party’s
		control, regardless whether such reason or cause may be currently
		foreseen (such as a new Covid or other pandemic lockdown) then
		performance of such act shall be excused for the period of the
		delay, provided that the Licensor uses reasonable efforts to remedy
		such delay, hindrance or prevention<font size="2" style="font-size: 9pt"><span style="background: #ffffff">.
		</span></font>Licensor shall not be liable or responsible for any
		failure to perform, or delay in performance of, any of our
		obligations under this Licence subject to force majeure as
		described in this article 15.1.</font></font></p>
	</ol>
</ol>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"> <font face="Arial, serif"><b>	</b><b>MEDICAL
	DEVICE </b></font></font>
	</p>
	<ol>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Licensor will
		identify in good faith and in accordance to local regulations those
		Products that qualify as a medical device under the Medical Devices
		Regulation (Regulation (EU) 2017/745). The Software is a medical
		device under the Medical Devices Regulation.</font></font></p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">End User Licensee
		is responsible for monitoring that the Software is correctly used
		in conformity with local laws and regulations. </font></font>
		</p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">Whenever End User
		Licensee becomes aware of quality problems, Incidents or
		information that reasonably suggests that the Software or the
		Documents are not compliant with the EU Medical Devices Regulation
		or national medical devices legislation, End User Licensee shall
		provide Licensor with any information and access to the device
		concerned needed for the purpose of determining the problem and
		will not use the<b> </b>Software until Licensor provides clearance.
		End User Licensee shall provide a first notification of such
		quality problem, Incident or information concerning compliance with
		applicable medical devices legislation within 24 hours of becoming
		aware thereof and will follow-up with any information that was not
		yet available at the moment of the initial notification without
		undue delay. </font></font>
		</p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">End User Licensee
		shall forward such information to Licensor without delay and in no
		event later than 24 hours after becoming aware of such information.</font></font></p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">If Licensor
		notifies End User Licensee that a field safety corrective action or
		Product recall is required, End User Licensee shall expediently
		confirm receipt thereof and cooperate with Licensor at its own
		costs to implement the corrective actions. Under no circumstance
		shall End User Licensee implement any corrective action without
		explicit consent from Licensor. </font></font>
		</p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">End User Licensee
		undertakes to notify Licensor of and assist Licensor with any
		government or third-party action regarding the Software as soon as
		End User Licensee becomes aware of such action.</font></font></p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">If End User
		Licensee has reason to believe that the Software may present a
		serious risk or may be falsified, it shall not inform any competent
		authority before having informed Licensor and having agreed with
		Licensor on the specifics of information to be communicated to the
		competent authority. </font></font>
		</p>
	</ol>
</ol>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"> <font face="Arial, serif"><b>	MISCELLANEOUS</b></font></font></p>
	<ol>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><u>Transfer </u><u>of
		rights by Licensor.</u> End User Licensee hereby provides
		permission in advance for Licensor to transfer its rights and
		obligations under this Agreement to a third party. </font></font>
		</p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif"><u>Transfer of
		rights by End User Licensee.</u> End User Licensee may only
		transfer its rights and obligations under this Licence to another
		person upon receipt of prior written explicit consent by Licensor.</font></font></p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">This Licence and
		any document expressly referred to in it constitutes the entire
		agreement between the Parties and supersedes and extinguishes all
		previous agreements, promises, assurances, warranties,
		representations and understandings between us, whether written or
		oral, relating to its subject matter.</font></font></p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
		<font color="#000000"><font face="Arial, serif">If Licensor fails
		to insist that End User Licensee performs any of its obligations
		under this Licence, or if Licensor does not enforce Licensor’s
		rights against End User Licensee or is delayed doing so, that will
		not mean that Licensor has waived its rights against End User
		Licensee and will not mean that End User Licensee does not have to
		comply with those obligations. Any waiver of default by End User
		Licensee, will be given in writing. Licensor is not obliged to
		waive any default by End User Licensee.</font></font></p>
		<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0in">
		<font color="#000000"><font face="Arial, serif">Each of the
		articles of this Licence operates separately. If any court or
		competent authority decides that any of them are unlawful or
		unenforceable, the remaining articles will remain in full force and
		effect<b>.</b></font></font></p>
	</ol>
</ol>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
<br/>
<br/>

</p>
<ol start="4">
	<li><p lang="en-GB" align="justify" style="line-height: 115%; margin-bottom: 0.08in">
	<font color="#000000"> <font face="Arial, serif"><b>GOVERNING LAW
	AND DISPUTES</b></font></font></p>
</ol>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-left: 0.49in; text-indent: -0.49in; margin-bottom: 0.08in">
<font color="#000000"><font face="Arial, serif">18.1 	This Licence,
its subject matter and its formation are governed by the laws of
Germany to the exclusion of the conflict of laws, rules and the UN
Convention on Agreements for the International Sale of Deliverables
dated 11 April 1980. </font></font>
</p>
<p lang="en-GB" align="justify" style="line-height: 115%; margin-left: 0.49in; text-indent: -0.49in; margin-bottom: 0.08in"><a name="kh_relatedContentOffset_1"></a><a name="co_anchor_a840535_1"></a>
<font color="#000000"><font face="Arial, serif">18.2	Any disputes in
relation to this EULA and the Licence shall be subject to the
exclusive jurisdiction of the registered office of the defendant.</font></font></p>
<p lang="en-GB" align="center" style="line-height: 150%; margin-bottom: 0.08in; page-break-before: always">
<font color="#000000"><font face="Arial, serif"><b>Privacy Notice</b></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.11in"><font color="#000000"><font face="Arial, serif"><span lang="en-CA">Welcome
to the </span></font><font face="Arial, serif"><span lang="en-US">Vintens™
Digital Wound Management Device and Software</span></font><font face="Arial, serif"><span lang="en-CA">.
We (</span></font><font face="Arial, serif"><span lang="en-US">KroniKare
Ltd) </span></font><font face="Arial, serif"><span lang="en-CA">want
to inform you that we shall serve as controller of all personal data
concerning the agreements entered into with KroniKare Ltd, generally
with regards to personal data of the End User Licensee and for the
avoidance of doubt, especially where the End User Licensee is an
individual or sole proprietor e.g., as a practicing or independent
physician or as an independent or self-employed nurse.</span></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.14in"><font color="#000000"><font face="Arial, serif"><span lang="en-CA">Therefore,
before you start using the Device and Software, we would like to
remind you (by providing this privacy notice) that we collect certain
limited personal data about you (the “Personal Data”). We would
ask you to read this privacy notice carefully and confirm you have
read it. </span></font></font>
</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.11in"><font color="#000000"><font face="Arial, serif"><span lang="en-CA"><b>1)
Who is responsible for the processing of Personal Data,
representative in the EU?</b></span></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.11in"><font color="#000000"><font face="Arial, serif"><span lang="en-CA">KroniKare
Ltd, The Wenta Business Centre, Watford, Hertfordshire, England, WD24
7ND, United Kingdom, is responsible for the processing of Personal
Data.</span></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.11in"><font color="#000000"><font face="Arial, serif"><span lang="en-CA">The
representative in the European Union on all issues related to the
processing of personal data according to Art. 27 GDPR can be reached
as follows: Kaleidoscope Data Privacy Consultants SL (KDPC ES), Calle
Balmes 173, 4-2, Barcelona 08006, Espana (<a class="western" href="mailto:dpr.kronikare@kdpc.es">dpr.kronikare@kdpc.es</a>)
on behalf of KroniKare Ltd.</span></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.11in"><font color="#000000"><font face="Arial, serif"><span lang="en-CA">This
representative acts in accordance with a written mandate received
from KroniKare Ltd., that empowers the representative to cooperate
with the supervisory authorities and to act as a contact point for
data subjects.</span></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.11in"><font color="#000000"><font face="Arial, serif"><span lang="en-CA">For
further contact details please see 8) below.</span></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.11in"><font color="#000000"><font face="Arial, serif"><span lang="en-CA"><b>2)
What kind of personal data is being processed and what is the purpose
and legal basis?</b></span></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.11in"><font color="#000000"><font face="Arial, serif"><span lang="en-CA">We
collect in particular the following basic personal data as well as
relevant usage data about you: Username, Email Address, Phone Number,
First Name, Middle Name, Last Name, Gender, Location, Title,
Category, Role, Job Description, Log-in Data (Log Files) and IP
Address.  The purpose of the data processing is to fulfill the
agreement, especially to enable registration, log-in or
administration and use of the </span></font><font face="Arial, serif"><span lang="en-US">Digital
Wound Management Device and Software based on Art. 6 (1) b GDPR.</span></font><font face="Arial, serif"><span lang="en-CA">
 </span></font></font>
</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.11in"><font color="#000000"><font face="Arial, serif"><span lang="en-CA"><b>3)
Who receives my personal data?</b></span></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.14in"><font color="#000000"><font face="Arial, serif"><span lang="en-CA">As
a rule, we only process your personal data internally. If we transfer
your personal data to other persons, companies or other third parties
or grant them other access to the personal data, this will only be
done on the basis of legal permission. If we commission third parties
with the processing of personal data on the basis of a Data
Processing Agreement, this is done on the basis of Art. 28 GDPR.
However, we remain responsible to you for the lawfulness of the data
processing. </span></font></font>
</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.11in"><font color="#000000"><font face="Arial, serif"><span lang="en-CA"><b>4)
Where and how long will my personal data be processed or stored?</b></span></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.14in"><font color="#000000"><font face="Arial, serif"><span lang="en-CA">As
far as possible, we will carry out the processing of your personal
data only within the European Economic Area. However, if the
processing of your personal data in third countries is necessary, we
will ensure that the specific legal requirements for such processing
operations are met and that an adequate level of data protection
prevails in the respective third country. </span></font></font>
</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.11in"><font color="#000000"><font face="Arial, serif"><span lang="en-CA">Therefore,
</span></font><font face="Arial, serif"><span lang="en-CA">we will
ensure the existence of appropriate </span></font><font face="Arial, serif"><span lang="en-CA">safeguards
and measures in accordance with Art. 46 GDPR (if no adequacy decision
has been adopted), in particular we may apply the standard
contractual clauses (SCC). </span></font><font face="Arial, serif"><span lang="en-GB">As
KroniKare Ltd. is established in the United Kingdom and according to
the European Commission the level of protection is substantially
equivalent in the United Kingdom to that of the European Union (cf.
Art. 45 GDPR), transfers of personal data from the </span></font><font face="Arial, serif"><span lang="en-CA">European
Union </span></font><font face="Arial, serif"><span lang="en-GB">to
this third country could take place without further </span></font><font face="Arial, serif"><span lang="en-CA">appropriate
</span></font><font face="Arial, serif"><span lang="en-CA">safeguards
and measures</span></font><font face="Arial, serif"><span lang="en-US">.</span></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.11in"><font color="#000000"><font face="Arial, serif"><span lang="en-CA">We
store your personal data in principle for the duration of the
contractual relationship. The above information on deletion does not
apply if, among other things, legally prescribed retention periods
prevent immediate deletion (cf. Art. 17 (3) GDPR) or if a new purpose
justifies further processing.</span></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.11in"><font color="#000000"><font face="Arial, serif"><span lang="en-CA"><b>5)
What data subjects’ rights do I have?</b></span></font></font></p>
<ul>
	<li><p lang="en-GB" align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-CA">In
	accordance with Art. 15 GDPR, you can request information about your
	personal data processed by us.</span></font></font></p>
	<li><p lang="en-GB" align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-CA">In
	accordance with Art. 16 GDPR, you can demand the immediate
	correction of incorrect or incomplete personal data stored by us.</span></font></font></p>
	<li><p lang="en-GB" align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-CA">In
	accordance with Art. 17 GDPR, you can request the deletion of your
	personal data stored by us in accordance with the conditions stated
	therein, unless legally prescribed retention periods prevent
	immediate deletion (see Art. 17 (3) GDPR) and/or another case of
	Art. 17 (3) GDPR exists and/or a new purpose justifies further
	processing.</span></font></font></p>
	<li><p lang="en-GB" align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-CA">Pursuant
	to Art. 18 (1) GDPR, you may request the restriction of data
	processing if one or more conditions pursuant to Art. 18 (1) GDPR
	lit. a) to d) are met.</span></font></font></p>
	<li><p lang="en-GB" align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-CA">In
	accordance with Art. 20 (1) GDPR, you can receive the personal data
	processed by us in a structured, common and machine-readable format
	and transfer this personal data to another person responsible
	without hindrance from us.</span></font></font></p>
	<li><p lang="en-GB" align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-CA">In
	addition, you can object to the processing of your personal data in
	accordance with Art. 21 (1) GDPR. In the event of an objection, we
	will terminate the processing of your personal data. However, the
	right of objection only applies in the event of special
	circumstances arising from your personal situation. In addition,
	compelling reasons worthy of protection that speak in favour of
	processing may prevail. Furthermore, certain processing purposes may
	conflict with your right of objection. </span></font></font>
	</p>
	<li><p lang="en-GB" align="justify" style="line-height: 150%; orphans: 0; widows: 0; margin-bottom: 0.08in">
	<font color="#000000"><font face="Arial, serif"><span lang="en-CA">Without
	prejudice to any other administrative or judicial remedy, you also
	have the right to appeal to the competent supervisory authority (see
	Art. 77 GDPR) if you believe that the processing of your personal
	data violates data protection provisions. In this context, however,
	we would ask you to address any complaints first to the contact
	details given under 8) below. </span></font></font>
	</p>
</ul>
<p lang="en-GB" align="justify" style="line-height: 150%; margin-left: 0.2in; margin-bottom: 0in">
<br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.11in"><font color="#000000"><font face="Arial, serif"><span lang="en-CA"><b>6)
Am I obliged to provide my personal data?</b></span></font><font face="Arial, serif"><span lang="en-CA">
</span></font></font>
</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.11in"><font color="#000000"><font face="Arial, serif"><span lang="en-CA">You
are only obliged to provide us with the personal data that is
necessary for the initiation, execution and termination of a
contractual relationship or that we generally require for the
execution of our services. Without this personal data, we will
generally not be able to conclude and carry out the contractual
relationship with you or provide our services to you. </span></font></font>
</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.11in"><font color="#000000"><font face="Arial, serif"><span lang="en-CA"><b>7)
Do we use automated decision making?</b></span></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.11in"><font color="#000000"><font face="Arial, serif"><span lang="en-CA">We
do not use purely automated decision-making procedures in accordance
with Art. 22 GDPR. Should we nevertheless use such a procedure in
individual cases in the future, we will inform you of this separately
if this is required by law.</span></font></font></p>
<p lang="en-GB" align="justify" style="line-height: 150%; margin-bottom: 0.11in">
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><span lang="en-CA"><b>8)
Do you have any further questions?</b></span></font></font></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.14in"><font color="#000000"><font face="Arial, serif"><span lang="en-CA">Please
contact us to find out more about data protection </span></font><font face="Arial, serif"><span lang="en-CA">or
with any further questions in particular with regard to the execution
of your data subject rights </span></font><font face="Arial, serif"><span lang="en-CA">at
KroniKare Ltd. DPO for the United Kingdom (dpo.kronikare@kdpc.uk) and
representative in the European Union (dpr.kronikare@kdpc.es).</span></font><span lang="en-US">
</span></font>
</p>
</body>
</html>`,
}

export default { ...main }
