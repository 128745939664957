//basic
const main = {
  ErrorMessages_400_BadRequest_text: 'Something went wrong... Bad Request!',
  ErrorMessages_401_Unauthorised_text: 'Something went wrong... Unauthorised. Please login.',
  ErrorMessages_401_LoginInvalidCredentials_text: 'Wrong Username/Password! Please try again.',
  ErrorMessages_403_Forbidden_text: 'You do not have access to the requested resource!',
  ErrorMessages_404_NotFound_text: 'Something went wrong... Resource is not found!',
  ErrorMessages_405_MethodNotAllowed_text: 'Something went wrong... Method not Allowed!',
  ErrorMessages_406_NotAcceptable_text: 'Something went wrong... Content not found!',
  ErrorMessages_408_RequestTimeout_text: 'Something went wrong... Request Timeout',
  ErrorMessages_500_InternalServerError_text:
    'Something went wrong... Server Error, please contact administrator.',
  ErrorMessages_501_NotImplemented_text: 'Something went wrong... Method not supported.',
  ErrorMessages_502_BadGateway_text: 'Something went wrong... Bad Gateway.',
  ErrorMessages_503_ServiceUnavailable_text: 'Something went wrong... Service Unavailable',
  ErrorMessages_504_ServiceUnavailable_text: 'Something went wrong... Gateway Timeout',
  ErrorMessages_GeneralErrorMessage_text: 'Something went wrong...',
  ErrorMessages_409_Username_already_exists_text: 'A similar user already exists please change it.',

  SuccessMessages_ForgetPasswordLink_text: `
The verification code has been sent to the user's registered email address.`,
  SuccessMessages_PasswordSetSuccess_text: 'Password has been successfully set.',
  SuccessMessages_PasswordChangeSuccess_text: 'Password has been successfully changed.',
  SuccessMessages_UsernamePasswordSetSuccess_text:
    'Username and Password has been successfully set.',
  SuccessMessages_PINSetSuccess_text: 'PIN has been successfully set.',
  SuccessMessages_TOTPSetSuccess_text: 'TOTP registration is successful.',
}

export default {
  ...main,
}
