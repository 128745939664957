//basic
const main = {
    Login_titleLogin_display: true,
    Login_titleLogin_text: 'Login',
    Login_labelUsername_display: true,
    Login_labelUsername_text: 'Username',
    Login_labelPassword_display: true,
    Login_labelPassword_text: 'Password',
    Login_labelLoginButton_display: true,
    Login_labelLoginButton_text: 'Login',
    Login_labelForgotPasswordLink_display: true,
    Login_labelForgotPasswordLink_text: 'Forgot Password?',
    Login_validationMessageUsernameRequired_display: true,
    Login_validationMessageUsernameRequired_text: 'Username is required',
    Login_validationMessagePasswordRequired_display: true,
    Login_validationMessagePasswordRequired_text: 'Password is required',
    Login_errorMessageIncorrectCredentials_display: true,
    Login_errorMessageIncorrectCredentials_text: 'Wrong Username/Password! Please try again.',
    Login_errorMessageNetworkIssues_display: true,
    Login_errorMessageNetworkIssues_text: 'Please check your network!',
    Login_errorMessageServerIssues_display: true,
    Login_errorMessageServerIssues_text: 'Unable to get a response! Please try again.',
}

export default {
    ...main
}
