//ES
const main = {
  ValidationMessages_UsernameRequired_display: true,
  ValidationMessages_UsernameRequired_text: 'Se requiere el nombre de usuario',
  ValidationMessages_PreferredUsernameRequired_display: true,
  ValidationMessages_PreferredUsernameRequired_text: 'Se requiere el nombre de usuario preferido',
  ValidationMessages_PasswordRequired_display: true,
  ValidationMessages_PasswordRequired_text: 'Se requiere una contraseña',
  ValidationMessages_CurrentPasswordRequired_display: true,
  ValidationMessages_CurrentPasswordRequired_text: 'Se requiere una contraseña actual',
  ValidationMessages_NewPasswordRequired_display: true,
  ValidationMessages_NewPasswordRequired_text: 'Se requiere una nueva contraseña',
  ValidationMessages_ConfirmNewPasswordRequired_display: true,
  ValidationMessages_ConfirmNewPasswordRequired_text: 'Es necesario confirmar la nueva contraseña',
  ValidationMessages_PasswordDoNotMatch_display: true,
  ValidationMessages_PasswordDoNotMatch_text:
    'Las contraseñas no coinciden. Introduzca contraseñas que coincidan.',
  ValidationMessages_PasswordCriteria_display: true,
  ValidationMessages_PasswordCriteria_text:
    "Debe contener al menos 8 caracteres (se recomiendan más de 12) que incluyan una letra mayúscula, una letra minúscula, un número y un carácter especial (!”#$%&'()*+,-./:;<=>?",
  ValidationMessages_verificationCodeRequired_display: true,
  ValidationMessages_verificationCodeRequired_text: 'Se requiere código de verificación',
}

export default { ...main }
