import customizations from '../customizations'

const customization = (organizationListAcronym) => {
  // Catch-all customization to prevent missing values
  let c = { ...customizations.basic }

  if (organizationListAcronym) {
    // Use exisiting institute customization if available
    if (
      customizations[organizationListAcronym] &&
      Object.keys(customizations[organizationListAcronym]).length > 0
    ) {
      c = { ...c, ...customizations[organizationListAcronym] }
    } else {
      c = { ...c, ...customizations.basic }
    }
  }

  return c
}

export default customization
