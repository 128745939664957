//IT
const main = {
  Login_titleLogin_display: true,
  Login_titleLogin_text: 'Accesso',
  Login_labelUsername_display: true,
  Login_labelUsername_text: 'Nome utente',
  Login_labelPassword_display: true,
  Login_labelPassword_text: 'Password',
  Login_labelLoginButton_display: true,
  Login_labelLoginButton_text: 'Accesso',
  Login_labelForgotPasswordLink_display: true,
  Login_labelForgotPasswordLink_text: 'Password dimenticata?',
  Login_validationMessageUsernameRequired_display: true,
  Login_validationMessageUsernameRequired_text: 'Il nome utente è obbligatorio',
  Login_validationMessagePasswordRequired_display: true,
  Login_validationMessagePasswordRequired_text: 'La password è obbligatoria',
  Login_errorMessageIncorrectCredentials_display: true,
  Login_errorMessageIncorrectCredentials_text: 'Nome utente o password errati! Per favore riprova.',
  Login_errorMessageNetworkIssues_display: true,
  Login_errorMessageNetworkIssues_text: 'Controlla la tua connessione di rete!',
  Login_errorMessageServerIssues_display: true,
  Login_errorMessageServerIssues_text: 'Impossibile ottenere una risposta! Per favore riprova.',
}

export default {
  ...main,
}
