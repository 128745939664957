import store from '../store/store'
import { loaderActions, snackbarActions } from '../store/actions/ui-actions';
const { dispatch } = store;

export const onRequest = config => {
  dispatch(loaderActions.showLoader())
  return config;
};
export const onRequestError = error => {
  dispatch(loaderActions.hideLoader())
  return error;
};

export const onResponse = response => {
  dispatch(loaderActions.hideLoader())
  return response;
};
export const onResponseError = error => {
  dispatch(loaderActions.hideLoader())
  // if (error.response === undefined) {
  //   dispatch(snackbarActions.showSnackbar("Unable to send request, Please check your internet connection", 'error'))
  // }
  // else if (error.response.status === 401) {
  //   dispatch(snackbarActions.showSnackbar(error.response.data.message || "401 Unauthorized", 'error'))
  // }
  // else if (error.response.status === 404) {
  //   dispatch(snackbarActions.showSnackbar(error.response.data.message || "Requested resource not found", 'error'))
  // }
  // else {
  //   dispatch(snackbarActions.showSnackbar(error.response.data.message || "Somethin went wrong", 'error'))
  // }
  return error;
};