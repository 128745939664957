//ES
const main = {
    RegisterTOTP_titleRegisterTOTP_display: true,
    RegisterTOTP_titleRegisterTOTP_text: 'Registro de contraseña temporal única (TOTP)',
    RegisterTOTP_labelQRCode_display: true,
    RegisterTOTP_labelQRCode_text: 'Código QR',
    RegisterTOTP_labelTimeRemaining_display: true,
    RegisterTOTP_labelTimeRemaining_text: 'Tiempo restante',
    RegisterTOTP_timerUnit_display: true,
    RegisterTOTP_timerUnit_text: 's',
    RegisterTOTP_label6DigitTOTP_display: true,
    RegisterTOTP_label6DigitTOTP_text: 'Contraseña temporal única (TOTP) de 6 dígitos',
    RegisterTOTP_labelSubmitButton_display: true,
    RegisterTOTP_labelSubmitButton_text: 'Enviar',
    RegisterTOTP_labelBackToDashboard_display: true,
    RegisterTOTP_labelBackToDashboard_text: 'Volver al Panel',
    RegisterTOTP_noticeForTimer_display: true,
    RegisterTOTP_noticeForTimer_text: 'Introduzca la contraseña temporal única (TOTP) antes de que termine el temporizador. Si el temporizador termina, actualice la página.',
    RegisterTOTP_infoForTOTP_display: true,
    RegisterTOTP_infoForTOTP_text: 
        'El registro de la contraseña temporal única (TOTP) requiere un autentificador como Google Authenticator (disponible en Android y iOS). Si es necesario, contacte con su administrador para obtener asistencia.',
    RegisterTOTP_validationMessageTOTPRequired_display: true,
    RegisterTOTP_validationMessageTOTPRequired_text: 'Se requiere una contraseña temporal única (TOTP)',
    RegisterTOTP_validationMessageTOTPOnlyDigits_display: true,
    RegisterTOTP_validationMessageTOTPOnlyDigits_text: 'Deben ser sólo dígitos',
    RegisterTOTP_validationMessageTOTPSixDigit_display: true,
    RegisterTOTP_validationMessageTOTPSixDigit_text: 'Debe tener exactamente 6 dígitos',
    RegisterTOTP_errorMessageTimerExpire_display: true,
    RegisterTOTP_errorMessageTimerExpire_text: 'El tiempo ha expirado. Actualice la página para obtener un nuevo código QR.',
    RegisterTOTP_errorMessageProcessFailed_display: true,
    RegisterTOTP_errorMessageProcessFailed_text: 'Registro de contraseña temporal única (TOTP) ha fallado. Por favor, inténtelo de nuevo.',
    RegisterTOTP_errorMessageNetworkIssues_display: true,
    RegisterTOTP_errorMessageNetworkIssues_text: 'Por favor, compruebe su red.',
    RegisterTOTP_errorMessageServerIssues_display: true,
    RegisterTOTP_errorMessageServerIssues_text: 'No se ha podido obtener una respuesta. Por favor, inténtelo de nuevo.',
}

export default {...main}
