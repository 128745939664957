//FR
const main = {
    Login_titleLogin_display: true,
    Login_titleLogin_text: 'Connexion',
    Login_labelUsername_display: true,
    Login_labelUsername_text: "Nom d'utilisateur",
    Login_labelPassword_display: true,
    Login_labelPassword_text: 'Mot de passe',
    Login_labelLoginButton_display: true,
    Login_labelLoginButton_text: 'Connexion',
    Login_labelForgotPasswordLink_display: true,
    Login_labelForgotPasswordLink_text: 'Mot de passe oublié?',
    Login_validationMessageUsernameRequired_display: true,
    Login_validationMessageUsernameRequired_text: "Le nom d'utilisateur est requis",
    Login_validationMessagePasswordRequired_display: true,
    Login_validationMessagePasswordRequired_text: 'Le mot de passe est requis',
    Login_errorMessageIncorrectCredentials_display: true,
    Login_errorMessageIncorrectCredentials_text: "Nom d'utilisateur/mot de passe incorrect! Veuillez réessayer",
    Login_errorMessageNetworkIssues_display: true,
    Login_errorMessageNetworkIssues_text: 'Veuillez vérifier votre réseau',
    Login_errorMessageServerIssues_display: true,
    Login_errorMessageServerIssues_text: "Impossible d'obtenir une réponse. Veuillez réessayer.",
}

export default {
    ...main
}
