//FR
const main = {
    RegisterTOTP_titleRegisterTOTP_display: true,
    RegisterTOTP_titleRegisterTOTP_text: 'Enregistrer le mot de passe à usage unique (MDPUU)',
    RegisterTOTP_labelQRCode_display: true,
    RegisterTOTP_labelQRCode_text: 'Code QR',
    RegisterTOTP_labelTimeRemaining_display: true,
    RegisterTOTP_labelTimeRemaining_text: 'Temps restant',
    RegisterTOTP_timerUnit_display: true,
    RegisterTOTP_timerUnit_text: 's',
    RegisterTOTP_label6DigitTOTP_display: true,
    RegisterTOTP_label6DigitTOTP_text: 'Mot de passe à usage unique (MDPUU) à 6 chiffres',
    RegisterTOTP_labelSubmitButton_display: true,
    RegisterTOTP_labelSubmitButton_text: 'Soumettre',
    RegisterTOTP_labelBackToDashboard_display: true,
    RegisterTOTP_labelBackToDashboard_text: 'Retour au tableau de bord',
    RegisterTOTP_noticeForTimer_display: true,
    RegisterTOTP_noticeForTimer_text: 'Veuillez saisir le mot de passe à usage unique (MDPUU) avant la fin du délai. Rafraîchir la page si le délai expire.',
    RegisterTOTP_infoForTOTP_display: true,
    RegisterTOTP_infoForTOTP_text: 
        "L'enregistrement du mot de passe à usage unique (MDPUU) nécessite un authentifiant comme celui de Google (disponible pour Android et iOS). Contactez votre administrateur si vous avez besoin d'aide.",
    RegisterTOTP_validationMessageTOTPRequired_display: true,
    RegisterTOTP_validationMessageTOTPRequired_text: 'Un MDPUU à 6 chiffres est requis',
    RegisterTOTP_validationMessageTOTPOnlyDigits_display: true,
    RegisterTOTP_validationMessageTOTPOnlyDigits_text: 'Doit être uniquement des chiffres',
    RegisterTOTP_validationMessageTOTPSixDigit_display: true,
    RegisterTOTP_validationMessageTOTPSixDigit_text: 'Doit être exactement 6 chiffres',
    RegisterTOTP_errorMessageTimerExpire_display: true,
    RegisterTOTP_errorMessageTimerExpire_text: 'Le temps est écoulé. Veuillez rafraîchir la page pour obtenir un nouveau QR Code.',
    RegisterTOTP_errorMessageProcessFailed_display: true,
    RegisterTOTP_errorMessageProcessFailed_text: "L'enregistrement du mot de passe à usage unique (MDPUU) a échoué. Veuillez réessayer.",
    RegisterTOTP_errorMessageNetworkIssues_display: true,
    RegisterTOTP_errorMessageNetworkIssues_text: 'Veuillez vérifier votre réseau',
    RegisterTOTP_errorMessageServerIssues_display: true,
    RegisterTOTP_errorMessageServerIssues_text: "Impossible d'obtenir une réponse. Veuillez réessayer.",
}

export default {...main}
