//ES
const main = {
    ForgotPassword_titleForgotPassword_display: true,
    ForgotPassword_titleForgotPassword_text: '¿Ha olvidado la contraseña?',
    ForgotPassword_labelProcessInfo_display: true,
    ForgotPassword_labelProcessInfo_text: "Introduce tu nombre de usuario registrado y te enviaremos un enlace para restablecer tu contraseña.",
    ForgotPassword_labelUsername_display: true,
    ForgotPassword_labelUsername_text: 'Nombre de usuario',
    ForgotPassword_labelSubmitButton_display: true,
    ForgotPassword_labelSubmitButton_text: 'Enviar',
    ForgotPassword_labelBackToLoginLink_display: true,
    ForgotPassword_labelBackToLoginLink_text: 'Volver al inicio de sesión',
    ForgotPassword_validationMessageUsernameRequired_display: true,
    ForgotPassword_validationMessageUsernameRequired_text: 'El nombre de usuario es obligatorio',
    ForgotPassword_validationMessageEmailInvalid_display: true,
    ForgotPassword_validationMessageEmailInvalid_text: 'Formato de dirección de correo electrónico no válido',
    ForgotPassword_errorMessageUsernameNotExist_display: true,
    ForgotPassword_errorMessageUsernameNotExist_text: 'El nombre de usuario no existe en la base de datos. Por favor, introduzca el nombre de usuario registrado correcto.',
    ForgotPassword_errorMessageProcessFailed_display: true,
    ForgotPassword_errorMessageProcessFailed_text: 'El proceso ha fallado. Por favor, inténtelo de nuevo.',
    ForgotPassword_errorMessageNetworkIssues_display: true,
    ForgotPassword_errorMessageNetworkIssues_text: 'Por favor, compruebe su red.',
    ForgotPassword_errorMessageServerIssues_display: true,
    ForgotPassword_errorMessageServerIssues_text: 'No se ha podido obtener una respuesta. Por favor, inténtelo de nuevo.',
}

export default {
    ...main
}
