//DE
const main = {
  ValidationMessages_UsernameRequired_display: true,
  ValidationMessages_UsernameRequired_text: 'Benutzername ist erforderlich',
  ValidationMessages_PreferredUsernameRequired_display: true,
  ValidationMessages_PreferredUsernameRequired_text: 'Bevorzugter Benutzername ist erforderlich',
  ValidationMessages_PasswordRequired_display: true,
  ValidationMessages_PasswordRequired_text: 'Neues Passwort ist erforderlich',
  ValidationMessages_CurrentPasswordRequired_display: true,
  ValidationMessages_CurrentPasswordRequired_text: 'Aktuelles Passwort ist erforderlich',
  ValidationMessages_NewPasswordRequired_display: true,
  ValidationMessages_NewPasswordRequired_text: 'Neues Passwort ist erforderlich',
  ValidationMessages_ConfirmNewPasswordRequired_display: true,
  ValidationMessages_ConfirmNewPasswordRequired_text: 'Neues Passwort bestätigen ist erforderlich',
  ValidationMessages_PasswordDoNotMatch_display: true,
  ValidationMessages_PasswordDoNotMatch_text:
    'Die Passwörter sind nicht identisch! Bitte geben Sie identische Passwörter ein.',
  ValidationMessages_PasswordCriteria_display: true,
  ValidationMessages_PasswordCriteria_text:
    "Muss mindestens 8 Zeichen enthalten (empfohlen: mehr als 12), darunter ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen (!”#$%&'()*+,-./:;<=>?",
  ValidationMessages_verificationCodeRequired_display: true,
  ValidationMessages_verificationCodeRequired_text: 'Verifizierungscode ist erforderlich',
}

export default { ...main }
