import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Drawer,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
  Box,
  Collapse,
} from "@material-ui/core";
import Settings from "@material-ui/icons/Settings";
import EnhancedEncryptionIcon from "@material-ui/icons/EnhancedEncryption";
import Place from "@material-ui/icons/Place";
import AdjustIcon from "@material-ui/icons/Adjust";
import PersonIcon from "@material-ui/icons/Person";
import DevicesOther from "@material-ui/icons/DevicesOther";
import DashboardOutlined from "@material-ui/icons/DashboardOutlined";
import { ExpandLess, ExpandMore } from "@material-ui/icons/";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useViewport from "../../../hooks/useViewport";
import routeConstants from "../../../constants/route-constants";
// import PHLogo from "../../../resources/Paul_Hartman_Logo_lowres.png";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
const userMenus = [
  // { name: 'organizations', title: "Organisations", to: routeConstants.ORGANIZATIONS, icon: <Business /> },
  {
    name: "overview",
    title: "Overview",
    to: routeConstants.DASHBOARD,
    icon: <DashboardOutlined />,
  },
  {
    name: "patients",
    title: "Patients",
    to: routeConstants.PATIENTS,
    icon: <EnhancedEncryptionIcon />,
  },
  {
    name: "locations",
    title: "Locations",
    to: routeConstants.LOCATIONS,
    icon: <Place />,
  },
  {
    name: "users",
    title: "Users",
    to: routeConstants.USERS,
    icon: <PersonIcon />,
  },
  {
    name: "devices",
    title: "Devices",
    to: routeConstants.WOUNDS,
    icon: <DevicesOther />,
  },
  {
    name: "support",
    title: "Support",
    to: routeConstants.SUPPORT,
    icon: <AdjustIcon />,
  },
  {
    name: "configurations",
    title: "Configurations",
    // to: routeConstants.SUPPORT,
    icon: <Settings />,
  },
];
const SideNavigation = ({ handleDrawerToggle, mobileOpen }) => {
  const { width } = useViewport();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [open, setOpen] = useState(null);

  const handleExpand = (openedItem) => {
    if (open === openedItem) {
      setOpen(null);
    } else {
      setOpen(openedItem);
    }
  };
  const drawer = (
    <div>
      <Box
        bgcolor="primary.main"
        display="flex"
        justifyContent="center"
        boxShadow={5}
      >
        {/* <img
          className={classes.logo}
          src={PHLogo}
          alt="PH Logo"
          style={{ height: width > 1024 ? 52 : width < 623 ? 56 : 64 }}
        /> */}
      </Box>
      <Divider />
      <List>
        {userMenus.map((menu) => {
          return !menu.subMenus ? (
            <Fragment key={menu.name}>
              <ListItem
                button
                onClick={() => {
                  history.push(menu.to);
                  handleDrawerToggle();
                }}
                selected={history.location.pathname === menu.to}
              >
                <ListItemIcon>{menu.icon}</ListItemIcon>
                <ListItemText primary={menu.title} />
              </ListItem>
              <Divider />
            </Fragment>
          ) : (
            <Fragment key={menu.name}>
              <ListItem button onClick={() => handleExpand(menu.name)}>
                <ListItemIcon>{menu.icon}</ListItemIcon>
                <ListItemText primary={menu.title} />
                {open === menu.name ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Divider />
              {menu.subMenus.map((subMenu) => {
                return (
                  <Collapse
                    in={open === menu.name}
                    timeout="auto"
                    unmountOnExit
                    key={subMenu.name}
                  >
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        onClick={() => {
                          history.push(subMenu.to);
                          handleDrawerToggle();
                        }}
                        selected={history.location.pathname === subMenu.to}
                        className={classes.nested}
                      >
                        <ListItemIcon>{subMenu.icon}</ListItemIcon>
                        <ListItemText primary={subMenu.title} />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </List>
                  </Collapse>
                );
              })}
            </Fragment>
          );
        })}
      </List>
    </div>
  );
  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Drawer
        variant="temporary"
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </Drawer>
    </nav>
  );
};
export default React.memo(SideNavigation);
