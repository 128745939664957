import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { IconButton, InputAdornment } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { Formik, Field, Form } from 'formik'
import { TextField } from 'formik-material-ui'
import useViewport from '../hooks/useViewport'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { initialPasswordChange } from '../store/actions/account-action'
// import { fetchOrganisationList } from '../store/actions/organization-actions'
import { setThemeLocale } from '../store/actions/ui-actions'
import routeConstants from '../constants/route-constants'
import customization from '../utils/customization-utils'
// import { getOrganizationLocale } from '../utils/localization-utils'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  demo1: {
    // backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
  },
  inputField: {
    marginBottom: 32,
  },
  input: {
    backgroundColor: 'white',
    border: '1px solid #C2C5C7',
    borderRadius: 8,
    padding: '8px 8px',
    [theme.breakpoints.down('md')]: {
      padding: '5px 5px',
    },
  },
  label: {
    color: 'black',
  },
  menuPaper: {
    maxHeight: 500,
  },
  link: {
    color: '#0D3082',
    marginTop: 16,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
  },
  submitButton: {
    whiteSpace: 'nowrap',
    minWidth: '100%',
    // textTransform: "none",
    marginTop: 9,
    marginBottom: 16,
    borderRadius: 8,
    padding: '8px 8px',
    [theme.breakpoints.down('md')]: {
      padding: '5px',
      fontSize: 14,
    },
    height: 48,
    fontSize: 16,
  },
}))

const SetInitialPassword = () => {
  const params = new URLSearchParams(window.location.search)
  const unique_id = params.get('unique_id')
  const { width } = useViewport()
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const selectedLocale = useSelector((state) => state.ui.locale)
  // const organizationListLoaded = useSelector((state) => state.data.organizationListLoaded)
  // const organizationList = useSelector((state) => state.data.list.organizationList)
  // const organizationListId =
  //   organizationList.organizations && organizationList.organizations.length
  //     ? organizationList.organizations[0].id
  //     : ''
  // const organizationListAcronym =
  //   organizationList.organizations && organizationList.organizations.length
  //     ? organizationList.organizations[0].acronym
  //     : ''
  // const organizationListAcronym = 'PHGR'

  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)
  // const [organizationAcronym, setOrganizationAcronym] = useState('')
  // const [organizationLocale, setOrganizationLocale] = useState('')

  const userLocale =
    navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language

  useEffect(() => {
    if (/^de\b/.test(userLocale)) {
      dispatch(setThemeLocale('DE'))
    } else if (/^es\b/.test(userLocale)) {
      dispatch(setThemeLocale('ES'))
    } else if (/^fr\b/.test(userLocale)) {
      dispatch(setThemeLocale('FR'))
    } else {
      dispatch(setThemeLocale('EN'))
    }
  }, [])

  // useEffect(() => {
  //   dispatch(fetchOrganisationList())

  //   if (process.env.NODE_ENV !== 'production') {
  //     localStorage.setItem(
  //       'ph_kronikare_user_session',
  //       `{"unique_id":"seif","challenges":[],"id":"330","role_ids":["2","3","5","6","7","11","12"]}`
  //     )
  //   }
  // }, [dispatch])

  // useEffect(() => {
  //   if(organizationListLoaded === true && organizationList.organizations && organizationList.organizations.length){
  //     setOrganizationAcronym(organizationList.organizations[0].acronym)
  //   }
  // }, [organizationListLoaded, organizationList])

  // useEffect(() => {
  //   if(organizationAcronym){
  //     setOrganizationLocale(getOrganizationLocale(organizationAcronym))
  //   }
  // }, [organizationAcronym])

  // useEffect(() => {
  //   if(organizationLocale){
  //     dispatch(setThemeLocale(organizationLocale))
  //   }
  // },[dispatch, organizationLocale])

  const c = customization(selectedLocale)

  const validationSchema = Yup.object({
    preferred_username: Yup.string().required(c.ValidationMessages_PreferredUsernameRequired_text),
    new_password: Yup.string()
      .required(c.ValidationMessages_NewPasswordRequired_text)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        c.ValidationMessages_PasswordCriteria_text
      ),
    confirm_new_password: Yup.string()
      .required(c.ValidationMessages_ConfirmNewPasswordRequired_text)
      .oneOf([Yup.ref('new_password'), null], c.ValidationMessages_PasswordDoNotMatch_text),
  })

  const handleToggleNewPassword = () => setShowNewPassword((showNewPassword) => !showNewPassword)
  const handleToggleConfirmNewPassword = () =>
    setShowConfirmNewPassword((showConfirmNewPassword) => !showConfirmNewPassword)

  // console.log('organizationListLoaded -------->>>>>>>', organizationListLoaded)
  const [eulaDialog, setEulaDialog] = useState(true)
  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <Dialog open={eulaDialog && c.Eula_display} maxWidth="md">
          <DialogTitle style={{ textAlign: 'center' }}>
            <b>{c.Eula_title_text}</b>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div dangerouslySetInnerHTML={{ __html: c.Eula_text }} />
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.submitButton}
              style={{ minWidth: '50%' }}
              onClick={() => {
                setEulaDialog(false)
              }}
            >
              {c.Eula_labelAgreeButton_text}
            </Button>
          </DialogActions>
        </Dialog>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="column"
        >
          <Box style={{ maxWidth: 500 }}>
            <Typography
              variant="h4"
              style={{ fontWeight: 500, marginBottom: 46, marginTop: 52, textAlign: 'center' }}
            >
              {c.SetInitialPassword_titleSetInitialPassword_text}
            </Typography>
            <Formik
              enableReinitialize={true}
              initialValues={{
                preferred_username: unique_id ? unique_id : '',
                password: '',
                passwordConfirmation: '',
              }}
              onSubmit={(values, { setSubmitting }) => {
                dispatch(
                  initialPasswordChange(
                    values.preferred_username,
                    values.new_password,
                    selectedLocale
                  )
                ).then(() => setSubmitting(false))
                setSubmitting(true)
              }}
              validationSchema={validationSchema}
            >
              {({ errors, touched, isSubmitting, values, setFieldValue, isValid, dirty }) => (
                <Form>
                  <Field
                    component={TextField}
                    className={classes.inputField}
                    label={c.SetInitialPassword_labelPreferredUsername_text}
                    name="preferred_username"
                    type="text"
                    fullWidth
                    onChange={(event) => {
                      const newValue = event.target.value.replace(/ /g, '')
                      setFieldValue('preferred_username', newValue)
                    }}
                    autocomplete="off"
                    error={errors.preferred_username && touched.preferred_username}
                    helperText={
                      errors.preferred_username && touched.preferred_username
                        ? errors.preferred_username
                        : null
                    }
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Field
                    component={TextField}
                    className={classes.inputField}
                    label={c.SetInitialPassword_labelNewPassword_text}
                    name="new_password"
                    type={showNewPassword ? 'text' : 'password'}
                    fullWidth
                    autocomplete="nope"
                    error={errors.new_password && touched.new_password}
                    helperText={
                      errors.new_password && touched.new_password ? errors.new_password : null
                    }
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleToggleNewPassword}>
                            {showNewPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Field
                    component={TextField}
                    className={classes.inputField}
                    label={c.SetInitialPassword_labelConfirmNewPassword_text}
                    type={showConfirmNewPassword ? 'text' : 'password'}
                    name="confirm_new_password"
                    fullWidth
                    autocomplete="nope"
                    error={errors.confirm_new_password && touched.confirm_new_password}
                    helperText={
                      errors.confirm_new_password && touched.confirm_new_password
                        ? errors.confirm_new_password
                        : null
                    }
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleToggleConfirmNewPassword}>
                            {showConfirmNewPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Button
                    type="submit"
                    disabled={
                      isSubmitting ||
                      !values.preferred_username ||
                      !values.new_password ||
                      !values.confirm_new_password
                    }
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}
                  >
                    {c.SetInitialPassword_labelSubmitButton_text}
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </div>
    </div>
  )
}

export default SetInitialPassword
