import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert'
import Link from '@material-ui/core/Link'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { Formik, Field, Form } from 'formik'
import { TextField } from 'formik-material-ui'
import useViewport from '../hooks/useViewport'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import qrcode from 'qrcode'
import CircularProgress from '@material-ui/core/CircularProgress'

import { requestSecretKey, validateTotp, confirmSecretKey } from '../store/actions/account-action'
import routeConstants from '../constants/route-constants'
import customization from '../utils/customization-utils'
import { setThemeLocale } from '../store/actions/ui-actions'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  demo1: {
    // backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
  },
  inputField: {
    marginBottom: 32,
  },
  input: {
    backgroundColor: 'white',
    border: '1px solid #C2C5C7',
    borderRadius: 8,
    padding: '8px 8px',
    [theme.breakpoints.down('md')]: {
      padding: '8px 8px',
    },
  },
  label: {
    color: 'black',
  },
  menuPaper: {
    maxHeight: 500,
  },
  link: {
    color: '#0D3082',
    // marginTop: 16,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
  },
  submitButton: {
    whiteSpace: 'nowrap',
    minWidth: '100%',
    // textTransform: "none",
    marginBottom: 16,
    borderRadius: 8,
    padding: '8px 8px',
    [theme.breakpoints.down('md')]: {
      padding: '5px',
      fontSize: 14,
    },
    height: 48,
    fontSize: 16,
  },
}))

export const SetupTOTP = () => {
  const { width } = useViewport()
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const selectedLocale = useSelector((state) => state.ui.locale)
  const organizationListLoaded = useSelector((state) => state.data.organizationListLoaded)
  const organizationList = useSelector((state) => state.data.list.organizationList)
  const organizationListId =
    organizationList.organizations && organizationList.organizations.length
      ? organizationList.organizations[0].id
      : ''
  const organizationListAcronym =
    organizationList.organizations && organizationList.organizations.length
      ? organizationList.organizations[0].acronym
      : ''
  // const organizationListAcronym = 'PHGR'
  // const c = customization(organizationListAcronym)
  const orgLocale = customization(organizationListAcronym).Locale_defaultSelection_text
  const [timerExpire, setTimerExpire] = useState(false)
  const [c, setC] = useState(customization(selectedLocale))
  const [qrImage, setQRImage] = useState('')
  const [alreadySetup, setAlreadySetup] = useState(false)

  useEffect(() => {
    dispatch(setThemeLocale(orgLocale))
    dispatch(
      requestSecretKey({
        selectedLocale,
        onSuccessCallback: (response) => {
          qrcode.toDataURL(
            response.data.uri,
            { errorCorrectionLevel: 'H', margin: 0 },
            (err, url) => {
              setQRImage(url)
            }
          )
        },
      })
    ).then((result) => {
      if (result == 401) {
        setAlreadySetup(true)
      }
    })
  }, [])
  console.log(qrImage)

  useEffect(() => {
    setC(customization(selectedLocale))
  }, [selectedLocale])

  const validationSchema = Yup.object({
    totp: Yup.string()
      .required(c.RegisterTOTP_validationMessageTOTPRequired_text)
      .matches(/^[0-9]+$/, c.RegisterTOTP_validationMessageTOTPOnlyDigits_text)
      .min(6, c.RegisterTOTP_validationMessageTOTPSixDigit_text)
      .max(6, c.RegisterTOTP_validationMessageTOTPSixDigit_text),
  })
  return qrImage ? (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="column"
        >
          <Box style={{ maxWidth: 350 }}>
            <Typography
              variant="h4"
              style={{ fontWeight: 500, marginBottom: 16, marginTop: 22, textAlign: 'center' }}
            >
              {c.RegisterTOTP_titleRegisterTOTP_text}
            </Typography>
            <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: 5 }}>
              <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: 5 }}>
                {/* {c.RegisterTOTP_labelQRCode_text} */}
                <img src={qrImage} alt="qr" style={{ height: 250, width: 250 }} />
              </Box>
            </Box>
            {timerExpire ? (
              <Typography
                variant="subtitle2"
                style={{ marginBottom: 5, fontWeight: 'bold', color: 'red' }}
              >
                {c.RegisterTOTP_errorMessageTimerExpire_text}
              </Typography>
            ) : (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  marginBottom: 5,
                }}
              >
                <Typography variant="subtitle2" style={{ fontWeight: 'bold', marginRight: 10 }}>
                  {c.RegisterTOTP_labelTimeRemaining_text}:
                </Typography>
                <CountdownCircleTimer
                  onComplete={() => {
                    setTimerExpire(true)
                  }}
                  isPlaying
                  duration={120}
                  colors={[
                    ['#0d3082', 0.33],
                    ['#4d59b2', 0.33],
                    ['#67ade0', 0.33],
                  ]}
                  size={40}
                  strokeWidth={3}
                >
                  {({ remainingTime }) => remainingTime + c.RegisterTOTP_timerUnit_text}
                </CountdownCircleTimer>
              </Box>
            )}
            <Formik
              enableReinitialize={true}
              initialValues={{
                totp: '',
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)
                dispatch(confirmSecretKey(values.totp, selectedLocale)).then(() => {
                  setSubmitting(false)
                })
              }}
              validationSchema={validationSchema}
            >
              {({ errors, touched, isSubmitting, values, setFieldValue }) => (
                <Form>
                  <Field
                    component={TextField}
                    className={classes.inputField}
                    label={c.RegisterTOTP_label6DigitTOTP_text}
                    name="totp"
                    type="text"
                    fullWidth
                    error={errors.totp && touched.totp}
                    helperText={errors.totp && touched.totp ? errors.totp : null}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}
                  >
                    {c.RegisterTOTP_labelSubmitButton_text}
                  </Button>
                </Form>
              )}
            </Formik>
            <Typography variant="subtitle2" style={{ marginBottom: 16 }}>
              {c.RegisterTOTP_noticeForTimer_text}
            </Typography>
          </Box>
          <Box style={{ marginTop: 16, maxWidth: 600 }}>
            <Typography variant="subtitle2" style={{ marginBottom: 16 }}>
              {c.RegisterTOTP_infoForTOTP_text}
            </Typography>
          </Box>
        </Box>
      </div>
    </div>
  ) : (
    <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="column">
      <Box justifyContent="center">
        {alreadySetup ? (
          <>
            <Alert severity="error">The totp has been already setup.</Alert>
            <Button
              variant="contained"
              style={{ marginTop: '10px' }}
              className={classes.submitButton}
              onClick={() => window.location.replace('/')}
            >
              Go Back
            </Button>
          </>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Box>
  )
}

export const ValidateTOTP = () => {
  const { width } = useViewport()
  const dispatch = useDispatch()
  const classes = useStyles()
  const selectedLocale = useSelector((state) => state.ui.locale)
  const [c, setC] = useState(customization(selectedLocale))

  useEffect(() => {
    setC(customization(selectedLocale))
  }, [selectedLocale])

  const validationSchema = Yup.object({
    totp: Yup.string()
      .required(c.RegisterTOTP_validationMessageTOTPRequired_text)
      .matches(/^[0-9]+$/, c.RegisterTOTP_validationMessageTOTPOnlyDigits_text)
      .min(6, c.RegisterTOTP_validationMessageTOTPSixDigit_text)
      .max(6, c.RegisterTOTP_validationMessageTOTPSixDigit_text),
  })
  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="column"
        >
          <Box style={{ maxWidth: 350 }}>
            <Typography
              variant="h4"
              style={{ fontWeight: 500, marginBottom: 16, marginTop: 22, textAlign: 'center' }}
            >
              TOTP
            </Typography>

            <Formik
              enableReinitialize={true}
              initialValues={{
                totp: '',
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)
                dispatch(validateTotp(values.totp, selectedLocale)).then(() => {
                  setSubmitting(false)
                })
              }}
              validationSchema={validationSchema}
            >
              {({ errors, touched, isSubmitting, values, setFieldValue }) => (
                <Form>
                  <Field
                    component={TextField}
                    className={classes.inputField}
                    label={c.RegisterTOTP_label6DigitTOTP_text}
                    name="totp"
                    type="text"
                    fullWidth
                    error={errors.totp && touched.totp}
                    helperText={errors.totp && touched.totp ? errors.totp : null}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}
                  >
                    {c.RegisterTOTP_labelSubmitButton_text}
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </div>
    </div>
  )
}
