import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { COMMON, PRIMARY, SECONDARY } from "../constants/colors";

let theme = createTheme({
  palette: {
    primary: {
      light: PRIMARY.light,
      main: PRIMARY.main,
      dark: PRIMARY.dark,
      contrastText: PRIMARY.contrastText,
    },
    secondary: {
      light: SECONDARY.light,
      main: SECONDARY.main,
      dark: SECONDARY.dark,
      contrastText: SECONDARY.contrastText,
    },
    background: {
      default: COMMON.grey,
    },
  },
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true,
  },
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: "1rem !important",
      },
    },
    button: {
      fontSize: "1rem",
    },
  },
});
export default responsiveFontSizes(theme);
