//basic
const main = {
  RegisterPIN_titleRegisterPIN_display: true,
  RegisterPIN_titleRegisterPIN_text: 'Registra PIN',
  RegisterPIN_label6DigitPin_display: true,
  RegisterPIN_label6DigitPin_text: "PIN a 6 cifre per l'applicazione mobile",
  RegisterPIN_labelSubmitButton_display: true,
  RegisterPIN_labelSubmitButton_text: 'Invia',
  RegisterPIN_labelBackToDashboard_display: true,
  RegisterPIN_labelBackToDashboard_text: 'Torna alla dashboard',
  RegisterPIN_validationMessagePinRequired_display: true,
  RegisterPIN_validationMessagePinRequired_text: 'Il PIN è obbligatorio',
  RegisterPIN_validationMessagePinOnlyDigits_display: true,
  RegisterPIN_validationMessagePinOnlyDigits_text: 'Deve contenere solo cifre',
  RegisterPIN_validationMessagePinSixDigit_display: true,
  RegisterPIN_validationMessagePinSixDigit_text: 'Deve essere esattamente di 6 cifre',
  RegisterPIN_errorMessageProcessFailed_display: true,
  RegisterPIN_errorMessageProcessFailed_text: 'Impostazione del PIN fallita. Per favore riprova.',
  RegisterPIN_errorMessageNetworkIssues_display: true,
  RegisterPIN_errorMessageNetworkIssues_text: 'Controlla la tua connessione di rete!',
  RegisterPIN_errorMessageServerIssues_display: true,
  RegisterPIN_errorMessageServerIssues_text:
    'Impossibile ottenere una risposta! Per favore riprova.',
}

export default { ...main }
