//ES
const main = {
  ResetPassword_titleResetPassword_display: true,
  ResetPassword_titleResetPassword_text: 'Restablecer contraseña',
  ResetPassword_labelNewPassword_display: true,
  ResetPassword_labelNewPassword_text: 'Nueva contraseña',
  ResetPassword_labelConfirmNewPassword_display: true,
  ResetPassword_labelConfirmNewPassword_text: 'Confirmar nueva contraseña',
  ResetPassword_labelVerificationCode_display: true,
  ResetPassword_labelVerificationCode_text: 'Código de verificación',
  ResetPassword_labelSubmitButton_display: true,
  ResetPassword_labelSubmitButton_text: 'Enviar',
  ResetPassword_validationMessageNewPasswordRequired_display: true,
  ResetPassword_validationMessageNewPasswordRequired_text: 'Se requiere una nueva contraseña',
  ResetPassword_validationMessageConfirmNewPasswordRequired_display: true,
  ResetPassword_validationMessageConfirmNewPasswordRequired_text:
    'Es necesario confirmar la nueva contraseña',
  ResetPassword_validationMessagePasswordDoNotMatch_display: true,
  ResetPassword_validationMessagePasswordDoNotMatch_text: '¡Las contraseñas no coinciden!',
  ResetPassword_errorMessageProcessFailed_display: true,
  ResetPassword_errorMessageProcessFailed_text:
    'La configuración de la nueva contraseña ha fallado. Por favor, inténtelo de nuevo.',
  ResetPassword_errorMessageNetworkIssues_display: true,
  ResetPassword_errorMessageNetworkIssues_text: 'Por favor, compruebe su red.',
  ResetPassword_errorMessageServerIssues_display: true,
  ResetPassword_errorMessageServerIssues_text:
    'No se ha podido obtener una respuesta. Por favor, inténtelo de nuevo.',
}

export default { ...main }
