//DE
const main = {
  ErrorMessages_400_BadRequest_text: 'Etwas ist schief gelaufen... Schlechte Anfrage!',
  ErrorMessages_401_Unauthorised_text:
    'Etwas ist schief gelaufen... Nicht autorisiert. Bitte einloggen.',
  ErrorMessages_401_LoginInvalidCredentials_text:
    'Falscher Benutzername oder falsches Passwort! Bitte versuchen Sie es erneut.',
  ErrorMessages_403_Forbidden_text: 'Sie haben keinen Zugriff auf die angeforderte Ressource!',
  ErrorMessages_404_NotFound_text: 'Etwas ist schief gelaufen... Ressource wird nicht gefunden!',
  ErrorMessages_405_MethodNotAllowed_text: 'Etwas ist schief gelaufen... Methode nicht erlaubt!',
  ErrorMessages_406_NotAcceptable_text: 'Etwas ist schief gelaufen... Inhalt nicht gefunden!',
  ErrorMessages_408_RequestTimeout_text:
    'Etwas ist schief gelaufen... Zeitüberschreitung anfordern',
  ErrorMessages_500_InternalServerError_text:
    'Etwas ist schief gelaufen... Serverfehler, bitte kontaktieren Sie den Administrator.',
  ErrorMessages_501_NotImplemented_text: 'Etwas ist schief gelaufen... Methode nicht unterstützt.',
  ErrorMessages_502_BadGateway_text: 'Etwas ist schief gelaufen... Bad Gateway.',
  ErrorMessages_503_ServiceUnavailable_text: 'Etwas ist schief gelaufen... Dienst nicht verfügbar',
  ErrorMessages_504_ServiceUnavailable_text:
    'Etwas ist schief gelaufen... Gateway Zeitüberschreitung',
  ErrorMessages_GeneralErrorMessage_text: 'Etwas ist schief gelaufen...',
  ErrorMessages_409_Username_already_exists_text:
    'Ein ähnlicher Benutzer existiert bereits, bitte ändern Sie ihn.',

  SuccessMessages_ForgetPasswordLink_text: `
Der Verifizierungscode wurde an die registrierte E-Mail-Adresse des Benutzers gesendet.`,
  SuccessMessages_PasswordSetSuccess_text: 'Das Passwort wurde erfolgreich festgelegt.',
  SuccessMessages_PasswordChangeSuccess_text: 'Das Passwort wurde erfolgreich geändert.',
  SuccessMessages_UsernamePasswordSetSuccess_text:
    'Benutzername und Passwort wurden erfolgreich festgelegt.',
  SuccessMessages_PINSetSuccess_text: 'Die PIN wurde erfolgreich gesetzt.',
  SuccessMessages_TOTPSetSuccess_text: 'Zeitbasierte Einmalpasswort-Registrierung ist erfolgreich',
}

export default {
  ...main,
}
