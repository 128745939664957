//DE
const main = {
    SetInitialPassword_titleSetInitialPassword_display: true,
    SetInitialPassword_titleSetInitialPassword_text: 'Initiales Passwort festlegen',
    SetInitialPassword_labelPreferredUsername_display: true,
    SetInitialPassword_labelPreferredUsername_text: 'Bevorzugter Benutzername',
    SetInitialPassword_labelNewPassword_display: true,
    SetInitialPassword_labelNewPassword_text: 'Neues Passwort',
    SetInitialPassword_labelConfirmNewPassword_display: true,
    SetInitialPassword_labelConfirmNewPassword_text: 'Neues Passwort bestätigen',
    SetInitialPassword_labelSubmitButton_display: true,
    SetInitialPassword_labelSubmitButton_text: 'Absenden',
    SetInitialPassword_validationMessagePreferredUsernameRequired_display: true,
    SetInitialPassword_validationMessagePreferredUsernameRequired_text: 'Bevorzugter Benutzername ist erforderlich',
    SetInitialPassword_validationMessagePasswordRequired_display: true,
    SetInitialPassword_validationMessagePasswordRequired_text: 'Neues Passwort ist erforderlich',
    SetInitialPassword_validationMessageNewPasswordRequired_display: true,
    SetInitialPassword_validationMessageNewPasswordRequired_text: 'Neues Passwort bestätigen ist erforderlich',
    SetInitialPassword_validationMessagePasswordDoNotMatch_display: true,
    SetInitialPassword_validationMessagePasswordDoNotMatch_text: 'Die Passwörter sind nicht identisch! Bitte geben Sie identische Passwörter ein.',
    SetInitialPassword_validationMessagePasswordCriteria_display: true,
    SetInitialPassword_validationMessagePasswordCriteria_text: 'Muss 8 Zeichen enthalten, darunter ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen',
    SetInitialPassword_errorMessageProcessFailed_display: true,
    SetInitialPassword_errorMessageProcessFailed_text: 'Das Setzen des Initialpassworts ist fehlgeschlagen. Bitte melden Sie sich an und versuchen Sie es erneut.',
    SetInitialPassword_errorMessageNetworkIssues_display: true,
    SetInitialPassword_errorMessageNetworkIssues_text: 'Bitte überprüfen Sie Ihr Netzwerk!',
    SetInitialPassword_errorMessageServerIssues_display: true,
    SetInitialPassword_errorMessageServerIssues_text: 'Sie haben keine Antwort erhalten! Bitte versuchen Sie es erneut.',
}

export default {...main}
