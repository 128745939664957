import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import { IconButton, InputAdornment } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Field, Form } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import useViewport from '../hooks/useViewport'
import { setPin } from '../store/actions/account-action'
import routeConstants from '../constants/route-constants'
import customization from '../utils/customization-utils'
import { setThemeLocale } from '../store/actions/ui-actions'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  demo1: {
    // backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
  },
  inputField: {
    marginBottom: 32,
  },
  input: {
    backgroundColor: 'white',
    border: '1px solid #C2C5C7',
    borderRadius: 8,
    padding: '8px 8px',
    [theme.breakpoints.down('md')]: {
      padding: '8px 8px',
    },
  },
  label: {
    color: 'black',
  },
  menuPaper: {
    maxHeight: 500,
  },
  link: {
    color: '#0D3082',
    marginTop: 16,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
  },
  submitButton: {
    whiteSpace: 'nowrap',
    minWidth: '100%',
    // textTransform: "none",
    marginTop: 9,
    marginBottom: 16,
    borderRadius: 8,
    padding: '8px 8px',
    [theme.breakpoints.down('md')]: {
      padding: '5px',
      fontSize: 14,
    },
    height: 48,
    fontSize: 16,
  },
}))

const RegisterPin = () => {
  const { width } = useViewport()
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const selectedLocale = useSelector((state) => state.ui.locale)
  const organizationListLoaded = useSelector((state) => state.data.organizationListLoaded)
  const organizationList = useSelector((state) => state.data.list.organizationList)
  const organizationListId =
    organizationList.organizations && organizationList.organizations.length
      ? organizationList.organizations[0].id
      : ''
  const organizationListAcronym =
    organizationList.organizations && organizationList.organizations.length
      ? organizationList.organizations[0].acronym
      : ''
  // const organizationListAcronym = 'PHGR'
  // const c = customization(organizationListAcronym)
  const orgLocale = customization(organizationListAcronym).Locale_defaultSelection_text
  const [showPin, setShowPin] = useState(false)
  const [c, setC] = useState(customization(selectedLocale))

  useEffect(() => {
    dispatch(setThemeLocale(orgLocale))
  }, [])

  useEffect(() => {
    setC(customization(selectedLocale))
  }, [selectedLocale])

  const validationSchema = Yup.object({
    pin: Yup.string()
      .required(c.RegisterPIN_validationMessagePinRequired_text)
      .matches(/^[0-9]+$/, c.RegisterPIN_validationMessagePinOnlyDigits_text)
      .min(6, c.RegisterPIN_validationMessagePinSixDigit_text)
      .max(6, c.RegisterPIN_validationMessagePinSixDigit_text),
  })

  const handleTogglePin = () => setShowPin((showPin) => !showPin)

  return (
    organizationListLoaded && (
      <div className={classes.root}>
        <div className={classes.demo1}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="column"
          >
            <Box>
              <Typography
                variant="h4"
                style={{ fontWeight: 500, marginBottom: 46, marginTop: 72, textAlign: 'center' }}
              >
                {c.RegisterPIN_titleRegisterPIN_text}
              </Typography>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  pin: '',
                }}
                onSubmit={(values, { setSubmitting }) => {
                  dispatch(setPin(values.pin, organizationListAcronym))
                  setSubmitting(true)
                }}
                validationSchema={validationSchema}
              >
                {({ errors, touched, isSubmitting, values, setFieldValue }) => (
                  <Form>
                    <Typography style={{ marginBottom: 10, fontWeight: 'bold' }}>
                      {c.RegisterPIN_label6DigitPin_text}
                    </Typography>
                    <Field
                      component={TextField}
                      className={classes.inputField}
                      name="pin"
                      type={showPin ? 'text' : 'password'}
                      fullWidth
                      error={errors.pin && touched.pin}
                      helperText={errors.pin && touched.pin ? errors.pin : null}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleTogglePin}>
                              {showPin ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                      className={classes.submitButton}
                    >
                      {c.RegisterPIN_labelSubmitButton_text}
                    </Button>
                    <Link href={routeConstants.DASHBOARD} className={classes.link}>
                      <ArrowBackIosIcon />
                      <Typography style={{ marginLeft: 10 }}>
                        {c.RegisterPIN_labelBackToDashboard_text}
                      </Typography>
                    </Link>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </div>
      </div>
    )
  )
}

export default RegisterPin
