import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Settings from '@material-ui/icons/Settings'

import SideNavigation from './AppSidebar/AppMenuNavigation'
import AppMenu from './AppSidebar/AppMenu'

import TopBar from './AppNavigation/TopBar'

import SnackbarAlert from './SnackbarAlert'
import { useSelector } from 'react-redux'
import { Box, Link } from '@material-ui/core'
import routeConstants from '../../constants/route-constants'

const PageContainer = ({ children }) => {
  const loggedIn = useSelector((state) => state.account.loggedIn)

  const classes = useStyles()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <React.Fragment>
      <div className={clsx('App', classes.root)}>
        <div className={classes.appHeader}>
          <TopBar handleDrawerToggle={handleDrawerToggle} loggedIn={loggedIn} />
        </div>
        {loggedIn ? (
          <SideNavigation handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
        ) : null}
        <div className={classes.appContent}>
          {loggedIn ? (
            <div className={classes.appSidebar}>
              <AppMenu />
              <div className={classes.settingsButton}>
                <Settings />
                Configurations
              </div>
            </div>
          ) : null}
          <div
            className={clsx(classes.appConatiner, {
              [classes.appConatinerNonLI]: !loggedIn,
            })}
          >
            {children}
          </div>
        </div>
        {!loggedIn &&
          (window.location.hostname === 'ph.kronikare.ai' ||
            window.location.hostname === 'vintens-dashboard.hartmann.info') && (
            <Box
              sx={{
                backgroundColor: '#fff',
                display: 'flex',
                paddingRight: '30px',
                paddingY: '10px',
                justifyContent: 'flex-end',
                gridGap: '30px',
              }}
            >
              <Link
                href={`http://${window.location.hostname}/web/drwound/login${routeConstants.TERMANDCONDIITON}`}
                underline="hover"
                target="_blank"
              >
                Terms & Conditions
              </Link>
              <Link
                href="https://www.hartmann.info/en-corp/legal"
                underline="hover"
                target="_blank"
                rel="nofollow"
              >
                Legal Information
              </Link>
              <Link
                href="https://www.hartmann.info/en-corp/data-protection"
                underline="hover"
                target="_blank"
                rel="nofollow"
              >
                Data Protection
              </Link>
              <Link
                href="https://www.hartmann.info/en-corp/cookie-policy"
                underline="hover"
                target="_blank"
                rel="nofollow"
              >
                Cookie Policy
              </Link>
            </Box>
          )}
      </div>

      <SnackbarAlert />
    </React.Fragment>
  )
}
const drawerWidth = '105px'
const headerHeight = '64px'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  appHeader: {
    height: headerHeight,
    position: 'fixed',
    top: 0,
    zIndex: 9999,
  },
  appContent: {
    flex: 1,
    display: 'flex',
    backgroundColor: '#FAFAFA',
    height: '100%',
  },
  appSidebar: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    maxWidth: drawerWidth,
    position: 'fixed',
    top: headerHeight,
    height: `calc(100vh - ${headerHeight})`,
    overflow: 'auto',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    // paddingBottom: theme.spacing(4),
    background: '#fff',
    color: '#000',
    display: 'flex',
    flexDirection: 'column',
  },
  appConatiner: {
    height: '100%',
    paddingTop: `calc(${headerHeight} + 32px)`,
    paddingLeft: `calc(${drawerWidth} + 40px)`,
    paddingBottom: 33,
    paddingRight: 40,
    width: '100%',
    backgroundColor: '#FAFAFA',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  appConatinerNonLI: {
    paddingLeft: 10,
  },
  content: {
    flexGrow: 1,
    // height: "100vh",
    overflow: 'auto',
    padding: '30px 20px',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: '#FAFAFA',
  },
  settingsButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 8px',
    color: '#485159',
    marginBottom: 35,
  },
}))

export default React.memo(PageContainer)
