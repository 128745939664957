//EN
const main = {
    SetInitialPassword_titleSetInitialPassword_display: true,
    SetInitialPassword_titleSetInitialPassword_text: 'Set Initial Password',
    SetInitialPassword_labelPreferredUsername_display: true,
    SetInitialPassword_labelPreferredUsername_text: 'Preferred Username',
    SetInitialPassword_labelNewPassword_display: true,
    SetInitialPassword_labelNewPassword_text: 'New Password',
    SetInitialPassword_labelConfirmNewPassword_display: true,
    SetInitialPassword_labelConfirmNewPassword_text: 'Confirm New Password',
    SetInitialPassword_labelSubmitButton_display: true,
    SetInitialPassword_labelSubmitButton_text: 'Submit',
    SetInitialPassword_validationMessagePreferredUsernameRequired_display: true,
    SetInitialPassword_validationMessagePreferredUsernameRequired_text: 'Preferred Username is required',
    SetInitialPassword_validationMessagePasswordRequired_display: true,
    SetInitialPassword_validationMessagePasswordRequired_text: 'Password is required',
    SetInitialPassword_validationMessageNewPasswordRequired_display: true,
    SetInitialPassword_validationMessageNewPasswordRequired_text: 'Confirm New Password is required',
    SetInitialPassword_validationMessagePasswordDoNotMatch_display: true,
    SetInitialPassword_validationMessagePasswordDoNotMatch_text: 'Passwords do not match!',
    SetInitialPassword_validationMessagePasswordCriteria_display: true,
    SetInitialPassword_validationMessagePasswordCriteria_text: 'Must Contain 8 Characters which includes One Uppercase, One Lowercase, One Number and One Special Case Character',
    SetInitialPassword_errorMessageProcessFailed_display: true,
    SetInitialPassword_errorMessageProcessFailed_text: 'Setting of initial password failed. Please login and try again.',
    SetInitialPassword_errorMessageNetworkIssues_display: true,
    SetInitialPassword_errorMessageNetworkIssues_text: 'Please check your network!',
    SetInitialPassword_errorMessageServerIssues_display: true,
    SetInitialPassword_errorMessageServerIssues_text: 'Unable to get a response! Please try again.',
}

export default {...main}
