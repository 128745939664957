const routes = {
  AFTERLOGIN: '/web/drwound/after-login',
  DASHBOARD: `https://${window.location.hostname}/web/drwound/dashboard`,
  LOGIN: '/',
  LOGOUT: '/web/drwound/logout',
  SETINITIALPASSWORD: '/set-initial-password',
  SETPASSWORD: '/set-password',
  FORGOTPASSWORD: '/forgot-password',
  RESETPASSWORD: '/reset-password',
  RESETPASSWORD_DEV: '/reset-password?token=testingToken',
  REGISTERPIN: '/register-pin',
  SETTOTP: '/setup-totp',
  VALIDATETOTP: '/get-totp',
  TERMANDCONDIITON: '/terms-and-conditions',
}

export default routes
