import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import {
  AppBar,
  makeStyles,
  alpha,
  MenuItem,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  LinearProgress,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
// import PHLogo from "../../../resources/Paul_Hartman_Logo_lowres.png";
import KronikareLogo from '../../../resources/KroniKareNewLogo_NoBG_LowRes.png'
import useViewport from '../../../hooks/useViewport'
import MenuIcon from '@material-ui/icons/Menu'
import BreakPoints from '../../../constants/breakpoints'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useSelector, useDispatch } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import * as localeList from '@material-ui/core/locale'
import { setThemeLocale, fetchGeoIPLocation } from '../../../store/actions/ui-actions'
import routeConstants from '../../../constants/route-constants'

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#fff',
    color: '#000',
    height: 64,
    boxShadow: 'none',
  },
  toolBar: {
    height: '100%',
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    width: 'auto',
    height: 40,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      // marginLeft: theme.spacing(1),
      width: 'auto',
    },
    border: '1px solid #C2C5C7',
  },
  search2: {
    marginLeft: 10,
    marginRight: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: '200px',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    // position: "absolute",
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: theme.spacing(2),
    // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
      '&:focus': {
        width: '24ch',
      },
    },
  },
}))

const TopBar = ({ handleDrawerToggle, loggedIn }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { width } = useViewport()
  const showLoader = useSelector((state) => state.ui.showLoader)
  const locale = useSelector((state) => state.ui.locale)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const [search, setsearch] = useState('')
  let history = useHistory()

  useEffect(() => {
    dispatch(fetchGeoIPLocation())
  }, [])
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleChange = (e) => {
    e.preventDefault()
    setsearch(e.target.value)
  }
  const transfer = (e) => {
    e.preventDefault()
    history.push({
      pathname: `${routeConstants.PATIENTS}/list/search`,
      search: `?q=${encodeURIComponent(search)}`,
    })
  }
  const filteredLocales = localeList
    ? Object.keys(localeList).filter(
        (locale) =>
          locale === 'deDE' ||
          locale === 'enUS' ||
          locale === 'esES' ||
          locale === 'frFR' ||
          locale === 'itIT'
      )
    : []
  const filteredTransformedLocales = filteredLocales.length
    ? filteredLocales.map((locale) => locale.substring(0, 2).toUpperCase())
    : []

  return (
    <AppBar className={classes.appBar}>
      <Toolbar
        variant={width > BreakPoints.MEDIUM ? 'dense' : 'regular'}
        className={classes.toolBar}
      >
        {/* Hamburger Menu Tablet & Mobile */}
        {width <= BreakPoints.MEDIUM && loggedIn && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        )}

        {(window.location.hostname === 'sea.kronikare.ai' ||
          window.location.hostname === 'eu.kronikare.ai') && (
          <img
            // src={
            //   process.env.NODE_ENV === 'production'
            //     ? '/web/drwound/login/images/hartmann_logo.png'
            //     : 'http://ph.kronikare.ai/web/drwound/login/images/hartmann_logo.png'
            // }
            src={KronikareLogo}
            alt="KroniKare"
            className={classes.logo}
          />
        )}
        <Autocomplete
          options={filteredTransformedLocales}
          getOptionLabel={(key) => {
            // let string = `${key.substring(0, 2).toUpperCase()}-${key.substring(
            //   2,
            //   4
            // )}`;
            let string = `${key.substring(0, 2).toUpperCase()}`
            return string
          }}
          style={{
            minWidth: 60,
            marginRight: 20,
            // marginLeft:
            //   width > BreakPoints.MEDIUM ? (loggedIn ? 0 : "auto") : "auto",
            marginLeft: 'auto',
          }}
          value={locale}
          disableClearable
          onChange={(event, newValue) => {
            dispatch(setThemeLocale(newValue.substring(0, 2).toUpperCase()))
          }}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
        <TopBarMenu handleClose={handleClose} anchorEl={anchorEl} open={open} screenWidth={width} />
      </Toolbar>
      {showLoader && <LinearProgress color="secondary" />}
    </AppBar>
  )
}

const TopBarMenu = ({ handleClose, anchorEl, open, screenWidth }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          marginTop: '54px',
        },
      }}
    >
      {screenWidth < BreakPoints.MEDIUM && <MenuItem disabled>Organization User V</MenuItem>}
      <MenuItem onClick={() => history.push(`${routeConstants.SETPASSWORD}`)}>
        Change Password
      </MenuItem>
      <MenuItem onClick={() => history.push(`${routeConstants.SETPASSWORD}`)}>
        Register TOTP
      </MenuItem>
      <MenuItem onClick={() => history.push(`${routeConstants.SETPASSWORD}`)}>
        Register PIN
      </MenuItem>
    </Menu>
  )
}

export default TopBar
