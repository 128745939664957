import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import AdjustIcon from "@material-ui/icons/Adjust";
import DashboardOutlined from "@material-ui/icons/DashboardOutlined";
import appRoutes from "../../../constants/route-constants";
import AppMenuItem from "./AppMenuItem";

const appMenuItems = [
  {
    name: "Overview",
    link: appRoutes.DASHBOARD,
    Icon: DashboardOutlined,
  },
  {
    name: "Support",
    link: appRoutes.SUPPORT,
    Icon: AdjustIcon,
  },
];

const AppMenu = () => {
  const classes = useStyles();

  return (
    <List component="nav" className={classes.appMenu} disablePadding>
      {appMenuItems.map((item, index) => (
        <AppMenuItem {...item} key={index} />
      ))}
    </List>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    appMenu: {
      width: "100%",
      padding: "35px 0",
      height: "100%",
    },
  })
);

export default AppMenu;
