import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers/index";
import logger from "redux-logger";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [thunk];
// if (process.env.NODE_ENV === "development") middlewares.push(logger);

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(...middlewares))
);
export default store;
