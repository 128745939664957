//IT
const main = {
  ValidationMessages_UsernameRequired_display: true,
  ValidationMessages_UsernameRequired_text: 'Il nome utente è obbligatorio',
  ValidationMessages_PreferredUsernameRequired_display: true,
  ValidationMessages_PreferredUsernameRequired_text: 'Preferred Username is required',
  ValidationMessages_PasswordRequired_display: true,
  ValidationMessages_PasswordRequired_text: 'La password è obbligatoria',
  ValidationMessages_CurrentPasswordRequired_display: true,
  ValidationMessages_CurrentPasswordRequired_text: 'La password attuale è obbligatoria',
  ValidationMessages_NewPasswordRequired_display: true,
  ValidationMessages_NewPasswordRequired_text: 'Una nuova password è obbligatoria',
  ValidationMessages_ConfirmNewPasswordRequired_display: true,
  ValidationMessages_ConfirmNewPasswordRequired_text:
    'La conferma della nuova password è obbligatoria',
  ValidationMessages_PasswordDoNotMatch_display: true,
  ValidationMessages_PasswordDoNotMatch_text: 'Le password non corrispondono!',
  ValidationMessages_PasswordCriteria_display: true,
  ValidationMessages_PasswordCriteria_text:
    "Deve contenere almeno 8 caratteri (raccomandati 12 o più) e includere una lettera maiuscola, una minuscola, un numero e un carattere speciale (!”#$%&'()*+,-./:;<=>?)",
  ValidationMessages_verificationCodeRequired_display: true,
  ValidationMessages_verificationCodeRequired_text: 'Il codice di verifica è obbligatorio',
}

export default { ...main }
