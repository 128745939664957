import Login from './Login'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import SetInitialPassword from './SetInitialPassword'
import SetNewPassword from './SetNewPassword'
import RegisterPIN from './RegisterPIN'
import RegisterTOTP from './RegisterTOTP'
import ErrorSuccessMessages from './ErrorSuccessMessages'
import ValidationMessages from './ValidationMessages'
import Eula from './Eula'

export default {
  name: 'FR',
  ...Login,
  ...ForgotPassword,
  ...ResetPassword,
  ...SetInitialPassword,
  ...SetNewPassword,
  ...RegisterPIN,
  ...RegisterTOTP,
  ...ErrorSuccessMessages,
  ...ValidationMessages,
  ...Eula,
}
