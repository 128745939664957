import basic from './basic'
import PHFR from './PHFR'
import PHGR from './PHGR'
import PHSP from './PHSP'
import PHUK from './PHUK'
import EN from './EN'
import DE from './DE'
import FR from './FR'
import ES from './ES'
import IT from './IT'

export default {
  basic,
  PHFR,
  PHGR,
  PHSP,
  PHUK,
  EN,
  DE,
  FR,
  ES,
  IT,
}
