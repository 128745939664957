//basic
const main = {
    SetNewPassword_titleSetNewPassword_display: true,
    SetNewPassword_titleSetNewPassword_text: 'Set New Password',
    SetNewPassword_labelCurrentPassword_display: true,
    SetNewPassword_labelCurrentPassword_text: 'Current password',
    SetNewPassword_labelNewPassword_display: true,
    SetNewPassword_labelNewPassword_text: 'New Password',
    SetNewPassword_labelConfirmNewPassword_display: true,
    SetNewPassword_labelConfirmNewPassword_text: 'Confirm New Password',
    SetNewPassword_labelSubmitButton_display: true,
    SetNewPassword_labelSubmitButton_text: 'Submit',
    SetNewPassword_labelBackToDashboard_display: true,
    SetNewPassword_labelBackToDashboard_text: 'Back to Dashboard',
}

export default {...main}
