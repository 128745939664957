//FR
const main = {
  RegisterPIN_titleRegisterPIN_display: true,
  RegisterPIN_titleRegisterPIN_text: 'Enregistrer le code PIN',
  RegisterPIN_label6DigitPin_display: true,
  RegisterPIN_label6DigitPin_text: "Code PIN à 6 chiffres pour l'application mobile",
  RegisterPIN_labelSubmitButton_display: true,
  RegisterPIN_labelSubmitButton_text: 'Soumettre',
  RegisterPIN_labelBackToDashboard_display: true,
  RegisterPIN_labelBackToDashboard_text: 'Retour au tableau de bord',
  RegisterPIN_validationMessagePinRequired_display: true,
  RegisterPIN_validationMessagePinRequired_text: 'Un code PIN est requis',
  RegisterPIN_validationMessagePinOnlyDigits_display: true,
  RegisterPIN_validationMessagePinOnlyDigits_text: 'Doit être uniquement des chiffres',
  RegisterPIN_validationMessagePinSixDigit_display: true,
  RegisterPIN_validationMessagePinSixDigit_text: 'Doit être exactement 6 chiffres',
  RegisterPIN_errorMessageProcessFailed_display: true,
  RegisterPIN_errorMessageProcessFailed_text:
    'Le réglage du code PIN a échoué. Veuillez réessayer.',
  RegisterPIN_errorMessageNetworkIssues_display: true,
  RegisterPIN_errorMessageNetworkIssues_text: 'Veuillez vérifier votre réseau',
  RegisterPIN_errorMessageServerIssues_display: true,
  RegisterPIN_errorMessageServerIssues_text:
    "Impossible d'obtenir une réponse. Veuillez réessayer.",
}

export default { ...main }
