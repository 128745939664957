//DE
const main = {
  ResetPassword_titleResetPassword_display: true,
  ResetPassword_titleResetPassword_text: 'Passwort zurücksetzen',
  ResetPassword_labelNewPassword_display: true,
  ResetPassword_labelNewPassword_text: 'Neues Passwort',
  ResetPassword_labelConfirmNewPassword_display: true,
  ResetPassword_labelConfirmNewPassword_text: 'Neues Passwort bestätigen',
  ResetPassword_labelVerificationCode_display: true,
  ResetPassword_labelVerificationCode_text: 'Verifizierungscode',
  ResetPassword_labelSubmitButton_display: true,
  ResetPassword_labelSubmitButton_text: 'Absenden',
  ResetPassword_validationMessageNewPasswordRequired_display: true,
  ResetPassword_validationMessageNewPasswordRequired_text: 'Neues Passwort ist erforderlich',
  ResetPassword_validationMessageConfirmNewPasswordRequired_display: true,
  ResetPassword_validationMessageConfirmNewPasswordRequired_text:
    'Neues Passwort bestätigen ist erforderlich',
  ResetPassword_validationMessagePasswordDoNotMatch_display: true,
  ResetPassword_validationMessagePasswordDoNotMatch_text:
    'Die Passwörter sind nicht identisch! Bitte geben Sie identische Passwörter ein.',
  ResetPassword_errorMessageProcessFailed_display: true,
  ResetPassword_errorMessageProcessFailed_text:
    'Die Eingabe des neuen Passworts ist fehlgeschlagen. Bitte versuchen Sie es erneut.',
  ResetPassword_errorMessageNetworkIssues_display: true,
  ResetPassword_errorMessageNetworkIssues_text: 'Bitte überprüfen Sie Ihr Netzwerk!',
  ResetPassword_errorMessageServerIssues_display: true,
  ResetPassword_errorMessageServerIssues_text:
    'Sie haben keine Antwort erhalten! Bitte versuchen Sie es erneut.',
}

export default { ...main }
