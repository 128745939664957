import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from '@material-ui/core/Link';
import { IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import useViewport from "../hooks/useViewport";
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import { passwordChange } from "../store/actions/account-action";
import routeConstants from "../constants/route-constants";
import customization from '../utils/customization-utils'
import { setThemeLocale } from "../store/actions/ui-actions";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  demo1: {
    // backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
  },
  inputField: {
    marginBottom: 32,
  },
  input: {
    backgroundColor: "white",
    border: "1px solid #C2C5C7",
    borderRadius: 8,
    padding: "8px 8px",
    [theme.breakpoints.down("md")]: {
      padding: "5px 5px",
    },
  },
  label: {
    color: "black",
  },
  menuPaper: {
    maxHeight: 500,
  },
  link: {
    color: "#0D3082",
    marginTop: 16,
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
  },
  submitButton: {
    whiteSpace: "nowrap",
    minWidth: "100%",
    // textTransform: "none",
    marginTop: 9,
    marginBottom: 16,
    borderRadius: 8,
    padding: "8px 8px",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: 14,
    },
    height: 48,
    fontSize: 16,
  }
}));

const SetPassword = () => {
  const { width } = useViewport();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const selectedLocale = useSelector((state) => state.ui.locale)
  const organizationListLoaded = useSelector((state) => state.data.organizationListLoaded)
  const organizationList = useSelector((state) => state.data.list.organizationList)
  const organizationListId =
    organizationList.organizations && organizationList.organizations.length
      ? organizationList.organizations[0].id
      : ''
  const organizationListAcronym =
    organizationList.organizations && organizationList.organizations.length
      ? organizationList.organizations[0].acronym
      : ''
  // const organizationListAcronym = 'PHGR'
  const orgLocale = customization(organizationListAcronym).Locale_defaultSelection_text
  // const c = customization(organizationListAcronym)
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)
  const [c, setC] = useState(customization(selectedLocale))

  useEffect(() => {
      dispatch(setThemeLocale(orgLocale))
  },[])

  useEffect(() => {
    setC(customization(selectedLocale))
  },[selectedLocale])
  

  const validationSchema = Yup.object({
    current_password: Yup.string().required(c.ValidationMessages_CurrentPasswordRequired_text),
    new_password: Yup.string()
    .required(c.ValidationMessages_NewPasswordRequired_text)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      c.ValidationMessages_PasswordCriteria_text
    ),
    confirm_new_password: Yup.string()
    .required(c.ValidationMessages_ConfirmNewPasswordRequired_text)
    .oneOf(
      [Yup.ref("new_password"), null],
      c.ValidationMessages_PasswordDoNotMatch_text
    ),
  });

  const handleToggleCurrentPassword = () => setShowCurrentPassword(showCurrentPassword => !showCurrentPassword)
  const handleToggleNewPassword = () => setShowNewPassword(showNewPassword => !showNewPassword)
  const handleToggleConfirmNewPassword = () => setShowConfirmNewPassword(showConfirmNewPassword => !showConfirmNewPassword)

  return organizationListLoaded && (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="column"
        >
          <Box style={{ maxWidth: 500 }}>
            <Typography
              variant="h4"
              style={{ fontWeight: 500, marginBottom: 46, marginTop: 52, textAlign: "center" }}
            >
              {c.SetNewPassword_titleSetNewPassword_text}
            </Typography>
            <Formik
              enableReinitialize={true}
              initialValues={{
                password: "",
                passwordConfirmation: "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                dispatch(passwordChange(values.current_password, values.new_password, organizationListAcronym));
                setSubmitting(true);
              }}
              validationSchema={validationSchema}
            >
              {({ errors, touched, isSubmitting, values, setFieldValue, isValid, dirty }) => (
                <Form>
                  <Field
                    component={TextField}
                    className={classes.inputField}
                    label={c.SetNewPassword_labelCurrentPassword_text}
                    name="current_password"
                    type={showCurrentPassword?'text':'password'}
                    fullWidth
                    error={errors.current_password && touched.current_password}
                    helperText={
                      errors.current_password && touched.current_password
                        ? errors.current_password
                        : null
                    }
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton onClick={handleToggleCurrentPassword} >
                            {showNewPassword ? <Visibility />:<VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Field
                    component={TextField}
                    className={classes.inputField}
                    label={c.SetNewPassword_labelNewPassword_text}
                    name="new_password"
                    type={showNewPassword?'text':'password'}
                    fullWidth
                    error={errors.new_password && touched.new_password}
                    helperText={
                      errors.new_password && touched.new_password
                        ? errors.new_password
                        : null
                    }
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton onClick={handleToggleNewPassword} >
                            {showNewPassword ? <Visibility />:<VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Field
                    component={TextField}
                    className={classes.inputField}
                    label={c.SetNewPassword_labelConfirmNewPassword_text}
                    type={showConfirmNewPassword?'text':'password'}
                    name="confirm_new_password"
                    fullWidth
                    error={
                      errors.confirm_new_password &&
                      touched.confirm_new_password
                    }
                    helperText={
                      errors.confirm_new_password &&
                      touched.confirm_new_password
                        ? errors.confirm_new_password
                        : null
                    }
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton onClick={handleToggleConfirmNewPassword} >
                            {showConfirmNewPassword ? <Visibility />:<VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Button
                    type="submit"
                    disabled={isSubmitting || (!values.current_password||!values.new_password||!values.confirm_new_password) }
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}
                  >
                    {c.SetNewPassword_labelSubmitButton_text}
                  </Button>
                  <Link href={routeConstants.DASHBOARD} className={classes.link}>
                    <ArrowBackIosIcon />
                    <Typography style={{ marginLeft: 10 }}>
                      {c.SetNewPassword_labelBackToDashboard_text}
                    </Typography>
                  </Link>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default SetPassword;
