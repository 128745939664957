import history from '../../utils/history'
// import { useHistory } from 'react-router'

import { ACCOUNT } from '../../constants/action-constants'
import accountService from '../../services/account-service'
import { snackbarActions } from './ui-actions'
import routes from '../../constants/route-constants'
import customization from '../../utils/customization-utils'

export function login(unique_id, password, next, selectedLocale) {
  const c = customization(selectedLocale)
  let user = next ? { unique_id, next } : { unique_id }
  return async (dispatch) => {
    try {
      const response = await accountService().login(unique_id, password)
      localStorage.removeItem('force_registerPin')

      if (
        response.response &&
        response.response.data &&
        (response.response.status === 447 ||
          response.response.status === 446 ||
          response.response.status === 443 ||
          response.response.status === 444)
      ) {
        let userSession = response.response.data.user_session
        let userSessionMesh = { ...user, ...userSession }
        localStorage.removeItem('selectedOrganization')
        localStorage.setItem('ph_kronikare_user_session', JSON.stringify(userSessionMesh))
      }

      if (response.status === 200 && response.data) {
        let userSession = response.data.user_session
        let userSessionMesh = { ...user, ...userSession }
        localStorage.removeItem('selectedOrganization')
        localStorage.setItem('ph_kronikare_user_session', JSON.stringify(userSessionMesh))
        if (process.env.NODE_ENV === 'production') {
          window.location.replace(
            next ? `${routes.AFTERLOGIN}?next=${encodeURIComponent(next)}` : routes.AFTERLOGIN
          )
        } else {
          window.location.replace(`http://localhost:3001`)
        }
      } else if (response.response && response.response.status === 443) {
        let userSession = response.response.data.user_session
        if (
          userSession &&
          userSession.challenges.length &&
          userSession.challenges.includes('first_login')
        ) {
          // force users to register pin, except manufacturer role
          if (!userSession.role_ids.includes('12')) {
            localStorage.setItem('force_registerPin', true)
          }
          window.location.replace(
            `/web/drwound/login${routes.SETINITIALPASSWORD}?unique_id=${unique_id}`
          )
        }
      } else if (response.response && response.response.status === 444) {
        let userSession = response.response.data.user_session
        if (
          userSession &&
          userSession.challenges.length &&
          userSession.challenges.includes('new_password_required')
        ) {
          window.location.replace(`/web/drwound/login${routes.SETPASSWORD}`)
        }
      } else if (response.response && response.response.status === 447) {
        //setup totp
        window.location.replace(`/web/drwound/login${routes.SETTOTP}`)
      } else if (response.response && response.response.status === 446) {
        // totp_required
        window.location.replace(
          next
            ? `/web/drwound/login${routes.VALIDATETOTP}?next=${encodeURIComponent(next)}`
            : `/web/drwound/login${routes.VALIDATETOTP}`
        )
      } else if (response.response && response.response.status === 400) {
        dispatch(
          snackbarActions.showSnackbar(
            c.ErrorMessages_400_BadRequest_text || 'Something went wrong... Bad Request!',
            'error'
          )
        )
      } else if (response.response && response.response.status === 401) {
        dispatch(
          snackbarActions.showSnackbar(
            c.ErrorMessages_401_LoginInvalidCredentials_text ||
              'Wrong Username/Password! Please try again.',
            'error'
          )
        )
      } else if (response.response && response.response.status === 404) {
        dispatch(
          snackbarActions.showSnackbar(
            c.ErrorMessages_404_NotFound_text || 'Something went wrong... Resource is not found!',
            'error'
          )
        )
      } else {
        dispatch(
          snackbarActions.showSnackbar(
            c.ErrorMessages_GeneralErrorMessage_text || 'Something went wrong...',
            'error'
          )
        )
      }
    } catch (e) {
      dispatch(
        snackbarActions.showSnackbar(
          c.ErrorMessages_GeneralErrorMessage_text || 'Something went wrong...',
          'error'
        )
      )
    }
  }
}

export function requestSecretKey({ selectedLocale, onSuccessCallback = () => {} }) {
  const c = customization(selectedLocale)
  return async (dispatch) => {
    try {
      const response = await accountService().requestSecretKey()
      if (response.status === 200 && response.data) {
        onSuccessCallback(response)
      } else if (response.response && response.response.status === 440) {
        // session is missing, redirect to login page
        dispatch(snackbarActions.showSnackbar('The user session is missing', 'error'))
        window.location.replace(`/web/drwound/login`)
      } else if (response.response && response.response.status === 401) {
        // The totp has been already setup
        dispatch(snackbarActions.showSnackbar('The totp has been already setup', 'error'))
        return 401
      } else {
        dispatch(
          snackbarActions.showSnackbar(
            c.ErrorMessages_GeneralErrorMessage_text || 'Something went wrong...',
            'error'
          )
        )
      }
    } catch (e) {
      dispatch(
        snackbarActions.showSnackbar(
          c.ErrorMessages_GeneralErrorMessage_text || 'Something went wrong...',
          'error'
        )
      )
    }
  }
}

export function forgotPassword({ unique_id, selectedLocale, onSuccessCallback = () => {} }) {
  const c = customization(selectedLocale)
  return async (dispatch) => {
    try {
      const response = await accountService().forgotPassword(unique_id)
      if (response.status === 200 && response.data) {
        dispatch(
          snackbarActions.showSnackbar(
            c.SuccessMessages_ForgetPasswordLink_text ||
              'The verification code has been sent to the user registered email address',
            'success'
          )
        )
        onSuccessCallback()
      } else {
        dispatch(
          snackbarActions.showSnackbar(
            c.ErrorMessages_GeneralErrorMessage_text || 'Something went wrong...',
            'error'
          )
        )
      }
    } catch (e) {
      dispatch(
        snackbarActions.showSnackbar(
          c.ErrorMessages_GeneralErrorMessage_text || 'Something went wrong...',
          'error'
        )
      )
    }
  }
}

export function resetPassword({
  unique_id,
  verification_code,
  new_password,
  selectedLocale,
  onSuccessCallback = () => {},
  onErrorCallback = () => {},
}) {
  const c = customization(selectedLocale)
  return async (dispatch) => {
    try {
      const response = await accountService().resetPassword(
        unique_id,
        verification_code,
        new_password
      )
      if (response.response && response.response.status === 440) {
        dispatch(
          snackbarActions.showSnackbar(
            c.SuccessMessages_PasswordSetSuccess_text || 'Password has been successfully set.',
            'success'
          )
        )
        onSuccessCallback()
        // if (process.env.NODE_ENV === 'production') {
        //   window.location.replace(routes.LOGIN)
        // } else {
        //   window.location.replace(`http://localhost:3001`)
        // }
      } else {
        dispatch(
          snackbarActions.showSnackbar(
            c.ErrorMessages_GeneralErrorMessage_text || 'Something went wrong...',
            'error'
          )
        )
        onErrorCallback()
        // if (process.env.NODE_ENV === 'production') {
        //   window.location.replace(routes.LOGIN)
        // } else {
        //   window.location.replace(`http://localhost:3001`)
        // }
      }
    } catch (e) {
      dispatch(
        snackbarActions.showSnackbar(
          c.ErrorMessages_GeneralErrorMessage_text || 'Something went wrong...',
          'error'
        )
      )
      if (process.env.NODE_ENV === 'production') {
        window.location.replace(routes.LOGIN)
      } else {
        window.location.replace(`http://localhost:3001`)
      }
    }
  }
}

export function passwordChange(current_password, new_password, orgAcronym) {
  const c = customization(orgAcronym)
  return async (dispatch) => {
    try {
      const response = await accountService().passwordChange(current_password, new_password)
      if (response.status === 200 && response.data) {
        dispatch(
          snackbarActions.showSnackbar(
            c.SuccessMessages_PasswordChangeSuccess_text ||
              'Password has been successfully changed.',
            'success'
          )
        )
        if (process.env.NODE_ENV === 'production') {
          window.location.replace(routes.DASHBOARD)
        } else {
          window.location.replace(`http://localhost:3001`)
        }
      } else {
        dispatch(
          snackbarActions.showSnackbar(
            c.ErrorMessages_GeneralErrorMessage_text || 'Password change is unsuccessful!',
            'error'
          )
        )
        if (process.env.NODE_ENV === 'production') {
          window.location.replace(routes.DASHBOARD)
        } else {
          window.location.replace(`http://localhost:3001`)
        }
      }
    } catch (e) {
      dispatch(
        snackbarActions.showSnackbar(
          c.ErrorMessages_GeneralErrorMessage_text || 'Password change is unsuccessful!',
          'error'
        )
      )
      if (process.env.NODE_ENV === 'production') {
        window.location.replace(routes.DASHBOARD)
      } else {
        window.location.replace(`http://localhost:3001`)
      }
    }
  }
}
export function initialPasswordChange(preferred_username, password, selectedLocale) {
  const c = customization(selectedLocale)
  return async (dispatch) => {
    try {
      const response = await accountService().initialPasswordChange(preferred_username, password)
      if (response.status === 200 && response.data) {
        dispatch(
          snackbarActions.showSnackbar(
            c.SuccessMessages_UsernamePasswordSetSuccess_text ||
              'Preffered Username and Password has been successfully set.',
            'success'
          )
        )
        if (localStorage.getItem('force_registerPin')) {
          window.location.replace(`/web/drwound/login${routes.REGISTERPIN}`)
        } else {
          window.location.replace(routes.DASHBOARD)
        }
      } else if (response.response && response.response.status === 409) {
        dispatch(
          snackbarActions.showSnackbar(
            c.ErrorMessages_409_Username_already_exists_text ||
              'A similar user already exists please change it.',
            'error'
          )
        )
      } else if (response.response && response.response.status === 447) {
        //setup totp
        window.location.replace(`/web/drwound/login${routes.SETTOTP}`)
      } else {
        dispatch(
          snackbarActions.showSnackbar(
            c.ErrorMessages_GeneralErrorMessage_text ||
              'Preffered Username and Password change is unsuccessful!',
            'error'
          )
        )
        if (process.env.NODE_ENV === 'production') {
          window.location.replace(routes.LOGIN)
        } else {
          window.location.replace(`http://localhost:3001`)
        }
      }
    } catch (e) {
      dispatch(
        snackbarActions.showSnackbar(
          c.ErrorMessages_GeneralErrorMessage_text ||
            'Preffered Username and Password change is unsuccessful!',
          'error'
        )
      )
      if (process.env.NODE_ENV === 'production') {
        window.location.replace(routes.LOGIN)
      } else {
        window.location.replace(`http://localhost:3001`)
      }
    }
  }
}

export function setPin(pin, orgAcronym) {
  const c = customization(orgAcronym)
  return async (dispatch) => {
    try {
      const response = await accountService().setPin(pin)
      if (
        (response.status === 200 && response.data) ||
        (response.response &&
          response.response.status === 440 &&
          response.response.data?.message === 'The pin has been set')
      ) {
        localStorage.removeItem('force_registerPin')
        dispatch(
          snackbarActions.showSnackbar(
            c.SuccessMessages_PINSetSuccess_text || 'PIN registration is successful.',
            'success'
          )
        )
        if (process.env.NODE_ENV === 'production') {
          window.location.replace(routes.LOGIN)
        } else {
          window.location.replace(`http://localhost:3001`)
        }
      } else {
        dispatch(
          snackbarActions.showSnackbar(
            c.ErrorMessages_GeneralErrorMessage_text ||
              'Pin registration is unsuccessful! Please try again.',
            'error'
          )
        )
        if (process.env.NODE_ENV === 'production') {
          window.location.replace(routes.DASHBOARD)
        } else {
          window.location.replace(`http://localhost:3001`)
        }
      }
    } catch (e) {
      dispatch(
        snackbarActions.showSnackbar(
          c.ErrorMessages_GeneralErrorMessage_text ||
            'Pin registration is unsuccessful! Please try again.',
          'error'
        )
      )
      if (process.env.NODE_ENV === 'production') {
        window.location.replace(routes.DASHBOARD)
      } else {
        window.location.replace(`http://localhost:3001`)
      }
    }
  }
}
export function confirmSecretKey(totp, orgAcronym) {
  const c = customization(orgAcronym)
  return async (dispatch) => {
    try {
      const response = await accountService().confirmSecretKey(totp)
      if (response.status === 200 && response.data) {
        dispatch(
          snackbarActions.showSnackbar(
            c.SuccessMessages_TOTPSetSuccess_text || 'TOTP registration is successful.',
            'success'
          )
        )
        if (localStorage.getItem('force_registerPin')) {
          window.location.replace(`/web/drwound/login${routes.REGISTERPIN}`)
        } else {
          window.location.replace(routes.DASHBOARD)
        }
      } else if (response.response && response.response.status === 401) {
        // totp incorect
        dispatch(snackbarActions.showSnackbar('Incorrect TOTP', 'error'))
      } else if (response.response && response.response.status === 440) {
        // session is missing, redirect to login page
        dispatch(snackbarActions.showSnackbar('The user session is missing', 'error'))
        window.location.replace(`/web/drwound/login`)
      } else if (response.response && response.response.status === 404) {
        dispatch(
          snackbarActions.showSnackbar(
            'The secret key is not found or expired, Please refresh the page and scan the Qr code.',
            'error'
          )
        )
        // window.location.reload()
      } else {
        dispatch(
          snackbarActions.showSnackbar(
            c.ErrorMessages_GeneralErrorMessage_text || 'TOTP registration is unsuccessful!',
            'error'
          )
        )
        if (process.env.NODE_ENV === 'production') {
          window.location.replace(routes.DASHBOARD)
        } else {
          window.location.replace(`http://localhost:3001`)
        }
      }
    } catch (e) {
      dispatch(
        snackbarActions.showSnackbar(
          c.ErrorMessages_GeneralErrorMessage_text || 'TOTP registration is unsuccessful!',
          'error'
        )
      )
      if (process.env.NODE_ENV === 'production') {
        window.location.replace(routes.DASHBOARD)
      } else {
        window.location.replace(`http://localhost:3001`)
      }
    }
  }
}

export function validateTotp(totp, selectedLocale) {
  const c = customization(selectedLocale)
  return async (dispatch) => {
    try {
      const response = await accountService().validateTotp(totp)
      if (response.status === 200 && response.data) {
        if (process.env.NODE_ENV === 'production') {
          window.location.replace(routes.DASHBOARD)
        } else {
          window.location.replace(`http://localhost:3001`)
        }
      } else if (response.response && response.response.status === 401) {
        // totp incorect
        dispatch(snackbarActions.showSnackbar('Incorrect TOTP', 'error'))
      } else if (response.response && response.response.status === 440) {
        // session is missing, redirect to login page
        dispatch(snackbarActions.showSnackbar('The user session is missing', 'error'))
        window.location.replace(`/web/drwound/login`)
      }
    } catch (e) {
      dispatch(snackbarActions.showSnackbar(c.ErrorMessages_GeneralErrorMessage_text, 'error'))
      if (process.env.NODE_ENV === 'production') {
        window.location.replace(routes.DASHBOARD)
      } else {
        window.location.replace(`http://localhost:3001`)
      }
    }
  }
}
