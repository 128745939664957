import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { snackbarActions } from '../../store/actions/ui-actions';
import { useDispatch, useSelector } from 'react-redux';

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} variant="filled" {...props} ref={ref} />);

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},
}));

const SnackbarAlert = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const snackbar = useSelector(state => state.ui)

	useEffect(() => {
		if (snackbar.showSnackbar) {
			const timer = setTimeout(() => {
				dispatch(snackbarActions.hideSnackbar())
			}, 6000);
			return () => {
				clearTimeout(timer)
			}
		}
	}, [dispatch, snackbar])
	return (
		<div className={classes.root}>
			<Snackbar
				open={snackbar.showSnackbar}
				autoHideDuration={6000} onClose={() => dispatch(snackbarActions.hideSnackbar())}
			>
				<Alert onClose={() => dispatch(snackbarActions.hideSnackbar())} severity={snackbar.snackbarVariant}>
					{snackbar.snackbarMessage}
				</Alert>
			</Snackbar>
		</div>
	);
}

export default SnackbarAlert