//basic
const main = {
  ResetPassword_titleResetPassword_display: true,
  ResetPassword_titleResetPassword_text: 'Reset Password',
  ResetPassword_labelNewPassword_display: true,
  ResetPassword_labelNewPassword_text: 'New Password',
  ResetPassword_labelConfirmNewPassword_display: true,
  ResetPassword_labelConfirmNewPassword_text: 'Confirm New Password',
  ResetPassword_labelVerificationCode_display: true,
  ResetPassword_labelVerificationCode_text: 'Verification Code',
  ResetPassword_labelSubmitButton_display: true,
  ResetPassword_labelSubmitButton_text: 'Submit',
  ResetPassword_validationMessageNewPasswordRequired_display: true,
  ResetPassword_validationMessageNewPasswordRequired_text: 'New Password is required',
  ResetPassword_validationMessageConfirmNewPasswordRequired_display: true,
  ResetPassword_validationMessageConfirmNewPasswordRequired_text:
    'Confirm New Password is required',
  ResetPassword_validationMessagePasswordDoNotMatch_display: true,
  ResetPassword_validationMessagePasswordDoNotMatch_text: 'Passwords do not match!',
  ResetPassword_errorMessageProcessFailed_display: true,
  ResetPassword_errorMessageProcessFailed_text: 'Resetting of password failed. Please try again.',
  ResetPassword_errorMessageNetworkIssues_display: true,
  ResetPassword_errorMessageNetworkIssues_text: 'Please check your network!',
  ResetPassword_errorMessageServerIssues_display: true,
  ResetPassword_errorMessageServerIssues_text: 'Unable to get a response! Please try again.',
}

export default { ...main }
