//ES
const main = {
    SetInitialPassword_titleSetInitialPassword_display: true,
    SetInitialPassword_titleSetInitialPassword_text: 'Establecer la contraseña inicial',
    SetInitialPassword_labelPreferredUsername_display: true,
    SetInitialPassword_labelPreferredUsername_text: 'Nombre de usuario preferido',
    SetInitialPassword_labelNewPassword_display: true,
    SetInitialPassword_labelNewPassword_text: 'Nueva contraseña',
    SetInitialPassword_labelConfirmNewPassword_display: true,
    SetInitialPassword_labelConfirmNewPassword_text: 'Confirmar nueva contraseña',
    SetInitialPassword_labelSubmitButton_display: true,
    SetInitialPassword_labelSubmitButton_text: 'Enviar',
    SetInitialPassword_validationMessagePreferredUsernameRequired_display: true,
    SetInitialPassword_validationMessagePreferredUsernameRequired_text: 'Se requiere el nombre de usuario preferido',
    SetInitialPassword_validationMessagePasswordRequired_display: true,
    SetInitialPassword_validationMessagePasswordRequired_text: 'Se requiere una contraseña',
    SetInitialPassword_validationMessageNewPasswordRequired_display: true,
    SetInitialPassword_validationMessageNewPasswordRequired_text: 'Es necesario confirmar la nueva contraseña',
    SetInitialPassword_validationMessagePasswordDoNotMatch_display: true,
    SetInitialPassword_validationMessagePasswordDoNotMatch_text: 'Las contraseñas no coinciden. Introduzca contraseñas que coincidan.',
    SetInitialPassword_validationMessagePasswordCriteria_display: true,
    SetInitialPassword_validationMessagePasswordCriteria_text: 'Debe contener 8 caracteres que incluyan una mayúscula, una minúscula, un número y un carácter especial',
    SetInitialPassword_errorMessageProcessFailed_display: true,
    SetInitialPassword_errorMessageProcessFailed_text: 'La configuración de la contraseña inicial ha fallado. Por favor, inicie sesión e inténtelo de nuevo.',
    SetInitialPassword_errorMessageNetworkIssues_display: true,
    SetInitialPassword_errorMessageNetworkIssues_text: 'Por favor, compruebe su red.',
    SetInitialPassword_errorMessageServerIssues_display: true,
    SetInitialPassword_errorMessageServerIssues_text: 'No se ha podido obtener una respuesta. Por favor, inténtelo de nuevo.',
}

export default {...main}
