//DE
const main = {
    SetNewPassword_titleSetNewPassword_display: true,
    SetNewPassword_titleSetNewPassword_text: 'Neues Passwort festlegen',
    SetNewPassword_labelCurrentPassword_display: true,
    SetNewPassword_labelCurrentPassword_text: 'Aktuelles Passwort',
    SetNewPassword_labelNewPassword_display: true,
    SetNewPassword_labelNewPassword_text: 'Neues Passwort',
    SetNewPassword_labelConfirmNewPassword_display: true,
    SetNewPassword_labelConfirmNewPassword_text: 'Neues Passwort bestätigen',
    SetNewPassword_labelSubmitButton_display: true,
    SetNewPassword_labelSubmitButton_text: 'Absenden',
    SetNewPassword_labelBackToDashboard_display: true,
    SetNewPassword_labelBackToDashboard_text: 'Zurück zu Dashboard',
}

export default {...main}
