import { combineReducers } from "redux";
import ui from "./ui-reducer";
import account from "./account-reducer";
import organization from './organization-reducer'
import data from './data-reducer'

export default combineReducers({
  ui,
  account,
  organization,
  data,
});
