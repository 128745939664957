//ES
const main = {
    SetNewPassword_titleSetNewPassword_display: true,
    SetNewPassword_titleSetNewPassword_text: 'Establecer nueva contraseña',
    SetNewPassword_labelCurrentPassword_display: true,
    SetNewPassword_labelCurrentPassword_text: 'Contraseña actual',
    SetNewPassword_labelNewPassword_display: true,
    SetNewPassword_labelNewPassword_text: 'Nueva contraseña',
    SetNewPassword_labelConfirmNewPassword_display: true,
    SetNewPassword_labelConfirmNewPassword_text: 'Confirmar nueva contraseña',
    SetNewPassword_labelSubmitButton_display: true,
    SetNewPassword_labelSubmitButton_text: 'Enviar',
    SetNewPassword_labelBackToDashboard_display: true,
    SetNewPassword_labelBackToDashboard_text: 'Volver al panel de control',
}

export default {...main}
