//IT
const main = {
  RegisterTOTP_titleRegisterTOTP_display: true,
  RegisterTOTP_titleRegisterTOTP_text: 'Registra TOTP (password monouso a tempo)',
  RegisterTOTP_labelQRCode_display: true,
  RegisterTOTP_labelQRCode_text: 'Codice QR',
  RegisterTOTP_labelTimeRemaining_display: true,
  RegisterTOTP_labelTimeRemaining_text: 'Tempo rimanente',
  RegisterTOTP_timerUnit_display: true,
  RegisterTOTP_timerUnit_text: 's',
  RegisterTOTP_label6DigitTOTP_display: true,
  RegisterTOTP_label6DigitTOTP_text: 'TOTP (password monouso a tempo) a 6 cifre',
  RegisterTOTP_labelSubmitButton_display: true,
  RegisterTOTP_labelSubmitButton_text: 'Invia',
  RegisterTOTP_labelBackToDashboard_display: true,
  RegisterTOTP_labelBackToDashboard_text: 'Torna alla dashboard',
  RegisterTOTP_noticeForTimer_display: true,
  RegisterTOTP_noticeForTimer_text:
    'Inserisci il TOTP (password monouso a tempo) prima che scada il timer. Aggiorna la pagina se il timer scade.',
  RegisterTOTP_infoForTOTP_display: true,
  RegisterTOTP_infoForTOTP_text:
    "La registrazione dell'OTP basato sul tempo richiede un autenticatore come Google Authenticator (disponibile su Android e iOS). Contatta il tuo amministratore per assistenza se necessario.",
  RegisterTOTP_validationMessageTOTPRequired_display: true,
  RegisterTOTP_validationMessageTOTPRequired_text:
    'Il TOTP (password monouso a tempo) a 6 cifre è obbligatorio',
  RegisterTOTP_validationMessageTOTPOnlyDigits_display: true,
  RegisterTOTP_validationMessageTOTPOnlyDigits_text: 'Deve contenere solo cifre',
  RegisterTOTP_validationMessageTOTPSixDigit_display: true,
  RegisterTOTP_validationMessageTOTPSixDigit_text: 'Deve essere esattamente di 6 cifre',
  RegisterTOTP_errorMessageTimerExpire_display: true,
  RegisterTOTP_errorMessageTimerExpire_text:
    'Il tempo è scaduto. Aggiorna la pagina per ottenere un nuovo codice QR.',
  RegisterTOTP_errorMessageProcessFailed_display: true,
  RegisterTOTP_errorMessageProcessFailed_text: 'Impostazione del TOTP fallita. Per favore riprova.',
  RegisterTOTP_errorMessageNetworkIssues_display: true,
  RegisterTOTP_errorMessageNetworkIssues_text: 'Controlla la tua connessione di rete!',
  RegisterTOTP_errorMessageServerIssues_display: true,
  RegisterTOTP_errorMessageServerIssues_text:
    'Impossibile ottenere una risposta! Per favore riprova.',
}

export default { ...main }
