import { DATA } from '../../constants/action-constants'

const initialState = {
  list: {
    organizationList: [],
  },
  organizationListLoaded: 'pending',
}
export default (state = initialState, action) => {
  switch (action.type) {
    case DATA.FETCH_LIST:
      return {
        ...state,
        list: { ...state.list, [action.listType]: action.list },
        [`${action.listType}Loaded`]: true,
      }
    case DATA.RESET_LIST:
      return {
        ...state,
        [`${action.listType}Loaded`]: false,
      }
    default:
      return state
  }
}
