import { ACCOUNT } from "../../constants/action-constants";

const baseValue = { loggedIn: false, user: null };

export default function (state = baseValue, action) {
  switch (action.type) {
    case ACCOUNT.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
      };
    case ACCOUNT.LOGIN_FAILURE:
      return baseValue;
    case ACCOUNT.LOGOUT:
      return baseValue;
    default:
      return state;
  }
}
