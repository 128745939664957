//EN
const main = {
  ValidationMessages_UsernameRequired_display: true,
  ValidationMessages_UsernameRequired_text: 'Username is required',
  ValidationMessages_PreferredUsernameRequired_display: true,
  ValidationMessages_PreferredUsernameRequired_text: 'Preferred Username is required',
  ValidationMessages_PasswordRequired_display: true,
  ValidationMessages_PasswordRequired_text: 'Password is required',
  ValidationMessages_CurrentPasswordRequired_display: true,
  ValidationMessages_CurrentPasswordRequired_text: 'Current Password is required',
  ValidationMessages_NewPasswordRequired_display: true,
  ValidationMessages_NewPasswordRequired_text: 'New Password is required',
  ValidationMessages_ConfirmNewPasswordRequired_display: true,
  ValidationMessages_ConfirmNewPasswordRequired_text: 'Confirm New Password is required',
  ValidationMessages_PasswordDoNotMatch_display: true,
  ValidationMessages_PasswordDoNotMatch_text: 'Passwords do not match!',
  ValidationMessages_PasswordCriteria_display: true,
  ValidationMessages_PasswordCriteria_text:
    "Must contain at least 8 characters (12+ recommended) which includes one uppercase letter, one lowercase letter, one number and one special character (!”#$%&'()*+,-./:;<=>?",
  ValidationMessages_verificationCodeRequired_display: true,
  ValidationMessages_verificationCodeRequired_text: 'Verification Code is required',
}

export default { ...main }
