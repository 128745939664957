//EN
const main = {
  RegisterPIN_titleRegisterPIN_display: true,
  RegisterPIN_titleRegisterPIN_text: 'Register PIN',
  RegisterPIN_label6DigitPin_display: true,
  RegisterPIN_label6DigitPin_text: '6 Digit PIN for Mobile Application',
  RegisterPIN_labelSubmitButton_display: true,
  RegisterPIN_labelSubmitButton_text: 'Submit',
  RegisterPIN_labelBackToDashboard_display: true,
  RegisterPIN_labelBackToDashboard_text: 'Back to Dashboard',
  RegisterPIN_validationMessagePinRequired_display: true,
  RegisterPIN_validationMessagePinRequired_text: 'PIN is required',
  RegisterPIN_validationMessagePinOnlyDigits_display: true,
  RegisterPIN_validationMessagePinOnlyDigits_text: 'Must be only digits',
  RegisterPIN_validationMessagePinSixDigit_display: true,
  RegisterPIN_validationMessagePinSixDigit_text: 'Must be exactly 6 digits',
  RegisterPIN_errorMessageProcessFailed_display: true,
  RegisterPIN_errorMessageProcessFailed_text: 'Setting of PIN failed. Please try again.',
  RegisterPIN_errorMessageNetworkIssues_display: true,
  RegisterPIN_errorMessageNetworkIssues_text: 'Please check your network!',
  RegisterPIN_errorMessageServerIssues_display: true,
  RegisterPIN_errorMessageServerIssues_text: 'Unable to get a response! Please try again.',
}

export default { ...main }
