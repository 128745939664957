//basic
const main = {
    RegisterTOTP_titleRegisterTOTP_display: true,
    RegisterTOTP_titleRegisterTOTP_text: 'Register TOTP',
    RegisterTOTP_labelQRCode_display: true,
    RegisterTOTP_labelQRCode_text: 'QR Code',
    RegisterTOTP_labelTimeRemaining_display: true,
    RegisterTOTP_labelTimeRemaining_text: 'Time Remaining',
    RegisterTOTP_timerUnit_display: true,
    RegisterTOTP_timerUnit_text: 's',
    RegisterTOTP_label6DigitTOTP_display: true,
    RegisterTOTP_label6DigitTOTP_text: '6 Digit TOTP',
    RegisterTOTP_labelSubmitButton_display: true,
    RegisterTOTP_labelSubmitButton_text: 'Submit',
    RegisterTOTP_labelBackToDashboard_display: true,
    RegisterTOTP_labelBackToDashboard_text: 'Back to Dashboard',
    RegisterTOTP_noticeForTimer_display: true,
    RegisterTOTP_noticeForTimer_text: 'Please enter the TOTP before the timer expires. Refresh the page if the timer expires.',
    RegisterTOTP_infoForTOTP_display: true,
    RegisterTOTP_infoForTOTP_text: 
        'Registration of Time-based OTP requires an authenticator such as Google Authenticator (Available on Android and iOS). Contact your administrator for assistance if required.',
    RegisterTOTP_validationMessageTOTPRequired_display: true,
    RegisterTOTP_validationMessageTOTPRequired_text: '6 Digit TOTP is required',
    RegisterTOTP_validationMessageTOTPOnlyDigits_display: true,
    RegisterTOTP_validationMessageTOTPOnlyDigits_text: 'Must be only digits',
    RegisterTOTP_validationMessageTOTPSixDigit_display: true,
    RegisterTOTP_validationMessageTOTPSixDigit_text: 'Must be exactly 6 digits',
    RegisterTOTP_errorMessageTimerExpire_display: true,
    RegisterTOTP_errorMessageTimerExpire_text: 'Time has expired. Please refresh page to get a new QR Code.',
    RegisterTOTP_errorMessageProcessFailed_display: true,
    RegisterTOTP_errorMessageProcessFailed_text: 'Setting of TOTP failed. Please try again.',
    RegisterTOTP_errorMessageNetworkIssues_display: true,
    RegisterTOTP_errorMessageNetworkIssues_text: 'Please check your network!',
    RegisterTOTP_errorMessageServerIssues_display: true,
    RegisterTOTP_errorMessageServerIssues_text: 'Unable to get a response! Please try again.',
}

export default {...main}
