const ENV = process.env.NODE_ENV

const ROOT = '/'
const dashboardAuth = 'auth/drwound/api/dashboard-auth'
const deviceAuth = 'auth/drwound/api/device-auth'
const managementEndPoint =
  ENV === 'production' ? 'admin/drwound/api/management' : '/management/admin/drwound/api/management'

const devURIs = {
  ROOT: ROOT,
  GEOIP_LOCATION: 'https://ipapi.co/json/',
  LOGIN: '/login',
  FORGOT_PASSWORD: 'https://run.mocky.io/v3/a9aad785-78fe-49ff-b6f7-958aab8a4752',
  RESET_PASSWORD: 'https://run.mocky.io/v3/48cdc4e3-6a40-45b6-9d86-573abaa6a0d8',
  INITIAL_PASSWORD_CHANGE: 'https://run.mocky.io/v3/077c9b49-90a4-4a06-b7b7-5c83dde95ca0', //success
  PASSWORD_CHANGE: 'https://run.mocky.io/v3/077c9b49-90a4-4a06-b7b7-5c83dde95ca0', //success
  SET_PIN: 'https://run.mocky.io/v3/e149739a-6717-4e34-8942-c8d0fce6f77e', //success
  REQUEST_SECRET_KEY: 'https://run.mocky.io/v3/bd47e70a-4b18-4560-812f-717f9e25d730',
  CONFIRM_SECRET_KEY: 'auth/drwound/api/mfa/confirm-secret-key',
  VALIDATE_TOTP: 'auth/drwound/api/mfa/validate-totp',

  ORGANIZATION_LIST: 'https://run.mocky.io/v3/fb3bce83-aba4-4ae2-8456-be5411194551', //exist
  // ORGANIZATION_LIST: 'https://run.mocky.io/v3/643390c7-9779-4489-907b-1e3a552cbff2', //access-denied
}

const prodURIs = {
  ROOT: ROOT,
  GEOIP_LOCATION: 'https://ipapi.co/json/',
  LOGIN: dashboardAuth + '/login',
  FORGOT_PASSWORD: dashboardAuth + '/forgot-password',
  RESET_PASSWORD: dashboardAuth + '/reset-password',
  INITIAL_PASSWORD_CHANGE: dashboardAuth + '/initial-password-change',
  PASSWORD_CHANGE: dashboardAuth + '/password-change',
  SET_PIN: deviceAuth + '/register-pin',
  REQUEST_SECRET_KEY: 'auth/drwound/api/mfa/request-secret-key',
  CONFIRM_SECRET_KEY: 'auth/drwound/api/mfa/confirm-secret-key',
  VALIDATE_TOTP: 'auth/drwound/api/mfa/validate-totp',
  ORGANIZATION_LIST: managementEndPoint + '/organizations',
}

const URIs = ENV === 'production' ? prodURIs : devURIs
// const URIs = devURIs;

export default URIs
