import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";

import useViewport from "../hooks/useViewport";
import { login } from "../store/actions/account-action";
// import { setThemeLocale } from "../store/actions/ui-actions";
import routeConstants from "../constants/route-constants";
import customization from '../utils/customization-utils'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  demo1: {
    // backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
  },
  inputField: {
    marginBottom: 32,
  },
  input: {
    backgroundColor: "white",
    border: "1px solid #C2C5C7",
    borderRadius: 8,
    padding: "8px 8px",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
    },
  },
  label: {
    color: "black",
  },
  menuPaper: {
    maxHeight: 500,
  },
  link: {
    color: "#0D3082",
    textAlign: "center",
    marginTop: 18,
    display: "block",
    textDecoration: "none",
  },
  logo: {
    width: 'auto',
    height: 70,
    marginTop: 32,
  },
  loginButton: {
    whiteSpace: "nowrap",
    minWidth: "100%",
    // textTransform: "none",
    marginTop: 9,
    marginBottom: 16,
    borderRadius: 8,
    padding: "8px 8px",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: 14,
    },
    height: 48,
    fontSize: 16,
  }
}));

const Login = () => {
  const { width } = useViewport();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search)
  const next = params.get('next')
  const selectedLocale = useSelector((state) => state.ui.locale)
  const [showPassword, setShowPassword] = useState(false)
  // const userLocale =
  // navigator.languages && navigator.languages.length
  //   ? navigator.languages[0]
  //   : navigator.language;

  // useEffect(() => {
  //   if (/^de\b/.test(userLocale)) {
  //     dispatch(setThemeLocale('DE'))
  //   } else if(/^es\b/.test(userLocale)){
  //     dispatch(setThemeLocale('ES'))
  //   } else if(/^fr\b/.test(userLocale)){
  //     dispatch(setThemeLocale('FR'))
  //   } else {
  //     dispatch(setThemeLocale('EN'))
  //   }
  // },[])

  const c = customization(selectedLocale)

  const validationSchema = Yup.object().shape({
    unique_id: Yup.string().required(c.ValidationMessages_UsernameRequired_text),
    password: Yup.string().required(c.ValidationMessages_PasswordRequired_text),
  });

  const handleTogglePassword = () => setShowPassword(showPassword => !showPassword)

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="column"
        >
          <Box style={{ maxWidth: 500 }}>
            {/* <img
              src={
                process.env.NODE_ENV === 'production'
                  ? '/web/drwound/login/images/hartmann_logo.png'
                  : 'http://ph.kronikare.ai/web/drwound/login/images/hartmann_logo.png'
              }
              alt="vintens logo"
              className={classes.logo}
            /> */}
            <Typography
              variant="h4"
              style={{
                fontWeight: 500,
                marginBottom: 14,
                marginTop: 72,
                textAlign: "center",
              }}
            >
              {c.Login_titleLogin_text}
            </Typography>
            <Formik
              enableReinitialize={true}
              initialValues={{
                unique_id: "",
                password: "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)
                dispatch(login(values.unique_id, values.password, next, selectedLocale))
                .then(()=>{setSubmitting(false)})
              }}
              validationSchema={validationSchema}
            >
              {({ errors, touched, isSubmitting, values, setFieldValue }) => (
                <Form>
                  <Field
                    component={TextField}
                    className={classes.inputField}
                    label={c.Login_labelUsername_text}
                    name="unique_id"
                    type="text"
                    fullWidth
                    onChange={(event) => {
                      const newValue = event.target.value.replace(/ /g, '')
                      setFieldValue('unique_id', newValue)
                    }}
                    error={errors.unique_id && touched.unique_id}
                    helperText={
                      errors.unique_id && touched.unique_id
                        ? errors.unique_id
                        : null
                    }
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Field
                    component={TextField}
                    className={classes.inputField}
                    label={c.Login_labelPassword_text}
                    type={showPassword?'text':'password'}
                    name="password"
                    fullWidth
                    error={errors.password && touched.password}
                    helperText={
                      errors.password && touched.password
                        ? errors.password
                        : null
                    }
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton onClick={handleTogglePassword} >
                            {showPassword ? <Visibility />:<VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    className={classes.loginButton}
                  >
                    {c.Login_labelLoginButton_text}
                  </Button>
                  <Link
                    to={routeConstants.FORGOTPASSWORD}
                    className={classes.link}
                  >
                    <Typography>
                      {c.Login_labelForgotPasswordLink_text}
                    </Typography>
                  </Link>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default Login;
